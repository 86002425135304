import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetProductCompareMutation } from "../../../../store/user/userProductApiSlice";

export const DetailComparison = () => {
    const location = useLocation()
    const productId : any = new URLSearchParams(location.search).get('product')
    const navigate = useNavigate()
    const [selectProduct, setSelectProduct] = useState<any>(productId)
    const [getProductCompareApi] = useGetProductCompareMutation()
    const [product, setProduct] = useState<any>([])
    const [compare, setCompare] = useState<any>([])
    const [showDiffence, setShowDifference] = useState<boolean>(false)
    const getList = async() => {
        const result : any = await getProductCompareApi({productId : selectProduct})
        if(result){setProduct(result.data.productList); setCompare(result.data.List)}
    }
    const handleDelProduct = (id : any) => {
        if(selectProduct?.split(',')?.length === 1){
            alert("Please select the product again"); navigate('/productComparison')
        }else{
            const arraySelectProduct = selectProduct?.split(',')
            const newData = arraySelectProduct?.filter((x:any)=> x !== id)
            const fomatData = newData?.join(',')
            setSelectProduct(fomatData)
            navigate(`${location.pathname}?product=${fomatData}`)
        }
    }
    
    useLayoutEffect(()=>{getList(); setShowDifference(false)},[productId])
    
    return(
        <>
        <div className={`subPage product_comparison_view active_${selectProduct?.split(',')?.length}`}>
            <div className="contentBox">
                <div className="top">
                    <div className="left">
                        <h2>
                            Product
                            <span>Comparison</span>
                        </h2>
                        <div>
                            <input type="checkbox" id="only_differences" checked={showDiffence}/>
                            <label htmlFor="only_differences" onClick={()=>setShowDifference(!showDiffence)}>Show only differences</label>
                        </div>
                    </div>
                    <div className="right">
                        <ul>
                            {product?.map((product:any) => (
                            <li key={product?.productId}>
                                <button onClick={()=>handleDelProduct(product?.productId)}></button>
                                <div className="imgBox">
                                    <img src={product?.productImage} alt="GX4K"/>
                                </div>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/* <!-- top Fin --> */}

                <div className="tableWrap">
                    <table>
                        <thead>
                            <tr>
                                <th>Model</th>
                                {compare[0]?.optionName?.map((list:any,index:number)=>(
                                    <th key={index}>{list}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {compare?.slice(1)?.map((list:any)=>{
                                return(
                                <tr style={{display : specsSame(list?.optionName) && showDiffence ? 'none' : ''}}>
                                    <th>{list?.specName}</th>
                                    {list?.optionName?.map((option:any, index:number)=>{
                                        return(
                                            <td key={index}>{option ? option : ''}</td>
                                        )
                                    })}
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                
                <div className="btnBox">
                    <button className="blackBtn" onClick={()=>navigate('/productComparison')}>Back</button>
                </div>
            </div>
        </div>
        </>
    )
}

const specsSame = (spec : any) => {return spec?.every((value : any) => value === spec[0])}
