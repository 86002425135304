import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useGetContentsListMutation, useUpdContentsActiveStatusMutation } from "../../../../../../store/admin/adminContentsApiSlice";
export interface Props {data:any, refetch : Function}
export const ListContents:React.FC<Props> = ({data, refetch}) => {
    const navigate = useNavigate()
    const [updContentsActiveStatus] = useUpdContentsActiveStatusMutation()
    const handleChangeStatus = async(id : any , status : string) => {
        const formData = new FormData()
        formData.append('prom_idx', id)
        formData.append('promActiveStatus', status === 'Y' ? 'N' : 'Y')
        const res : any = await updContentsActiveStatus(formData)
        if(res.data.result === true) refetch()
    }
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr className="active" key={list?.idx}>
                <td onClick={()=>navigate(`/admin/contents/content/${list?.idx}`)}>
                    <img src={list?.pdsFile} alt="모델링 썸네일 이미지"/>
                </td>
                <td onClick={()=>navigate(`/admin/contents/content/${list?.idx}`)}>
                    <span className="ellipsis">{list?.contents_title}</span>
                </td>
                <td onClick={()=>navigate(`/admin/contents/content/${list?.idx}`)}>{list?.contents_mdate}</td>
                <td>
                    <span className="toggleSwitch">
                        <input
                            type="checkbox"
                            id={`contents_${list?.idx}`}
                            className="toggleIpt"
                            checked={list?.share_yn==='Y'?true:false}
                            onChange={(e)=>handleChangeStatus(list?.idx, list?.share_yn)}
                            hidden
                        /> 
                        <label htmlFor={`contents_${list?.idx}`} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}