import React, { useEffect, useState } from "react";
import { useGetCommonCountryListMutation } from "../../../store/user/commonApiSlice";
import Select from 'react-select';
export interface Props {data : any ,setData : Function}
export const ContactSection_1:React.FC<Props> = ({data, setData}) => {
    const [countryList, setCountryList] = useState<any>([])
    const [getCommonCountryListApi]:any = useGetCommonCountryListMutation()
    const handleChange = (e:any) => {
        const {type, name , value} = e.target;
        setData((prev:any) => ({...prev, [name] : value}))
    }
    const handleSelect = (e:any) => {
        setData((prev:any) => ({...prev, country : e.value}))
    }
    const getCountry = async() => {
        const result : any = await getCommonCountryListApi()
        if(result.data.result === true){
            const formatData = result.data?.list.map((list:any)=>({
                value : parseInt(list?.idx), label : list.country
            }))
            setCountryList(formatData)
        }
    }
    useEffect(()=>{getCountry()},[])
    return(
        <>
        <div className="inputBox">
            <label htmlFor="">Full Name<span> *</span></label>
            <input type="text" name="name" id="" value={data?.name} placeholder="placeholder" onChange={handleChange} required/>
        </div>
        <div className="inputBox">
            <label htmlFor="">Email Address<span> *</span></label>
            <input type="text" name="email" id="" value={data?.email} placeholder="placeholder" onChange={handleChange} required/>
        </div>
        <div className="inputBox">
            <label htmlFor="">Country<span> *</span></label>
            <div className="selectBox">
                <Select
                value={countryList?.find((x:any)=>x.value === data?.country)}
                name={'country'}
                options={countryList}
                onChange={handleSelect}
                isSearchable={true}
                placeholder={'Please Choose a Country'}
                />
            </div>
        </div>
        </>
    )
}