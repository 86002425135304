import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductsItemFilter } from "../../../../components/user/products/all-item/list-filter";
import { useGetProductByAll2Mutation } from "../../../../store/user/userProductApiSlice";
export interface SelectType {channel : boolean, resoluation : boolean, features : boolean}
export const ProductsAllItem = () => {
    const location = useLocation()
    const channel : any = new URLSearchParams(location.search).get('ch') || ''
    const resolution : any = new URLSearchParams(location.search).get('rs') || ''
    const features : any = new URLSearchParams(location.search).get('ft') || ''
    const navigate = useNavigate()
    const [data, setData] = useState<any>([])
    const [getProductByAll2Api] = useGetProductByAll2Mutation()
    const [param, setParam] = useState<any>({channelType : channel, resolutionType : resolution, featuresType : features, page : 1, size : 10})
    const getList = async() => {
        const result : any = await getProductByAll2Api(param)
        if(result.data.Result === 'true'){
            setData(result.data.List)
        }
    }
    const reArr : any = [...data] || [];
    const itemM = reArr?.find((x:any) => x?.productId === '38');
    if(itemM){
        const index = reArr?.indexOf(itemM)
        reArr?.splice(index , 1);
        reArr?.unshift(itemM);
    }
    useEffect(()=>{setParam((prev:any)=>({...prev, channelType : channel, resolutionType : resolution, featuresType : features}))},[channel,resolution,features])
    useEffect(()=>{getList()},[param.channelType, param.resolutionType, param.featuresType])
    return(
        <>
        <div className="grayBox">
            <ProductsItemFilter
                channel={channel}
                resolution={resolution}
                features={features}
            />
            </div>
            <div className="contentBox">
                <div className="titleBox">
                    <h2>PRODUCT INFORMATION</h2>
                </div>
                <ul>
                    {reArr && reArr?.map((list:any) => {
                        return (
                            <>
                            <li key={list?.productId}>
                                <div>
                                    <div className="left" onClick={()=>navigate(`/products/${list?.productId}?product=${list?.productName.replace(/ /g,"-")}`)}>
                                        <div className="imgBox">
                                            <img src={list?.productWhiteImageFile} alt="FineVu GX4K"/>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <h3 onClick={()=>navigate(`/products/${list?.productId}?product=${list?.productName.replace(/ /g,"-")}`)}>{list?.productName}</h3>
                                        <div dangerouslySetInnerHTML={{__html : list?.icons}}></div>
                                    </div>
                                </div>
                            </li>
                            </>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}