import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
interface DataType {email : string, serialNumber : string}
export const FindPassword = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [data, setData] = useState<DataType>({email : '', serialNumber : ''})
    const handleTransPage = (url : string) => {
        if(location.pathname !== url) {
            navigate(url)
        }
    }
    const handleChange = (e:any) => {
        const {name, value} = e.target
        setData((prev)=>({...prev, [name] : value}))
    }
    const handleFindPassword = async() => {

    }
    return(
        <>
        <div className="contentBox">
            <h2>Find Password</h2>
            <p>Please change the password after email authentication.</p>

            <div className="formBox">
                <div className="inputWrap">
                    <div className="inputBox">
                        <label htmlFor="">Email</label>
                        <input type="text" name="email" onChange={handleChange} placeholder="Your Name" required/>
                    </div>
                    <div className="inputBox">
                        <label htmlFor="">Serial Number</label>
                        <input type="password" name="serialNumber" onChange={handleChange} placeholder="Serial Number" required/>
                    </div>
                </div>

                <button className="orangeBtn" onClick={handleFindPassword}>Send authentication mail</button>
                
                <div className="btnBox">
                    <button onClick={()=>handleTransPage('/support/warrentyService/findEmail')}>Find Email</button>
                    <button onClick={()=>handleTransPage('/support/warrentyService/myDashcam')}>Log In</button>
                    <button onClick={()=>handleTransPage('/support/warrentyService/prRegistration')}>Sign Up</button>
                </div>
            </div>
        </div>

        <div className="alertPopUp popUp_active">
            <div>
                <div>
                    <div className="editBox">
                        <p>
                            We have sent an authentication email.
                            <strong>Please change the password after authentication.</strong>
                        </p>
                    </div>
                </div>
                <div className="only">
                    <button className="orangeBtn">Confirm</button>
                </div>
            </div>
        </div>
        </>
    )
}