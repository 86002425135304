import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/admin/country";

export const adminCountryApiSlice = createApi({
  reducerPath: "adminCountryApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 국가 리스트 불러오기
    getCountryList : builder.mutation({
      query : (param : {page: any, size : number, keyword: string, sortOrder : string, sortColumn : string}) => {
        return{
          url : `/getCountryLists.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 국가 저장
    setCountry : builder.mutation({
        query : (body : any) => {
            return{
                url : '/setCountrys.php', method :'post', body
            }
        }
    }),
    // 국가 상세보기
    getCountryDetail : builder.mutation({
        query : (param : {idx : string}) => {
            return{
                url : `/getCountryDetails.php?idx=${param.idx}`
            }
        }
    }),
    // 국가 수정하기
    uptCountry : builder.mutation({
        query : (body : any) => {
            return {
                url : '/uptCountrys.php', method : 'post', body
            }
        }
    }),
    // 국가 공개상태 수정
    uptCountryStatus : builder.mutation({
        query : (body : any) => {
            return{
                url : '/uptCountrysShare.php', method : 'post', body 
            }
        }
    }),
    
  }),
});

export const {
  useGetCountryListMutation, useSetCountryMutation, useGetCountryDetailMutation, useUptCountryMutation, useUptCountryStatusMutation
} = adminCountryApiSlice;