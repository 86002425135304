import React, { useEffect, useRef, useState } from "react";
import { ManualBox } from "../../../../components/user/products/detail/manualsBox/products-manualsBox";
import { useNavigate, useParams } from "react-router-dom";
import { GX4K } from "../../../../components/user/products/detail/product/GX4K";
import { GX1000 } from "../../../../components/user/products/detail/product/GX1000";
import { GX400 } from "../../../../components/user/products/detail/product/GX400";
import { GX7000 } from "../../../../components/user/products/detail/product/GX7000";
import { GX300 } from "../../../../components/user/products/detail/product/GX300";
import { GX5000 } from "../../../../components/user/products/detail/product/GX5000";
import { GX33 } from "../../../../components/user/products/detail/product/GX33";
import { GX30 } from "../../../../components/user/products/detail/product/GX30";
import { GX35 } from "../../../../components/user/products/detail/product/GX35";
import { GX1000Cloud } from "../../../../components/user/products/detail/product/GX1000Cloud";
import { GX400_1CH } from "../../../../components/user/products/detail/product/GX400_1CH";
import { GX35Cloud } from "../../../../components/user/products/detail/product/GX35Cloud";

export const ProductsDetail = () => {
    const naivate = useNavigate()
    const {productId} = useParams()
    const [tabIndex, setTabIndex] = useState<number>(1)
    const scrollRef = [useRef<any>(null), useRef<any>(null)]
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
        <div className="contentBox">
            {productId === '1' ? <GX4K/> : ''}
            {productId === '2' ? <GX1000/> : ''}
            {productId === '3' ? <GX400/> : ''}
            {productId === '30' ? <GX7000/> : ''}
            {productId === '31' ? <GX300/> : ''}
            {productId === '32' ? <GX5000/> : ''}
            {productId === '33' ? <GX33/> : ''}
            {productId === '34' ? <GX30/> : ''}
            {productId === '35' ? <GX35/> : ''}
            {productId === '36' ? <GX1000Cloud/> : ''}
            {productId === '37' ? <GX400_1CH/> : ''}
            {productId === '38' ? <GX35Cloud/> : ''}
        </div>
        </>
    )
}