import React, { useState } from "react";

export const Section_2 =()=> {
    const [state, setState] = useState<number>(0)
    const handleActive = (index:number) => {
        if(index === state) setState(0)
        else setState(index)
    }
    return(
        <>
        <section className="mainSection_2">
            <div>
                <h2>
                    <span>About FineVu</span>
                    No.1 Market Share Brand in DashCam Industry in Korea
                </h2>

                <ul>
                    <li>
                        <img src="/assets/images/mainSection_2_1.png" alt="About FineVu" />
                    </li>
                    <li>
                        <img src="/assets/images/mainSection_2_2.png" alt="About FineVu" />
                    </li>
                    <li>
                        <img src="/assets/images/mainSection_2_3.png" alt="About FineVu" /> 
                    </li>
                </ul>
            </div>
        </section>

        
        <section className="mainSection_8">
            <div>
                <h2>Our Features</h2>
                <ul>
                    <li onClick={()=>handleActive(1)}>
                        <span></span>
                        <dl>
                            <dt>Night Vision</dt>
                            {state === 1 &&
                            <dd>
                                Smart AI system assesses 
                                light environment and finds 
                                the perfect time to switch it on. 
                                Keeping you completely hand-free.
                            </dd>
                            }
                        </dl>
                    </li>
                    <li onClick={()=>handleActive(2)}>
                        <span></span>
                        <dl>
                            <dt>HDR</dt>
                            {state === 2 &&
                            <dd>
                                Optimized with high dynamic range, 
                                exposure is balanced for 
                                both-front and rear keeping all 
                                the details intact.
                            </dd>
                            }
                        </dl>
                    </li>
                    <li onClick={()=>handleActive(3)}>
                        <span></span>
                        <dl>
                            <dt>Speed Cam Alert</dt>
                            {state === 3 &&
                            <dd>
                                FineVu safety camera database is 
                                always up to date. 
                                Download the quarterly updates, 
                                and get informed  
                                with FineVu's voice & visual alerts. 
                                Worry no more about 
                                accidents and speeding tickets.
                            </dd>
                            }
                        </dl>
                    </li>
                    <li onClick={()=>handleActive(4)}>
                        <span></span>
                        <dl>
                            <dt>ADAS PLUS</dt>
                            {state === 4 &&
                            <dd>
                                ADAS 
                                (Advanced Driver Assistance Systems) 
                                helps safe driving with sound alerts. 
                                The system evaluates 
                                its surroundings with integrated 
                                sensors, and warns a driver 
                                if necessary.
                            </dd>
                            }
                        </dl>
                    </li>
                </ul>
            </div>
        </section>
        </>
    )
}