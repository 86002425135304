import React from "react";
import { useUpdMainSpecRemarkActiveStatusMutation } from "../../../store/admin/adminProductSpecApiSlice";
export interface Props {data:any,specInfo:any,specTypeCode:string,getList:Function,setSpecInfo:Function}
export const RemarkRightList:React.FC<Props> = ({data, specInfo, specTypeCode, getList, setSpecInfo}) => {
    const [updMainSpecRemarkActiveStatusApi] = useUpdMainSpecRemarkActiveStatusMutation()
    const handleSelectRemark = (x:any) => {
        setSpecInfo({isActive : true, id : x?.remarkIdx, optionName : x?.remarkEn})
    }
    const handleChangeRemarkStatus = async(x:any) => {
        const formData : any = new FormData()
        formData.append('remarkIdx', x?.remarkIdx)
        formData.append('remarkActiveStatus', x?.remarkActivekStatus==='Y'?'N':'Y')
        const result : any = await updMainSpecRemarkActiveStatusApi(formData)
        if(result.data.result===true){getList()}
    }
    return(
        <>
        <tbody>
            {data?.map((x:any)=>(
            <tr key={x?.remarkIdx}>
                <td><span className="ellipsis">{x?.remarkIdx}</span></td>
                <td><span className="ellipsis">{x?.remarkEn}</span></td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id="member_statUs_1" className="toggleIpt" onChange={()=>handleChangeRemarkStatus(x)} checked={x?.remarkActivekStatus === 'Y' ? true : false} hidden/> 
                        <label htmlFor="member_statUs_1" className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
                <td>
                    <button onClick={()=>handleSelectRemark(x)}></button>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}