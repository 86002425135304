import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/user";

export const userApiSlice = createApi({
  reducerPath: "userApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 회원가입 
    setUser : builder.mutation({
        query : (body : any) => {
            return{
                url : '/user/setUsers.php', method : 'post', body
            }
        }
    }),
    // 로그인
    getUserLogin : builder.mutation({
        query : (body : any) => {
            return{
                url : '/user/getUserLogin.php', method : 'post', body
            }
        }
    }),
    // 문의하기
    setUserInquriy : builder.mutation({
        query : (body : any) => {
            return{
                url : '/inquiry/setUserInquirys.php', method : 'post', body
            }
        }
    }),
    // 아이디 (이메일) 찾기
    getUserEmail : builder.mutation({
        query : (param : {name : string, serialNumber : string}) => {
            return{
                url : `/user/getUserEmail.php?userName=${param.name}&serialNo=${param.serialNumber}`
            }
        }
    }),
    // 사용자 등록한 제품 관련 문서 파일 가져오기
    getUserProductDocs : builder.mutation({
        query : (param : {userId : any, docType : string, langType : string}) => {
            return{
                url : `/user/getUserProductDocs.php?userId=${param.userId}&docType=${param.docType}&langType=${param.langType}`
            }
        }
    }),
    // 유저 정보 조회
    getUserInfo : builder.mutation({
        query : (param : {idx : any}) => {
            return{
                url : `/user/getUserInfo.php?idx=${param.idx}`
            }
        }
    })
  }),
});

export const {
  useSetUserMutation, useGetUserLoginMutation, useSetUserInquriyMutation, useGetUserEmailMutation, useGetUserProductDocsMutation, useGetUserInfoMutation
} = userApiSlice;