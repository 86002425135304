import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import { useDelInquirysMutation, useGetInquiryDetailMutation } from "../../../../store/admin/adminInquiryApiSlice";

export const ViewInquiry = () => {
    const {inquiryId} : any = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState<any>([])
    const [getInquiryDetailApi] = useGetInquiryDetailMutation()
    const [delInquirysApi] = useDelInquirysMutation()
    const getDetail = async() => {
        const result : any = await getInquiryDetailApi({idx : inquiryId})
        if(result.data.result===true){setData(result.data)}
    }
    const handleDelInquiry = async() => {
        const confirm = window.confirm("해당 문의를 삭제하시겠습니까?")
        if(confirm){
            const result : any = await delInquirysApi({idx : data?.idx})
            if(result.data.result === true){navigate(-1)}
        }
    }
    useEffect(()=>{getDetail()},[inquiryId])
    return(
        <>
        <div className="contentBox inquiryView">
            <h3>Inquiry</h3>
            <div className="flexWrap">
                <h4>Inquiry View</h4>
                
                <div>
                    <button className="blackBtn" onClick={()=>navigate(-1)}>목록으로</button>
                    <button className="redBtn" onClick={handleDelInquiry}>문의삭제</button>
                    <button className="blueBtn">답변완료</button>
                </div>
            </div>
            
            <div className="content">
                <div className="titleBox">
                    <span className="answer_before">{data?.action_state}</span>
                    <span className="answer_title">{data?.inquiry_title}</span>
                    <span className="date"><Moment format="YYYY.MM.DD">{data?.inquiry_mdate}</Moment></span>
                </div>
                <ul>
                    <li>
                        <span>이름:</span>
                        <span> {data?.inquiry_username}</span>
                    </li>
                    <li>
                        <span>이메일:</span>
                        <span> {data?.inquiry_email}</span>
                    </li>
                    <li>
                        <span>국적:</span>
                        <span> {data?.inquiry_country}</span>
                    </li>
                </ul>

                <ul>
                    <li>
                        <span>문의유형</span>
                        <div>
                            <span>{data?.inquiry_type}</span>
                        </div>
                    </li>
                    <li>
                        <span>문의내용</span>
                        <div>
                            {data?.inquiry_msg}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}