import React from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack'
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {page:any; totalCount:number; size : number; setPage : Function}
export const Paginate:React.FC<Props> = ({page,totalCount,size, setPage}) => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const navigate = useNavigate()
    const selPage = (e:React.ChangeEvent<unknown>, pageNumber : number) => {
       setPage(pageNumber)
    }
    
    return(
        <>
        <Stack spacing={2}>
            <Pagination page={parseInt(page)} count={Math.ceil(totalCount/size)} shape="rounded" onChange={selPage}/>
        </Stack>
        </>
    )
}