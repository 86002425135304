import React, { useRef, useState } from "react";
import { ManualBox } from "../manualsBox/products-manualsBox";
export const tabList = [
    {idx: 1, title : 'Features'},
    {idx: 2, title : 'Description'},
    {idx: 3, title : "What's In The Box"},
    {idx: 4, title : 'Specifications'},
    {idx: 5, title : 'Manuals & Firmware'},
    {idx: 6, title : "User's Review"},
]
export const GX300 = () => {
    const [state, setState] = useState<any>({first : 1, second : 1})
    const [tabIndex, setTabIndex] = useState<number>(1)
    const scrollRef = [
        useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)
    ];
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
        <div className="product_view gx300">
            <div className="bgBox" style={{backgroundImage: "url('/assets/images/banner_GX300.png')"}}>
                <div className="centerBox">
                    <h2><span>GX</span>300</h2>
                    <p>PERFECT VISIBILITY, QHD DASHCAM</p>
                    <ul>
                        <li onClick={()=>handleScroll(0)}>QHD/FHD</li>
                        <li onClick={()=>handleScroll(1)}>SONY STARVIS Sensor</li>
                        <li onClick={()=>handleScroll(2)}>Built-in GPS & Wi-Fi</li>
                        <li onClick={()=>handleScroll(3)}>Speed Camera Alert</li>
                        <li onClick={()=>handleScroll(4)}>5X Efficiency</li>
                        <li onClick={()=>handleScroll(5)}>ADAS</li>
                        <li onClick={()=>handleScroll(6)}>HDR</li>
                        <li onClick={()=>handleScroll(7)}>Faster Dual Core CPU</li>
                        <li onClick={()=>handleScroll(8)}>Installation Angle Guide</li>
                    </ul>
                </div>

                <div className="scrollDownBox">
                    <div>
                        <span></span>
                    </div>
                    <span>SCROLL</span>
                </div>
            </div>
            {/* <!-- bgBox Fin --> */}

            <div className="tabWrap">
                <div className="tabBtnWrap">
                    <div className="tabBtnBox">
                        {tabList.map((list:{idx:number,title:string})=>(
                            <button className={tabIndex===list?.idx?'active':''} onClick={()=>setTabIndex(list?.idx)}>
                                {list?.title}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={`features ${tabIndex === 1? 'active' : ''}`}>
                        <div className="sliderWrap">
                            <ul>
                                <li>
                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX300_1.png" alt="GX300"/>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- slider fin --> */}
                            <div className="dotsBox">
                                <span></span>
                                <span className="active"></span>
                                <span></span>
                            </div>
                        </div>
                        {/* <!-- sliderArea Fin --> */}

                        <div className="iconBox">
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/qhdfhd.png" alt="Front QHD Rear FHD"/>
                                    </div>
                                    <span>
                                        QHD/FHD
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/Sony_STARVIS.png" alt="SONY STARVIS Sensor"/>
                                    </div>
                                    <span>
                                        SONY STARVIS<br/>
                                        Sensor
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/Built_inGPS.png" alt="Built-in GPS&Wi-Fi"/>
                                    </div>
                                    <span>
                                        Built-in<br/>
                                        GPS&Wi-Fi
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/SpeedCameraAlert.png" alt="Speed Camera Alert"/>
                                    </div>
                                    <span>
                                        Speed Camera<br/>
                                        Alert
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/5XEfficiency.png" alt="5X Efficiency"/>
                                    </div>
                                    <span>5X Efficiency</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/ADAS.png" alt="ADAS"/>
                                    </div>
                                    <span>ADAS</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/hdr_icon.png" alt="HDR"/>
                                    </div>
                                    <span>HDR</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/FasterDualCoreCPU.png" alt="Faster Dual Core CPU"/>
                                    </div>
                                    <span>
                                        Faster<br/>
                                        Dual Core CPU
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/installationAngleGuide.png" alt="Installation Angle Guide"/>
                                    </div>
                                    <span>
                                        Installation<br/>
                                        Angle Guide
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- iconBox Fin --> */}

                        <section className="section_1" ref={scrollRef[0]}>
                            <h3>
                                <span>QHD</span>
                                SECURING CLEAR IMAGES WITH FRONT QHD QUALITY
                            </h3>
                            <p>
                                Ultra-high definition front QHD is 4 times higher than HD quality.<br/>
                                It cleary records the surrounding situation.
                            </p>
                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.first}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 1}))}>
                                        <span>Front</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 2}))}>
                                        <span>Rear</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tabContentBox">
                                <div className={state.first === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx300_section1_front.png" alt="front"/>
                                    </div>
                                    <p>This photo was taken in QHD quality. The license plate was mosaicized to protect personal information.</p>
                                </div>
                                <div className={state.first === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx300_section1_rear.png" alt="rear"/>
                                    </div>
                                    <p>
                                        This photo was taken in FHD quality. The license plate was mosaicized to protect personal information.
                                        <mark>* The quality of recording may vary depending on conditions (including camera angle, windshield conditions, and light conditions).</mark>
                                    </p>
                                </div>
                            </div>
                        </section>

                        <section className="section_2" ref={scrollRef[2]}>
                            <h3>
                                <span>Built-in Wi-Fi</span>
                                EASY-TO-USE WITH SMARTPHONE APP
                            </h3>
                            <p>
                                Connect your dashcam to your smartphone with the built-in Wi-Fi.<br/>
                                Major functions of the FineVu Wi-Fi app are easily usable with a smartphone:<br/>
                                Live Streaming of driving video, downloading recorded videos and new firmware updates with alarm message!
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section2.png" alt="Built-in Wi-Fi"/>
                            </div>

                            <ul>
                                <li>* You can download the FineVu Wi-Fi app in Google Play/App Store.</li>
                                <li>* For detailed installation methods, please refer to the manual.</li>
                            </ul>
                        </section>

                        <section className="section_3" ref={scrollRef[3]}>
                            <h3>
                                <span>SPEED CAMERA ALERT</span>
                                THE LATEST INFORMATION OF SPEED CAMERA FOR<br/>
                                SAFE DRIVING
                            </h3>
                            <p>
                                The speed Camera Alert is a built-in function supporting voice guidance with information on 7 types of Camera is including speed, red-light,<br/>
                                and average speed camera. You can always enjoy safe driving by using the quarterly-updated DB service.
                            </p>

                            <div className="imgBox">
                                <img src="/assets/images/gx300_section3.png" alt="SPEED CAMERA ALERT"/>
                            </div>
                            <ul>
                                <li>* The latest data is updated quarterly. Its interconnection with the FineVu Wi-Fi app is informed via a popup message.</li>
                                <li>* This feature may not be supported depending on the countries.</li>
                            </ul>
                        </section>

                        <section className="section_4" ref={scrollRef[6]}>
                            <h3>
                                <span>HDR</span>
                                ALWAYS SUPPORT CLEAR VIDEO WITH<br/>
                                APPLICATION OF HDR
                            </h3>
                            <p>
                                HDR is a function that displays objects clearly by automatically combining two videos with different luminance settings.<br/>
                                It is effective at night or in a place with high contrast differences where it is difficult to identify a license plate.<br/>
                                Moreover, it is more useful as it allows you to record videos clearly even where luminance varies rapidly.
                            </p>

                            <div className="imgBox">
                                <img src="/assets/images/gx300_section4_1.png" alt="HDR"/>
                            </div>
                            <div className="imgBox">
                                <img src="/assets/images/detail/gx300_section4_2.png" alt="HDR"/>
                            </div>
                            <ul>
                                <li>* The quality of recording may vary depending on conditions (including camera angle, windshield conditions, and light conditions).</li>
                                <li>* Front HDR can be turned ON/OFF in the menu settings.</li>
                                <li>* D-HDR function is automatically applied to the rear.</li>
                            </ul>

                        </section>

                        <section className="section_5" ref={scrollRef[1]}>
                            <h3>
                                <span>SONY STARVIS Sensor</span>
                                BRIGHTER AND CLEARER PERFORMANCE AT NIGHT!<br/>
                                SONY STARVIS IMX335 SENSOR
                            </h3>
                            <p>
                                The detail of the dashcam image depends on the image sensor. Cutting-edge SONY STARVIS IMX335 sensor realizes much brighter and<br/>
                                clearer performance even at night. Drive safe even in dark roads with images that are brighter and clearer than you can see.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail/gx300_section5.png" alt="SONY STARVIS Sensor"/>
                            </div>
                            <p>* This image sensor is applied to front camera.</p>
                        </section>

                        <section className="section_6">
                            <h3>
                                <span>AUTO NIGHT VISION</span>
                                AUTOMATICALLY FINDS OPTIMAL VIDEO QUALITY
                            </h3>
                            <p>
                                AUTO NIGHT VISION enables FineVu GX300 to record clear and balanced video in tough-to-capture environments like night time,<br/>
                                underground parking lots and tunnels, as well as very bright day light. AUTO NIGHT VISION provides the optimal video quality (brightness
                                and clarity) by automatic tuning in any conditions.
                            </p>

                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.second}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 1}) )}>
                                        <span>Night</span>
                                    </button>
                                    <button  onClick={()=>setState((prev:any)=>({...prev, second : 2}) )}>
                                        <span>Day</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tabContentBox">
                                <div className={state.second === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx300_section6_night.png" alt="night"/>
                                    </div>
                                </div>
                                <div className={state.second === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx300_section6_day.png" alt="day"/>
                                    </div>
                                </div>
                            </div>
                            <p>
                                The license plate is covered with mosaic.
                                <mark>* The quality of recording may vary depending on conditions (including camera angle, windshield conditions, and light conditions).</mark>
                            </p>
                        </section>

                        <section className="section_7" ref={scrollRef[4]}>
                            <h3>
                                <span>5X EFFICIENCY</span>
                                LONG-DISTANCE DRIVING WORRIES OVER!<br/>
                                SAVE 5X WITHOUT MISSING OR LOSS OF VIDEO
                            </h3>
                            <p>
                                Smart Time Lapse function saves more than 5 times longer without video loss or omission. In standard recording mode based on 32GB, 193<br/>
                                minutes are recorded. However, you can record 1,134 minutes without overwriting with the Smart Time Lapse mode, which is more efficient.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section7.png" alt="EVENT SUMMARY"/>
                            </div>
                            <ul>
                                <li>* This is a comparison between standard recording and Smart Time Lapse recording times based on 32GB.</li>
                                <li>* There may be a difference in storage time depending on other factors such as the occurrence of an impact event.</li>
                            </ul>
                        </section>

                        <section className="section_8" ref={scrollRef[5]}>
                            <h3>
                                <span>ADAS PLUS</span>
                                ADVANCED DRIVER SAFETY WARNING SYSTEMS<br/>
                                KEEP YOU SAFE ON THE ROAD
                            </h3>
                            <p>The ADAS (Advanced Driver Assistance Systems) help safe driving with warnings of sound alerts.</p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_ADASPLUS.png" alt="ADAS PLUS"/>
                            </div>
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Front Vehicle Motion Alert (FVMA)</strong>
                                        When the car is stopped at an intersection or in traffic, FVMA informs the driver that<br/>
                                        front vehicle has started to move to prevent a delayed response.
                                    </p>
                                </div>
                                <img src="/assets/images/detail_ADASPLUS_FVMA.png" alt="ADAS PLUS"/>
                            </div>
                        </section>

                        <section className="section_9">
                            <h3>
                                <span>EVENT SUMMARY</span>
                                CHECK EVENT OCCURRENCE INFORMATION AT ONE VIEW<br/>
                                DURING DRIVING/PARKING!
                            </h3>
                            <p>
                                When a certain event occurred during driving/parking, use Event Summary function to check various information at one view, including<br/>
                                recorded videos, driving hour, driving distance, and average driving speed. It is more advantageous as you can see the the situation vividly at<br/>
                                the time, and even understand your driving habits.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section9.png" alt="EVENT SUMMARY"/>
                            </div>
                            <p>* You can use this function by connecting your dashcam to the FineVu Wi-Fi app.</p>
                        </section>

                        <section className="section_10" ref={scrollRef[7]}>
                            <h3>
                                <span>FASTER DUAL CORE CPU</span>
                                FASTER AND MORE STABLE PERFORMANCE WITH<br/>
                                HIGH-PERFORMANCE DUAL-CORE CPU
                            </h3>
                            <p>
                                Demonstrate enhanced performance of a dashcam with built-in high-performance dual core CPU.<br/>
                                With improved processing capability, it supports our innovative functions to work more stable and faster.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section10.png" alt="FASTER DUAL CORE CPU"/>
                            </div>
                        </section>

                        <section className="section_11" ref={scrollRef[8]}>
                            <h3>
                                <span>INSTALLATION ANGLE GUIDE</span>
                                ANGLE GUIDE ASSISTING RECORD AT RIGHT ANGLE
                            </h3>
                            <p>
                                No more slanted videos or those where only the sky was recorded with no object!<br/>
                                It assists you to adjust the angle of your dashcam when it is slanted.<br/>
                                It also helps you to record videos at a right angle by shooting at angles customized to a car, SUV, and truck.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section11.png" alt="INSTALLATION ANGLE GUIDE"/>
                            </div>
                            <p>* You can use this function after installing the FineVu Wi-Fi app.</p>
                        </section>

                        <section className="section_12">
                            <h3>
                                <span>FORMAT FREE 2.0</span>
                                FORMAT FREE 2.0 MAXIMIZES THE LIFE AND STABILITY<br/>
                                OF MEMORY CARDS
                            </h3>
                            <p>
                                Experience the most innovative Format-Free 2.0 that maximizes the life and stability of a memory card and maximizes efficiency.<br/>
                                By sequentially storing the recorded videos, it minimizes the waste of storage space.<br/>
                                With FineVu's own new server storage technology, the efficiency of the memory card has been maximized.<br/>
                                Experience the most innovative format-free technology that gurantees both longevity and stability of your memory card.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section12.png" alt="FORMAT FREE 2.0"/>
                            </div>
                        </section>

                        <section className="section_13">
                            <h3>
                                <span>EMERGENCY RECORDING</span>
                                NEED NO MORE THAN A BUTTON TO LOOK OUT FOR A<br/>
                                NEAR MISS!
                            </h3>
                            <p>
                                Press the REC button behind the dashcam to immediately activate the emergency recording mode, and capture evidence for unexpected<br/>
                                events. Choose and save your video clips at designated storage space and play whenever needed.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section13.png" alt="FASTER DUAL CORE CPU"/>
                            </div>
                        </section>

                        <section className="section_14">
                            <h3>
                                <span>DRIVING REST GUIDE</span>
                                PREVENT DROWSY DRIVING WITH VOICE GUIDANCE
                            </h3>
                            <p>
                                Don't make you drowsy while driving!<br/>
                                GX300 guides you by voice to advise you to take a break according to the driving time and notifies you every hour.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section14.png" alt="EMERGENCY RECORDING"/>
                            </div>
                        </section>

                        <section className="section_15">
                            <h3>
                                <span>BATTERY DISCHARGE PREVENTION</span>
                                NO WORRIES ABOUT BATTERY DISCHARGE IN HEAT AND<br/>
                                COLD!
                            </h3>
                            <p>
                                If the battery voltage of your car drops below a preset level, FineVu GX300 automatically detects and cuts off it's own power to protect the<br/>
                                battery of your car. You can set the auto cut-off voltage according to the vehicle type and battery, allowing you to utilize options optimized<br/>
                                for your car.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section15.png" alt="BATTERY DISCHARGE PREVENTION"/>
                            </div>
                            <ul>
                                <li>* For hybrid vehicles, the low voltage cutoff setting option (12.8V~13.4V) is applied.</li>
                                <li>* When using the vehicle's ISG (Idle Stop & Go) system, it is recommended to change the low voltage setting of the dashcam to Hybrid.</li>
                            </ul>
                        </section>

                        <section className="section_16">
                            <h3>
                                <span>HIGH TEMPERATURE PROTECTION</span>
                                DASHCAM VIDEO THAT PROTECTS EVERYTHING EVEN<br/>
                                AT HIGH TEMPERATURES
                            </h3>
                            <p>
                                FineVu GX300 is designed for efficient heat-discharging. It has high temperature protection function that safely protect the dashcam and<br/>
                                video files stored. When the ambient air temperature exceeds over a certain level, GX300 is automatically turned off to prevent overheating.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section16.png" alt="HIGH TEMPERATURE PROTECTION"/>
                            </div>
                        </section>

                        <section className="section_17">
                            <h3>
                                <span>BUILT-IN GPS</span>
                                MEASURE VEHICLE SPEED MORE ACCURATELY WITH ITS<br/>
                                BUILT-IN GPS
                            </h3>
                            <p>
                                Measure your vehicle's speed, location, and driving hour precisely with the built-in GPS.<br/>
                                It doesn't require cable arrangement in installing a dashcam.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx300_section17.png" alt="BUILT-IN GPS"/>
                            </div>
                        </section>

                        <section className="section_18">
                            <h3>
                                <span>NON-LCD</span>
                                EASY TO INSTALL IN ANY PLACE WITH IT'S COMPACT<br/>
                                DESIGN OF NON-LCD
                            </h3>
                            <p>
                                Can be installed easily and simply without the disturb of a rear-view mirror in any place a driver wants to install with its compact design of<br/>
                                non-LCD screen. Experience a complete sense of oneness with the interior design of your vehicle.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_NON-LCD.png" alt="NON-LCD"/>
                            </div>
                        </section>

                        <section className="section_19">
                            <h3>
                                <span>FINEVU PC PLAYER</span>
                                THE OPTIMAL DASHCAM ANALYSIS PROGRAM,<br/>
                                FINEVU PLAYER 2.0
                            </h3>
                            <p>
                                FineVu PC player is an easy-to-use PC software of FiveVu dashcam. You can playback, screenshot, enlarge the images with full resolution<br/>
                                and monitor the video records conveniently on your PC.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_FINEVU_PC_PLAYER.png" alt="FINEVU PC PLAYER"/>
                            </div>
                            <ul>
                                <li>* This feature can be used after installing FineVu Player program.</li>
                                <li>* It supports both Mac and Windows.</li>
                            </ul>
                        </section>
                    </div>
                    {/* <!-- Features Fin --> */}

                    <div className={`description ${tabIndex === 2 ? 'active' : ''}`}>
                        <section>
                            <h3>Product Description</h3>
                            <div className="imgBox">
                                <img src="/assets/images/fineVu_GX300_spec.jpg" alt="Product Description"/>
                            </div>
                        </section>
                    </div>
                    {/* <!-- Description Fin --> */}

                    <div className={`whatsInTheBox ${tabIndex === 3 ? 'active' : ''}`}>
                        <section>
                            <div>
                                <h3>Basic Configuration</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX300_basic_configuration.png" alt="Basic Configuration"/>
                                </div>
                                <ul>
                                    <li>※ To improve the performance of the product, its components may change without prior notice.</li>
                                    <li>※ The warranty period of the Micro SD card is 6 months.</li>
                                </ul>
                            </div>
                            <div>
                                <h3>Additional Options</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX300_additional_options.png" alt="Additional Options"/>
                                </div>
                            </div>
                        </section>
                    </div>
                    {/* <!-- What's In The Box Fin --> */}

                    <div className={`specifications ${tabIndex === 4 ? 'active' : ''}`}>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Model</th>
                                        <th>FineVu GX33</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Image Sensor</th>
                                        <td>
                                            Front : SONY STARVIS IMX335 5.1MP (HDR)<br></br>
                                            Rear : CMOS Image Sensor 2MP
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resolution</th>
                                        <td>
                                            Front : 2560*1440P (Quad HD)<br></br>
                                            Rear : 1920*1080 (Full HD)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Frames Per Second</th>
                                        <td>
                                            Driving : 30fps, 15fps, 6fps (Smart Time Lapse)<br></br>
                                            Parking : 30fps, 15fps, 2fps (Smart Time Lapse)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Viewing Angle</th>
                                        <td>
                                            Front : 122°<br></br>
                                            Rear : 134°
                                        </td>
                                        <td>Diagonal</td>
                                    </tr>
                                    <tr>
                                        <th>Bitrate</th>
                                        <td>
                                            Front : 12~8 Mbps<br></br>
                                            Rear : 8~6 Mbps
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Recording Mode</th>
                                        <td>Drive / Parking / Smart Time Lapse / 15FPS / Emergency</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Display</th>
                                        <td>Non-LCD</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Acceleration Sensor</th>
                                        <td>3-Axis Acceleration Sensor</td>
                                        <td>+2g / -2g</td>
                                    </tr>
                                    <tr>
                                        <th>GPS</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Wi-Fi</th>
                                        <td>Built-In(dongle)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Storage Capacity</th>
                                        <td>FineVu Micro SD Card 32~128GB</td>
                                        <td>Class 10 or above, UHS-1 recommended, SDXC compatible</td>
                                    </tr>
                                    <tr>
                                        <th>Battery</th>
                                        <td>Supercapacitor</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Microphone</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Speaker</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Security LED</th>
                                        <td>
                                            Front : White<br></br>
                                            Rear : X
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Input Power</th>
                                        <td>12V ~ 24V DC</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Power Consumption</th>
                                        <td>
                                            Parking Mode : 5W
                                        </td>
                                        <td>2CH, GPS Off / Wi-Fi Off</td>
                                    </tr>
                                    <tr>
                                        <th>High-Temperature Cut-Off</th>
                                        <td>O</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Low Voltage Cut-Off</th>
                                        <td>Supported</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Temperature</th>
                                        <td>
                                            Operation : -20℃ ~ 70℃<br></br>
                                            Storage : -30℃ ~ 80℃
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Size(WxDxH) / Weight</th>
                                        <td>
                                            Front : 78.0 x 64.5 x 50.0 mm / 120g<br></br>
                                            Rear : 53.4 x 30.3 x 41.5 mm / 27g
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Mobile Application</th>
                                        <td>FineVu Wi-Fi App.</td>
                                        <td>Android, IOS</td>
                                    </tr>
                                    <tr>
                                        <th>PC Viewer</th>
                                        <td>FineVu PC Player 2.0</td>
                                        <td>Windows, MAC</td>
                                    </tr>
                                    <tr>
                                        <th>Warranty</th>
                                        <td>3-Years</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>
                                            HDR, Auto Night Vision, Emergency Recording, Parking Event Voice Alert, ADAS*(FVMA), Speed Camera Alert**,
                                            High-Temperature Cut-Off, Low Voltage Cut-Off, Overwrite On/Off, Format Free 2.0, Memory Storage Allocation,
                                            In-App. Live View, Installation Angle Guide, Driving Rest Guide, Time Alarm
                                        </td>
                                        <td>ADAS and Speed Camera Alert requires GPS reception</td>
                                    </tr>
                                    <tr>
                                        <th>Regulatory Compliance</th>
                                        <td>
                                            KC No.: R-R-FDK-FineR032<br></br>
                                            Model name: FineVu GX300<br></br>
                                            Date of manufacture: Marked separately<br></br>
                                            Name(Model) : Dashcam(GX300)<br></br>
                                            Manufacturer: Finedigital Inc.<br></br>
                                            Company name: Finedigital Inc.<br></br>
                                            Manufacturing country: Rep. of Korea
                                        </td>
                                        <td>
                                            Passed KN41<br></br>
                                            (EMC test for vehicles, and internal combustion engines)
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Certification</th>
                                        <td className="imgArea">
                                            <div>
                                                <img src="/assets/images/table_certification.png" alt="Certification" />
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Language Supported</th>
                                        <td>
                                            English, Japanese, Russian, Polish, Vietnamese, German, Spanish, French, Hebrew, Thai, Chinese(Mandarin), Malay
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- Specifications Fin --> */}

                    {tabIndex === 5 ? <ManualBox/> : ''}
                    {/* <!-- Manuals & Firmware Fin --> */}

                    <div className={`usersReview ${tabIndex === 6 ? 'active' : ''}`}>
                        <div>
                            <ul>
                                <li>
                                    <img src="/assets/images/amazon_logo.png" alt="amazon logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Saul & Vivian Batres</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                It take great high quality videos, the only thing you have to consider this is hard wire but
                                                you can purchase a seperate unit to connect to a DC outlet.
                                            </p>
                                            <p>It came with a memory card and set up was easy as well.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Brandon Lanham</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Awesome dashcam. Works amazing and has high quality display and detail. Loop records
                                                the entire time you're driving. Highly recommend.
                                            </p>
                                            <p>One person found this helpful.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- amazon Fin --> */}

                            <ul>
                                <li>
                                    <img src="/assets/images/blackBoxMyCar_logo.png" alt="blackBoxMyCar logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Gus</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                I’ve had a couple of dashcams in the past. Some were very difficult to navigate. With
                                                numerous buttons and a small unit viewing window they became complicated for this ‘ol
                                                man to use. This time I wanted a simple and easy dashcam to operate.
                                            </p>
                                            <p>
                                                The FineVu GX1000 meets that criteria. It’s fairly new on the market. But found a few
                                                YouTube videos that walked you through the set up in car, how to use and review files.
                                                The simplicity of setup and operation are genius. It tucks up nicely (w/o any ugly wires)
                                                to the windshield. Plus, with the thin cables marks hardwiring a breeze.
                                            </p>
                                            <p>
                                                The video and audio quality are fantastic. Whether you’re watching on your phone or a
                                                32 inch monitor! There are many different configurations you can use capture video.
                                                Many options for notifications and parking mods. As I said, I’m a simple man and have
                                                only a notice when I start my car starts up. I’ve deselected most of the dashcams
                                                features. Why? My new 2022 vehicle already has all the safety nannies I need.
                                            </p>
                                            <p>
                                                Lastly, I like to review the videos after taking a leisurely trip through the back country
                                                roads.
                                            </p>
                                            <p>
                                                I highly recommend this unit if you’re looking for a well built, easy to setup and use dash
                                                cam.
                                            </p>
                                            <p>GWB</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Frank T.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                So far so good. I hardwired the dashcam to my truck. I also like the feature where I can
                                                manually shut the camera off with a slide switch. This prevents the vehicles battery from
                                                being drained.
                                            </p>
                                            <p>
                                                Michael at BlackboxMyCar (Canada) has been extremely helpful with answering any
                                                questions. Great customer support👏
                                            </p>
                                            <p>I've purchased from this company in the past and will continue to do so.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- blackBoxMyCar Fin --> */}

                            <ul>
                                <li>
                                    <img src="/assets/images/unavi_logo.png" alt="unavi logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Derrick Thomas</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                One of the easiest dash cameras to down load and view videos from. The videos can be
                                                Wi-Fi directly to the phone and sent via email or text. Crystal clear video and audio. Very
                                                impressed
                                            </p>
                                            <p>User picture</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Ali A.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Most dashcams I’ve seen require it to be hooked up to the cars battery which makes
                                                you tuck wires everywhere. This one plugs in and uses power straight from the rear view
                                                mirror, not having to tuck or hide any wires.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
