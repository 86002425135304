import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/user";

export const menuApiSlice = createApi({
  reducerPath: "menuApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getProductMenuList : builder.mutation({
        query : (param : {menuType : string}) => {
            return{
                url : `/menu/getProductMenus.php?menuType=${param.menuType}`
            }
        }
    }),
  }),
});

export const {
  useGetProductMenuListMutation
} = menuApiSlice;