import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/admin";

export const adminProductSpecApiSlice = createApi({
  reducerPath: "adminProductSpecApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    
    // 메인 스펙 리스트 가져오기
    getMainSpecList : builder.mutation({
      query : (param : {page : any, size : number, keyword : string, sortOrder : string, sortColumn : string}) => {
        return{
          url : `/setup/getMainSpecList.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 메인 스펙 저장
    setMainSpec : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/setMainSpec.php', method : 'post', body
        }
      }
    }),
    // 메인 스펙 상세보기
    getMainSpecDetail : builder.mutation({
      query : (param : {id: any}) => {
        return{
          url : `/setup/getMainSpecDetail.php?specId=${param.id}`
        }
      }
    }),
    // 메인 스펙 수정하기
    updMainSpec : builder.mutation({
      query : (body : any) => {
        return {
          url : '/setup/updMainSpec.php', method : 'post', body
        }
      }
    }),
    // 메인 스펙 Remark 여부 수정
    updMainSpecRemarkActiveStatus2 : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/updMainSpecRemarkActiveStatus2.php', method : 'post', body
        }
      }
    }),
    // 메인 스펙 image 여부 수정
    updMainSpecImageActiveStatus : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/updMainSpecImageActiveStatus.php', method : 'post', body
        }
      }
    }),
    // 메인 스펙 공개여부 수정
    updMainSpecActiveStatus : builder.mutation({
      query : (body:any)=>{
        return{
          url : '/setup/updMainSpecActiveStatus.php', method : 'post', body
        }
      }
    }),
    // 제품 메인사양 서브 유형(Front, Rear , Driving,등) 정보 리스트
    getMainSpecSubList : builder.mutation({
      query : () => {
        return{
          url : `setup/getMainSpecSubList.php`
        }
      }
    }),
    // 메인스펙 가져오기 (서브 유형 포함 )
    getMainSpecListWithSub : builder.mutation({
      query : (param : {keyword : string, sortOrder: string, sortColumn : string}) => {
        return{
          url : `/setup/getMainSpecListWithSub.php?keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),

    // 메인스펙 별 사양리스트 가져오기
    getSpecOption : builder.mutation({
      query : (param : {specId: any, specSubType : any, page : any, size : number, keyword : string, sortOrder : string, sortColumn : string}) => {
        return{
          url : `/setup/getSpecOptionList.php?specId=${param.specId}&specSubType=${param.specSubType}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 메인스펙 별 사양 저장
    setSpecOption : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/setSpecOption.php', method : 'post', body
        }
      }
    }),
    // 메인스펙 별 사양 수정
    updSpecOption : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/updSpecOption.php', method : 'post', body
        }
      }
    }),
    // 메인스펙 별 공개여부 수정
    updSpecOptionActiveStatus : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/updSpecOptionActiveStatus.php', method : 'post', body
        }
      }
    }),

    // 메인스펙 별 비고 리스트 가져오기
    getMainSpecRemark : builder.mutation({
      query : (param : {specId: any, page : any, size : number, keyword : string, sortOrder : string, sortColumn : string}) => {
        return{
          url : `/setup/getMainSpecRemarkList.php?specId=${param.specId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 메인스펙 별 비고 등록
    setMainSpecRemark : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/setMainSpecRemark.php', method : 'post', body
        }
      }
    }),
    // 메인스펙 별 비고 수정
    updMainSpecRemark : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/updMainSpecRemark.php', method : 'post', body
        }
      }
    }),
    // 메인스펫별 비고 공개여부 수정
    updMainSpecRemarkActiveStatus : builder.mutation({
      query: (body : any) => {
        return{
          url : '/setup/updMainSpecRemarkActiveStatus.php', method : 'post', body
        }
      }
    }),

    // 서브유형 가져오기
    getMainSpecSubList2 : builder.mutation({
      query : (param : {page : any, size : number, keyword : string, sortOrder : string, sortColumn : string})=>{
        return{
          url : `/setup/getMainSpecSubList2.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 서브유형 등록
    setMainSpecSub : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/setMainSpecSub.php', method : 'post', body
        }
      }
    }),
    // 서브유형 수정
    updMainSpecSub : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/updMainSpecSub.php', method : 'post', body
        }
      }
    }),
    // 서브유형 공개여부 수정
    updMainSpecSubActiveStatus : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setup/updMainSpecSubActiveStatus.php', method :'post', body
        }
      }
    })
  }),
});

export const {
  useGetMainSpecListMutation, useSetMainSpecMutation, useGetMainSpecDetailMutation, useGetMainSpecSubListMutation, useUpdMainSpecMutation, useGetMainSpecSubList2Mutation,
  useGetMainSpecListWithSubMutation, useGetSpecOptionMutation, useSetSpecOptionMutation, useGetMainSpecRemarkMutation, useUpdSpecOptionMutation, useUpdMainSpecActiveStatusMutation,
  useSetMainSpecRemarkMutation, useUpdMainSpecRemarkMutation, useUpdSpecOptionActiveStatusMutation, useUpdMainSpecRemarkActiveStatusMutation, useSetMainSpecSubMutation,
  useUpdMainSpecSubMutation, useUpdMainSpecSubActiveStatusMutation, useUpdMainSpecRemarkActiveStatus2Mutation, useUpdMainSpecImageActiveStatusMutation
} = adminProductSpecApiSlice;