import React, { useEffect, useState } from "react";
import { ManualBox } from "../../../../../../components/user/products/detail/manualsBox/products-manualsBox";
import { useAppDispatch, useAppSelector } from "../../../../../../store/storeHooks";
import { useNavigate } from "react-router-dom";

export const MyDashcamView = () => {
    const navigate = useNavigate()
    const userInfo : any = useAppSelector(state=>state.loginUser.userInfo)
    const dispatch = useAppDispatch()
    
    return(
        <>
        <div className="contentBox">
            <div className="bgBox" style={{backgroundImage: "url('/assets/images/banner_myDashcam.png')"}}>
                <div className="centerBox">
                    <h2>My Dashcam</h2>
                </div>
            </div>
            {/* <!-- bgBox Fin --> */}
            
            <div className="tabWrap">

                <div className="tabContentBox">

                    <ManualBox/>
                </div>
            </div>

        </div>
        </>
    )
}