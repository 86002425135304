import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export const DashboardHeader = () => {
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['key']); // 쿠키에 접근

    const handleLogout = () => {
        removeCookie('key');
        window.location.href = '/admin'
    }
    return(
        <>
        <div className="dotsHeader">
            <div className="header">
                <div>
                    <div>
                        <div>
                            <span>Admin</span>
                            <i className="fa-regular fa-chevron-down"></i>
                        </div>
                    </div>
                    <div>
                        <span>홈페이지 바로가기<i className="fa-regular fa-chevron-right"></i></span>
                        <button onClick={handleLogout}>로그아웃</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}