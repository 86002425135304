import React, { useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export interface Props {mainSpecData : any}
export const DashboardSideBar:React.FC<Props> = ({mainSpecData}) => {
    const [data, setData] = useState<any>([])
    const activeRef = useRef<any>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname.split('/')
    const {typeId} = useParams()
    const handleTrans = () => {
        if(mainSpecData.length > 0 && mainSpecData){
            navigate(`/admin/specification/productSpec/${mainSpecData[0]?.specIdx}?type=${mainSpecData[0]?.specSubType}`)
        }
    }
    useEffect(()=>{
        setData(mainSpecData)
    }, [mainSpecData])
    return(
        <>
            <aside>
                <h2>
                    <img src="/assets/images/fineVe_logo.png" alt="fineVe_logo"/>
                </h2>
                <ul className="mainMenu">
                    {/* <li className="analytics">
                        <span>Analytics</span>
                    </li> */}
                    <li className={path[2] === 'members'?`active members`:`members`}>
                        <span onClick={()=>navigate('/admin/members/member')}>Members</span>
                        <AnimateHeight duration={500} height={path[2]==='members' ? 'auto' : 0}>
                        <ul className="subMenu active">
                            <li className="active">
                                <span>List of Members</span>
                            </li>
                        </ul>
                        </AnimateHeight>
                    </li>
                    <li className={path[2] === 'products'?`product active`:`product`}>
                        <span onClick={()=>navigate(`/admin/products/product`)}>Product</span>
                        <AnimateHeight duration={500} height={path[2]==='products' ? 'auto' : 0}>
                        <ul className="subMenu active">
                            <li className={path[3]==='product'?'active':''} onClick={()=>navigate(`/admin/products/product`)}>
                                <span>List of Products</span>
                            </li>
                            <li className={path[3]==='download'?'active':''} onClick={()=>navigate(`/admin/products/download`)}>
                                <span>List of Download file</span>
                            </li>
                            <li className={path[3]==='language'?'active':''} onClick={()=>navigate(`/admin/products/language`)}>
                                <span>List of Language</span>
                            </li>
                        </ul>
                        </AnimateHeight>
                    </li>
                    <li className={path[2] === 'specification'?`specification active`:`specification`}>
                        <span onClick={()=>navigate(`/admin/specification/mainSpec`)}>Specification</span>
                        <AnimateHeight duration={500} height={path[2]==='specification' ? 'auto' : 0}>
                        <ul className="subMenu active">
                            <li className={path[3]==='mainSpec'?'active':''} onClick={()=>navigate(`/admin/specification/mainSpec`)}>
                                <span>List of Main Spec</span>
                            </li>
                            <li className={path[3]==='productSpec'?'active':''} onClick={()=>navigate(`/admin/specification/productSpec/0?type=0`)}>
                                <span>List of Spec Option</span>
                            </li>
                            <li className={path[3]==='remark'?'active':''} onClick={()=>navigate(`/admin/specification/remark/0?type=0`)}>
                                <span>List of Spec. Remark</span>
                            </li>
                            <li className={path[3]==='specOption'?'active':''} onClick={()=>navigate(`/admin/specification/specOption`)}>
                                <span>List of Spec. Sub Type</span>
                            </li>
                        </ul>
                        </AnimateHeight>
                    </li>
                    <li className={path[2] === 'contents'?`content active`:`content`} onClick={()=>navigate('/admin/contents/content')}>
                        <span>Content</span>
                    </li>
                    <li className={path[2] === 'product'?`store active`:`store`}>
                        <span onClick={()=>navigate('/admin/stores/country')}>Store</span>
                        <AnimateHeight duration={500} height={path[2]==='stores' ? 'auto' : 0}>
                            <ul className="subMenu active">
                                <li className={path[3]==='country'?'active':''} onClick={()=>navigate('/admin/stores/country')}>
                                    <span>List of Country</span>
                                </li>
                                <li className={path[3]==='store'?'active':''} onClick={()=>navigate('/admin/stores/store')}>
                                    <span>List of Store</span>
                                </li>
                            </ul>
                        </AnimateHeight>
                    </li>
                    <li className={path[2] === 'inquirys'?`inquiry active`:`inquiry`} onClick={()=>navigate('/admin/inquirys/inquiry')}>
                        <span>Inquiry</span>
                    </li>
                    <li className={path[2] === 'setting'?`setting active`:`setting`}>
                        <span onClick={()=>navigate('/admin/setting/adminstrators')}>Administrator Setting</span>
                        <AnimateHeight duration={500} height={path[2]==='setting' ? 'auto' : 0}>
                        <ul className="subMenu active">
                            <li className="active">
                                <span onClick={()=>navigate('/admin/setting/adminstrators')}>Administrator Setting</span>
                                <button onClick={()=>navigate('/admin/setting/adminstrators/regist')}><i className="fa-regular fa-plus"></i></button>
                            </li>
                        </ul>
                        </AnimateHeight>
                    </li>
                </ul>
            </aside>
        </>
    )
}