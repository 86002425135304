import React from "react";
import { RadioBoxItem } from "./item-radioBox";
export interface Props {data:any, setData : any}
export const SpecMountedCheckBox:React.FC<Props> = ({data,setData}) => {
    return(
        <>
        <div className="right">
            <ul>
                <RadioBoxItem title={"HDR"} name={'hdr'} value={data?.hdr} setData={setData} />
                <RadioBoxItem title={'Auto Night Vision'} name={'autoNightVersion'} value={data?.autoNightVersion} setData={setData} />
                <RadioBoxItem title={'Image Quality Setting'} name={'imageQualitySetting'} value={data?.imageQualitySetting} setData={setData} />
                <RadioBoxItem title={'Driving Recording Mode: Normal (impact)'} name={'drivingRecordingModeNormal'} value={data?.drivingRecordingModeNormal} setData={setData} />
                <RadioBoxItem title={'Driving Recording Mode: 15FPS'} name={'drivingRecordingModeFps'} value={data?.drivingRecordingModeFps} setData={setData} />
                <RadioBoxItem title={'Driving Recording Mode: Smart Time Lapse'} name={'drivingRecordingModeStl'} value={data?.drivingRecordingModeStl} setData={setData} />
                <RadioBoxItem title={'Parking Recording Mode: Motion & Impact'} name={'parkingRecordingModeMotionImpact'} value={data?.parkingRecordingModeMotionImpact} setData={setData} />
                <RadioBoxItem title={'Parking Recording Mode: 15FPS'} name={'parkingRecordingModeFps'} value={data?.parkingRecordingModeFps} setData={setData} />
                <RadioBoxItem title={'Parking Recording Mode: Smart Time Lapse'} name={'parkingRecordingModeStl'} value={data?.parkingRecordingModeStl} setData={setData} />
                <RadioBoxItem title={'Parking Recording Mode: Power Saving'} name={'parkingRecordingModePowerSaving'} value={data?.parkingRecordingModePowerSaving} setData={setData} />
                <RadioBoxItem title={'Emergency Recording'} name={'emergencyRecording'} value={data?.emergencyRecording} setData={setData} />
                <RadioBoxItem title={'Parking Event Voice Alert'} name={'parkingEventVoiceAlert'} value={data?.parkingEventVoiceAlert} setData={setData} />
                <RadioBoxItem title={'AI Heat Monitoring'} name={'aiHeatMonitoring'} value={data?.aiHeatMonitoring} setData={setData} />
                <RadioBoxItem title={'AI Damage Detection 2.0'} name={'aiDamageDetection'} value={data?.aiDamageDetection} setData={setData} />
                <RadioBoxItem title={'ADAS FVMA'} name={'adasFvma'} value={data?.adasFvma} setData={setData} />
                <RadioBoxItem title={'ADAS LDWS'} name={'adasLdws'} value={data?.adasLdws} setData={setData} />
                <RadioBoxItem title={'Speed Camera Alert**'} name={'speedCameraAlert'} value={data?.speedCameraAlert} setData={setData} />
                <RadioBoxItem title={'High-Temperature Cut-Off'} name={'highTemperatureCutOff'} value={data?.highTemperatureCutOff} setData={setData} />
                <RadioBoxItem title={'High-Temperature Cut-Off A.I Heat Monitoring'} name={'highTemperatureCutOffHeatMonitoring'} value={data?.highTemperatureCutOffHeatMonitoring} setData={setData} />
                <RadioBoxItem title={'Low Voltage Cut-Off'} name={'lowVoltageCutOff'} value={data?.lowVoltageCutOff} setData={setData} />
                <RadioBoxItem title={'Overwrite On/Off'} name={'overwriteOnOff'} value={data?.overwriteOnOff} setData={setData} />
                <RadioBoxItem title={'Format Free 2.0'} name={'formatFree'} value={data?.formatFree} setData={setData} />
                <RadioBoxItem title={'Memory Storage Allocation'} name={'memoryStorageAllocation'} value={data?.memoryStorageAllocation} setData={setData} />
                <RadioBoxItem title={'Privacy Mode'} name={'privacyMode'} value={data?.privacyMode} setData={setData} />
                <RadioBoxItem title={'In-App. Live View'} name={'inAppLiveView'} value={data?.inAppLiveView} setData={setData} />
                <RadioBoxItem title={'LCD Live View'} name={'lcdLiveView'} value={data?.lcdLiveView} setData={setData} />
                <RadioBoxItem title={'Installation Angle Guide'} name={'installationAngleGuide'} value={data?.installationAngleGuide} setData={setData} />
                <RadioBoxItem title={'Driving Rest Guide'} name={'drivingTestGuide'} value={data?.drivingTestGuide} setData={setData} />
                <RadioBoxItem title={'Time Alarm'} name={'timeAlarm'} value={data?.timeAlarm} setData={setData} />
            </ul>
        </div>
        </>
    )
}