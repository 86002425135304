import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export interface Props {pageInfo : any,setPageInfo : Function, totalCount:number; setParam : Function}
export const ListHeader:React.FC<Props> = ({pageInfo,setPageInfo,totalCount,setParam}) => {
    const {type} = useParams()
    const searchRef = useRef<any>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname.split('/')
    const subType : any = new URLSearchParams(location.search).get('type') || 0;
    const keyword : any = new URLSearchParams(location.search).get('keyword') || '';
    const handleAddBtn = () => {
        if(path[3]==='country' || path[3]==='language' || path[3]==='mainSpec' || path[3]==='specOption')
        {setPageInfo((prev:any)=>({...prev, isActive : true}))}
        else{navigate(`${location.pathname}/regist`)}
    }
    const pathname = path[3]==='productSpec' || path[3] === 'remark';
    const handleSearch = () => {
        if(pathname){
            navigate(`${location.pathname}?type=${0}&keyword=${searchRef.current.value}`)
        }else{
            navigate(`${location.pathname}?page=1&keyword=${searchRef.current.value}`)
        }
    }
    const handleEnter = (e:any) => {if(e.key==='Enter'){handleSearch()}}
    useLayoutEffect(()=>{
        switch(path[2]){
            case 'members' : setPageInfo((prev:any) => ({...prev, title1 : 'Members'}));break;
            case 'products' : setPageInfo((prev:any) => ({...prev, title1 : 'Product'})); break;
            case 'specification' : setPageInfo((prev:any) => ({...prev, title1 : 'Specification'}));break;
            case 'contents' : setPageInfo((prev : any) => ({...prev, title1 : 'Content'})); break;
            case 'stores' : setPageInfo((prev : any) => ({...prev, title1 : 'Store'})); break;
            case 'inquirys' : setPageInfo((prev : any) => ({...prev, title1 : 'Inquiry'})); break;
        }
        switch(path[3]){
            case 'member' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Members'}));break;
            case 'product' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Products'}));break;
            case 'productSpec' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Product Spec.'}));break;
            case 'content' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Content'})); break;
            case 'country' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Country'})); break;
            case 'store' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Store'})); break;
            case 'inquiry' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Inquiry'})); break;
            case 'download' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Download File'})); break;
            case 'language' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Language'})); break;
            case 'mainSpec' : setPageInfo((prev:any) => ({...prev, title2 : 'List of MainSpec'})); break;
            case 'remark' : setPageInfo((prev:any) => ({...prev, title2 : 'List of Remark'})); break;
            case 'specOption' : setPageInfo((prev:any) => ({...prev, title2 : 'List of SpecOption'})); break;
        }
    }, [location])
    return(
        <>
        <h3>{pageInfo.title1}</h3>
        <div className="flexWrap">
            <div className="flexBox">
                <h4>
                    {pageInfo.title2}
                    <span className="content_count">{totalCount}</span>
                </h4>
                {path[3]==='member' || path[3]==='productSpec' || path[3]==='remark' || path[3]==='download'
                ? '' :
                <button className="addBtn" onClick={handleAddBtn}>
                    <i className="fa-solid fa-plus"></i>
                    <span>Add New</span>
                </button>
                }
            </div>

            <div className="search_box">
                <input type="text" ref={searchRef} placeholder="Search ..." defaultValue={keyword} onKeyDown={handleEnter}/>
                <button type="button" className="ic-button" onClick={handleSearch}>
                    <img src="/assets/images/searchBox.png" alt="검색아이콘"/>
                </button>
            </div>
        </div>
        </>
    )
}