import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSetSpecOptionMutation, useUpdSpecOptionMutation } from "../../../../store/admin/adminProductSpecApiSlice";
export interface Props {specInfo : any,specType:string, getList:any, onClose:Function}
export const RegistSpec:React.FC<Props> = ({specInfo,getList,onClose}) => {
    const {typeId} : any = useParams()
    const location = useLocation()
    const type : any = new URLSearchParams(location.search).get('type')
    const [setSpecOptionApi] = useSetSpecOptionMutation()
    const [updSpecOptionApi] = useUpdSpecOptionMutation()
    const [specText, setSpecText] = useState<string>('')
    const [specImage, setSpecImage] = useState<any>({image : '', previewImage : ''})
    const handleChange = (e:any) => {
        const {name , value, type} = e.target;
        if(type === 'file'){
            const reader = new FileReader();
            if(e.target.files[0]){reader.readAsDataURL(e.target.files[0])}
            reader.onload=()=>{
                setSpecImage((prev:any)=>({...prev, image : e.target.files[0], previewImage : reader.result}))
            }
        }else{setSpecText(e.target.value)}
    }
    const handleSave = async() => {
        if(specText === ''){alert("사양을 입력해주세요.");return;}
        const formData = new FormData()
        if(specInfo.id !== 0){formData.append('optionId', specInfo.id)}
        if(specInfo.id === 0){formData.append('specId', typeId)}
        formData.append('optionSubType', type)
        formData.append('optionSpecification', specText)
        formData.append('optionImageFile', specImage?.image)
        if(specInfo.id === 0){
            const result : any = await setSpecOptionApi(formData)
            if(result.data.result === true){
                alert("등록이 완료되었습니다."); getList(); onClose();
            }
        }else{
            const result : any = await updSpecOptionApi(formData)
            if(result.data.result === true){
                alert("수정이 완료되었습니다.");getList();onClose()
            }
        }
    }
    
    useEffect(()=>{
        if(specInfo.id!==0){setSpecText(specInfo.optionName); setSpecImage((prev:any)=>({...prev, previewImage : specInfo?.imageUrl}))}
    },[specInfo])
    return(
        <>
        <div className="registration_popUp popUp_active active">
            <button className="closeBtn" onClick={()=>onClose()}>
                <i className="fa-light fa-xmark"></i>
            </button>

            <div className="titleBox">
                <span>{specInfo.id===0?'Add':'Edit'} Specification</span>
                <span>Detail Specification</span>
            </div>
            <div className="userInfoBox">
                <ul>
                    <li>
                        <label htmlFor="">사양</label>
                        <textarea name="" id="" value={specText} onChange={handleChange} placeholder="사양 입력란"></textarea>
                    </li>
                    {specInfo?.imageStatus === 'N'? '' : 
                    <li className="selectFileBox">
                        <span>이미지</span>
                        <div>
                            <input type="file" name="image" id="image" onChange={handleChange}/>
                            <label htmlFor="image">Select a file</label>
                        </div>
                        {specImage?.previewImage === '' ? '' : 
                        <ul>
                            <li>
                                <button></button>
                                <img src={specImage?.previewImage} alt="이미지"/>
                            </li>
                        </ul>
                        }
                    </li>
                    }
                </ul>
            </div>

            <div className="btnBox">
                <button onClick={()=>onClose()}>취소</button>
                <button onClick={handleSave}>{specText===''?'등록':'수정'}</button>
            </div>
        </div>
        </>
    )
}