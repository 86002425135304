import React, { useEffect, useState } from "react";
import { Inquiry_Popup } from "./inquiry_popUp";

export const ScrollBtn = () => {
    const [showScrollBtn, setShowScrollBtn] = useState<boolean>(false)
    const [isActive, setIsActive] = useState<boolean>(false)
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setShowScrollBtn(isScrolled);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {window.removeEventListener("scroll", handleScroll);};
    }, []);
    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    const handleScrollBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    }
    const handleClose = () => {setIsActive(false)}
    return(
        <>
        <div className="btnBox">
            <div>
                {showScrollBtn && 
                <div className="active">
                    <button onClick={handleScrollTop}>topBtn</button>
                    <button onClick={handleScrollBottom}>bottomBtn</button>
                </div>
                }
                
                <button onClick={()=>setIsActive(prev => !prev)}>
                    <span>Inquiry</span>
                </button>
            </div>
        </div>
        {isActive && <Inquiry_Popup onClose={handleClose}/>}
        </>
    )
}