import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetProductMenuListMutation } from "../../../../store/user/menuApiSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/storeHooks";
import { menuListInfo, setMenuList } from "../../../../store/stroeApi/menuInfoSlice";
import { setuserInfo } from "../../../../store/user/loginUserSlice";
import { useCookies } from "react-cookie";

const Header_Pc = () => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userInfo : any = useAppSelector(state => state.loginUser.userInfo)
    const menuInfo : menuListInfo = useAppSelector(state => state.menuInfo.menuInfo)
    const [cookies, setCookie, removeCookie] = useCookies(['userKey']); // 쿠키에 접근

    const handleTransPage = (page:string) => {
        if(location.pathname !== page){navigate(page)}    
    }
    const [getProductMenuListApi] = useGetProductMenuListMutation()
    const getMenuList = async() => {
        const result : any = await getProductMenuListApi({menuType : 'CH'})
        if(result.data.Result === 'true'){
            dispatch(setMenuList({channel : result.data.channel, resolution : result.data.resolution, features : result.data.features}))
        }
    }
    const handleLogout = () => {
        dispatch(setuserInfo({userId : '', account : '', name : '', apiId : '', productId : ''}))
        // navigate('/support/warrentyService/myDashcam')
        // sessionStorage.removeItem('userKey')
        removeCookie('userKey');
        window.location.href = '/support/warrentyService/myDashcam';
    }
    useEffect(()=>{getMenuList()},[])
    return(
        <>
        <div className="header_pc">
            <h1>
                <span onClick={()=>navigate('/')}>
                    logo
                </span>
            </h1>

            <div>
                <nav>
                    <ul>
                        <li className={path[1]==='products'?'active':''}>
                            <span onClick={()=>navigate('/products?ch=&rs=&ft=')}>Products</span>
                            <div className="subMenu">
                                <div>
                                    <div className="leftArea"></div>
                                    <ul>
                                        <li onClick={()=>navigate('/products?ch=&rs=&ft=')}>
                                            <ul>
                                                <li className="none">
                                                    <span>All Items</span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                <li>
                                                    <span>By Channel</span>
                                                </li>
                                                {menuInfo?.channel?.map((channel : any, index : number) => (
                                                <li key={index} onClick={()=>navigate(`/products?ch=${channel?.menuId}&rs=&ft=`)}>
                                                    <span>{channel?.menuName}</span>
                                                </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                <li>
                                                    <span>By Resolution</span>
                                                </li>
                                                {menuInfo?.resolution?.map((resolution : any, index : number) => (
                                                <li key={index} onClick={()=>navigate(`/products?ch=&rs=${resolution?.menuId}&ft=`)}>
                                                    <span>{resolution?.menuName}</span>
                                                </li>
                                                ))}
                                                <li>
                                                    <span className="disabled">(front cam only)</span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                <li>
                                                    <span>By Features</span>
                                                </li>
                                                {menuInfo?.features?.map((features : any, index : number) => (
                                                <li key={index} onClick={()=>navigate(`/products?ch=&rs=&ft=${features?.menuId}`)}>
                                                    <span>{features?.menuName}</span>
                                                </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                <li className="none">
                                                    <span>Accessories</span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li onClick={()=>handleTransPage('/productComparison')}>
                            <span>Product Comparison</span>
                        </li>
                        <li>
                            <span>Support</span>
                            <div className="subMenu">
                                <div>
                                    <div className="leftArea"></div>
                                    <ul>
                                        <li>
                                            <ul>
                                                <li>
                                                    <span>Download</span>
                                                </li>
                                                <li onClick={() => handleTransPage('/support/download/dashcam')}>
                                                    <span>FineVu Dashcam</span>
                                                </li>
                                                <li onClick={() => handleTransPage('/support/download/wifiapp')}>
                                                    <span>FineVu App</span>
                                                </li>
                                                <li onClick={() => handleTransPage('/support/download/pcplayer')}>
                                                    <span>FineVu PC Player</span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                <li>
                                                    <span>Warranty Services</span>
                                                </li>
                                                <li onClick={() => handleTransPage('/support/warrentyService/prRegistration')}>
                                                    <span>Product Registration</span>
                                                </li>
                                                <li onClick={() => handleTransPage('/support/warrentyService/policy')}>
                                                    <span>Warranty Policy</span>
                                                </li>
                                                <li onClick={() => handleTransPage(userInfo?.userId === 'null' || userInfo?.userId === null || userInfo?.userId === '' ? `/support/warrentyService/myDashcam` : `/support/warrentyService/myDashcam/${userInfo?.userId}`)}>
                                                    <span>My Dashcam</span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                <li className="none" onClick={() => handleTransPage('/support/warrentyService/trouble-shooting')}>
                                                    <span>Troubleshooting</span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li style={{width:'120px'}}>
                                            <ul>
                                                <li className="none" onClick={() => handleTransPage('/support/warrentyService/contact')}>
                                                    <span>Contact</span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                <li className="none" onClick={() => handleTransPage('/location')}>
                                                    <span>Where to Buy</span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li onClick={() => handleTransPage('/blog')}>
                            <span>Blog</span>
                        </li>
                        <li onClick={() => handleTransPage('/fineVu')}>
                            <span>FineVu</span>
                        </li>
                    </ul>
                </nav>
                <div className="rightBox">
                    {userInfo?.userId === 'null' || userInfo?.userId === null || userInfo?.userId === '' ? 
                    <>
                    <button className="myDashCamBtn" onClick={()=>handleTransPage('/support/warrentyService/myDashcam')}>My Dashcam</button>
                    <button className="logoutBtn active" onClick={()=>handleTransPage('/support/warrentyService/myDashcam')}>Login</button>
                    </>
                    :
                    <>
                    <button className="myDashCamBtn" onClick={()=>handleTransPage(`/support/warrentyService/myDashcam/${userInfo?.userId}`)}>My Dashcam</button>
                    <button className="logoutBtn active" onClick={handleLogout}>Logout</button>
                    </>
                    }
                    {/* <!-- 로그인 했을 때만 활성화 --> */}
                </div>
            </div>
        </div>
        </>
    )
}
export default Header_Pc;