import React, { useRef, useState } from "react";
import { ManualBox } from "../manualsBox/products-manualsBox";
export const tabList = [
    {idx: 1, title : 'Features'},
    {idx: 2, title : 'Description'},
    {idx: 3, title : "What's In The Box"},
    {idx: 4, title : 'Specifications'},
    {idx: 5, title : 'Manuals & Firmware'},
    {idx: 6, title : "User's Review"},
]
export const GX5000 = () => {
    const [state, setState] = useState<any>({first : 1, second : 1})
    const [tabIndex, setTabIndex] = useState<number>(1)
    const scrollRef = [
        useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)
    ];
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
        <div className="product_view gx5000">
            <div className="bgBox" style={{backgroundImage: "url('/assets/images/banner_GX5000.png')"}}>
                <div className="centerBox">
                    <h2><span>GX</span>5000</h2>
                    <p>PERFECT VISIBILITY, FHD DASHCAM</p>
                    <ul>
                        <li onClick={()=>handleScroll(0)}>Power Saving Parking Mode</li>
                        <li onClick={()=>handleScroll(1)}>AI Damage Detection 2.0</li>
                        <li onClick={()=>handleScroll(2)}>SAMSUNG Micro SD Card</li>
                        {/* 해당내용 없음 */}
                        <li onClick={()=>handleScroll(3)}>Dual FHD</li>
                        <li onClick={()=>handleScroll(4)}>SONY STARVIS Sensor</li>
                        <li onClick={()=>handleScroll(5)}>HDR</li>
                        <li onClick={()=>handleScroll(6)}>2X Efficiency</li>
                        <li onClick={()=>handleScroll(7)}>Speed Camera Alert</li>
                    </ul>
                </div>

                <div className="scrollDownBox">
                    <div>
                        <span></span>
                    </div>
                    <span>SCROLL</span>
                </div>
            </div>
            {/* <!-- bgBox Fin --> */}

            <div className="tabWrap">
                <div className="tabBtnWrap">
                    <div className="tabBtnBox">
                        {tabList.map((list:{idx:number,title:string})=>(
                            <button className={tabIndex===list?.idx?'active':''} onClick={()=>setTabIndex(list?.idx)}>
                                {list?.title}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={`features ${tabIndex === 1? 'active' : ''}`}>
                        <div className="sliderWrap">
                            <ul>
                                <li>
                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX5000_1.png" alt="GX5000"/>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- slider fin --> */}
                            <div className="dotsBox">
                                <span></span>
                                <span className="active"></span>
                                <span></span>
                            </div>
                        </div>
                        {/* <!-- sliderArea Fin --> */}

                        <div className="iconBox">
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/PowerSavingParkingMode.png" alt="Power Saving Parking Mode"/>
                                    </div>
                                    <span>
                                        Power Saving<br/>
                                        Parking Mode
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/AIDamageDetection.png" alt="AI Damage Detection 2.0"/>
                                    </div>
                                    <span>
                                        AI Damage<br/>
                                        Detection 2.0
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/SAMSUNG_MicroSDCard.png" alt="SAMSUNG Micro SD Card"/>
                                    </div>
                                    <span>
                                        SAMSUNG<br/>
                                        Micro SD Card
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/DualFHD.png" alt="Dual FHD"/>
                                    </div>
                                    <span>
                                        Dual<br/>
                                        FHD
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/Sony_STARVIS.png" alt="SONY STARVIS Sensor"/>
                                    </div>
                                    <span>
                                        SONY STARVIS<br/>
                                        Sensor
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/hdr_icon.png" alt="HDR"/>
                                    </div>
                                    <span>HDR</span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/2XEfficiency.png" alt="2X Efficiency"/>
                                    </div>
                                    <span>2X Efficiency</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/SpeedCameraAlert.png" alt="Speed Camera Alert"/>
                                    </div>
                                    <span>
                                        Speed Camera<br/>
                                        Alert
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- iconBox Fin --> */}

                        <section className="section_1" ref={scrollRef[0]}>
                            <h3>
                                <span>Power Saving Parking Mode</span>
                                POWER SAVING PARKING MODE
                            </h3>
                            <p>
                                Power Saving Parking Mode for impact recording for more than a month after the vehicle is turned off.<br/>
                                After the vehicle is turned off, recording standby is possible for up to 53 days* based on Parking Mode by using only the required power.<br/>
                                In the event of an impact, it can always record the situation, so you can always feel safe.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section1.png" alt="Power Saving Parking Mode"/>
                            </div>

                            <div className="resolution_tableWrap">
                                <ul>
                                    <li>
                                        <span>Mode</span>
                                        <span>Parking Mode (motion)</span>
                                        <span>Power Saving Parking Mode</span>
                                    </li>
                                    <li>
                                        <span>Power consumption (12V)</span>
                                        <span>4.1 W</span>
                                        <span>0.1 W</span>
                                    </li>
                                    <li>
                                        <span>Parking recording standby time</span>
                                        <span>30 hours</span>
                                        <span>1,275 hours</span>
                                    </li>
                                </ul>
                            </div>
                            <ul>
                                <li>* The number of recording-standby hours is based on maximum hours provided by hardwiring to fuse tap until low voltage cut-off initiates.</li>
                                <li>* Depending on dashcam’s power consumption, environmental factors, and vehicle’s battery condition actual recording standby hours may vary.</li>
                                <li>* Certain features, such as AI Damage Detection 2.0, may not be featured during Power Saving Parking Mode.</li>
                            </ul>
                        </section>

                        <section className="section_2" ref={scrollRef[1]}>
                            <h3>
                                <span>AI DAMAGE DETECTION 2.0</span>
                                GET ACTUAL IMPACT EVENTS AND<br/>
                                AREA DURING PARKING MODE
                            </h3>
                            <p>
                                AI Damage Detection 2.0 is completed with more than 3million experiments. Using machine learning techniques, the impact area during<br/>
                                parking is subdivided into 8 directions and reported in 3 levels of impact size. You can be relieved of accidents such as door dings, scratch,<br/>
                                and hit-and-runs.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section2.png" alt="AI DAMAGE DETECTION 2.0"/>
                            </div>

                            <ul>
                                <li>* If the impact areas cannot be detected, the notification display may not be seen or a certain icon() may be displayed.</li>
                                <li>* In the case of non-accidental events, there will be no separate notifications.</li>
                                <li>There may be errors in the guidance depending on the usage environment.</li>
                                <li>* When the Power Saving Parking Mode is set, this function may not work.</li>
                                <li>* The measured impact when hitting or shaking the dashcam may differ from the actual impact of accidents.</li>
                            </ul>
                        </section>

                        <section className="section_3" ref={scrollRef[3]}>
                            <h3>
                                <span>DUAL FULL HD</span>
                                FRONT & REAR FULL HD RECORDING
                            </h3>
                            <p>
                                Full HD 1920x1080 resolution gives FineVu GX5000 outstanding video clarity under all circumstances.<br/>
                                FineVu GX5000 records every moment with clear video.
                            </p>

                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.first}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 1}))}>
                                        <span>Front</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 2}))}>
                                        <span>Rear</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tabContentBox">
                                <div className={state.first === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx5000_section3_front.png" alt="front"/>
                                    </div>
                                    <p>* The license plate is mosaicized to protect personal information.</p>
                                </div>
                                <div className={state.first === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx5000_section3_rear.png" alt="rear"/>
                                    </div>
                                    <ul>
                                        <li>* The license plate is mosaicized to protect personal information.</li>
                                        <li>* The quality of recording may vary depending on conditions (including camera angle, windshield conditions, and light conditions).</li>
                                    </ul>
                                </div>
                            </div>
                        </section>

                        <section className="section_4" ref={scrollRef[4]}>
                            <h3>
                                <span>SONY STARVIS Sensor</span>
                                WITH SONY STARVIS SENSOR, FEEL BRIGHTER AND<br/>
                                CLEARER PERFORMANCE AT NIGHT
                            </h3>
                            <p>
                                With a SONY STARVIS Sensor, it provides brighter and clearer image resolution at night.<br/>
                                Even driving at night is reassuring with a powerful SONY STARVIS Sensor in dark alleys.
                            </p>

                            <div className="imgBox">
                                <img src="/assets/images/detail/gx5000_section4.png" alt="SONY STARVIS Sensor"/>
                            </div>
                            <ul>
                                <li>* This image sensor is applied to the front camera.</li>
                                <li>* The license plate is mosaicized to protect personal information.</li>
                            </ul>

                        </section>

                        <section className="section_5" ref={scrollRef[5]}>
                            <h3>
                                <span>HDR</span>
                                ALWAYS SUPPORT CLEAR VIDEO WITH HDR
                            </h3>
                            <p>
                                HDR is a function displaying objects clearly by automatically combining two videos with different luminance settings.<br/>
                                It is effective at night or in a place with high contrast differences where it is difficult to identify a license plate.<br/>
                                Moreover, it is more useful as it allows you to record videos clearly even where luminance varies rapidly.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section5_1.png" alt="HDR"/>
                            </div>
                            <div className="imgBox">
                                <img src="/assets/images/detail/gx5000_section5_2.png" alt="HDR"/>
                            </div>
                            <ul>
                                <li>* * The quality of recording may vary depending on conditions (including camera angle, windshield conditions, and light conditions).</li>
                                <li>* Front HDR can be turned ON/OFF in the menu settings.</li>
                                <li>* D-HDR function is automatically applied to the rear camera.</li>
                            </ul>
                        </section>

                        <section className="section_6">
                            <h3>
                                <span>IPS LCD</span>
                                HIGH RESOLUTION AND WIDE VIEWING ANGLE IPS LCD<br/>
                                TOUCH SCREEN
                            </h3>
                            <p>
                                High resolution (800x480) of IPS LCD screen is suitable to present clearer and sharper images of Full HD video.<br/>
                                On the wide LCD touch screen, get an instant video playback, change the settings including visual/audio warnings for ADAS Plus.
                            </p>

                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section6.png" alt="IPS LCD"/>
                            </div>
                        </section>

                        <section className="section_7" ref={scrollRef[6]}>
                            <h3>
                                <span>2X EFFICIENCY</span>
                                LONG-DISTANCE DRIVING WORRIES OVER!<br/>
                                SAVE 2X WITHOUT MISSING OR LOSS OF VIDEOS
                            </h3>
                            <p>
                                Smart Time Lapse function saves more than 2 times longer without video loss. In standard recording mode based on 32GB, 276 minutes are<br/>
                                recorded. However, you can record 573 minutes without overwriting with the Smart Time Lapse mode, which is more efficient.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section7.png" alt="2X EFFICIENCY"/>
                            </div>
                            <ul>
                                <li>* This is a comparison between Standard Recording and Smart Time Lapse Recording times based on 32GB.</li>
                                <li>* There may be a difference in storage time depending on other factors such as the occurrence of an impact event.</li>
                            </ul>
                        </section>

                        <section className="section_8" ref={scrollRef[7]}>
                            <h3>
                                <span>Speed Camera Alert</span>
                                THE LATEST INFORMATION OF SPEED CAMERA FOR<br/>
                                SAFE DRIVING
                            </h3>
                            <p>
                                The Speed Camera Alert is a built-in function supporting voice guidance with information on 7 types of cameras including speed, red-light,<br/>
                                and average speed camera. You can always enjoy safe driving by using the quarterly-updated DB service.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section8.png" alt="Speed Camera Alert"/>
                            </div>
                            <ul>
                                <li>* This feature may not be supported depending on the countries.</li>
                                <li>* It operates when GPS signals are received.</li>
                            </ul>
                        </section>

                        <section className="section_9">
                            <h3>
                                <span>SMART PLAYBACK</span>
                                CONVENIENT VIDEO CHECK WITH EXPANDING/SAVING<br/>
                                VIDEOS
                            </h3>
                            <p>
                                When playing a recorded video in the playback mode, You can enlarge screenshot and save images to check the parts that need to be<br/>
                                accurately identified like license plates.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section9.png" alt="SMART PLAYBACK"/>
                            </div>
                        </section>

                        <section className="section_10">
                            <h3>
                                <span>FASTER DUAL CORE CPU</span>
                                FASTER AND MORE STABLE PERFORMANCE WITH<br/>
                                HIGH-PERFORMANCE DUAL-CORE CPU
                            </h3>
                            <p>
                                Enhanced performance with built-in high-performance dual core CPU.<br/>
                                With improved processing capability, it supports our innovative functions to work more stable and faster.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section10.png" alt="FASTER DUAL CORE CPU"/>
                            </div>
                        </section>

                        <section className="section_11">
                            <h3>
                                <span>ADAS PLUS</span>
                                ADVANCED DRIVER SAFETY WARNING SYSTEMS<br/>
                                KEEP YOU SAFE ON THE ROAD
                            </h3>
                            {/* <p>
                                No more slanted videos or those where only the sky was recorded with no object!<br/>
                                It assists you to adjust the angle of your dashcam when it is slanted.<br/>
                                It also helps you to record videos at a right angle by shooting at angles customized to a car, SUV, and truck.
                            </p> */}
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section11_1.png" alt="ADAS PLUS"/>
                            </div>
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Front Vehicle Motion Alert (FVMA)</strong>
                                        When the car is stopped at an intersection or in traffic,<br/>
                                        FVMA informs the driver that the front vehicle has started to move to prevent a delayed response.
                                    </p>
                                </div>
                                <img src="/assets/images/gx5000_section11_2.png" alt="ADAS PLUS"/>
                            </div>
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Lane Departure Warning System (LDWS)</strong>
                                        When your car goes off the traffic lane, LDWS detects it in real time, and alerts the driver.
                                    </p>
                                </div>
                                <img src="/assets/images/gx5000_section11_3.png" alt="ADAS PLUS"/>
                            </div>
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Safety Screen</strong>
                                        Beyond displaying time and speed, GX5000 displays ADAS and speed cameras to help drivers drive safely.
                                    </p>
                                </div>
                                <img src="/assets/images/gx5000_section11_4.png" alt="ADAS PLUS"/>
                                <p>* It operates when GPS signals are received.</p>
                            </div>
                        </section>

                        <section className="section_12">
                            <h3>
                                <span>AUTO NIGHT VISION</span>
                                AUTOMATICALLY FINDS OPTIMAL VIDEO QUALITY
                            </h3>
                            <p>
                                AUTO NIGHT VISION enables FineVu GX5000 to record clear and balanced video in tough-to-capture environments like night time,<br/>
                                underground parking lots and tunnels, as well as very bright day light. AUTO NIGHT VISION provides the optimal video quality (brightness
                                and clarity) by automatic tuning in any conditions.
                            </p>
                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.second}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 1}))}>
                                        <span>Night</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 2}))}>
                                        <span>Day</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tabContentBox">
                                <div className={state.second === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx5000_section12_night.png" alt="night"/>
                                    </div>
                                </div>
                                <div className={state.second === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx5000_section12_day.png" alt="day"/>
                                    </div>
                                </div>
                            </div>
                            <ul>
                                <li>* The license plate is mosaicized to protect personal information.</li>
                                <li>* The quality of recording may vary depending on conditions (including camera angle, windshield conditions, and light conditions).</li>
                            </ul>
                        </section>

                        <section className="section_13">
                            <h3>
                                <span>SECRET MODE</span>
                                PROTECT YOUR VIDEO PROOF WITH PASSWORDS
                            </h3>
                            <p>
                                The records of a dashcam can be used as a video proof for your legal protection from the arguements that you may have, as well as contains<br/>
                                so many privacy information. By setting the secret mode of FineVu GX5000, the records can be protected by passwords from others.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section13.png" alt="SECRET MODE"/>
                            </div>
                        </section>

                        <section className="section_14">
                            <h3>
                                <span>INSTALLATION ANGLE GUIDE</span>
                                ANGLE GUIDE ASSISTING RECORD AT RIGHT ANGLE
                            </h3>
                            <p>
                                No more slanted videos or those where only the sky was recorded with no object! It assists you to adjust the angle of your dashcam when it<br/>
                                is slanted. It also helps you to record videos at a right angle by shooting at angles customized to a car, SUV, and truck.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section14.png" alt="INSTALLATION ANGLE GUIDE"/>
                            </div>
                            <ul>
                                <li>* Pop-ups and alarms will be exposed only once for the first time, and will be deactivated by pressing the "Do not show again" button.</li>
                                <li>* Disabled functions can be reactivated through 'Reset Settings'</li>
                            </ul>
                        </section>

                        <section className="section_15">
                            <h3>
                                <span>LICENSE PLATE ZOOM IN MODE</span>
                                CHECK OBJECTS CLOSER
                            </h3>
                            <p>
                                Do you want to see the driving situation in a dashcam a little closer? Set the Zoom Mode.<br/>
                                Zoom function supports the front and rear, making it easier to grasp the surrounding environment.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section15.png" alt="LICENSE PLATE ZOOM IN MODE"/>
                            </div>
                            <p>* Regardless of the mode, the video will be saved as the original.</p>
                        </section>

                        <section className="section_16">
                            <h3>
                                <span>DRIVING REST GUIDE</span>
                                PREVENT DROWSY DRIVING WITH VOICE GUIDANCE
                            </h3>
                            <p>
                                Don't make you drowsy while driving!<br/>
                                GX5000 guides you with voice to advise you to take a break according to the driving time and notifies you every hour.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section16.png" alt="DRIVING REST GUIDE"/>
                            </div>
                            <ul>
                                <li>* When entering the menu or switching to the parking mode, the accumulated driving time is reset.</li>
                                <li>* The hourly time notification function works only when GPS signals are received.</li>
                            </ul>
                        </section>

                        <section className="section_17">
                            <h3>
                                <span>FORMAT FREE</span>
                                ADVANCED FORMAT FREE TECHNOLOGY
                            </h3>
                            <p>
                                Format Free technology eliminates the needs of manual reformatting the micro SD memory card.<br/>
                                Also, it helps to extend memory card lifetime and improving reliability.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section17.png" alt="FORMAT FREE"/>
                            </div>
                        </section>

                        <section className="section_18">
                            <h3>
                                <span>BATTERY DISCHARGE PREVENTION</span>
                                NO WORRIES ABOUT BATTERY DISCHARGE IN HEAT AND<br/>
                                COLD!
                            </h3>
                            <p>
                                If the battery voltage of your car drops below a preset level, FineVu GX5000 automatically detects and cuts off it's own power to protect the<br/>
                                battery of your car. You can set the auto power-off voltage according to the vehicle type and battery, allowing you to utilize options<br/>
                                optimized for your car.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section18.png" alt="BATTERY DISCHARGE PREVENTION"/>
                            </div>
                            <ul>
                                <li>* For hybrid vehicles, the low voltage cutoff setting option (12.8V~13.4V) is applied.</li>
                                <li>* When using the vehicle's ISG (Idle Stop & Go) function, it is recommended to change the Low Voltage Setting of the dashcam to hybrid.</li>
                            </ul>
                        </section>

                        <section className="section_19">
                            <h3>
                                <span>HIGH TEMPERATURE PROTECTION</span>
                                DASHCAM VIDEO THAT PROTECTS EVERYTHING EVEN<br/>
                                AT HIGH TEMPERATURES
                            </h3>
                            <p>
                                FineVu GX5000 is designed for efficient heat-discharging. It has high temperature protection function that safely protect the dashcam and<br/>
                                video files stored. When the ambient air temperature exceeds over a certain level, GX5000 is automatically turned off to prevent overheating.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section19.png" alt="HIGH TEMPERATURE PROTECTION"/>
                            </div>
                        </section>

                        <section className="section_20">
                            <h3>
                                <span>FINEVU PC PLAYER</span>
                                THE OPTIMAL DASHCAM ANALYSIS PROGRAM,<br/>
                                FINEVU PLAYER 2.0
                            </h3>
                            <p>
                                FineVu PC player is an easy-to-use PC software of FiveVu dashcam.<br/>
                                You can playback, screenshot, enlarge the images with full resolution and monitor the video records conveniently on your PC.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx5000_section20.png" alt="FINEVU PC PLAYER"/>
                            </div>
                            <ul>
                                <li>* This feature can be used after installing FineVu Player program.</li>
                                <li>* It supports both Mac and Windows.</li>
                            </ul>
                        </section>
                    </div>
                    {/* <!-- Features Fin --> */}

                    <div className={`description ${tabIndex === 2 ? 'active' : ''}`}>
                        <section>
                            <h3>Product Description</h3>
                            <div className="imgBox">
                                <img src="/assets/images/fineVu_GX30_spec.jpg" alt="Product Description"/>
                            </div>
                        </section>
                    </div>
                    {/* <!-- Description Fin --> */}

                    <div className={`whatsInTheBox ${tabIndex === 3 ? 'active' : ''}`}>
                        <section>
                            <div>
                                <h3>Basic Configuration</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX5000_basic_configuration.png" alt="Basic Configuration"/>
                                </div>
                                <ul>
                                    <li>※ To improve the performance of the product, its components may change without prior notice.</li>
                                    <li>※ The warranty period of the Micro SD card is 6 months.</li>
                                </ul>
                            </div>
                            <div>
                                <h3>Additional Options</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX5000_additional_options.png" alt="Additional Options"/>
                                </div>
                            </div>
                        </section>
                    </div>
                    {/* <!-- What's In The Box Fin --> */}

                    <div className={`specifications ${tabIndex === 4 ? 'active' : ''}`}>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Model</th>
                                        <th>FineVu GX33</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Image Sensor</th>
                                        <td>
                                            Front : SONY STARVIS IMX307 2.1MP (HDR)<br></br>
                                            Rear : CMOS Image Sensor 2MP
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resolution</th>
                                        <td>
                                            Front : 1920*1080 (Full HD)<br></br>
                                            Rear : 1920*1080 (Full HD)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Frames Per Second</th>
                                        <td>
                                            Driving : 30fps, 15fps, 6fps (Smart Time Lapse)<br></br>
                                            Parking : 30fps, 15fps, 2fps (Smart Time Lapse)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Viewing Angle</th>
                                        <td>
                                            Front : 145°<br></br>
                                            Rear : 134°
                                        </td>
                                        <td>Diagonal</td>
                                    </tr>
                                    <tr>
                                        <th>Bitrate</th>
                                        <td>
                                            Front : 8 Mbps<br></br>
                                            Rear : 6 Mbps
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Recording Mode</th>
                                        <td>Drive / Parking / Smart Time Lapse / 15FPS / Emergency</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Display</th>
                                        <td>Touch 3.5’’ IPS LCD(800x480)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Acceleration Sensor</th>
                                        <td>3-Axis Acceleration Sensor</td>
                                        <td>+2g / -2g</td>
                                    </tr>
                                    <tr>
                                        <th>GPS</th>
                                        <td>External (Optional)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Wi-Fi</th>
                                        <td>X</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Storage Capacity</th>
                                        <td>FineVu Micro SD Card 32~128GB</td>
                                        <td>Class 10 or above, UHS-1 recommended, SDXC compatible</td>
                                    </tr>
                                    <tr>
                                        <th>Battery</th>
                                        <td>Supercapacitor</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Microphone</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Speaker</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Security LED</th>
                                        <td>
                                            Front : White<br></br>
                                            Rear : X
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Input Power</th>
                                        <td>12V ~ 24V DC</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Power Consumption</th>
                                        <td>
                                            Parking Mode : 4.1W<br></br>
                                            Power Saving Parking Mode : 0.1W
                                        </td>
                                        <td>2CH, GPS Off / Wi-Fi Off</td>
                                    </tr>
                                    <tr>
                                        <th>High-Temperature Cut-Off</th>
                                        <td>O</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Low Voltage Cut-Off</th>
                                        <td>Supported</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Temperature</th>
                                        <td>
                                            Operation : -10℃ ~ 60℃<br></br>
                                            Storage : -20℃ ~ 70℃
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Size(WxDxH) / Weight</th>
                                        <td>
                                            Front : 98.5 x 64.8 x 31.04 mm / 107.5g<br></br>
                                            Rear : 53.4 x 30.3 x 41.5 mm / 26.5g
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Mobile Application</th>
                                        <td>FineVu Wi-Fi App.</td>
                                        <td>Android, IOS</td>
                                    </tr>
                                    <tr>
                                        <th>PC Viewer</th>
                                        <td>FineVu PC Player 2.0</td>
                                        <td>Windows, MAC</td>
                                    </tr>
                                    <tr>
                                        <th>Warranty</th>
                                        <td>3-Years</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>
                                            HDR, Auto Night Vision, Emergency Recording, Parking Event Voice Alert, AI Damage Detection 2.0,
                                            ADAS*(FVMA&LDWS), Speed Camera Alert**, High-Temperature Cut-Off, Low Voltage Cut-Off, Overwrite On/Off,
                                            Format Free 2.0, Memory Storage Allocation, LCD Live View, Installation Angle Guide, Driving Rest Guide, Time Alarm
                                        </td>
                                        <td>ADAS and Speed Camera Alert requires GPS reception</td>
                                    </tr>
                                    <tr>
                                        <th>Regulatory Compliance</th>
                                        <td>
                                            KC ( R-R-FDK-FineR030)<br></br>
                                            Model name : FineVu GX5000<br></br>
                                            Date of manufacture : Marked separately<br></br>
                                            Name(Model) : Dashcam(GX5000)<br></br>
                                            Manufacturer : Finedigital Inc.<br></br>
                                            Company name : Finedigital Inc.<br></br>
                                            Manufacturing country : Rep. of Korea
                                        </td>
                                        <td>
                                            Passed KN41<br></br>
                                            (EMC test for vehicles, and internal combustion engines)
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Certification</th>
                                        <td className="imgArea">
                                            <div>
                                                <img src="/assets/images/table_certification.png" alt="Certification" />
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Language Supported</th>
                                        <td>
                                            English, Polish
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- Specifications Fin --> */}

                    {tabIndex === 5 ? <ManualBox/> : ''}
                    {/* <!-- Manuals & Firmware Fin --> */}

                    <div className={`usersReview ${tabIndex === 6 ? 'active' : ''}`}>
                        <div>
                            <ul>
                                <li>
                                    <img src="/assets/images/amazon_logo.png" alt="amazon logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Saul & Vivian Batres</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                It take great high quality videos, the only thing you have to consider this is hard wire but
                                                you can purchase a seperate unit to connect to a DC outlet.
                                            </p>
                                            <p>It came with a memory card and set up was easy as well.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Brandon Lanham</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Awesome dashcam. Works amazing and has high quality display and detail. Loop records
                                                the entire time you're driving. Highly recommend.
                                            </p>
                                            <p>One person found this helpful.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- amazon Fin --> */}

                            <ul>
                                <li>
                                    <img src="/assets/images/blackBoxMyCar_logo.png" alt="blackBoxMyCar logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Gus</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                I’ve had a couple of dashcams in the past. Some were very difficult to navigate. With
                                                numerous buttons and a small unit viewing window they became complicated for this ‘ol
                                                man to use. This time I wanted a simple and easy dashcam to operate.
                                            </p>
                                            <p>
                                                The FineVu GX1000 meets that criteria. It’s fairly new on the market. But found a few
                                                YouTube videos that walked you through the set up in car, how to use and review files.
                                                The simplicity of setup and operation are genius. It tucks up nicely (w/o any ugly wires)
                                                to the windshield. Plus, with the thin cables marks hardwiring a breeze.
                                            </p>
                                            <p>
                                                The video and audio quality are fantastic. Whether you’re watching on your phone or a
                                                32 inch monitor! There are many different configurations you can use capture video.
                                                Many options for notifications and parking mods. As I said, I’m a simple man and have
                                                only a notice when I start my car starts up. I’ve deselected most of the dashcams
                                                features. Why? My new 2022 vehicle already has all the safety nannies I need.
                                            </p>
                                            <p>
                                                Lastly, I like to review the videos after taking a leisurely trip through the back country
                                                roads.
                                            </p>
                                            <p>
                                                I highly recommend this unit if you’re looking for a well built, easy to setup and use dash
                                                cam.
                                            </p>
                                            <p>GWB</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Frank T.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                So far so good. I hardwired the dashcam to my truck. I also like the feature where I can
                                                manually shut the camera off with a slide switch. This prevents the vehicles battery from
                                                being drained.
                                            </p>
                                            <p>
                                                Michael at BlackboxMyCar (Canada) has been extremely helpful with answering any
                                                questions. Great customer support👏
                                            </p>
                                            <p>I've purchased from this company in the past and will continue to do so.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- blackBoxMyCar Fin --> */}

                            <ul>
                                <li>
                                    <img src="/assets/images/unavi_logo.png" alt="unavi logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Derrick Thomas</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                One of the easiest dash cameras to down load and view videos from. The videos can be
                                                Wi-Fi directly to the phone and sent via email or text. Crystal clear video and audio. Very
                                                impressed
                                            </p>
                                            <p>User picture</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Ali A.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Most dashcams I’ve seen require it to be hooked up to the cars battery which makes
                                                you tuck wires everywhere. This one plugs in and uses power straight from the rear view
                                                mirror, not having to tuck or hide any wires.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
