import React from "react";
import { useUpdMainSpecActiveStatusMutation, useUpdMainSpecImageActiveStatusMutation, useUpdMainSpecRemarkActiveStatus2Mutation, useUpdMainSpecRemarkActiveStatusMutation } from "../../../../../../store/admin/adminProductSpecApiSlice";
export interface Props {data:any, setPageInfo : Function, getList:Function}
export const ListMainSpec:React.FC<Props> = ({data,setPageInfo, getList}) => {
    const [updMainSpecActiveStatusApi] = useUpdMainSpecActiveStatusMutation()
    const [updMainSpecImageActiveStatusApi] = useUpdMainSpecImageActiveStatusMutation()
    const [updMainSpecRemarkActiveStatus2Api] = useUpdMainSpecRemarkActiveStatus2Mutation()
    const handleChangeActiveStatus = async(idx: number,status : string) => {
        const formData :any = new FormData()
        formData.append('specIdx', idx)
        formData.append('specActiveStatus', status === 'Y' ? 'N' : 'Y')
        const result : any = await updMainSpecActiveStatusApi(formData)
        if(result.data.result===true){getList()}
    }
    const handleChangeRemarkStatus = async(idx : number, status : string) => {
        const formData : any = new FormData()
        formData.append('specId', idx)
        formData.append('remarkActiveStatus', status === 'Y' ? 'N' : 'Y')
        const result : any = await updMainSpecRemarkActiveStatus2Api(formData)
        if(result.data.result===true){getList()}
    }
    const handleChangeImageStatus = async(idx: number, status : string) => {
        const formData : any = new FormData()
        formData.append('specId', idx)
        formData.append('imageActiveStatus', status === 'Y' ? 'N' : 'Y')
        const result : any = await updMainSpecImageActiveStatusApi(formData)
        if(result.data.result===true){getList()}
    }

    return(
        <>
        <tbody>
            {data?.map((x:any) => (
            <tr key={x?.specIdx}>
                <td>
                    <span className="ellipsis">{x?.specName}</span>
                </td>
                <td>{x?.specSubType}</td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={`${x?.specIdx}_1`} onChange={()=>handleChangeRemarkStatus(x?.specIdx,x?.specRemarkStatus)} className="toggleIpt" checked={x?.specRemarkStatus === 'Y' ? true : false} hidden/>
                        <label htmlFor={`${x?.specIdx}_1`} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={`${x?.specIdx}_2`} onChange={()=>handleChangeImageStatus(x?.specIdx,x?.specImageStatus)} className="toggleIpt" checked={x?.specImageStatus === 'Y' ? true : false} hidden/>
                        <label htmlFor={`${x?.specIdx}_2`} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={`${x?.specIdx}_3`} onChange={()=>handleChangeActiveStatus(x?.specIdx,x?.specActivekStatus)} className="toggleIpt" checked={x?.specActivekStatus === 'Y' ? true : false} hidden/>
                        <label htmlFor={`${x?.specIdx}_3`} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
                <td> 
                    <button onClick={()=>setPageInfo((prev:any)=>({...prev, isActive : true, mainSpecId : x?.specIdx}))}></button>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}