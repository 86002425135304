import React, { useEffect, useState } from "react";
import { useSetProductDocLangMutation, useUpdProductDocLangMutation } from "../../../../store/admin/adminProductApiSlice";
export interface Props {languageId : number, languageName : string, setPageInfo : Function, getList : Function, docType : string}
export const RegistLanguage:React.FC<Props> = ({languageId,languageName,setPageInfo,getList,docType}) => {
    const [setProductDocLangApi] = useSetProductDocLangMutation()
    const [updProductDocLangApi] = useUpdProductDocLangMutation()
    const [data, setData] = useState<any>({
        language : '', docType : ''
    })
    const handleChange = (e:any) => {
        const {name, value} = e.target;
        setData((prev:any) => ({...prev, [name] : value}))
    }
    const handleSave = async() => {
        if(data?.language === '') {alert('언어를 입력해주세요.'); return;}
        else if(data?.docType === '') {alert('유형을 선택해주세요.'); return;}
        else{
            const formData:any = new FormData()
            if(languageId !== 0){formData.append('langIdx', languageId)}
            formData.append('langNameEn', data?.language)
            formData.append('langNameKr', data?.language)
            formData.append('docType', data?.docType)
            if(languageId === 0){
                const result : any = await setProductDocLangApi(formData)
                if(result.data.result === true){
                    alert("등록이 완료되었습니다."); getList(); setPageInfo((prev: any) => ({...prev, isActive : false, languageId : 0, languageName : ''}))
                }
            }else {
                const result : any = await updProductDocLangApi(formData)
                if(result.data.result === true){
                    alert("수정이 완료되었습니다."); getList(); setPageInfo((prev: any) => ({...prev, isActive : false, languageId : 0, languageName : ''}))
                }
            }
        }
    }
    useEffect(()=>{
        setData((prev:any) => ({...prev, language : languageName, docType : docType}))
    }, [languageId])
             
    return(
        <>
        <div className="registration_popUp popUp_active active">
            <button className="closeBtn" onClick={()=>setPageInfo((prev:any)=>({...prev, isActive : false, languageId : 0, languageName : ''}))}>
                <i className="fa-light fa-xmark"></i>
            </button>

            <div className="titleBox">
                <span>Download</span>
                <span>{languageId === 0?'Add':'Edit'} Language</span>
            </div>
            <div className="userInfoBox">
                <ul>
                    <li>
                        <label htmlFor="">Language</label>
                        <input type="text" name="language" id="" value={data?.language} onChange={handleChange} placeholder="언어를 입력해 주세요."/>
                    </li>
                    
                    <li>
                        <label htmlFor="">Type</label>
                        <div>
                            <div>
                                <input type="radio" value={'F'} checked={data?.docType === 'F'} onChange={handleChange} name="docType" id="F" />
                                <label htmlFor="F">firmware</label>
                            </div>
                            <div>
                                <input type="radio" value={'M'} checked={data?.docType === 'M'} onChange={handleChange} name="docType" id="M" />
                                <label htmlFor="M">manual</label>
                            </div>
                            <div>
                                <input type="radio" value={'S'} checked={data?.docType === 'S'} onChange={handleChange} name="docType" id="S" />
                                <label htmlFor="S">Speed cam data</label>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="btnBox">
                <button onClick={()=>setPageInfo((prev:any)=>({...prev, isActive : false, languageId : 0, languageName : ''}))}>취소</button>
                <button onClick={handleSave}>{languageId === 0 ? '등록' : '수정'}</button>
            </div>
        </div>
        </>
    )
}