import React, { useState } from "react";
import { useSetInquiryMutation } from "../../../store/user/inquiryApiSlice";
export interface Props {onClose : Function}
export const Inquiry_Popup:React.FC<Props> = ({onClose}) => {
    const [setInquiryApi] = useSetInquiryMutation()
    const [data, setData] = useState<any>({name : '', email : '', message : '', attachedFile : [], agree : 'N'})
    const [fileName, setFileName] = useState<any>({attachedFile : []})
    const handleChange = (e:any) => {
        const {type, name, value, files, checked} = e.target
        if(type === 'file') {
            const reader = new FileReader()
            if(files[0]) {reader.readAsDataURL(files[0])}
            setData((prev:any) => ({...prev, [name] : files[0]}))
            setFileName((prev:any) => ({...prev, [name] : files[0].name}))
        }else{
            if(name === 'agree2') {
                setData((prev:any) => ({...prev, agree : prev.agree === 'Y' ? 'N' : 'Y'}))
            }else{
                setData((prev:any) => ({...prev, [name] : value}))
            }
        }
    }
    const handleRadio = (e:any) => {
        setData((prevData:any) => ({
            ...prevData,
            agree: prevData.agree === 'Y' ? 'N' : 'Y',
          }));
    }
    const handleInquiry = async () => {
        if(data?.agree === 'N') {
            alert('You need to check the consent items.'); return;
        }else{
            const formData : any = new FormData()
            formData.append('senderName', data?.name)
            formData.append('senderEmail', data?.email)
            formData.append('contents', data?.message)
            formData.append('attachedFile', data?.attachedFile)
            const res : any = await setInquiryApi(formData)
            if(res) {
                alert('Your inquiry submit to FineVu support team. we will contact you ASAP. Thanks.'); onClose()
            }
        }
    }
    return(
        <>
        <div className="popUpBox active">
            <div className="top">
                <span>Inquiry</span>
                <button onClick={()=>onClose()}></button>
            </div>

            <div className="formBox">
                <ul>
                    <li>
                        <label htmlFor="">Name</label>
                        <input type="text" onChange={handleChange} name="name" id=""/>
                    </li>
                    <li>
                        <label htmlFor="">Email Address</label>
                        <input type="text" onChange={handleChange} name="email" id=""/>
                    </li>
                    <li>
                        <label htmlFor="">What can I do for you?</label>
                        <textarea name="message" onChange={handleChange} id=""></textarea>
                    </li>
                    <li>
                        <div>
                            <div className="noticeBox">
                                <span>Attachment</span>
                                <div>
                                    <div>
                                        <span>Attachment</span>
                                        <span>: File upload up to 5</span>
                                    </div>
                                </div>
                            </div>
                            <div className="selectFileBox">
                                <input type="file" name="attachedFile" onChange={handleChange} multiple id="attachedFile"/>
                                <label htmlFor="attachedFile">Select a file</label>
                            </div>
                        </div>
                        {/* <!-- 파일선택박스 --> */}


                        {/* <!-- 선택된 파일이 없을 때는 active 클래스가 없는 상태, */}
                        {/* 선택된 파일이 있을 때는 active 클래스 추가 --> */}
                        <div className={data?.attachedFile ? 'active' : ''}>
                            <span>* No file selected</span>
                            {/* <!-- 선택된 file 없을 때, active가 안붙어있을 때 보임 --> */}
                            <ul>
                                <li>
                                    <div>
                                        <input type="text" name="" id="" readOnly value={fileName?.attachedFile}/>
                                    </div>
                                </li>
                                
                            </ul>
                            {/* <ul>
                                {fileName?.attachedFile?.map((file: any) => (
                                <li>
                                    <div>
                                        <input type="text" name="" id="" readOnly value={file}/>
                                    </div>
                                </li>
                                ))}
                            </ul> */}
                            {/* <!-- 선택된 file 있을 때, active가 붙어있을 때 보임 --> */}
                        </div>
                        {/* <!-- 파일선택되기전 , 후 --> */}
                    </li>
                </ul>
                <div>
                    <input type="radio" name="agree2" id="agree" onClick={handleRadio} checked={data?.agree === 'Y'}/>
                    <label htmlFor="agree">
                        I agree with the purpose of collection and{data?.agree}
                        access of personal information.
                    </label>
                </div>
            </div>
            
            <button onClick={handleInquiry}></button>
        </div>
        {/* <!-- 우측 'Inquiry'버튼 클릭 시 active 클래스 추가 --> */}
        </>
    )
}