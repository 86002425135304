import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
export interface Props {title:string, name : string, setData : Function, imageUrl : string}
export const ProductImageUploadBox:React.FC<Props> = ({title, name, setData, imageUrl}) => {
    const {productId} = useParams()
    const [previewImage, setPreviewImage] = useState<any>('')
    const handleChange = (e:any) => {
        const {name} = e.target;
        const reader = new FileReader();
        if(e.target.files[0]){reader.readAsDataURL(e.target.files[0])}
        reader.onload=()=>{
            setData((prev:any)=>({...prev, [name] : e.target.files[0]}))
            setPreviewImage(reader.result)
        }
    }
    useEffect(()=>{
        if(productId !== 'regist'){
            setPreviewImage(imageUrl)
        }
    }, [imageUrl])
    return(
        <>
        <tr>
            <th className="thumbnail">{title}</th>
            <td>
                {previewImage === '' || previewImage === undefined || previewImage === null ? 
                <div className="imgUploadBox imgUploadBox_before">
                    <input type="file" name={name} id={name} onChange={handleChange}/>
                    <label htmlFor={name} className="imgUploadBtn imgUploadBtn_before">
                        <i className="fa-light fa-upload"></i>
                        이미지 업로드
                    </label>
                </div>
                :
                <div className="imgUploadBox imgUploadBox_after">
                    <img src={previewImage} alt="thumbnail img"/>
                    <input type="file" name={name} id={name} onChange={handleChange}/>
                    <label htmlFor={name} className="imgUploadBtn imgUploadBtn_after">
                        <i className="fa-light fa-upload"></i>
                        이미지 편집
                    </label>
                </div>
                }

            </td>
        </tr>
        </>
    )
}