import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/admin/store";

export const adminStoreApiSlice = createApi({
  reducerPath: "adminStoreApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 대리점 리스트 불러오기
    getStoreList : builder.mutation({
        query : (param : {page: any, size : number, keyword: string, sortOrder : string, sortColumn : string}) => {
            return{
                url : `/getStoreLists.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
            }
        }
    }),
    // 대리점 저장
    setStores : builder.mutation({
        query : (body : any) => {
            return{
                url : '/setStores.php', method : 'post', body
            }
        }
    }),
    // 국가 selectBox 코드 가져오기
    getCommonCountry : builder.mutation({
        query : () => {
            return{
                url : '/getCommonCountrys.php'
            }
        }
    }),
    // 대리점 상세보기
    getStoreDetail : builder.mutation({
        query : (param : {idx: string}) => {
            return{
                url : `/getStoreDetails.php?idx=${param.idx}`
            }
        }
    }),
    // 대리점 수정
    uptStore : builder.mutation({
        query : (body : any) => {
            return{
                url : '/uptStores.php', method : 'post', body
            }
        }
    }),
    // 대리점 공개여부 수정
    uptStoreStatus : builder.mutation({
        query : (body : any) => {
            return{
                url : '/uptStoresShare.php', method : 'post', body
            }
        }
    })
  }),
});

export const {
  useGetStoreListMutation, useSetStoresMutation, useGetCommonCountryMutation, useGetStoreDetailMutation, useUptStoreMutation, useUptStoreStatusMutation
} = adminStoreApiSlice;