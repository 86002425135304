import React, { useEffect, useLayoutEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { RemarkRightList } from "../../../../../../components/admin/productSpec/remarkRightList";
import { SpecFilter } from "../../../../../../components/admin/productSpec/spec-filter";
import { SpecLeftList } from "../../../../../../components/admin/productSpec/spec-leftList";
import { RemarkToggleBox } from "../../../../../../components/admin/productSpec/spec-remarkToggleBox";
import { SpecRightList } from "../../../../../../components/admin/productSpec/spec-rightList";
import { useGetMainSpecRemarkMutation, useGetSpecOptionMutation } from "../../../../../../store/admin/adminProductSpecApiSlice";
import { RegistRemark } from "../../../regist/regist-remark";
import { RegistSpec } from "../../../regist/regist-spec";
export interface Props {data:any,setPageInfo:Function;getList:Function}
export const ListSpecification:React.FC<Props> = ({data,setPageInfo,getList}) => {
    const {type, typeId} : any = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const subType : any = new URLSearchParams(location.search).get('type') || 0
    const keyword = new URLSearchParams(location.search).get('keyword') || ''
    const [getSpecOptionApi] = useGetSpecOptionMutation()
    const [getMainSpecRemarkApi] = useGetMainSpecRemarkMutation()
    const initSortColumn = type === 'productSpec' ? 'date' : 'remarkName';
    const [param, setParam] = useState<any>({specId : typeId, specSubType : subType, page : 1, size : 10, keyword : '', sortOrder : 'desc', sortColumn : initSortColumn})
    const [specList , setSpecList] = useState<any>([])
    const [specInfo, setSpecInfo] = useState<any>({isActive : false, id : 0, optionName : '', imageStatus : '', imageUrl : ''})
    const handleClose = () => {setSpecInfo((prev:any)=>({...prev, isActive : false, id : 0, optionName : '', imageUrl : ''}))}
    // 선택한 메인 스펙의 사양 가져오기
    const getOptionList = async() => {
        if(type === 'productSpec'){
            const result : any = await getSpecOptionApi(param)
            if(result.data.Result === 'true'){setSpecList(result.data?.List); setPageInfo((prev:any)=>({...prev, totalCount : result.data.totalCnt}))}
        }else{
            const result : any = await getMainSpecRemarkApi(param)
            if(result.data.Result === 'true'){setSpecList(result.data?.List); setPageInfo((prev:any)=>({...prev, totalCount : result.data.totalCnt}))}
        }
    }
    useEffect(()=>{setParam((prev:any)=>({...prev, specId : typeId, specSubType : subType}))},[typeId, subType])
    useEffect(()=>{
        if(param.specId){
            getOptionList()
        }
    }, [param.specId, param.specSubType, param.keyword])
    return(
        <>
         <div className="tableWrap">

            <div className="left">
                <table className="table_common">
                    <SpecLeftList data={data} specType={param.specType} setSpecInfo={setSpecInfo} getList={getList}/>
                </table>
            </div>
            
            <div className="right">
                <div className="tableBox active">
                    <table className="table_common">
                        <SpecFilter/>
                        {type === 'productSpec' ? <SpecRightList data={specList} specInfo={specInfo} specType={param.specType} getList={getOptionList} setSpecInfo={setSpecInfo}/> : ''}
                        {type === 'remark' ? <RemarkRightList data={specList} specInfo={specInfo} specTypeCode={''} getList={getOptionList} setSpecInfo={setSpecInfo} /> : ''}
                    </table>
                </div>
                {typeId !== '0' ? <button className="addNewBtn" onClick={()=>setSpecInfo((prev:any)=>({...prev, isActive:true,id:0,optionName:''}))}>Add New</button> : ''}
                {/* <RemarkToggleBox specType={specTypeIndex} getList={getList}/> */}
            </div>
            {specInfo.isActive && type === 'productSpec' ? <RegistSpec specInfo={specInfo} specType={param.specType} getList={getOptionList} onClose={handleClose}/> : ''}
            {specInfo.isActive && type === 'remark' ? <RegistRemark specInfo={specInfo} specType={param.specType} getList={getOptionList} onClose={handleClose}/> : ''}
        </div>
        </>
    )
}