import React, { useState } from "react";
interface DataType {password : string, passwordConfirm : string}
export const ChangePassword = () => {
    const [data, setData] = useState<DataType>({password : '', passwordConfirm : ''})
    const handleChange = (e:any) => {
        const {name, value} = e.target
        setData((prev)=>({...prev, [name] : value}))
    }
    return(
        <>
        <div className="contentBox">
            <h2>Change PW</h2>
            <p>Please enter the password you want to change.</p>

            <div className="formBox login">
                <div className="inputWrap">
                    <div className="inputBox">
                        <span></span>
                        <input type="password" name="password" onChange={handleChange} placeholder="Password" required/>
                    </div>
                    <div className="inputBox">
                        <span></span>
                        <input type="password" name="passwordConfirm" onChange={handleChange} placeholder="Check your Password" required/>
                    </div>
                </div>

                <button className="orangeBtn">Change Password</button>
                
            </div>
        </div>
        </>
    )
}