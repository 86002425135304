import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetUserEmailMutation } from "../../../../../../store/user/userApiSlice";
interface DataType {name : string, serialNumber : string}
interface ResultType {isOpen : boolean, email : string}
export const FindEmail = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [data, setData] = useState<DataType>({name : '', serialNumber : ''})
    const [findEmail, setFindEmail] = useState<ResultType>({isOpen : false, email : ''})
    const [getUserEmailApi] = useGetUserEmailMutation()
    const handleTransPage = (url : string) => {
        if(location.pathname !== url){navigate(url)}
    }
    const handleChange = (e:any) => {
        const {name, value} = e.target
        setData((prev)=>({...prev, [name] : value}))
    }
    const handleFindEmail = async() => {
        const param = {name : data?.name, serialNumber : data?.serialNumber}
        const result : any = await getUserEmailApi(param)
        if(result.data.result === true && result.data.userEmail){
            setFindEmail((prev)=>({...prev, isOpen : true, email : result.data.userEmail}))
        }else{
            alert('There are no matching results.')
        }
    }
    return(
        <>
        <div className="contentBox">
            <h2>Find Email</h2>
            <p>Please enter the registered information.</p>

            <div className="formBox">
                <div className="inputWrap">
                    <div className="inputBox">
                        <label htmlFor="">Full Name</label>
                        <input type="text" name="name" onChange={handleChange} placeholder="Your Name" required/>
                    </div>
                    <div className="inputBox">
                        <label htmlFor="">Serial Number</label>
                        <input type="text" name="serialNumber" onChange={handleChange} placeholder="Serial Number" required/>
                    </div>
                </div>

                <button className="orangeBtn" onClick={handleFindEmail}>Find Email</button>
                
                <div className="btnBox">
                    <button onClick={()=>handleTransPage('/support/warrentyService/myDashcam')}>Login</button>
                    <button onClick={()=>handleTransPage('/support/warrentyService/findPassword')}>Find Password</button>
                    <button onClick={()=>handleTransPage('/support/warrentyService/prRegistration')}>Sign Up</button>
                </div>
            </div>
        </div>

        <div className={findEmail?.isOpen && findEmail?.email ? 'alertPopUp popUp_active active' : 'alertPopUp popUp_active'}>
            <div>
                <div>
                    <div className="editBox">
                        <p>
                            The email that matches the information you entered is
                            <strong>{findEmail?.email}</strong>
                        </p>
                    </div>
                </div>
                <div>
                    <button className="grayBtn" onClick={()=>handleTransPage('/support/warrentyService/findPassword')}>Find Password</button>
                    <button className="orangeBtn" onClick={()=>handleTransPage('/support/warrentyService/myDashcam')}>Login</button>
                </div>
            </div>
        </div>
        </>
    )
}