import React, { useRef, useState } from "react";
import { ManualBox } from "../manualsBox/products-manualsBox";
export const tabList = [
    {idx: 1, title : 'Features'},
    {idx: 2, title : 'Description'},
    {idx: 3, title : "What's In The Box"},
    {idx: 4, title : 'Specifications'},
    {idx: 5, title : 'Manuals & Firmware'},
    {idx: 6, title : "User's Review"},
]
export const GX4K = () => {
    const [tabIndex, setTabIndex] = useState<number>(1)
    const [state, setState] = useState<any>({first : 1, second : 1, third : 1})
    const scrollRef = [
        useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)
    ];
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
        <div className="product_view gx4k">
            <div className="bgBox" style={{backgroundImage: "url('/assets/images/banner_GX4K.png')"}}>
                <div className="centerBox">
                    <h2><span>GX</span>4K</h2>
                    <p>FINEVU 4K BEGINS, GX4K</p>
                    <ul>
                        <li onClick={()=>handleScroll(1)}>UHD/FHD</li>
                        <li onClick={()=>handleScroll(2)}>SONY STARVIS Sensor</li>
                        <li onClick={()=>handleScroll(3)}>Auto Night Vision</li>
                        <li onClick={()=>handleScroll(4)}>AI Heat Monitoring</li>
                        <li onClick={()=>handleScroll(5)}>Power Saving Parking Mode</li>
                        <li onClick={()=>handleScroll(6)}>Absolute Parking Mode</li>
                        <li onClick={()=>handleScroll(7)}>Time Lapse</li>
                        <li onClick={()=>handleScroll(8)}>ADAS PLUS</li>
                        <li onClick={()=>handleScroll(9)}>Speed Camera Alert</li>
                        <li onClick={()=>handleScroll(10)}>Low Voltage Cut-off</li>
                        <li onClick={()=>handleScroll(11)}>Format Free 2.0</li>
                        <li onClick={()=>handleScroll(12)}>Memory Allocation</li>
                        <li onClick={()=>handleScroll(13)}>Built-in GPS & Wi-Fi</li>
                        <li onClick={()=>handleScroll(14)}>FineVu Wi-Fi App</li>
                        <li onClick={()=>handleScroll(15)}>NON-LCD</li>
                    </ul>
                </div>

                <div className="scrollDownBox">
                    <div>
                        <span></span>
                    </div>
                    <span>SCROLL</span>
                </div>
            </div>

            <div className="tabWrap">
                <div className="tabBtnWrap">
                    <div className="tabBtnBox">
                        {tabList.map((list:{idx:number,title:string})=>(
                            <button className={tabIndex===list?.idx?'active':''} onClick={()=>setTabIndex(list?.idx)}>
                                {list?.title}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={`features ${tabIndex === 1? 'active' : ''}`}>

                    <div className="sliderWrap">
                        <ul>
                            <li>
                                <div className="imgBox">
                                    <img src="/assets/images/view_GX4K1.png" alt="GX4K"/>
                                </div>
                            </li>
                            {/* <!-- <li>
                                <div className="imgBox">
                                    <img src="/assets/images/view_GX4K2.png" alt="GX4K"/>
                                </div>
                            </li> --> */}
                        </ul>
                        {/* <!-- slider fin --> */}
                        <div className="dotsBox">
                            <span></span>
                            <span className="active"></span>
                            <span></span>
                        </div>
                    </div>
                    {/* <!-- sliderArea Fin --> */}

                    <div className="iconBox">
                        <ul>
                            <li>
                                <div>
                                    <img src="/assets/images/UHD_QHD.png" alt="UHD/QHD"/>
                                </div>
                                <span>UHD/FHD</span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/Sony_STARVIS.png" alt="SONY STARVIS Sensor"/>
                                </div>
                                <span>
                                    SONY STARVIS<br/>
                                    Sensor
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/AutoNightVision.png" alt="Auto Night Vision"/>
                                </div>
                                <span>
                                    Auto<br/>
                                    Night Vision
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/AIHeatDetection.png" alt="AI Heat Detection"/>
                                </div>
                                <span>
                                    AI Heat<br/>
                                    Monitoring
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/PowerSavingParkingMode.png" alt="Power Saving Parking Mode"/>
                                </div>
                                <span>
                                    Power Saving<br/>
                                    Parking Mode
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/AbsoluteParkingMode.png" alt="Absolute Parking Mode"/>
                                </div>
                                <span>
                                    Absolute<br/>
                                    Parking Mode
                                </span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div>
                                    <img src="/assets/images/SmartTimeLapse.png" alt="Smart Time Lapse"/>
                                </div>
                                <span>
                                    Time Lapse
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/ADASPLUS.png" alt="ADAS PLUS"/>
                                </div>
                                <span>ADAS PLUS</span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/SpeedCameraAlert.png" alt="Speed Camera Alert"/>
                                </div>
                                <span>
                                    Speed Camera<br/>
                                    Alert
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/LowVoltageCut_off.png" alt="Low Voltage Cut-off"/>
                                </div>
                                <span>
                                    Low Voltage<br/>
                                    Cut-off
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/FormatFree.png" alt="Format Free 2.0"/>
                                </div>
                                <span>
                                    Format Free<br/>
                                    2.0
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/MemoryAllocation.png" alt="Memory Allocation"/>
                                </div>
                                <span>
                                    Memory<br/>
                                    Allocation
                                </span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <div>
                                    <img src="/assets/images/Built_inGPS.png" alt="Built-in<br/>Built-in GPS&Wi-Fi"/>
                                </div>
                                <span>
                                    Built-in<br/>
                                    GPS&Wi-Fi
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/FineVuWi-FiApp.png" alt="FineVu Wi-Fi App"/>
                                </div>
                                <span>
                                    FineVu Wi-Fi<br/>
                                    App
                                </span>
                            </li>
                            <li>
                                <div>
                                    <img src="/assets/images/NON_LCD.png" alt="NON-LCD"/>
                                </div>
                                <span>NON-LCD</span>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- iconBox Fin --> */}

                    <div className="imgBox" ref={scrollRef[1]}>
                        <img src="/assets/images/productView_uhd.png" alt="product view"/>
                    </div>

                    <div className="resolution_tableWrap">
                        <ul>
                            <li>
                                <span>Definition</span>
                                <span>FULL HD</span>
                                <span>GX4K <span>ULTRA HD</span></span>
                            </li>
                            <li>
                                <span>Resolution</span>
                                <span>1920 × 1080</span>
                                <span>3840 × 2160</span>
                            </li>
                            <li>
                                <span>Pixels</span>
                                <span>2,073,600</span>
                                <span>8,294,400</span>
                            </li>
                        </ul>
                    </div>

                    <section className="section_1">
                        <h3>
                            <span>CRYSTAL CLEAR UHD RESOLUTION</span>
                            UHD CAPTURES EVERY MOMENT REALISTICALLY
                        </h3>

                        <div className="slide_tabBtnWrap">
                            <div className={`slide_tabBtnBox number2 active_${state.first}`}>
                                <button onClick={()=>setState((prev:any)=>({...prev, first : 1}))}>
                                    <span>Front. Day</span>
                                </button>
                                <button onClick={()=>setState((prev:any)=>({...prev, first : 2}))}>
                                    <span>Front. Night</span>
                                </button>
                            </div>
                        </div>

                        <div className="tabContentBox">
                            <div className={state.first === 1 ? 'active' : ''}>
                                <div className="imgBox">
                                    <img src="/assets/images/detail/gx4k_section1_Day.png" alt="front day"/>
                                </div>
                            </div>
                            <div className={state.first === 2 ? 'active' : ''}>
                                <div className="imgBox">
                                    <img src="/assets/images/detail/gx4k_section1_night.png" alt="night"/>
                                </div>
                            </div>
                        </div>

                        <p>* This video was taken in UHD quality. The license plate was mosaicized to protect personal information.</p>
                        <p>* The quality of recording may vary depending on conditions. (including camera angle, windshield conditions, and light conditions)</p>
                    </section>

                    <section className="section_2" ref={scrollRef[2]}>
                        <h3>
                            <span>SONY STARVIS SENSOR</span>
                            CLEAR RECORDING EVEN AT NIGHT
                        </h3>
                        <p>SONY STARVIS SENSOR provides unimaginably vivid image in regardless of day or night.</p>
                        <div className="imgBox">
                            <img src="/assets/images/detail/detail_SONY_STARVIS_SENSOR02.png" alt="CLEAR RECORDING EVEN AT NIGHT" />
                        </div>
                    </section>

                    <section className="section_3" ref={scrollRef[3]}>
                        <h3>
                            <span>AUTO NIGHT VISION</span>
                            AUTOMATED NIGHT VISION ADJUSTS<br/>
                            BRIGHTNESS AND CONTRAST TO SECURE CLARITY
                        </h3>
                        <p>
                            Smart AI system assesses light environment and finds the perfect time to switch it on.<br/>
                            Keeping you completely hand-free.
                        </p>

                        <div className="slide_tabBtnWrap">
                            <div className={`slide_tabBtnBox number2 active_${state.second}`}>
                                <button  onClick={()=>setState((prev:any)=>({...prev, second : 1}))}>
                                    <span>Night</span>
                                </button>
                                <button onClick={()=>setState((prev:any)=>({...prev, second : 2}))}>
                                    <span>Day</span>
                                </button>
                            </div>
                        </div>

                        <div className="tabContentBox">
                            <div className={state.second === 1 ? 'active' : ''}>
                                <div className="imgBox">
                                    <img src="/assets/images/detail/gx4k_section3_night.png" alt="night"/>
                                </div>
                            </div>
                            <div className={state.second === 2 ? 'active' : ''}>
                                <div className="imgBox">
                                    <img src="/assets/images/detail/gx4k_section3_day.png" alt="day"/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section_4" ref={scrollRef[4]}>
                        <h3>
                            <span>AI HEAT MONITORING</span>
                            SMART AI DASHCAM CONTROL
                        </h3>
                        <p>
                            If FineVu starts to overheat while at parking mode, the Al will automatically switch it to Power Saving Parking Mode. When it cools down and
                            temperature stabilizes, AI will switch it back to motion parking mode.
                        </p>
                        <div className="imgBox">
                            <img src="/assets/images/gx4k_section4.png" alt="SMART AI DASHCAM CONTROL"/>
                        </div>
                        <p> * The feature is only applicable during motion/time lapse parking mode.</p>
                    </section>

                <section className="section_5" ref={scrollRef[5]}>
                    <h3>
                        <span>POWER SAVING PARKING MODE</span>
                        PROLONGED RECORDING TIME
                    </h3>
                    <p>Consuming 98% less power, GX4K records 2,325 more hours than standard parking mode.</p>
                    <div className="imgBox">
                        <img src="/assets/images/gx4k_section5.png" alt="PROLONGED RECORDING TIME"/>
                    </div>
                    <ul>
                        <li>* The number of recording-standby hours is based on maximum hours provided by hardwiring to fuse tap until low voltage cut-off initiates.</li>
                        <li>* Depending on dashcam’s power consumption, environmental factors, and vehicle’s battery condition actual recording standby hours may vary.</li>
                        <li>* Certain features may not be featured during Power Saving Parking Mode.</li>
                    </ul>
                </section>

                <section className="section_6">
                    <h3>A MINUTE LONG SURVEILLANCE</h3>
                    <p>And that is not it. Any motion caught in the next 60-seconds will be secured in a minute-long footage.</p>
                    <div className="imgBox">
                        <img src="/assets/images/detail_A_MINUTE_LONG_SURVEILLANCE.png" alt="A MINUTE LONG SURVEILLANCE" />
                    </div>
                    <p>
                        Vacation, business trip, or any other long absence it may be. FineVu will look after your car.
                        <span>* Impact triggers recordings</span>
                    </p>
                </section>

                <section className="section_7" ref={scrollRef[6]}>
                    <h3>
                        <span>Absolute Parking Mode</span>
                        20-SECONDS OF COMPLETE EVIDENCE
                    </h3>
                    <p>FineVu’s Absolute Parking Mode secures vital 20-seconds of the accident scene.</p>
                    <div className="imgBox">
                        <img src="/assets/images/detail_AbsoluteParkingMode.png" alt="AI FALSE ALARM FILTER" />
                    </div>
                    <p>
                        10-seconds prior to the event, and 10-seconds after the event. No detail is left out from the scene.<br/>
                        Secure the right moment with included hardwire cable.
                        <span>* Requires hardwire cable installation</span>
                    </p>
                </section>

                <section className="section_8" ref={scrollRef[7]}>
                    <h3>
                        <span>TIME-LAPSE</span>
                        RECORD YOUR DRIVE WITH TIME-LAPSE
                    </h3>
                    <p>TIME-LAPSE feature captures up to 743 minutes with no data loss.</p>
                    <div className="imgBox">
                        <img src="/assets/images/gx4k_section8.png" alt="RECORD YOUR DRIVE WITH TIME-LAPSE"/>
                    </div>
                    <ul>
                        <li>* TIME-LAPSE Specifications were measured under 32GB and compared to the default driving mode.</li>
                        <li>* Factors such as collision events may affect the storage volume.</li>
                    </ul>
                </section>

                <section className="section_9" ref={scrollRef[8]}>
                    <h3>
                        <span>ADAS PLUS</span>
                        ADVANCED DRIVER SAFETY WARNING SYSTEMS<br/>
                        KEEP YOU SAFE ON THE ROAD
                    </h3>
                    <p>
                        ADAS(Advanced Driver Assistance Systems) helps safe driving with sound alerts.<br/>
                        The system evaluates its surroundings with integrated sensors, and warns driver if necessary.
                    </p>
                    <div className="imgBox">
                        <img src="/assets/images/detail_ADASPLUS.png" alt="ADAS PLUS"/>
                    </div>
                    <div className="imgBox">
                        <div>
                            <p>
                                <strong>Front Vehicle Motion Alert (FVMA)</strong>
                                While stopped in traffic, the movement of stationary vehicle ahead triggers alert to driver.
                            </p>
                        </div>
                        <img src="/assets/images/detail_ADASPLUS_FVMA.png" alt="Front Vehicle Motion Alert (FVMA)"/>
                    </div>
                    <div className="imgBox">
                        <div>
                            <p>
                                <strong>Lane Departure Warning System (LDWS)</strong>
                                Alert is triggered as vehicle veers off driving lane while in motion.
                            </p>
                        </div>
                        <img src="/assets/images/detail_ADASPLUS_LDWS.png" alt="Lane Departure Warning System (LDWS)"/>
                    </div>
                </section>

                <section className="section_10" ref={scrollRef[9]}>
                    <h3>
                        <span>SPEED CAMERA ALERT</span>
                        FINEVU KEEPS UP WITH<br/>
                        THE LATEST SAFETY CAMERA ALERT
                    </h3>
                    <p>
                        FineVu safety camera database is always up to date. Download the quarterly updates, and get informed with FineVu’s voice & visual alerts. Worry no
                        more about accidents and speeding tickets.
                    </p>
                    <div className="imgBox">
                        <img src="/assets/images/detail_SpeedCameraAlert.png" alt="SPEED CAMERA ALERT"/>
                    </div>
                    <p>* Database is updated quarterly.</p>
                </section>

                <section className="section_11" ref={scrollRef[10]}>
                    <h3>
                        <span>LOW VOLTAGE CUT-OFF</span>
                        BATTERY PROTECTION INTEGRATED
                    </h3>
                    <p>
                        FineVu is equipped with Battery Protection System. Dashcam powers down when battery voltage falls below preset value. Select your-car-specific
                        power setting on FineVu Wi-Fi app to maximize both dashcam and car battery with a single tap.
                    </p>
                    <div className="imgBox">
                        <img src="/assets/images/gx4k_section11.png" alt="LOW VOLTAGE CUT-OFF"/>
                    </div>
                    <p>* FineVu recommends changing the low voltage settings to ‘hybrid’ when using the ISG system.</p>
                </section>

                <section className="section_12" ref={scrollRef[11]}>
                    <h3>
                        <span>FORMAT FREE 2.0</span>
                        ADVANCED FORMAT FREE TECHNOLOGY
                    </h3>
                    <p>
                        Format Free 2.0 technology eliminates the needs of manual reformatting the micro SD memory card. Also, it helps to extend memory card lifetime and
                        improve reliability.
                    </p>
                    <div className="imgBox">
                        <img src="/assets/images/gx4k_section12.png" alt="FORMAT FREE 2.0"/>
                    </div>
                </section>

                <section className="section_13" ref={scrollRef[12]}>
                    <h3>
                        <span>MEMORY ALLOCATION</span>
                        EFFICIENT MEMORY MANAGEMENT
                    </h3>
                    <p>The memory allocation function enables efficient memory management according to the driving pattern.</p>
                    <div className="slide_tabBtnWrap">
                        <div className={`slide_tabBtnBox number4 active_${state.third}`}>
                            <button onClick={()=>setState((prev:any)=>({...prev, third : 1}))}>
                                <span>Driving Priority</span>
                            </button>
                            <button onClick={()=>setState((prev:any)=>({...prev, third : 2}))}>
                                <span>Event Priority</span>
                            </button>
                            <button onClick={()=>setState((prev:any)=>({...prev, third : 3}))}>
                                <span>Parking Priority</span>
                            </button>
                            <button onClick={()=>setState((prev:any)=>({...prev, third : 4}))}>
                                <span>Driving Only</span>
                            </button>
                        </div>
                    </div>
                    <div className="tabContentBox">
                        <div className={state.third === 1 ? 'active' : ''}>
                            <div className="imgBox">
                                <img src="/assets/images/gx4k_section13_1.png" alt="Driving Priority"/>
                            </div>
                        </div>
                        <div className={state.third === 2 ? 'active' : ''}>
                            <div className="imgBox">
                                <img src="/assets/images/gx4k_section13_2.png" alt="Event Priority"/>
                            </div>
                        </div>
                        <div className={state.third === 3 ? 'active' : ''}>
                            <div className="imgBox">
                                <img src="/assets/images/gx4k_section13_3.png" alt="Parking Priority"/>
                            </div>
                        </div>
                        <div className={state.third === 4 ? 'active' : ''}>
                            <div className="imgBox">
                                <img src="/assets/images/gx4k_section13_4.png" alt="Driving Only"/>
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li>* The MicroSD card is formatted when changing the memory allocation.</li>
                        <li>
                            * The memory format type is FAT32, and if the format type is different, the dashcam proceeds with formatting automatically.<br/>
                            (After formatting, once you insert it into the device and supply it with power, the formatting will be in progress to set the memory.)
                        </li>
                    </ul>
                </section>

                <section className="section_14" ref={scrollRef[13]}>
                    <h3>
                        <span>BUILT-IN GPS</span>
                        WITHOUT THE BURDEN OF PURCHASING ADDITIONAL EXTERNAL GPS
                    </h3>
                    <p>FEEL FREE TO USE FUNCTIONS SUCH AS SPEED CAMERA ALERT AND ADAS PLUS.</p>
                    <div className="imgBox">
                        <img src="/assets/images/gx4k_section14.png" alt="BUILT-IN GPS"/>
                    </div>
                </section>

                <section className="section_15" ref={scrollRef[14]}>
                    <h3>
                        <span>Easy Wi-Fi Pairing with FineVu App</span>
                        CHECK THE RECORDED VIDEOS AND UPDATE<br/>
                        THE FIRMWARE ON FINEVU APP
                    </h3>
                    <div className="imgBox">
                        <img src="/assets/images/detail_Easy_Wi-Fi.png" alt="Easy Wi-Fi Pairing with FineVu App"/>
                    </div>
                    <ul>
                        <li>* This product has built-in Wi-Fi and does not require a separate Wi-Fi dongle.</li>
                        <li>* The FineVu Wi-Fi app (  ) can be downloaded from the Google Play Store / App Store.</li>
                        <li>* For detailed connection method, please refer to the manual.</li>
                    </ul>
                </section>

                <section className="section_16" ref={scrollRef[15]}>
                    <h3>
                        <span>FINEVU PC PLAYER</span>
                        THE OPTIMAL DASHCAM ANALYSIS PROGRAM, FINEVU PLAYER 2.0
                    </h3>
                    <p>
                        FineVu PC player is an easy-to-use PC software of FineVu dashcam. You can playback, screenshot, enlarge the images with full resolution and
                        monitor the video records conveniently on your PC.
                    </p>
                    <div className="imgBox">
                        <img src="/assets/images/detail_FINEVU_PC_PLAYER.png" alt="FINEVU PC PLAYER"/>
                    </div>
                    <p>*This feature can be used after installing FineVu Player program.</p>
                </section>
                    </div>
                    <div className={`description ${tabIndex === 2 ? 'active' : ''}`}>
                        <section>
                            <h3>Product Description</h3>
                            <div className="imgBox">
                                <img src="/assets/images/gx4k_tab_description.png" alt="Product Description"/>
                            </div>
                        </section>
                    </div>
                    <div className={`whatsInTheBox ${tabIndex === 3 ? 'active' : ''}`}>
                        <section>
                            <div>
                                <h3>Basic Configuration</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/gx4k_tab_whatsInTheBox_1.png" alt="Basic Configuration"/>
                                </div>
                                <ul>
                                    <li>※ To improve the performance of the product, its components may change without prior notice.</li>
                                    <li>※ The warranty period of the Micro SD card is 6 months.</li>
                                </ul>
                            </div>
                            <div>
                                <h3>Additional Options</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/gx4k_tab_whatsInTheBox_2.png" alt="Additional Options"/>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className={`specifications ${tabIndex === 4 ? 'active' : ''}`}>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Model</th>
                                        <th>FineVu GX4K</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Image Sensor</th>
                                        <td>
                                            Front : SONY STARVIS IMX515 8.5MP<br></br>
                                            Rear : CMOS Image Sensor 2MP
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resolution</th>
                                        <td>
                                            Front : 3840*2160p (Ultra HD)<br></br>
                                            Rear : 1920*1080 (Full HD)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Frames Per Second</th>
                                        <td>
                                            Driving : 30fps, 6fps (Time Lapse)<br></br>
                                            Parking : 30fps, 2fps (Time Lapse)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Viewing Angle</th>
                                        <td>
                                            Front : 136°<br></br>
                                            Rear : 143°
                                        </td>
                                        <td>Diagonal</td>
                                    </tr>
                                    <tr>
                                        <th>Bitrate</th>
                                        <td>
                                            Front : 24 Mbps<br></br>
                                            Rear : 8 Mbps
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Recording Mode</th>
                                        <td>Drive / Parking / Time Lapse / Emergency</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Display</th>
                                        <td>Non-LCD</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Acceleration Sensor</th>
                                        <td>3-Axis Acceleration Sensor</td>
                                        <td>+2g / -2g</td>
                                    </tr>
                                    <tr>
                                        <th>GPS</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Wi-Fi</th>
                                        <td>Built-In(dongle) (2.4GHz/5.0Hz)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Storage Capacity</th>
                                        <td>FineVu Micro SD Card 128GB</td>
                                        <td>Class 10 or above, UHS-1 recommended, SDXC compatible</td>
                                    </tr>
                                    <tr>
                                        <th>Battery</th>
                                        <td>Supercapacitor</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Microphone</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Speaker</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Security LED</th>
                                        <td>
                                            Front : White<br></br>
                                            Rear : White
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Input Power</th>
                                        <td>DC 12V-24V</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Power Consumption</th>
                                        <td>
                                            Parking Mode : 4.8W<br></br>
                                            Power Saving Parking Mode : 0.06W
                                        </td>
                                        <td>2CH, GPS Off / Wi-Fi Off</td>
                                    </tr>
                                    <tr>
                                        <th>High-Temperature Cut-Off</th>
                                        <td>AI Heat Monitoring</td>
                                        <td>During parking mode</td>
                                    </tr>
                                    <tr>
                                        <th>Low Voltage Cut-Off</th>
                                        <td>Supported</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Temperature</th>
                                        <td>
                                            Operation : -20℃ ~ 70℃<br></br>
                                            Storage : -30℃ ~ 80℃
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Size(WxDxH) / Weight</th>
                                        <td>
                                            Front : 96.5 x 70.0 x 26.5 mm / 123g<br></br>
                                            Rear : 46.85 x 36.5 x 29.2 mm / 18g
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Mobile Application</th>
                                        <td>FineVu Wi-Fi App.</td>
                                        <td>Android, IOS</td>
                                    </tr>
                                    <tr>
                                        <th>PC Viewer</th>
                                        <td>FineVu PC Player 2.0</td>
                                        <td>Windows, MAC</td>
                                    </tr>
                                    <tr>
                                        <th>Warranty</th>
                                        <td>3-Years</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>
                                            HDR, Auto Night Vision, Image Quality Setting, Emergency Recording, Parking Event Voice Alert, AI Heat Monitoring, ADAS*(FVMA&LDWS), Speed Camera Alert**,
                                            High-Temperature Cut-Off, A.I Heat Monitoring, Low Voltage Cut-Off, Overwrite On/Off, Format Free 2.0, Memory Storage Allocation, In-App. Live View,
                                            Installation Angle Guide, Driving Rest Guide, Time Alarm
                                        </td>
                                        <td>ADAS and Speed Camera Alert requires GPS reception</td>
                                    </tr>
                                    <tr>
                                        <th>Regulatory Compliance</th>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Certification</th>
                                        <td className="imgArea">
                                            <div>
                                                <img src="/assets/images/table_certification.png" alt="Certification" />
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Language Supported</th>
                                        <td>
                                            English, Korean, Japanese, Russian, Polish, Vietnamese, German, Spanish, French, Hebrew, Thai, Chinese(Mandarin), Malay, Arabic, Turkish
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {tabIndex === 5 ? <ManualBox/> : ''}
                    <div className={`usersReview ${tabIndex === 6 ? 'active' : ''}`}>
                        <div>
                            <ul>
                                <li>
                                    <img src="/assets/images/sgcarmart_logo.png" alt="sgcarmart logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Jian Yang Mok</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Great product, excellent for my current usage. Will definitely recommend to all my friends and family. - Jian Yang Mok, Owner of BMW 420i SNM28--D
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Willy</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                UHD 4k is very clear. Love the sleek design at a reasonable price. - Willy, Owner of Honda Stepwagon SMS99--P
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Kenneth Chuan</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                I was surprised by the quality of the video, super good in day and especially better in night! The camera doesn't overheat, and the mobile app is very user friendly, easy to change the settings and download the files I require. - Kenneth Chuan, Owner of BMW 535i SNM80--R
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- amazon Fin --> */}

                            {/* <ul>
                                <li>
                                    <img src="/assets/images/blackBoxMyCar_logo.png" alt="blackBoxMyCar logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Gus</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                I’ve had a couple of dashcams in the past. Some were very difficult to navigate. With
                                                numerous buttons and a small unit viewing window they became complicated for this ‘ol
                                                man to use. This time I wanted a simple and easy dashcam to operate.
                                            </p>
                                            <p>
                                                The FineVu GX1000 meets that criteria. It’s fairly new on the market. But found a few
                                                YouTube videos that walked you through the set up in car, how to use and review files.
                                                The simplicity of setup and operation are genius. It tucks up nicely (w/o any ugly wires)
                                                to the windshield. Plus, with the thin cables marks hardwiring a breeze.
                                            </p>
                                            <p>
                                                The video and audio quality are fantastic. Whether you’re watching on your phone or a
                                                32 inch monitor! There are many different configurations you can use capture video.
                                                Many options for notifications and parking mods. As I said, I’m a simple man and have
                                                only a notice when I start my car starts up. I’ve deselected most of the dashcams
                                                features. Why? My new 2022 vehicle already has all the safety nannies I need.
                                            </p>
                                            <p>
                                                Lastly, I like to review the videos after taking a leisurely trip through the back country
                                                roads.
                                            </p>
                                            <p>
                                                I highly recommend this unit if you’re looking for a well built, easy to setup and use dash
                                                cam.
                                            </p>
                                            <p>GWB</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Frank T.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                So far so good. I hardwired the dashcam to my truck. I also like the feature where I can
                                                manually shut the camera off with a slide switch. This prevents the vehicles battery from
                                                being drained.
                                            </p>
                                            <p>
                                                Michael at BlackboxMyCar (Canada) has been extremely helpful with answering any
                                                questions. Great customer support👏
                                            </p>
                                            <p>I've purchased from this company in the past and will continue to do so.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul> */}
                            {/* <!-- blackBoxMyCar Fin --> */}

                            {/* <ul>
                                <li>
                                    <img src="/assets/images/unavi_logo.png" alt="unavi logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Derrick Thomas</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                One of the easiest dash cameras to down load and view videos from. The videos can be
                                                Wi-Fi directly to the phone and sent via email or text. Crystal clear video and audio. Very
                                                impressed
                                            </p>
                                            <p>User picture</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Ali A.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Most dashcams I’ve seen require it to be hooked up to the cars battery which makes
                                                you tuck wires everywhere. This one plugs in and uses power straight from the rear view
                                                mirror, not having to tuck or hide any wires.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
