import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/admin";

export const adminUserApiSlice = createApi({
  reducerPath: "adminUserApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getUserList : builder.mutation({
        query : (param : {page : any, size : number, keyword : string, sortOrder : string, sortColumn : string}) => {
            return{
                url : `/user/getUserLists.php?page=${param.page}&size=${param.size}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}&keyword=${param.keyword}`
            }
        }
    })
  }),
});

export const {
  useGetUserListMutation
} = adminUserApiSlice;