import { createSlice, PayloadAction } from "@reduxjs/toolkit"
interface menuState {
    menuInfo : menuListInfo
}

export interface menuListInfo {
    channel : any,
    resolution : any,
    features : any
}

const initMenuList : menuListInfo = {
    channel : [],
    resolution : [],
    features : []
};

const initialState : menuState = {
    menuInfo : initMenuList
}

export const menuInfoReducer = createSlice ({
    name : 'menuInfo',
    initialState,
    reducers : {
        setMenuList : (state, action) => {
            state.menuInfo = action.payload
        }
    },
});

export const {setMenuList} = menuInfoReducer.actions;
export default menuInfoReducer.reducer;