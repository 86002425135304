import React from "react";
import QuillEditor from "../quillEditor/quillEditor";
export interface Props {title : string, name : string, value : string, setData : Function}
export const SingleContentsBox:React.FC<Props> = ({title, setData, name, value}) => {
    const handleChange = (e:any) => {
        setData((prev:any)=>({...prev, [name] : e}))
    }
    return(
        <>
        <tr className="active">
            <th>{title}</th>
            <td>
                <QuillEditor value={value} onChange={handleChange}/> 
            </td>
        </tr>
        </>
    )
}