import React from "react";
import { useAppSelector } from "../../../store/storeHooks";
import { useLocation, useNavigate } from "react-router-dom";

export const SiteMap = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const menuInfo : any = useAppSelector(state=>state.menuInfo.menuInfo)
    const userInfo : any = useAppSelector(state=>state.loginUser.userInfo)
    const handleTransPage = (page:string) => {
        if(location.pathname !== page){navigate(page)}    
    }
    return(
        <>
        <div className="contentBox">
            <h2>
                FineVu
                <span>Site map</span>
            </h2>
            
            <div>
                <div>
                    <span className="none">Products</span>
                    <ul>
                        <li>
                            <span onClick={()=>navigate('/products?ch=&rs=&ft=')}>All items</span>
                            <span>Accessories</span>
                        </li>
                        <li>
                            <span className="none">By Channel</span>
                            {menuInfo?.channel?.map((channel : any, index : number) => (
                                <span key={index} onClick={()=>navigate(`/products?ch=${channel?.menuId}&rs=&ft=`)}>
                                    {channel?.menuName}
                                </span>
                            ))}
                        </li>
                        <li>
                            <span className="none">By Resolution</span>
                            {menuInfo?.resolution?.map((resolution : any, index : number) => (
                                <span key={index} onClick={()=>navigate(`/products?ch=&rs=${resolution?.menuId}&ft=`)}>
                                    {resolution?.menuName}
                                </span>
                            ))}
                            <span>(front cam only)</span>
                        </li>
                        <li>
                            <span className="none">By Features</span>
                            {menuInfo?.features?.map((features : any, index : number) => (
                                <span key={index} onClick={()=>navigate(`/products?ch=&rs=&ft=${features?.menuId}`)}>
                                    {features?.menuName}
                                </span>
                            ))}
                        </li>
                    </ul>
                    {/* <ul>
                        <li>
                            <span>FineVu GX4K</span>
                            <span>FineVu GX33</span>
                        </li>
                        <li>
                            <span>FineVu GX1000</span>
                            <span>FineVu GX300</span>
                        </li>
                        <li>
                            <span>FineVu GX400</span>
                            <span>FineVu GX30</span>
                        </li>
                        <li>
                            <span>FineVu GX7000</span>
                            <span>FineVu GX5000</span>
                        </li>
                    </ul> */}
                </div>
                {/* <div>
                    <span>Product Comparison</span>
                </div> */}
                <div>
                    <span className="none">Support</span>
                    <ul>
                        <li>
                            <span className="none">Download</span>
                            <span onClick={()=>handleTransPage('/support/download/dashcam')}>FineVu Dashcam</span>
                            <span onClick={()=>handleTransPage('/support/download/wifiapp')}>FineVu Wi-Fi App</span>
                            <span onClick={()=>handleTransPage('/support/download/pcplayer')}>FineVu PC Player</span>
                        </li>
                        <li>
                            <span className="none">Warranty Services</span>
                            <span onClick={()=>handleTransPage('/support/warrentyService/prRegistration')}>Product Registration</span>
                            <span onClick={()=>handleTransPage('/support/warrentyService/policy')}>Warranty Policy</span>
                            <span onClick={()=>handleTransPage(userInfo.userId ? `/support/warrentyService/myDashcam/${userInfo?.userId}` : '/support/warrentyService/myDashcam')}>My Dashcam</span>
                        </li>
                        <li onClick={()=>handleTransPage('/support/warrentyService/contact')}>
                            <span>Contact</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li onClick={()=>handleTransPage('/blog')}>
                            <span>Blog</span>
                        </li>
                        <li onClick={()=>navigate('/location')}>
                            <span>Where to Buy</span>
                        </li>
                        <li onClick={()=>handleTransPage('/fineVu')}>
                            <span>FineVu</span>
                        </li>
                        <li>
                            <span>SNS</span>
                            <ul>
                                <li className="none">SNS Link</li>
                                <li>
                                    <a href="https://www.facebook.com/FineVuDASHCAM" target="_blank">Facebook</a>
                                </li>
                                {/* <li>X (Twitter)</li> */}
                                <li>
                                    <a href="https://www.youtube.com/channel/UCcjdOYjx1kDfcYbgVz9JuCQ" target="_blank">Youtube</a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/finevu_global" target="_blank">Instagram</a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/finevu/about/" target="_blank">LinkedIn</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}