import React from "react";
export interface Props {data : any ,setData : Function}
export const ContactSection_2:React.FC<Props> = ({data, setData}) => {
    const handleChange = (e:any) => {
        setData((prev:any)=>({...prev, inquiryType : e.target.id}))
    }
    return(
        <>
        <ul>
            {radioList.map((list:any)=>(
            <li>
                <input type="radio" name="getInTouch" id={list.title} onChange={handleChange} checked={data.inquiryType===list.title ? true : false}/>
                <label htmlFor={list.title}>{list.title}</label>
            </li>
            ))}
        </ul>
        </>
    )
}

export const radioList = [
    {idx : 1, title : 'Dashcam purchasing'},
    {idx : 2, title : 'Technical support'},
    {idx : 3, title : 'Sales & Distributorship'},
    {idx : 4, title : 'Others'},
]