import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { useGetCommonCountryListMutation, useGetProductListMutation } from "../../../../../../store/user/commonApiSlice";
import { useSetUserMutation } from "../../../../../../store/user/userApiSlice";
import { useNavigate } from "react-router-dom";

export const RegistrationView = () => {
    const navigate = useNavigate()
    const [countryList, setCountryList] = useState<any>([])
    const [productList, setProductList] = useState<any>([])
    const [setUserApi] = useSetUserMutation()
    const [getCommonCountryListApi]:any = useGetCommonCountryListMutation()
    const [getProductListApi] : any = useGetProductListMutation()
    const [data, setData] = useState<any>({name : '', country : 0, serialNumber : '', product : 0, email : '', password : '', passwordConfirm : '',
    purchaseFrom : '', productLink : '', resellerName : ''})

    const getCountry = async() => {
        const result : any = await getCommonCountryListApi()
        if(result.data.result === true){
            const formatData = result.data?.list.map((list:any)=>({value : parseInt(list?.idx), label : list.country}))
            setCountryList(formatData)
        }
    }
    const getProduct = async() => {
        const result : any = await getProductListApi({})
        if(result.data.Result === 'true'){
            const formatData = result.data.List.map((list:any)=>({value : parseInt(list?.productId), label : list?.productName}))
            setProductList(formatData)
        }
    }
    const handleChange = (e:any) => {
        const {name, value} = e.target;
        setData((prev:any)=>({...prev, [name] : value}))
    }
    const handleSelect = (e:any, type:string) => {
        setData((prev:any)=>({...prev, [type] : e.value}))
    }
    const handleSave = async() => {
        const formData : any = new FormData();
        if(data?.name === '') {alert('Please enter your name.'); return;}
        if(data?.country === 0) {alert('Please select your country.'); return;}
        if(data?.serialNumber === '') {alert('Please enter your serial number.'); return;}
        if(data?.product === 0) {alert('Please select your product.'); return;}
        if(data?.email === '') {alert('Please enter your email.'); return;}
        if(data?.password === '') {
            alert('Please enter your password.'); return;
        }
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        if (!passwordPattern.test(data.password)) {
            alert('Please enter English + numeric + special symbol combination.'); 
            setData((prev:any) => ({...prev, password : '' , passwordConfirm : ''}))
            return;
        }
        if(data?.password && (data?.password !== data?.passwordConfirm)) {
            alert('Password does not match.'); return
        }

        else{
            formData.append('fullname', data.name)
            formData.append('country', data.country)
            formData.append('serial', data.serialNumber)
            formData.append('product', data.product)
            formData.append('email', data.email)
            formData.append('password', data.password)
            formData.append('purchase_form', data.purchaseFrom)
            formData.append('product_list', data.productLink)
            formData.append('reseller', data.resellerName)
            const result : any = await setUserApi(formData)
            if(result.data.result === true){alert("Thank you for registering the product."); navigate('/')}
            else{alert(result.data.resultMsg)}
        }
    }
    useEffect(()=>{getCountry(); getProduct()}, [])
    return(
        <>
        <div className="contentBox">
            <h2>
                Register
                <span>Your Dashcam</span>
            </h2>

            <div className="formBox">
                <ul>
                    <li>
                        <div className="inputBox">
                            <label htmlFor="yourDashcam_fullName">Full Name<span> *</span></label>
                            <input type="text" name="name" value={data?.name} id="yourDashcam_fullName" onChange={handleChange} placeholder="placeholder" required/>
                        </div>
                    </li>
                    <li>
                        <div className="inputBox">
                            <div className="noticeBox">
                                <label htmlFor="">Email Address<span> *</span></label>
                                <div>
                                    <span></span>
                                    <div>
                                        <div>
                                            <div>
                                                <p>
                                                    Confirmation email will be sent.
                                                    Please check your email box and
                                                    follow instruction.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input type="text" name="email" value={data?.email} onChange={handleChange} id="" placeholder="placeholder" required/>
                        </div>
                    </li>
                    <li>
                        <div className="inputBox">
                            <label htmlFor="">Password<span> *</span></label>
                            <input type="password" name="password" id="" value={data?.password} onChange={handleChange} placeholder="Please enter English + numeric + special symbol combination." required/>
                        </div>
                    </li>
                    <li>
                        <div className="inputBox">
                            <label htmlFor="">Password Confirm<span> *</span></label>
                            <input type="password" name="passwordConfirm" id="" value={data?.passwordConfirm} onChange={handleChange} placeholder="Please enter English + numeric + special symbol combination." required/>
                        </div>
                    </li>
                    <li>
                        <div className="inputBox">
                            <label htmlFor="">Country<span> *</span></label>
                            <div className="selectBox">
                            <Select
                            value={countryList?.find((x:any)=>x.value === data?.country)}
                            name={'country'}
                            options={countryList}
                            onChange={(e)=>handleSelect(e,'country')}
                            isSearchable={true}
                            placeholder={'Please Choose a Country'}
                            />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="inputBox">
                            <div className="noticeBox">
                                <label htmlFor="yourDashcam_serialNumber">Product(Serial Number)<span> *</span></label>
                                <div>
                                    <span></span>
                                    <div>
                                        <div>
                                            <div>
                                                <p>
                                                    This can be found on the back
                                                    of your dashcam and on the
                                                    packaging box.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="multiBox">
                                <div className="selectBox">
                                    <Select
                                        value={productList?.find((x:any)=>x.value === data?.product)}
                                        name={'product'}
                                        options={productList}
                                        onChange={(e)=>handleSelect(e,'product')}
                                        isSearchable={true}
                                        placeholder={'Select Product'}
                                    />
                                </div>
                                <input type="text" name="serialNumber" value={data?.serialNumber} onChange={handleChange} id="yourDashcam_serialNumber" placeholder="placeholder" required/>
                            </div>
                        </div>
                    </li>

                </ul>
                <div className="grayListBox">
                    <div className="inputBox">
                        <label htmlFor="">Purchase From</label>
                        <div className="selectBox">
                            <Select
                                value={PurchaseFromList?.find((x:any)=>x.value === data?.purchaseFrom)}
                                name={'purchaseFrom'}
                                options={PurchaseFromList}
                                onChange={(e)=>handleSelect(e,'purchaseFrom')}
                                isSearchable={true}
                                placeholder={'Please Choose a Purchase From'}
                            />
                        </div>
                    </div>
                    <div className="inputBox">
                        <label htmlFor="">Product Listing/Link/Url<span> *</span></label>
                        <input type="text" id="" value={data?.productLink} name="productLink" onChange={handleChange} placeholder="placeholder" required/>
                    </div>

                    <div className="inputBox">
                        <label htmlFor="">Reseller Name<span> *</span></label>
                        <input type="text" name="resellerName" value={data?.resellerName} id="" onChange={handleChange} placeholder="placeholder" required/>
                    </div>
                </div>
                <div className="btnBoxWrap">
                    <p>By registering a product, you are considered to have agreed to the use of personal information.</p>
                    <div className="btnBox">
                        <button className="blackBorderBtn">Cancel</button>
                        <button className="blackBtn" onClick={handleSave}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export const PurchaseFromList = [
    {value: 'Amazon', label: 'Amazon'},
    {value: 'eBay', label: 'eBay'},
    {value: 'Local reseller', label : 'Local reseller'},
    {value : 'Other', label : 'Other'},
]
