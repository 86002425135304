import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RadioBox } from "../../../../components/admin/contents/radioBox";
import { SingleInputBox } from "../../../../components/admin/contents/singleInputBox";
import { SingleSelectBox } from "../../../../components/admin/contents/singleSelectBox";
import { useGetCountryListMutation } from "../../../../store/admin/adminCountryApiSlice";
import { useGetStoreDetailMutation, useSetStoresMutation, useUptStoreMutation } from "../../../../store/admin/adminStoreApiSlice";

export const RegistStore = () => {
    const {storeId}:any = useParams()
    const navigate = useNavigate()
    const [uptStoreApi] = useUptStoreMutation()
    const [getStoreDetailApi] = useGetStoreDetailMutation()
    const [setStoreApi] = useSetStoresMutation()
    const [data, setData] = useState<any>({country : 0, type : 'W', storeName : '', address : '', tel : '', mail : ''})
    const getDetail = async() => {
        const result : any = await getStoreDetailApi({idx : storeId})
        if(result.data.result===true){
            setData((prev:any)=>({...prev, country : parseInt(result.data.country), type : result.data.type, storeName : result.data.store_name, address:result.data.address,
            tel : result.data.tel, mail : result.data.email }))
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        if(storeId){formData.append('idx', storeId)}
        formData.append('country', data.country)
        formData.append('type', data.type)
        formData.append('store_name', data.storeName)
        formData.append('address', data.address)
        formData.append('tel', data.tel)
        formData.append('email', data.mail)
        if(storeId){
            const result : any = await uptStoreApi(formData)
            if(result.data.result === true){alert("수정이 완료되었습니다."); navigate(-1)}
        }else{
            const result : any = await setStoreApi(formData)
            if(result.data.result === true){alert("등록이 완료되었습니다."); navigate('/admin/stores/store')}
        }
    }
    useEffect(()=>{if(storeId){getDetail()}},[storeId])
    return(
        <>
        <div className="contentBox upload">
            <h3>Store</h3>
            <div className="flexWrap">
                <div className="flexBox">
                    <h4>
                        Add Store
                    </h4>
                </div>
                <div>
                    <button className="blackBtn">목록으로</button>
                    <button className="blueBtn" onClick={handleSave}>저장하기</button>
                </div>
            </div>
            
            <table>
                <tbody>
                    <SingleSelectBox title={'Country'} name={'country'} value={data?.country} setData={setData}/>
                    <RadioBox title={'Type'} selectList={typeList} name={'type'} value={data?.type} setData={setData} />
                    <SingleInputBox setData={setData} name={'storeName'} value={data?.storeName} placeholder={'매장명을 입력해 주세요.'} title={'Store Name'}/>
                    <SingleInputBox setData={setData} name={'address'} value={data?.address} placeholder={'주소를 입력해 주세요.'} title={'Address'}/>
                    <SingleInputBox setData={setData} name={'tel'} value={data?.tel} placeholder={'전화번호를 입력해 주세요.'} title={'TEL'}/>
                    <SingleInputBox setData={setData} name={'mail'} value={data?.mail} placeholder={'이메일 주소를 입력해 주세요.'} title={'MAIL'}/>
                </tbody>
            </table>


        </div>
        </>
    )
}

export const typeList = [
    {idx : 1, value : 'W', title : 'Where to buy Online'},
    {idx : 2, value : 'R', title : 'Retailers'},
    {idx : 3, value : 'I', title : 'Installers'},
]