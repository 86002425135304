import React from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
export interface Props {data : any, getList : Function}
export const ListProduct:React.FC<Props> = ({data, getList}) => {
    const navigate = useNavigate()
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr key={list?.productId}>
                <td onClick={()=>navigate(`/admin/products/product/${list?.productId}`)}>
                    <img src={list?.productWhiteImageFile} alt="모델링 썸네일 이미지"/>
                </td>
                <td onClick={()=>navigate(`/admin/products/product/${list?.productId}`)}>
                    <span className="ellipsis">{list?.productName}</span>
                </td>
                <td onClick={()=>navigate(`/admin/products/product/${list?.productId}`)}><Moment format="YYYY.MM.DD">{list?.productMdate}</Moment></td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={`${list?.productId}_1`} checked={list?.productActiveStatus==='Y'?true:false} className="toggleIpt" hidden/> 
                        <label htmlFor={`${list?.productId}_1`} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}