import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/user";

export const userProductApiSlice = createApi({
  reducerPath: "userProductApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 제품 리스트 가져오기 
    getProductByOption : builder.mutation({
        query : (param : {channelType : any, resolutionType : any, featuresType : any, page : any, size : number, keyword : string, sortColumn : string, sortOrder : string, efficacy : any}) => {
            return{
                url : `/product/getProductByOption.php?channelType=${param.channelType}&resolutionType=${param.resolutionType}&featuresType=${param.featuresType}&page=${param.page}&size=${param.size}`
            }
        }
    }),
    // 제품 관련 문서 가져오기
    getProductDoc : builder.mutation({
      query : (param : {docType : string, productId : any, langType : any}) => {
        return{
          url : `/product/getProductDoc.php?docType=${param.docType}&productId=${param.productId}&langType=${param.langType}`
        }
      }
    }),
    // 제품 관련 언어 가져오기
    getDocLang : builder.mutation({
      query : (param : {docType : string, productId : any}) => {
        return{
          url : `/product/getDocLangList.php?docType=${param.docType}&productId=${param.productId}`
        }
      }
    }),
    // 제품 비교 불러오기
    getProductCompare : builder.mutation({
      query : (param : {productId : any}) => {
        return{
          url : `/product/getProductCompare.php?productId=${param.productId}`
        }
      }
    }),
    // 제품 비교 불러오기 : 비교하기 전 제품 리스트
     getProducrtByAll : builder.mutation({
      query : ({}) => {
        return{
          url : `/product/getProductByAll.php`
        }
      }
     }),
     // 제품리스트 수정사항 : All - Item 리스트에 들어가면 실행
     getProductByAll2 : builder.mutation({
      query : (param : {channelType : any, resolutionType : any, featuresType : any, page : any, size : number, keyword : string, sortColumn : string, sortOrder : string, efficacy : any}) => {
        return{
          url : `/product/getProductByAll.php?channelType=${param.channelType}&resolutionType=${param.resolutionType}&featuresType=${param.featuresType}&page=${param.page}&size=${param.size}`
        }
      }
     })
  }),
});

export const {
  useGetProductByOptionMutation, useGetProductDocMutation, useGetDocLangMutation, useGetProductCompareMutation, useGetProducrtByAllMutation, useGetProductByAll2Mutation
} = userProductApiSlice;