import React from "react";

export const Section_6 = () => {

    return(
        <>
        <section className="mainSection_6">
            <div>
                <h2>
                    Discover how other users
                    <span>feel about our dashcams.</span>
                </h2>

                <div className="reviewBox">
                    <div>
                        <div className="top">
                            <span></span>
                            <div>
                                <span>FineVu GX4K</span>
                                <span>Very user friendly</span>
                            </div>
                        </div>
                        {/* <!-- top Fin --> */}
                        <div className="bottom">
                            <p>
                                I was surprised by the quality of the video, super good in day and especially better in night! The camera doesn't overheat, and the mobile app is very user friendly, easy to change the settings and download the files I require.
                            </p>
                            {/* <!-- 3줄 이상 말줄임 필요 --> */}
                            <div>
                                <span>Review on SGcarmart</span>
                                <ul className="starBox">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!-- review 1 Fin --> */}
                    <div>
                        <div className="top">
                            <span></span>
                            <div>
                                <span>FineVu GX35</span>
                                <span>Video recording was extremely clear</span>
                            </div>
                        </div>
                        {/* <!-- top Fin --> */}
                        <div className="bottom">
                            <p>
                                Really compact and the video recording was extremely clear. Value for money as well
                            </p>
                            {/* <!-- 3줄 이상 말줄임 필요 --> */}
                            <div>
                                <span>Review on SGcarmart</span>
                                <ul className="starBox">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- bottom Fin --> */}
                    </div>
                    {/* <!-- review 2 Fin --> */}
                    <div>
                        <div className="top">
                            <span></span>
                            <div>
                                <span>FineVu GX1000</span>
                                <span>Easy to navigate the app and program</span>
                            </div>
                        </div>
                        {/* <!-- top Fin --> */}
                        <div className="bottom">
                            <p>
                                I am very satisfied with my product GX1000. Great quality. Easy to navigate the app and program. Reliable brand with excellent after sales service. Best parts is able inform speed camera. Value for money.
                            </p>
                            {/* <!-- 3줄 이상 말줄임 필요 --> */}
                            <div>
                                <span>Review on SGcarmart</span>
                                <ul className="starBox">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- bottom Fin --> */}
                    </div>
                    {/* <!-- review 3 Fin --> */}
                </div>
            </div>
        </section>
        </>
    )
}