import React from "react";
export interface Props {data : any ,setData: Function}
export const ContactFileUpload:React.FC<Props> = ({data, setData}) => {
    const handleFiles = (e:any) => {
        const files = e.target.files;
        const filesArray = Array.from(files);
        const fileNames = filesArray.map((file: any) => file.name);
        setData((prev : any) => ({ ...prev, attachedFile: filesArray , attachedFileName : fileNames}));
    }
    const handleDelFile = (file:any) => {
        const updatedFiles = data.attachedFile.filter((files:any) => files.name !== file);
        const updatedFileNames = updatedFiles.map((files : any) => files.name);
        setData((prev:any) => ({
            ...prev,
            attachedFile: updatedFiles,
            attachedFileName: updatedFileNames,
        }));
    }
    return(
        <>
        <div className="fileSelectBox">
            <div className="noticeBox">
                <div>
                    <div>
                        <span>Attachment</span>
                        <span>: File upload up to 5</span>
                    </div>
                </div>
            </div>
            <div className="selectFileBox">
                <input type="file" name="" id="select_a_file" onChange={handleFiles} multiple/>
                <label htmlFor="select_a_file">Select a file</label>
            </div>

            {/* <!-- file이 선택되면 fileNameBox에 active클래스 추가 --> */}
            <div className={data?.attachedFileName?.length > 0 ? 'fileNameBox active' : 'fileNameBox'}>
                <span>No file selected</span>
                {/* <!-- file 없을 때 --> */}
                <ul>
                    {data?.attachedFileName?.map((file:any) => (
                        
                    <li onClick={()=>handleDelFile(file)}>
                        <div>
                            <input type="text" name="" id="" value={file} readOnly/>
                            <button></button>
                        </div>
                    </li>
                    ))}
                </ul>
            </div>
        </div>
        </>
    )
}