import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/user";

export const commonApiSlice = createApi({
  reducerPath: "commonApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 모든 국가 코드 가져오기 : 회원가입 시
    getCommonCountryList : builder.mutation({
        query : () => {
            return{
                url : '/country/getCountryCommonLists.php'
            }
        }
    }),
    // 대리점 등록된 국가리스트 가져오기
     getRegistCountryList : builder.mutation({
      query : () => {
        return{
          url : '/store/getCommonCountrys.php'
        }
      }
     }),
    // 제품 리스트 : 회원가입 시
    getProductList : builder.mutation({
      query : () => {
        return{
          url : '/user/getProductList.php'
        }
      }
    })
  }),
});

export const {
  useGetCommonCountryListMutation, useGetProductListMutation, useGetRegistCountryListMutation
} = commonApiSlice;