import React, { useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const SpecFilter = () => {
    const {type} = useParams()
    const location = useLocation()
    const [data, setData] = useState<any>([])
    const [sort, setSort] = useState<any>({order : 'desc', column : 'date'})
    useLayoutEffect(()=>{
        switch(type){
            case 'productSpec' : setData(spectRightFilter); break;
            case 'remark' : setData(remarkFilter); break;
        }
    }, [location])
    return(
        <>
        <thead>
            <tr>
                {data.map((list:any) => {
                    const display = list.column === '' ? '' : 'sort'
                    return(
                    <th key={list.idx}>
                        <span className="sortWrap">
                            {list.title}
                            {sort.column === list.column ? 
                            <span className={`sort ${sort.order}`}></span> :
                            <span className={display}></span>
                            }
                        </span>
                    </th>
                    )
                })}
            </tr>
        </thead>
        </>
    )
}

export const spectRightFilter = [
    {idx: 1, title : 'No', column : ''},
    {idx: 3, title : '사양', column : 'specName'},
    {idx: 5, title : '등록일자', column : 'date'},
    {idx: 6, title : '공개여부', column : ''},
    {idx: 7, title : '수정', column : ''}, 
]

export const remarkFilter = [
    {idx : 1, title : 'No', column : ''},
    {idx : 2, title : 'Remark', column : 'remarkName'},
    {idx : 3, title : '공개여부', column : ''},
    {idx : 4, title : '수정', column : ''},
]