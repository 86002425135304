import React from "react";

export const Section_3 = () =>{

    return(
        <>
        <section className="mainSection_3">
            <div>
                <div>
                    <h2>
                        What if it doesn’t work?
                        <span>FineVu always works.</span>
                    </h2>
                    <p>
                        At FineVu, we understand the importance of a dependable dashcam 
                        when it comes to safety and security on the road. Our dashcams are 
                        engineered for consistent performance, ensuring that you can trust FineVu 
                        to capture every moment and provide the evidence you need, no matter what.
                    </p>
                </div>
                <div className="imgBox">
                    <img src="/assets/images/mainSection3.png" alt="main section3"/>
                </div>
            </div>
        </section>
        </>
    )
}