import React from "react";
import { useUpdProductDocLangActiveStatusMutation } from "../../../../../store/admin/adminProductApiSlice";
export interface Props {data: any, getList : Function, setPageInfo:Function}
export const ListLanguage:React.FC<Props> = ({data, getList, setPageInfo}) => {
    const [updProductDocLangActiveStatusApi] = useUpdProductDocLangActiveStatusMutation()
    const handleChangeLangActiveStatus = async(lang : any) => {
        const formData = new FormData()
        formData.append('langIdx', lang?.langIdx)
        formData.append('langActiveStatus', lang?.langActiveStatus === 'Y'?'N':'Y')
        const result : any = await updProductDocLangActiveStatusApi(formData)
        if(result.data.result === true){getList()}
    }
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr key={list?.langIdx}>
                <td onClick={()=>setPageInfo((prev: any) => ({...prev, isActive : true, languageId : list?.langIdx, languageName : list?.langNameEn, docType : list?.docType}))}>
                    <span className="ellipsis">{list?.langNameEn}</span>
                </td>
                <td onClick={()=>setPageInfo((prev: any) => ({...prev, isActive : true, languageId : list?.langIdx, languageName : list?.langNameEn, docType : list?.docType}))}>
                    {list?.docType === 'F' ? 'firmware' : ''}
                    {list?.docType === 'M' ? 'Manual' : ''}
                    {list?.docType === 'S' ? 'Speed cam' : ''}
                </td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={`${list?.langIdx}`} onChange={()=>handleChangeLangActiveStatus(list)} checked={list?.langActiveStatus === 'Y' ? true : false} className="toggleIpt" hidden/> 
                        <label htmlFor={`${list?.langIdx}`} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}