import React from "react";
export interface Props {data : any, getList : Function}
export const ListMember:React.FC<Props> = ({data, getList}) => {

    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr key={list?.idx}>
                <td><span className="ellipsis">{list?.dots_fullname}</span></td>
                <td><span className="ellipsis">{list?.dots_user_email}</span></td>
                <td><span className="ellipsis">{list?.dots_country}</span></td>
                <td>{list?.dots_product}</td>
                <td>{list?.dots_serial}</td>
                <td>{list?.dots_purchase_form}
                    {list?.dots_product_list === '' ? 
                    <a target="_blank" className="none">icon</a>
                    :
                    <a href={list?.dots_product_list} target="_blank" className="">icon</a>
                    }
                </td>
                {/* <!-- 링크 등록이 안됐을 때 : a태그에 none클래스 부여 시 아이콘 흐려짐 --> */}
                {list?.dots_reseller === '' ? 
                <td>-</td>:
                <td>{list?.dots_reseller}</td>
                }
                {/* <!-- Reseller Name 값이 없을 때 하이픈(-)처리 --> */}
                <td>
                    <span className="toggleSwitch">
                        {/* <!-- id값과 for 값은 고유값으로 한쌍씩 같아야 토글애니메이션 작동합니다. --> */}
                        <input type="checkbox" id={list?.idx} className="toggleIpt" hidden/> 
                        <label htmlFor={list?.idx} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}