import React, { useEffect, useState } from "react";
export interface Props {list:any, fileRef : any, fileName : string}
export const DownLoadFileUploadbox:React.FC<Props> = ({list,fileRef,fileName}) => {
    const [file, setFileName] = useState<string>('')
    const handleChange = (e:any) => {
        setFileName(e.target.files[0].name)
    }
    useEffect(()=>{
        setFileName(fileName)
    }, [fileName])
    return(
        <>
        <div className="selectFileBox"> 
            <input type="file" id={`file_${list?.docId}`} ref={(e)=>fileRef.current[list?.docId]=e} onChange={handleChange}/>
            <label htmlFor={`file_${list?.docId}`}>Select a file</label>
        </div>
        <div className="selectFileList">
            {file === '' ?
            <div>
                <span>No file selected</span>
            </div>
            :
            <div>
                <span>{file}</span>
                <button></button>
            </div>
            }
        </div>
        </>
    )
}