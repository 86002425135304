import React from "react";
export interface Props {title : string, selectList : any, name : string, value : string, setData : Function}
export const RadioBox:React.FC<Props> = ({title, selectList, name, value, setData}) => {
    const handleChange = (e : any, value : string) => {
        setData((prev:any)=>({...prev, [name] : value}))
    }
    return(
        <>
        <tr>
            <th>{title}</th>
            <td className="langSelectBox">
                <div className="radioWrap">
                    {selectList.map((list:any) => (
                    <div className="radioBox" key={list?.idx}>
                        <input type="radio" name={list?.idx} id={list?.idx} onChange={(e)=>handleChange(e,list?.value)} checked={value === list.value ? true : false}/>
                        <label htmlFor={list?.idx}>{list.title}</label>
                    </div>
                    ))}
                </div>
            </td>
        </tr>
        </>
    )
}