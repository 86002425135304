import React from "react";
export interface Props {data : any ,setData : Function}
export const ContactSection_3:React.FC<Props> = ({data, setData}) => {

    const handleChange = (e:any) => {
        const {type, name, value} = e.target;
        setData((prev:any)=>({...prev, [name] : value}))
    }

    return(
        <>
        <div className="inputBox">
            <label htmlFor="">Subject<span> *</span></label>
            <input type="text" name="subject" value={data?.subject} id="" placeholder="placeholder" onChange={handleChange} required/>
        </div>
        <div className="inputBox">
            <label htmlFor="">Your Message<span> *</span></label>
            <textarea name="message" id="" value={data?.message} placeholder="placeholder" onChange={handleChange} required></textarea>
        </div>
        </>
    )
}