import React from "react";
import { useNavigate } from "react-router-dom";
import { useUptStoreStatusMutation } from "../../../../../../store/admin/adminStoreApiSlice";
export interface Props {data: any, getList:Function, page : any, totalCount : any}
export const ListStore:React.FC<Props> = ({data, getList, page, totalCount}) => {
    const navigate = useNavigate()
    const [uptStoreStatusApi] = useUptStoreStatusMutation()
    const handleChange = async(idx: string,status : string) => {
        const formData : any = new FormData()
        formData.append('idx', idx)
        formData.append('share', status === 'Y'?'N':'Y')
        const result : any = await uptStoreStatusApi(formData)
        if(result.data.result === true){getList()}
    }
    const CalCulateIndex = (currentPage:any, pageSize:number, totalCount:number, itemIndex:number) => {
        const lastIndex = totalCount - (currentPage -1) * pageSize;
        return lastIndex - itemIndex;
    }
    return(
        <>
        <tbody>
            {data?.map((list:any, index:number) => (
            <tr>
                <td onClick={()=>navigate(`/admin/stores/store/${list?.idx}`)}>
                    <span className="ellipsis">{list?.country}</span>
                </td>
                <td onClick={()=>navigate(`/admin/stores/store/${list?.idx}`)}>
                    <span className="ellipsis">{list?.type}</span>
                </td>
                <td onClick={()=>navigate(`/admin/stores/store/${list?.idx}`)}>
                    <span className="ellipsis">{list?.store_name}</span>
                </td>
                <td onClick={()=>navigate(`/admin/stores/store/${list?.idx}`)}>
                    <span className="ellipsis">{list?.address}</span>
                </td>
                <td onClick={()=>navigate(`/admin/stores/store/${list?.idx}`)}>
                    <span className="ellipsis">{list?.tel}</span>
                </td>
                <td onClick={()=>navigate(`/admin/stores/store/${list?.idx}`)}>
                    <span className="ellipsis">{list?.email}</span>
                </td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={list?.idx} className="toggleIpt" onChange={()=>handleChange(list?.idx,list?.share_yn)} checked={list?.share_yn==='Y'?true:false} hidden/> 
                        <label htmlFor={list?.idx} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}