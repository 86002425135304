import React from "react";
import Moment from "react-moment";
import { Navigate, useNavigate } from "react-router-dom";
export interface Props {data:any}
export const ListInquiry:React.FC<Props> = ({data}) => {
    const navigate = useNavigate()
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr className="active" key={list?.idx} onClick={()=>navigate(`/admin/inquirys/inquiry/${list?.idx}`)}>
                <td>
                    <span className="answer_before">{list?.action_state}</span>
                </td>
                <td>
                    <span className="ellipsis">{list?.inquiry_type}</span>
                </td>
                <td>{list?.inquiry_title}</td>
                <td><Moment format="YYYY.MM.DD">{list?.inquiry_mdate}</Moment></td>
            </tr>
            ))}
        </tbody>
        </>
    )
}