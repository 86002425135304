import React from "react";

export interface Props2{title : string, name : string, value : string, setData : Function}
export const RadioBoxItem:React.FC<Props2> = ({title, name , value, setData}) => {
    const handleChange = (e:any, yn : string) => {
        setData((prev:any)=>({...prev, [name] : yn}))
    }
    return(
        <>
        <li>
            <label htmlFor={`${name}_y`}>{title}<span> *</span></label>
            <div>
                <div>
                    <input type="radio" name={name} onChange={(e)=>handleChange(e,'Y')} checked={value === 'Y' ? true : false} id={name}/>
                    <label htmlFor={name}>O</label>
                </div>
                <div>
                    <input type="radio" name={name} onChange={(e)=>handleChange(e,'N')} checked={value === 'N' ? true : false} id={`${name}_n`}/>
                    <label htmlFor={`${name}_n`}>X</label>
                </div>
            </div>
        </li>
        </>
    )
}