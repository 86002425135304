import React from "react";
import { useNavigate } from "react-router-dom";

export const WarrentyServiceProductRegistration = () => {
    const navigate = useNavigate()
    return(
        <>
        <div className="contentBox">
            <h2>
                Benefits for
                <span>Product Registration</span>
            </h2>

            <ul>
                <li>
                    <div>
                        <span></span>
                        <div>
                            <span>Warranty Validation:</span>
                            <p>
                                Registering a product ensures the warranty. It ensures that you can take full advantage of any warranty-related
                                services, such as repairs or replacements.
                            </p>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <span></span>
                        <div>
                            <span>Product Updates and Recalls:</span>
                            <p>
                                We can notify registered users of product updates, recalls, or safety alerts, helping customers stay informed and
                                safe.
                            </p>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <span></span>
                        <div>
                            <span>Customer Support:</span>
                            <p>Registered products can receive better customer support, including troubleshooting assistance and technical help.</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <span></span>
                        <div>
                            <span>Access to Additional Services:</span>
                            <p>We offer additional services, content, or resources exclusively to registered customers.</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <span></span>
                        <div>
                            <span>Ownership Documentation:</span>
                            <p>Registration serves as proof of ownership, which can be helpful in case of loss, theft, or insurance claims.</p>
                        </div>
                    </div>
                </li>
            </ul>

            <div className="btnBox">
                <button className="blackBtn" onClick={()=>navigate('/support/warrentyService/prRegistration/regist')}>Registration</button>
            </div>
        </div>
        </>
    )
}