import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/admin/inquiry";

export const adminInquiryApiSlice = createApi({
  reducerPath: "adminInquiryApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 문의내용가져오기
    getInquiryList : builder.mutation({
        query : (param : {page: any, size : number, keyword: string, sortOrder : string, sortColumn : string}) => {
            return{
                url : `/getInquiryLists.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
            }
        }
    }),
    // 문의 내용 상세보기
    getInquiryDetail : builder.mutation({
      query : (param : {idx : string}) => {
        return{
          url : `/getInquiryDetails.php?idx=${param.idx}`
        }
      }
    }),
    // 문의 내용 삭제
    delInquirys : builder.mutation({
      query : (param : {idx : string}) => {
        return{
          url : `/delInquirys.php?idx=${param.idx}`
        }
      }
    })
  }),
});

export const {
    useGetInquiryListMutation, useGetInquiryDetailMutation, useDelInquirysMutation
} = adminInquiryApiSlice;