import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { useGetCommonCountryMutation } from "../../../store/admin/adminStoreApiSlice";
export interface Props {title:string,name:string,value:number,setData:Function}
export const SingleSelectBox:React.FC<Props> = ({title, name, value, setData}) => {
    const [getCommonCountryApi] = useGetCommonCountryMutation()
    const getCountry = async() => {
        const result : any = await getCommonCountryApi({})
        if(result.data.result === true){
            const fommatData = result?.data?.list?.map((list:any, index:number) => ({
                value : parseInt(list?.idx), label : list.country
            }))
            setCountryList(fommatData)
        }
    }
    const [countryList, setCountryList] = useState<any>([])
    const handleChange = (e:any) => {
        setData((prev:any)=>({...prev, [name] : e.value}))
    }
    useEffect(()=>{
        getCountry()
    }, [])
    return(
        <>
        <tr>
            <th>{title}</th>
            <td>
                <Select
                    value={countryList?.find((x:any) => x.value === value)}
                    name={name}
                    options={countryList}
                    className="selectBox1"
                    onChange={handleChange}
                    isSearchable={true}
                    placeholder={'Please Choose a country'}
                />
            </td>
        </tr>
        </>
    )
}