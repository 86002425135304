import React, { useEffect, useState } from "react";
import { Section_1 } from "./section/section_1";
import { Section_2 } from "./section/section_2";
import { Section_3 } from "./section/section_3";
import { Section_4 } from "./section/section_4";
import { Section_5 } from "./section/section_5";
import { Section_6 } from "./section/section_6";
import { Section_7 } from "./section/section_7";
export const Main = () => {
    const handleChange = () => {}
    return(
        <>
        <div className="user_wrap">
            <Section_1/>
            <Section_2/>
            <Section_3/>
            <Section_4/>
            <Section_5/>
            <Section_6/>
            <Section_7/>
        </div>
        </>
    )
}