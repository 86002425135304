import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../store/storeHooks";
import { useLocation, useNavigate } from "react-router-dom";

const Header_Moblie = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [isTop, setTop] = useState<boolean>(false)
    const [isActive, setActive] = useState<boolean>(false)
    const menuInfo : any = useAppSelector(state => state.menuInfo.menuInfo)
    const userInfo : any = useAppSelector(state => state.loginUser.userInfo)
    const handleTransPage = (url : string) => {
        if(location.pathname !== url)
        navigate(url)
    }
    useEffect(()=>{
        const handleScroll = () => {
            const scrollY = window.scrollY
            setTop(scrollY > 100)
        }
        window.addEventListener('scroll', handleScroll)
        return()=>{window.removeEventListener('scroll', handleScroll)}
    })
    useEffect(()=>{
        setActive(false)
    }, [location.pathname])
    return(
        <>
        {/* <!-- 모바일 헤더 : 스크롤 시 top이라는 클래스가 붙은 div에 active클래스 추가. -->
        <!-- hamburger 버튼 클릭 시 header_mo에 active클래스 추가. --> */}
        <div className={isActive ? 'header_mo active' : 'header_mo'}>
            <div className={isTop || isActive || location.pathname !== '/' ? 'top active' : 'top'}>
                <h1>
                    <span style={{cursor : 'pointer'}} onClick={()=>navigate('/')}>
                        logo
                    </span>
                </h1>

                <div className="right_menu">
                    {/* <!-- <div className="lang_box">
                        <button></button>
                        <div className="lang_tit">
                            <span>KR</span>
                            <span>EN</span>
                        </div>
                    </div> --> */}
                    <button className="hamburger" onClick={()=>setActive(!isActive)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
            <div className="nav">
                <ul>
                    <li>
                        <span>
                            <span onClick={()=>navigate('/products?ch=&rs=&ft=')}>Products</span>
                            <span><img src="assets/images/header_subArrow.png" alt="arrow"/></span>
                        </span>
                        <ul className="mo_sub_menu">
                            <li onClick={()=>navigate('/products?ch=&rs=&ft=')}>
                                <span>All Items</span>
                            </li>
                            <li>
                                <span className="none">By Channel</span>
                                <ul>
                                    {menuInfo?.channel?.map((channel : any, index : number) => (
                                        <li key={index} onClick={()=>navigate(`/products?ch=${channel?.menuId}&rs=&ft=`)}>
                                            {channel?.menuName}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <span className="none">By Resolution</span>
                                <ul>
                                    {menuInfo?.resolution?.map((resolution : any, index : number) => (
                                    <li key={index} onClick={()=>navigate(`/products?ch=&rs=${resolution?.menuId}&ft=`)}>
                                        {resolution?.menuName}
                                    </li>
                                    ))}
                                    <li className="none">(front cam only)</li>
                                </ul>
                            </li>
                            <li>
                                <span className="none">By Features</span>
                                <ul>
                                    {menuInfo?.features?.map((features : any, index : number) => (
                                        <li key={index} onClick={()=>navigate(`/products?ch=&rs=&ft=${features?.menuId}`)}>
                                            {features?.menuName}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <span>Accessories</span>
                            </li>
                        </ul>
                    </li>
                    <li onClick={()=>handleTransPage('/productComparison')}>
                        <span>
                            <span>Product Comparison</span>
                        </span>
                    </li>
                    <li>
                        <span>
                            <span>Support</span>
                            <span><img src="assets/images/header_subArrow.png" alt="arrow"/></span>
                        </span>
                        <ul className="mo_sub_menu">
                            <li>
                                <span className="none">Download</span>
                                <ul>
                                    <li onClick={() => handleTransPage('/support/download/dashcam')}>FineVu Dashcam</li>
                                    <li onClick={() => handleTransPage('/support/download/wifiapp')}>FineVu App</li>
                                    <li onClick={() => handleTransPage('/support/download/pcplayer')}>FineVu PC Player</li>
                                </ul>
                            </li>
                            <li>
                                <span className="none">Warranty Services</span>
                                <ul>
                                    <li onClick={() => handleTransPage('/support/warrentyService/prRegistration')}>Product Registration</li>
                                    <li onClick={() => handleTransPage('/support/warrentyService/policy')}>Warranty Policy</li>
                                    <li onClick={() => handleTransPage(userInfo.userId ? `/support/warrentyService/myDashcam/${userInfo?.userId}` : '/support/warrentyService/myDashcam')}>My Dashcam</li>
                                </ul>
                            </li>
                            <li onClick={() => handleTransPage('/support/warrentyService/trouble-shooting')}>
                                <span>Troubleshooting</span>
                            </li>
                            <li onClick={() => handleTransPage('/support/warrentyService/contact')}>
                                <span>Contact</span>
                            </li>
                            <li onClick={() => handleTransPage('/location')}>
                                <span>Where to Buy</span>
                            </li>
                        </ul>
                    </li>
                    <li onClick={() => handleTransPage('/blog')}>
                        <span>
                            <span>Blog</span>
                        </span>
                    </li>
                    <li onClick={()=>handleTransPage('/fineVu')}>
                        <span>
                            <span>FineVu</span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default Header_Moblie;