import React, { useRef, useState } from "react";
import { ManualBox } from "../manualsBox/products-manualsBox";
export const tabList = [
    {idx: 1, title : 'Features'},
    {idx: 2, title : 'Description'},
    {idx: 3, title : "What's In The Box"},
    {idx: 4, title : 'Specifications'},
    {idx: 5, title : 'Manuals & Firmware'},
    {idx: 6, title : "User's Review"},
]
export const GX7000 = () => {
    const [state, setState] = useState<any>({first : 1, second : 1, third : 1})
    const [tabIndex, setTabIndex] = useState<number>(1)
    const scrollRef = [
        useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)
    ];
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
        <div className="product_view gx7000">
            <div className="bgBox" style={{backgroundImage: "url('/assets/images/banner_GX7000.png')"}}>
                <div className="centerBox">
                    <h2><span>GX</span>7000</h2>
                    <p>QHD IPS LCD DASHCAM, 5GHZ WI-FI</p>
                    <ul>
                        <li onClick={()=>handleScroll(0)}>QHD/FHD</li>
                        <li onClick={()=>handleScroll(1)}>HDR</li>
                        <li onClick={()=>handleScroll(2)}>IPS LCD</li>
                        <li onClick={()=>handleScroll(3)}>Auto Night Vision</li>
                        <li onClick={()=>handleScroll(4)}>SONY STARVIS Sensor</li>
                        <li onClick={()=>handleScroll(5)}>FineVu Wi-Fi App</li>
                        <li onClick={()=>handleScroll(6)}>Power Saving Parking Mode</li>
                        <li onClick={()=>handleScroll(7)}>Absolute Parking Mode</li>
                        <li onClick={()=>handleScroll(8)}>Smart Time Lapse</li>
                        <li onClick={()=>handleScroll(9)}>ADAS</li>
                        <li onClick={()=>handleScroll(10)}>High Temperature Protection</li>
                        <li onClick={()=>handleScroll(11)}>Battery Protection</li>
                        <li onClick={()=>handleScroll(12)}>Memory Allocation</li>
                        <li onClick={()=>handleScroll(13)}>Speed Camera Alert</li>
                        <li onClick={()=>handleScroll(14)}>Secret Mode</li>
                    </ul>
                </div>

                <div className="scrollDownBox">
                    <div>
                        <span></span>
                    </div>
                    <span>SCROLL</span>
                </div>
            </div>
            {/* <!-- bgBox Fin --> */}

            <div className="tabWrap">
                <div className="tabBtnWrap">
                    <div className="tabBtnBox">
                        {tabList.map((list:{idx:number,title:string})=>(
                            <button className={tabIndex===list?.idx?'active':''} onClick={()=>setTabIndex(list?.idx)}>
                                {list?.title}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={`features ${tabIndex === 1? 'active' : ''}`}>
                        <div className="sliderWrap">
                            <ul>
                                <li>
                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX7000_1.png" alt="GX7000"/>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- slider fin --> */}
                            <div className="dotsBox">
                                <span></span>
                                <span className="active"></span>
                                <span></span>
                            </div>
                        </div>
                        {/* <!-- sliderArea Fin --> */}


                        <div className="iconBox">
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/QHDFHD.png" alt="QHD/FHD" />
                                    </div>
                                    <span>QHD/FHD</span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/hdr_icon.png" alt="HDR" />
                                    </div>
                                    <span>
                                        HDR
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/IPSLCD.png" alt="IPS LCD" />
                                    </div>
                                    <span>
                                        IPS LCD
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/AutoNightVision.png" alt="Auto Night Vision" />
                                    </div>
                                    <span>
                                        Auto<br />
                                        Night Vision
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/Sony_STARVIS.png" alt="SONY STARVIS Sensor" />
                                    </div>
                                    <span>
                                        SONY STARVIS<br />
                                        Sensor
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/FineVuWi-FiApp.png" alt="FineVu Wi-Fi App" />
                                    </div>
                                    <span>
                                        FineVu Wi-Fi<br />
                                        App
                                    </span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/AbsoluteParkingMode.png" alt="Absolute Parking Mode" />
                                    </div>
                                    <span>
                                        Absolute<br />
                                        Parking Mode
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/SmartTimeLapse.png" alt="Smart Time Lapse" />
                                    </div>
                                    <span>
                                        Smart<br />
                                        Time Lapse
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/ADAS.png" alt="ADAS" />
                                    </div>
                                    <span>
                                        ADAS
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/HighTemperatureProtection.png" alt="High Temperature Protection" />
                                    </div>
                                    <span>
                                        High Temperature<br />
                                        Protection
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/BatteryProtection.png" alt="Battery Protection" />
                                    </div>
                                    <span>
                                        Battery<br />
                                        Protection
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/MemoryAllocation.png" alt="Memory Allocation" />
                                    </div>
                                    <span>
                                        Memory<br />
                                        Allocation
                                    </span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div>
                                        <img src="/assets/images/SpeedCameraAlert.png" alt="Speed Camera Alert" />
                                    </div>
                                    <span>
                                        Speed Camera<br />
                                        Alert
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <img src="/assets/images/SecretMode.png" alt="Secret Mode" />
                                    </div>
                                    <span>
                                        Secret<br />
                                        Mode
                                    </span>
                                </li>
                            </ul>
                        </div>
                        {/* <!-- iconBox Fin --> */}

                        <div className="imgBox">
                            <img src="/assets/images/productView_qhd_2k.png" alt="product view"/>
                        </div>

                        <div className="resolution_tableWrap">
                            <ul>
                                <li>
                                    <span>Definition</span>
                                    <span>FULL HD</span>
                                    <span><span>QUAD HD</span></span>
                                </li>
                                <li>
                                    <span>Resolution</span>
                                    <span>1920 × 1080</span>
                                    <span>2560 × 1440</span>
                                </li>
                                <li>
                                    <span>Pixels</span>
                                    <span>2,073,600</span>
                                    <span>3,686,400</span>
                                </li>
                            </ul>
                        </div>

                        <section className="section_1" ref={scrollRef[0]}>
                            <h3>
                                <span>Crystal Clear QHD Resolution</span>
                                QHD CAPTURES EVERY MOMENT REALISTICALLY
                            </h3>
                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.first}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 1}))}>
                                        <span>Front</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, first : 2}))}>
                                        <span>Rear</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tabContentBox">
                                <div className={state.first === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx7000_section1_front.png" alt="front"/>
                                    </div>
                                </div>
                                <div className={state.first === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx7000_section1_rear.png" alt="rear"/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section_2" ref={scrollRef[1]}>
                            <h3>
                                <span>HDR</span>
                                OPTIMIZED WITH HIGH DYNAMIC RANGE(HDR)
                            </h3>
                            <p>Exposure is balanced for keeping all details intact.</p>
                            <div className="imgBox">
                                <img src="/assets/images/detail/gx7000_section2.png" alt="HDR"/>
                            </div>
                        </section>

                        <section className="section_3" ref={scrollRef[2]}>
                            <h3>
                                <span>IPS LCD</span>
                                HIGH RESOLUTION AND WIDE VIEWING ANGLE IPS LCD<br/>
                                TOUCH SCREEN
                            </h3>
                            <p>
                                High resolution (800x480) of IPS LCD screen is suitable to present clearer and sharper images of video footages. On the wide LCD touch<br/>
                                screen, get an instant video playback, change the settings including visual/audio warnings for ADAS.
                            </p>

                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section3.png" alt="IPS LCD"/>
                            </div>
                        </section>

                        <section className="section_4" ref={scrollRef[3]}>
                            <h3>
                                <span>AUTO NIGHT VISION</span>
                                AUTOMATED NIGHT VISION ADJUSTS<br/>
                                BRIGHTNESS AND CONTRAST TO SECURE CLARITY
                            </h3>
                            <p>
                                Smart AI system assesses light environment and finds the perfect time to switch it on.<br/>
                                Keeping you completely hand-free.
                            </p>

                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number2 active_${state.second}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 1}))}>
                                        <span>NIGHT</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, second : 2}))}>
                                        <span>DAY</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tabContentBox">
                                <div className={state.second === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx7000_section4_night.png" alt="night"/>
                                    </div>
                                </div>
                                <div className={state.second === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx7000_section4_day.png" alt="day"/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section_5" ref={scrollRef[4]}>
                            <h3>
                                <span>SONY STARVIS Sensor</span>
                                CLEAR RECORDING EVEN AT NIGHT
                            </h3>
                            <p>SONY STARVIS IMX335 provides unimaginably vivid image in regardless of day or night.</p>
                            <div className="imgBox">
                                <img src="/assets/images/detail/detail_SONY_STARVIS_SENSOR05.png" alt="SONY STARVIS Sensor"/>
                            </div>
                        </section>

                        <section className="section_6" ref={scrollRef[5]}>
                            <h3>
                                <span>Easy Wi-Fi Pairing with FineVu App</span>
                                CHECK THE RECORDED VIDEOS AND UPDATE THE<br/>
                                FIRMWARE ON FINEVU APP
                            </h3>
                            <div className="imgBox">
                                <img src="/assets/images/detail_Easy_Wi-Fi.png" alt="Easy Wi-Fi Pairing with FineVu App"/>
                            </div>
                            <ul>
                                <li>* This product has built-in Wi-Fi and does not require a separate Wi-Fi dongle.</li>
                                <li>* The FineVu Wi-Fi app can be downloaded from the Google Play Store / App Store.</li>
                                <li>* For detailed connection method, please refer to the manual.</li>
                            </ul>
                        </section>

                        <section className="section_7">
                            <h3>
                                <span>SONY STARVIS Sensor</span>
                                PROLONGED RECORDING TIME
                            </h3>
                            <p>Consuming 98% less power, GX7000 records 2,583 more hours than standard parking mode.</p>
                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section7.png" alt="SONY STARVIS Sensor"/>
                            </div>
                        </section>

                        <section className="section_8" ref={scrollRef[6]}>
                            <h3>A MINUTE LONG SURVEILLANCE</h3>
                            <p>And that is not it. Any motion caught in the next 60-seconds will be secured in a minute-long footage.</p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_A_MINUTE_LONG_SURVEILLANCE.png" alt="A MINUTE LONG SURVEILLANCE"/>
                            </div>
                            <p>
                                Vacation, business trip, or any other long absence it may be. FineVu will look after your car.
                                <mark> * Impact triggers recordings.</mark>
                            </p>
                        </section>

                        <section className="section_9" ref={scrollRef[7]}>
                            <h3>
                                <span>Absolute Parking Mode</span>
                                20-SECONDS OF COMPLETE EVIDENCE
                            </h3>
                            <p>FineVu’s Absolute Parking Mode secures vital 20-seconds of the accident scene.</p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_AbsoluteParkingMode.png" alt="Absolute Parking Mode"/>
                            </div>
                            <p>
                                10-seconds prior to the event, and 10-seconds after the event. No detail is left out from the scene. Secure the right moment with included<br/>
                                hardwire cable.
                                <mark>* Requires hardwire cable installation</mark>
                            </p>
                        </section>

                        <section className="section_10" ref={scrollRef[8]}>
                            <h3>
                                <span>SMART TIME-LAPSE</span>
                                RECORD YOUR DRIVE WITH SMART TIME-LAPSE
                            </h3>
                            <p>
                                SMART TIME-LAPSE feature captures up to 729 minutes with no data loss.<br/>
                                When an impact is detected, FineVu will automatically save the footage in 30fps.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section10.png" alt="SMART TIME-LAPSE"/>
                            </div>
                            <ul>
                                <li>* SMART TIME-LAPSE Specifications were measured under 32GB and compared to the default driving mode.</li>
                                <li>* Factors such as collision events may affect the storage volume.</li>
                            </ul>
                        </section>

                        <section className="section_11" ref={scrollRef[9]}>
                            <h3>
                                <span>ADAS</span>
                                ADVANCED DRIVER SAFETY WARNING SYSTEMS<br/>
                                KEEP YOU SAFE ON THE ROAD
                            </h3>
                            <p>
                                ADAS(Advanced Driver Assistance Systems) helps safe driving with sound alerts.<br/>
                                The system evaluates its surroundings with integrated sensors, and warns driver if necessary.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/detail_ADASPLUS.png" alt="ADAS"/>
                            </div>
                            <div className="imgBox">
                                <div>
                                    <p>
                                        <strong>Front Vehicle Motion Alert (FVMA)</strong>
                                        While stopped in traffic, the movement of stationary vehicle ahead triggers alert to driver.
                                    </p>
                                </div>
                                <div>
                                    <img src="/assets/images/detail_ADASPLUS_FVMA.png" alt="ADAS"/>
                                    <p>* This function works only when an external GPS is connected.</p>
                                </div>
                            </div>
                        </section>

                        <section className="section_12" ref={scrollRef[10]}>
                            <h3>
                                <span>HIGH TEMPERATURE PROTECTION</span>
                                PROTECTS EVERYTHING EVEN AT HIGH TEMPERATURE
                            </h3>
                            <p>
                                If FineVu GX7000 starts to overheat and exceed over certain level of temperature,<br/>
                                GX7000 is automatically turned off to protect video footage.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section12.png" alt="HIGH TEMPERATURE PROTECTION"/>
                            </div>
                        </section>

                        <section className="section_13" ref={scrollRef[11]}>
                            <h3>
                                <span>Battery Protection</span>
                                BATTERY PROTECTION INTEGRATED
                            </h3>
                            <p>
                                FineVu is equipped with Battery Protection System. Dashcam powers down when battery voltage falls below preset value. Select your car’s
                                specific power setting on FineVu Wi-Fi app to maximize both dashcam and car battery with a single tap.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section13.png" alt="Battery Protection"/>
                            </div>
                            <p>* FineVu recommends changing the low voltage settings to ‘hybrid’ when using the ISG system.</p>
                        </section>

                        <section className="section_14" ref={scrollRef[12]}>
                            <h3>
                                <span>MEMORY ALLOCATION</span>
                                EFFICIENT MEMORY MANAGEMENT
                            </h3>
                            <p>The memory allocation function enables efficient memory management according to the driving pattern.</p>

                            <div className="slide_tabBtnWrap">
                                <div className={`slide_tabBtnBox number4 active_${state.third}`}>
                                    <button onClick={()=>setState((prev:any)=>({...prev, third : 1}))}>
                                        <span>Driving Priority</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, third : 2}))}>
                                        <span>Event Priority</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, third : 3}))}>
                                        <span>Parking Priority</span>
                                    </button>
                                    <button onClick={()=>setState((prev:any)=>({...prev, third : 4}))}>
                                        <span>Driving Only</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tabContentBox">


                                <div className={state.third === 1 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx35_section14_1.png" alt="MEMORY STORAGE ALLOCATION" />
                                    </div>
                                </div>
                                <div className={state.third === 2 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx35_section14_2.png" alt="MEMORY STORAGE ALLOCATION" />
                                    </div>
                                </div>
                                <div className={state.third === 3 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx35_section14_3.png" alt="MEMORY STORAGE ALLOCATION" />
                                    </div>
                                </div>
                                <div className={state.third === 4 ? 'active' : ''}>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx35_section14_4.png" alt="MEMORY STORAGE ALLOCATION" />
                                    </div>
                                </div>


                            </div>
                            <ul>
                                <li>* The MicroSD card is formatted when changing the memory allocation.</li>
                                <li>* The memory format type is FAT32, and if the format type is different, the dashcam proceeds with formatting automatically.</li>
                                <li>(After formatting, once you insert it into the device and supply it with power, the formatting will be in progress to set the memory.)</li>
                            </ul>
                        </section>

                        <section className="section_15" ref={scrollRef[13]}>
                            <h3>
                                <span>Speed Camera Alert</span>
                                FINEVU KEEPS UP WITH THE LATEST SAFETY<br/>
                                CAMERA ALERT
                            </h3>
                            <p>
                                FineVu safety camera database is always up to date. Download the quarterly updates, and get informed with FineVu’s voice & visual alerts.
                                Worry no more about accidents and speeding tickets.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section15.png" alt="Speed Camera Alert"/>
                            </div>
                            <ul>
                                <li>* This feature is not available in all areas.</li>
                                <li>* It operates when GPS signals are received.</li>
                            </ul>
                        </section>

                        <section className="section_16" ref={scrollRef[14]}>
                            <h3>
                                <span>SECRET MODE</span>
                                PROTECT YOUR VIDEO FOOTAGE WITH PASSWORD
                            </h3>
                            <p>
                                The records of a dashcam can be used as a video proof for your legal protection from the arguments that you may have, as well as contains
                                so many private information. By setting the secret mode of FineVu GX7000,the records can be protected by password from others.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section16.png" alt="SMART SD LOCK"/>
                            </div>
                        </section>

                        <section className="section_17">
                            <h3>
                                <span>FORMAT FREE 2.0</span>
                                ADVANCED FORMAT FREE TECHNOLOGY
                            </h3>
                            <p>
                                Format Free 2.0 technology eliminates the needs of manual reformatting the micro SD memory card. Also, it helps to extend memory card<br/>
                                lifetime and improve reliability.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section17.png" alt="FORMAT FREE 2.0"/>
                            </div>
                        </section>

                        <section className="section_18">
                            <h3>
                                <span>FINEVU PC PLAYER</span>
                                THE OPTIMAL DASHCAM ANALYSIS PROGRAM,<br/>
                                FINEVU PLAYER 2.0
                            </h3>
                            <p>
                                FineVu PC player is an easy-to-use PC software of FineVu dashcam. You can playback, screenshot, enlarge the images with full resolution<br/>
                                and monitor the video records conveniently on your PC.
                            </p>
                            <div className="imgBox">
                                <img src="/assets/images/gx7000_section18.png" alt="FINEVU PC PLAYER"/>
                            </div>
                            <p>*This feature can be used after installing FineVu Player program.</p>
                        </section>
                    </div>

                    <div className={`description ${tabIndex === 2 ? 'active' : ''}`}>
                        <section>
                            <h3>Product Description</h3>
                            <div className="imgBox">
                                <img src="/assets/images/fineVu_GX7000_spec.jpg" alt="Product Description"/>
                            </div>
                        </section>
                    </div>

                    <div className={`whatsInTheBox ${tabIndex === 3 ? 'active' : ''}`}>
                        <section>
                            <div>
                                <h3>Basic Configuration</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX7000_basic_configuration.png" alt="Basic Configuration"/>
                                </div>
                                <ul>
                                    <li>※ To improve the performance of the product, its components may change without prior notice.</li>
                                    <li>※ The warranty period of the Micro SD card is 6 months.</li>
                                </ul>
                            </div>
                            <div>
                                <h3>Additional Options</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/GX7000_additional_options.png" alt="Additional Options"/>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className={`specifications ${tabIndex === 4 ? 'active' : ''}`}>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Model</th>
                                        <th>FineVu GX7000</th>
                                        <th>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Image Sensor</th>
                                        <td>
                                            Front : SONY STARVIS IMX335 5.1MP (HDR)<br></br>
                                            Rear : CMOS Image Sensor 2MP
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Resolution</th>
                                        <td>
                                            Front : 2560*1440P (Quad HD)<br></br>
                                            Rear : 1920*1080 (Full HD)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Frames Per Second</th>
                                        <td>
                                            Driving : 30fps, 15fps, 6fps (Smart Time Lapse)<br></br>
                                            Parking : 30fps, 15fps, 2fps (Smart Time Lapse)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Viewing Angle</th>
                                        <td>
                                            Front : 122°<br></br>
                                            Rear : 124°
                                        </td>
                                        <td>Diagonal</td>
                                    </tr>
                                    <tr>
                                        <th>Bitrate</th>
                                        <td>
                                            Front : 10 Mbps<br></br>
                                            Rear : 8 Mbps
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Recording Mode</th>
                                        <td>Drive / Parking / Smart Time Lapse / 15FPS / Emergency</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Display</th>
                                        <td>Touch 3.5’’ IPS LCD(800x480)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Acceleration Sensor</th>
                                        <td>3-Axis Acceleration Sensor</td>
                                        <td>+2g / -2g</td>
                                    </tr>
                                    <tr>
                                        <th>GPS</th>
                                        <td>External (Optional)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Wi-Fi</th>
                                        <td>
                                            External(dongle) Optional<br></br>
                                            (2.4GHz/5.0Hz)
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Storage Capacity</th>
                                        <td>FineVu Micro SD Card 32~128GB</td>
                                        <td>Class 10 or above, SDXC compatible</td>
                                    </tr>
                                    <tr>
                                        <th>Battery</th>
                                        <td>Supercapacitor</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Microphone</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Speaker</th>
                                        <td>Built-In</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Security LED</th>
                                        <td>
                                            Front : White<br></br>
                                            Rear : X
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Input Power</th>
                                        <td>12V ~ 24V DC</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Power Consumption</th>
                                        <td>
                                            Parking Mode : 4.8W<br></br>
                                            Power Saving Parking Mode : 0.054W
                                        </td>
                                        <td>Based on 2CH, LCD Off, Actual power consumption may vary depending on the recording setting and usage environment.</td>
                                    </tr>
                                    <tr>
                                        <th>High-Temperature Cut-Off</th>
                                        <td>O</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Low Voltage Cut-Off</th>
                                        <td>Supported</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Temperature</th>
                                        <td>
                                            Operation : -10℃ ~ 60℃<br></br>
                                            Storage : -20℃ ~ 70℃
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Size(WxDxH) / Weight</th>
                                        <td>
                                            Front : 101.2 x 60.7 x 30.1 mm / 102g<br></br>
                                            Rear : 46.9 x 27.8 x 36.5 mm / 21g
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Mobile Application</th>
                                        <td>FineVu Wi-Fi App.</td>
                                        <td>Android, IOS</td>
                                    </tr>
                                    <tr>
                                        <th>PC Viewer</th>
                                        <td>FineVu PC Player 2.0</td>
                                        <td>Windows, MAC</td>
                                    </tr>
                                    <tr>
                                        <th>Warranty</th>
                                        <td>3-Years</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Features</th>
                                        <td>
                                            HDR, Auto Night Vision, Emergency Recording, Parking Event Voice Alert, ADAS*(FVMA), Speed Camera Alert**,
                                            High-Temperature Cut-Off, Low Voltage Cut-Off, Overwrite On/Off, Format Free 2.0, Memory Storage Allocation, Privacy Mode,
                                            In-App. Live View, LCD Live View, Installation Angle Guide, Driving Rest Guide, Time Alarm
                                        </td>
                                        <td>ADAS and Speed Camera Alert requires GPS reception</td>
                                    </tr>
                                    <tr>
                                        <th>Regulatory Compliance</th>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Certification</th>
                                        <td className="imgArea">
                                            <div>
                                                <img src="/assets/images/table_certification.png" alt="Certification" />
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Language Supported</th>
                                        <td>
                                            English, Japanese, Russian, Polish, Vietnamese, German, Spanish, French, Hebrew
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {tabIndex === 5 ? <ManualBox/> : ''}

                    <div className={`usersReview ${tabIndex === 6 ? 'active' : ''}`}>
                        <div>
                            <ul>
                                <li>
                                    <img src="/assets/images/amazon_logo.png" alt="amazon logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Saul & Vivian Batres</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                It take great high quality videos, the only thing you have to consider this is hard wire but
                                                you can purchase a seperate unit to connect to a DC outlet.
                                            </p>
                                            <p>It came with a memory card and set up was easy as well.</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Brandon Lanham</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Awesome dashcam. Works amazing and has high quality display and detail. Loop records
                                                the entire time you're driving. Highly recommend.
                                            </p>
                                            <p>One person found this helpful.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- amazon Fin --> */}

                            <ul>
                                <li>
                                    <img src="/assets/images/blackBoxMyCar_logo.png" alt="blackBoxMyCar logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Gus</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                I’ve had a couple of dashcams in the past. Some were very difficult to navigate. With
                                                numerous buttons and a small unit viewing window they became complicated for this ‘ol
                                                man to use. This time I wanted a simple and easy dashcam to operate.
                                            </p>
                                            <p>
                                                The FineVu GX1000 meets that criteria. It’s fairly new on the market. But found a few
                                                YouTube videos that walked you through the set up in car, how to use and review files.
                                                The simplicity of setup and operation are genius. It tucks up nicely (w/o any ugly wires)
                                                to the windshield. Plus, with the thin cables marks hardwiring a breeze.
                                            </p>
                                            <p>
                                                The video and audio quality are fantastic. Whether you’re watching on your phone or a
                                                32 inch monitor! There are many different configurations you can use capture video.
                                                Many options for notifications and parking mods. As I said, I’m a simple man and have
                                                only a notice when I start my car starts up. I’ve deselected most of the dashcams
                                                features. Why? My new 2022 vehicle already has all the safety nannies I need.
                                            </p>
                                            <p>
                                                Lastly, I like to review the videos after taking a leisurely trip through the back country
                                                roads.
                                            </p>
                                            <p>
                                                I highly recommend this unit if you’re looking for a well built, easy to setup and use dash
                                                cam.
                                            </p>
                                            <p>GWB</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Frank T.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                So far so good. I hardwired the dashcam to my truck. I also like the feature where I can
                                                manually shut the camera off with a slide switch. This prevents the vehicles battery from
                                                being drained.
                                            </p>
                                            <p>
                                                Michael at BlackboxMyCar (Canada) has been extremely helpful with answering any
                                                questions. Great customer support👏
                                            </p>
                                            <p>I've purchased from this company in the past and will continue to do so.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            {/* <!-- blackBoxMyCar Fin --> */}

                            <ul>
                                <li>
                                    <img src="/assets/images/unavi_logo.png" alt="unavi logo"/>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Derrick Thomas</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                One of the easiest dash cameras to down load and view videos from. The videos can be
                                                Wi-Fi directly to the phone and sent via email or text. Crystal clear video and audio. Very
                                                impressed
                                            </p>
                                            <p>User picture</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>
                                            <span>Ali A.</span>
                                            <ul className="starBox">
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p>
                                                Most dashcams I’ve seen require it to be hooked up to the cars battery which makes
                                                you tuck wires everywhere. This one plugs in and uses power straight from the rear view
                                                mirror, not having to tuck or hide any wires.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
