import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/admin";

export const adminProductApiSlice = createApi({
  reducerPath: "adminProductApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 제품 리스트 가져오기
    getProductList : builder.mutation({
      query : (param : {page : any, size : number, keyword : string, sortColumn : string, sortOrder : string}) => {
        return{
          url : `/product/getProductList.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 제품 등록
    setProduct : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/setProduct.php', method : 'post', body
        }
      }
    }),
    // 제품 수정
    updProduct : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/updProduct.php' , method : 'post', body
        }
      }
    }),
    // 제품 상세보기
    getProductDetail : builder.mutation({
      query : (param : {idx : any}) => {
        return{
          url : `/product/getProductDetail.php?productId=${param.idx}`
        }
      }
    }),
    // 제품 등록 및 수정을 위한 모든 사양 정보 불러오기
    getProductAllSpecs : builder.mutation({
      query : () => {
        return{
          url : '/product/getProductAllSpecs.php'
        }
      }
    }),
    // 제품 다운로드 리스트 가져오기
    getProductDocList : builder.mutation({
      query : (param : {page : any, size : number, keyword : string, sortColumn : string, sortOrder : string}) => {
        return{
          url : `/product/getProductDocList.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 제품 다운로드 상세보기
    getProductDocDetail : builder.mutation({
      query : (param : {idx: any}) => {
        return{
          url : `/product/getProductDocDetail.php?productId=${param.idx}`
        }
      }
    }),
    // 제품 다운로드 등록
    setProductDoc : builder.mutation({
      query : (body :any) => {
        return{
          url : '/product/setProductDoc.php', method : 'post', body
        }
      } 
    }),
    // 제품 다운로드 수정
    updProductDoc : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/updProductDoc.php', method : 'post', body
        }
      }
    }),
    // 제품 다운로드 삭제
    delProductDoc : builder.mutation({
      query : (body:any) => {
        return{
          url : '/product/delProductDoc.php', method :'post', body
        }
      }
    }),
    // 제품 등록 언어 리스트 가져오기
    getProductDocLangList : builder.mutation({
      query : (param : {page : any, size : number, keyword : string, sortOrder : string, sortColumn : string, docType : string}) => {
        return{
          url : `/product/getProductDocLangList.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}&docType=${param.docType}`
        }
      }
    }),
    // 제품 등록 언어 등록
    setProductDocLang : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/setProductDocLang.php', method : 'post', body
        }
      }
    }),
    // 제품 등록 언어 수정
    updProductDocLang : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/updProductDocLang.php', method : 'post', body
        }
      }
    }),
    // 제품 등록 공개여부 수정
    updProductDocLangActiveStatus : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/updProductDocLangActiveStatus.php', method : 'post', body
        }
      }
    }),
    // 제품 토스트 에디터 아이콘 사진 저장
    setToastFileUpload : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/setToastFileUpload.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useGetProductDocListMutation, useGetProductListMutation, useGetProductDocLangListMutation, useUpdProductDocLangActiveStatusMutation, useSetProductDocLangMutation,
  useUpdProductDocLangMutation, useGetProductAllSpecsMutation, useGetProductDocDetailMutation, useSetProductDocMutation, useUpdProductDocMutation, useDelProductDocMutation,
  useSetProductMutation, useGetProductDetailMutation, useSetToastFileUploadMutation, useUpdProductMutation
} = adminProductApiSlice;