import React, { useState } from "react";
import { ContactFileUpload } from "../../../../../components/user/contact/fileUpload";
import { ContactSection_1 } from "../../../../../components/user/contact/section_1";
import { ContactSection_2 } from "../../../../../components/user/contact/section_2";
import { ContactSection_3 } from "../../../../../components/user/contact/section_3";
import { useSetUserInquriyMutation } from "../../../../../store/user/userApiSlice";

export const WarrentyContact = () => {
    const [setUserInquriyApi] = useSetUserInquriyMutation();
    const [section, setSection] = useState<number>(1)
    const [data, setData] = useState<any>({
        name : '', email : '', country : 0, inquiryType : '', subject : '', message : '', attachedFile : [], attachedFileName : []
    })
    const handlePrevious = () => {
        setSection(section - 1)
    }
    const handleNext = () => {
        if(section === 1){
            if(data?.name!==''&&data?.email!==''&&data?.country!==0){
                setSection(2)
            } else {alert("Please enter required items.")}
        }else if(section === 2){
            if(data?.inquiryType!==''){
                setSection(3)
            }else{alert("Please select your inquiry.")}
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        formData.append('name', data?.name)
        formData.append('email', data?.email)
        formData.append('country', data?.country)
        formData.append('type', data?.inquiryType)
        formData.append('title', data?.subject)
        formData.append('msg', data?.message)
        if(data?.attachedFile?.length !==0){
            data?.attachedFile?.forEach((file:File,index:number) => {formData.append(`files_${index+1}`, file)})
        }
        formData.append('files_length', data?.attachedFile?.length)
        const result : any = await setUserInquriyApi(formData);
        if(result.data.result === true){
            alert("Your inquiry has been registered.");
            window.location.reload();
        } else {
            alert("Server error.")
        }
    } 

    return(
        <>
        <div className="contentBox">
            <div className="left">
                <h2>Get in Touch</h2>
                <div className="active_1">
                    <div className="formBox formBox_1">
                        <div className="legend">
                            {section === 1 || section === 3 ? <p>Please fill in the information for your inquiry.</p> : ''}
                            {section === 2 ? <p>What would you like to inquire about?</p> : ''}
                            <ol>
                                <li className={section === 1 ? 'active' : ''}>
                                    <span>1</span>
                                </li>
                                <li className={section === 2 ? 'active' : ''}>
                                    <span>2</span>
                                </li>
                                <li className={section === 3 ? 'active' : ''}>    
                                    <span>3</span>
                                </li>
                            </ol>
                        </div>

                        {section === 1 ? <ContactSection_1 data={data} setData={setData}/> : ''}
                        {section === 2 ? <ContactSection_2 data={data} setData={setData}/> : ''}
                        {section === 3 ? <ContactSection_3 data={data} setData={setData}/> : ''}

                        <div className="btnBox">
                            {section === 3 ? <ContactFileUpload data={data} setData={setData}/> : ''}
                            <div>
                                {section !== 1 ? <button onClick={handlePrevious}>Previous</button> : ''}
                                {section === 3 ? '' : <button onClick={handleNext}>Next</button>}
                                {section === 3 ? <button className="submitBtn" onClick={handleSave}>Submit</button> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- left Fin --> */}
            <div className="right">
                <h3>Contact Details</h3>
                <div>
                    <span>Phone</span>
                    <a href="tel:+16468956154">+1 646 895 6154 (USA Toll Free)</a>
                    <a href="tel:+14378872499">+1 437 887 2499 (Canada Toll Free)</a>
                    <a href="tel:+82317888800">+82 31 788 8800(Korea Only)</a>
                </div>
                {/* <!-- phoneBox Fin --> */}
                <div>
                    <span>Email</span>
                    <a href="mailto:support@finedigital.com">support@finedigital.com</a>
                </div>
                {/* <!-- EmailBox Fin --> */}
                <div>
                    <span>Head Office Address</span>
                    <address>
                        FINEDIGITAL INC. 7th Floor, Fine Venture
                        Bldg., 41, Seongnam-daero 925 beon-gil,
                        Bundang-gu, Seongnam-si, Gyeonggi-do,
                        13496 Rep. of KOREA 
                    </address>
                </div>
            </div>
        </div>
        </>
    )
}