import React from "react";

export const DownloadWifiApp = () => {
    
    return(
        <>
        <div className="contentBox">
            <h2>
                FineVu
                <span>Mobile Application</span>
            </h2>
            <p>
                Get more out of your security cameras with FINEVU integrated mobile app.<br/>
                Configure, change, or update devices/features and feature settings from<br/>
                your smartphone in minimal time.
            </p>

            <div>
                <div className="btnWrap">
                    <div className="btnBox">
                        <span>Wi-Fi App</span>
                        <div>
                            <a href="https://apps.apple.com/us/app/finevu-wi-fi/id1514069940" target="_blank"></a>
                            <a href="https://play.google.com/store/apps/details?id=com.finedigital.finevu3" target="_blank"></a>
                        </div>
                    </div>
                    <div className="btnBox">
                        <span>CLOUD & Wi-Fi App</span>
                        <div>
                            <a href="https://apps.apple.com/kr/app/%ED%8C%8C%EC%9D%B8%EB%B7%B0-cloud-wi-fi/id6468760045" target="_blank"></a>
                            <a href="https://play.google.com/store/apps/details?id=com.finedigital.finevu4a" target="_blank"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}