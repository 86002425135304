import React from "react";
import { useGetMainSpecSubListMutation, useUpdMainSpecSubActiveStatusMutation } from "../../../../../../store/admin/adminProductSpecApiSlice";
export interface Props {data :any, setPageInfo : Function, getList : Function}
export const ListSpecOption:React.FC<Props> = ({data, setPageInfo, getList}) => {
    const [updMainSpecSubActiveStatusApi] = useUpdMainSpecSubActiveStatusMutation()
    const handleChangeStatus = async(x:any) => {
        const formData : any = new FormData()
        formData.append('specSubIdx', x?.specSubIdx)
        formData.append('specSubActiveStatus', x?.specSubActivekStatus === 'Y'? 'N' : 'Y')
        const result : any = await updMainSpecSubActiveStatusApi(formData)
        if(result.data.result===true){getList()}
    }
    return(
        <>
        <tbody>
            {data?.map((x:any) => (
            <tr key={x?.specSubIdx}>
                <td>
                    <span className="ellipsis">{x?.specSubName}</span>
                </td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={`${x?.specSubIdx}_1`} className="toggleIpt" onChange={()=>handleChangeStatus(x)} checked={x?.specSubActivekStatus === 'Y' ? true : false } hidden/> 
                        <label htmlFor={`${x?.specSubIdx}_1`} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
                <td>
                    <button onClick={()=>setPageInfo((prev:any)=>({...prev, isActive : true, subSpecId : x?.specSubIdx, subSpecName : x?.specSubName}))}></button>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}