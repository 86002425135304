import React from "react";

export const WarrentyServicePolicy = () => {

    return(
        <>
        <div className="contentBox">
            <h2>
                FineVu Dashcam
                <span>Warranty Policy</span>
            </h2>

            <div>
                <h3>Warranty Policy: </h3>
                <p>
                    FineVu Main Units (Front and Rear Camera) are covered by 3 year (36-month) warranty against defects from the date of
                    purchase. Genuine FineVu Micro SD Cards and Accessories are covered by a 6 Month warranty.
                </p>
                <p>
                    You are entitled to a replacement for a major failure and compensation for any other reasonably foreseeable loss or damage.
                    You are also entitled to have the goods repaired or replaced if the goods fail to be of acceptable quality and the failure does not
                    amount to a major failure.
                </p>
                <p>
                    Damage resulting from use, accident, or normal wear and tear is not covered by this or any warranty. Finedigital assumes no
                    liability for any accident, injury, death, loss or other claim related to or resulting from the use of this device. In no event shall
                    Finedigital be liable for incidental or consequential damages relating to or resulting from the use of this device or any of its
                    parts.
                </p>
                <p>Finedigital is not liable for the costs of installing and removing the device and its accessories regardless of the warranty period.</p>
                <p>
                    This warranty covers FineVu Dash Cams and accessories purchased from an Authorized Dealer. You must provide a proof of
                    purchase in order to make a warranty claim. You are responsible for delivering the product to the store you purchased.
                </p>
            </div>
        </div>
        </>
    )
}