import React, { useRef, useState } from "react";
import { ManualBox } from "../manualsBox/products-manualsBox";
export const tabList = [
    {idx: 1, title : 'Features'},
    {idx: 2, title : 'Description'},
    {idx: 3, title : "What's In The Box"},
    {idx: 4, title : 'Specifications'},
    {idx: 5, title : 'Manuals & Firmware'},
    {idx: 6, title : "User's Review"},
]
export const GX35Cloud = () => {
    const [tabIndex, setTabIndex] = useState<number>(1)
    const [state, setState] = useState<any>({first : 1, second : 1, third : 1})
    const scrollRef = [
        useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)
    ];
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
        <div className="subPage products product_view gx35">

            <div className="contentBox">
                <div className="bgBox" style={{backgroundImage : `url(/assets/images/banner_GX35.jpg)`}}>
                    <div className="centerBox">
                        <h2><span>GX</span>35 CLOUD</h2>
                        <p>FAST &amp; EASY CLOUD DASHCAM</p>
                        <ul>
                            <li onClick={()=>handleScroll(0)}>FineVu CLOUD</li>
                            <li onClick={()=>handleScroll(1)}>Super Compact size</li>
                            <li onClick={()=>handleScroll(2)}>QHD/FHD</li>
                            <li onClick={()=>handleScroll(3)}>SONY STARVIS 2 Sensor</li>
                            <li onClick={()=>handleScroll(4)}>Auto Night Vision</li>
                            <li onClick={()=>handleScroll(5)}>HDR</li>
                            <li onClick={()=>handleScroll(6)}>Smart Time Lapse</li>
                            <li onClick={()=>handleScroll(7)}>Built-In-Wi-Fi</li>
                            <li onClick={()=>handleScroll(8)}>Power Saving Parking Mode</li>
                            <li onClick={()=>handleScroll(9)}>ADAS PLUS</li>
                            <li onClick={()=>handleScroll(10)}>Low Voltage Cut-off (Winter Season)</li>
                            <li onClick={()=>handleScroll(11)}>Customizing OSD Phrases</li>
                            <li onClick={()=>handleScroll(12)}>Memory Allocation</li>
                            <li onClick={()=>handleScroll(13)}>Format Free 2.0</li>
                            <li onClick={()=>handleScroll(14)}>Speed Camera Alert</li>
                            {/* 해당 내용 없음 */}
                        </ul>
                    </div>

                    <div className="scrollDownBox">
                        <div>
                            <span></span>
                        </div>
                        <span>SCROLL</span>
                    </div>
                </div>
                {/* <!-- bgBox Fin --> */}

                <div className="tabWrap">
                    <div className="tabBtnWrap">
                        <div className="tabBtnBox">
                            {tabList.map((list:{idx:number,title:string})=>(
                                <button className={tabIndex===list?.idx?'active':''} onClick={()=>setTabIndex(list?.idx)}>
                                    {list?.title}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="tabContentBox">
                        <div className={`features ${tabIndex === 1 ? 'active' : ''}`}>
                            <div className="sliderWrap">
                                <ul>
                                    <li>
                                        <div className="imgBox">
                                            <img src="/assets/images/FineVu_GX35-CLOUD_en_main.png" alt="GX35"/>
                                        </div>
                                    </li>
                                </ul>
                                {/* <!-- slider fin --> */}
                                <div className="dotsBox">
                                    <span></span>
                                    <span className="active"></span>
                                    <span></span>
                                </div>
                            </div>
                            {/* <!-- sliderArea Fin --> */}

                            <div className="iconBox">
                                <ul>
                                    <li>
                                        <div>
                                            <img src="/assets/images/fineVu_cloud.png" alt="FineVu CLOUD"/>
                                        </div>
                                        <span>
                                            FineVu CLOUD
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/superCompactSize.png" alt="Super Compact Size"/>
                                        </div>
                                        <span>
                                            Super<br/>
                                            Compact Size
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/QHDFHD.png" alt="QHD/FHD"/>
                                        </div>
                                        <span>QHD/FHD</span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/Sony_STARVIS.png" alt="SONY STARVIS 2 Sensor"/>
                                        </div>
                                        <span>
                                            SONY STARVIS 2<br/>
                                            Sensor
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/AutoNightVision.png" alt="Auto Night Vision"/>
                                        </div>
                                        <span>
                                            Auto<br/>
                                            Night Vision
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/hdr_icon.png" alt="HDR"/>
                                        </div>
                                        <span>HDR</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div>
                                            <img src="/assets/images/FineVuWi-FiApp.png" alt="FineVu Wi-Fi App"/>
                                        </div>
                                        <span>
                                            FineVu Wi-Fi<br/>
                                            App
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/SmartTimeLapse.png" alt="Smart Time Lapse"/>
                                        </div>
                                        <span>
                                            Smart<br/>
                                            Time Lapse
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/Built_inGPS.png" alt="Built-in Wi-Fi"/>
                                        </div>
                                        <span>
                                            Built-in <br/>
                                            Wi-Fi</span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/PowerSavingParkingMode.png" alt="Power Saving Parking Mode"/>
                                        </div>
                                        <span>
                                            Power Saving<br/>
                                            Parking Mode
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/ADASPLUS.png" alt="ADAS PLUS"/>
                                        </div>
                                        <span>ADAS PLUS</span>
                                    </li>
                                    {/* <li>
                                        <div>
                                            <img src="/assets/images/AIHeatDetection.png" alt="AI Heat Detection"/>
                                        </div>
                                        <span>
                                            AI Heat<br/>
                                            Monitoring
                                        </span>
                                    </li> */}
                                    <li>
                                        <div>
                                            <img src="/assets/images/LowVoltageCut_off.png" alt="Low Voltage Cut-off"/>
                                        </div>
                                        <span>
                                            Low Voltage<br/>
                                            Cut-off
                                        </span>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <div>
                                            <img src="/assets/images/MemoryAllocation.png" alt="Memory Storage Allocation"/>
                                        </div>
                                        <span>
                                            Memory<br/>
                                            Allocation
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/customizingOSD.png" alt="Customizing OSD Phrases"/>
                                        </div>
                                        <span>
                                            Customizing<br/>
                                            OSD Phrases
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/FormatFree.png" alt="Format Free 2.0"/>
                                        </div>
                                        <span>
                                            Format Free<br/>
                                            2.0
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <img src="/assets/images/SpeedCameraAlert.png" alt="Speed Camera Alert"/>
                                        </div>
                                        <span>
                                            Speed Camera<br/>
                                            Alert
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            {/* <!-- iconBox Fin --> */}
                            <div className="imgBox" ref={scrollRef[0]}>
                                <img src="/assets/images/FineVu_GX35-CLOUD_en_01.jpg" alt="GX35 cloud"/>

                                <div className="center">
                                    <h3>
                                        EVENT VIDEOS ARE UPLOADED TO <br/>
                                        FINEVU CLOUD AUTOMATICALLY
                                    </h3>


                                    <div className="imgBox">
                                        <img src="/assets/images/FineVu_GX35-CLOUD_en_02.jpg" alt="GX35 cloud"/>
                                    </div>
                                    <p>* A wireless Internet connection is required to use FineVu CLOUD feature. (ex. Mobile router, Mobile hotspot…etc.)</p>
                                </div>
                            </div>

                            {/* <div className="imgBox" ref={scrollRef[0]}>
                                <img src="/assets/images/FineVu_GX35-CLOUD_en_01.jpg" alt="Experience New Life with FineVu Cloud"/>
                            </div>

                            <div className="imgBox">
                                <img src="/assets/images/FineVu_GX35-CLOUD_en_02.jpg" alt="EVENT VIDEOS ARE UPLOADED TO FINEVU CLOUD AUTOMATICALLY"/>
                            </div> */}

                            <section className="section_1">
                                <h3>
                                    <span>EASY WI-FI PAIRMG WITH FINEVU APP</span>
                                    ENJOY EVERY FEATURE OF DASHCAM REMOTELY
                                </h3>

                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_03.jpg" alt="GX35 cloud"/>
                                </div>
                                <ul>
                                    <li>* A wireless Internet connection is required to use FineVu CLOUD feature. (ex. Mobile router, Mobile hotspot ··· etc.)</li>
                                    <li>* In environments where wireless Internet connectivity is not available, only Wi-Fi function is available in the FineVu CLOUD & Wi-Fi app.</li>
                                    <li>* FineVu Cloud & Wi-Fi app can be downloaded from Google Play Store / App Store.</li>
                                    <li>* For detailed connecting steps, please refer to manual.</li>
                                </ul>
                                <div className="imgBox" style={{'margin': '25px 0'}}>
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_04.jpg" alt="GX35 cloud"/>
                                </div>
                            </section>

                            <section className="section_2">
                                <h3>LIVE PUSH NOTIFICATIONS</h3>
                                <p>Driving, parking, events, and power shutdowns are notified in realtime.</p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_05.png" alt="GX35 cloud"/>
                                </div>
                            </section>

                            <section className="section_1">
                                <div className="freePlan_tableWrap">
                                    <h3>FREE PLAN</h3>
                                    <div className="freePlan_tableWrap">
                                        <ul>
                                            <li>
                                                <span>FineVu Cloud</span>
                                                <span>Free Plan</span>
                                            </li>
                                            <li>
                                                <span>Subscription Fee (in USD)</span>
                                                <span>$0</span>
                                            </li>
                                            <li>
                                                <span>Max.Dashcams/Account</span>
                                                <span>1</span>
                                            </li>
                                            <li>
                                                <span>Users Per Account</span>
                                                <span>1</span>
                                            </li>
                                            <li>
                                                <span>Live View / day</span>
                                                <span>10 minutes</span>
                                            </li>
                                            <li>
                                                <span>Remote Live View and Download / Month</span>
                                                <span>100 Times (Original video is not supported)</span>
                                            </li>
                                            <li>
                                                <span>Cloud Storage</span>
                                                <span>4.8GB (Auto-Upload) + 1.2GB (Storage)</span>
                                            </li>
                                            <li>
                                                <span>Push Notifications</span>
                                                <span>○</span>
                                            </li>
                                            <li>
                                                <span>Live GPS</span>
                                                <span>○</span>
                                            </li>
                                            <li>
                                                <span>Driving Statistics</span>
                                                <span>60 days</span>
                                            </li>
                                            <li>
                                                <span>Auto Upload</span>
                                                <span>7 Days</span>
                                            </li>
                                        </ul>
                                        <p>* The plan is subject to change later.</p>
                                    </div>
                                </div>
                            </section>

                            <section className="section_1" ref={scrollRef[1]}>
                                <h3>
                                    <span>SUPER COMPACT SIZE</span>
                                    SUPER COMPACT SIZE, NON-LCD DASHCAM
                                </h3>
                                <p>Compact size maximizes driver’s view.</p>

                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_06_1.png" alt="gx35 Cloud"/>
                                </div>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_06_2.png" alt="gx35 Cloud"/>
                                </div>
                            </section>

                            <div className="imgBox">
                                <img src="/assets/images/FineVu_GX35-CLOUD_en_07.jpg" alt="product view"/>
                            </div>

                            <div className="resolution_tableWrap">
                                <ul>
                                    <li>
                                        <span>&nbsp;</span>
                                        <span>FHD</span>
                                        <span><span>QHD</span></span>
                                    </li>
                                    <li>
                                        <span>Resolution</span>
                                        <span>1920 × 1080</span>
                                        <span>2560 × 1440</span>
                                    </li>
                                    <li>
                                        <span>Pixels</span>
                                        <span>2,073,600</span>
                                        <span>3,686,400</span>
                                    </li>
                                </ul>
                            </div>

                            <section className="section_2" ref={scrollRef[2]}>
                                <h3>
                                    <span>CRYSTAL CLEAR QHD RESOLUTION</span>
                                    QHD CAPTURES EVERY MOMENT REALISTICALLY
                                </h3>
                                <div className="slide_tabBtnWrap">
                                    <div className={`slide_tabBtnBox number2 active_${state.first}`}>
                                        <button onClick={() => setState((prev: any) => ({...prev, first: 1}))}>
                                            <span>Day</span>
                                        </button>
                                        <button onClick={() => setState((prev: any) => ({...prev, first: 2}))}>
                                            <span>Night</span>
                                        </button>
                                    </div>
                                </div>

                                <div className="tabContentBox">
                                    <div className={state.first === 1 ? 'active' : ''}>
                                        <div className="videoBox">
                                            <img src="/assets/images/detail/FineVu_GX35-CLOUD_en_08_day.png" alt="night" style={{width: "100%"}}/>
                                        </div>
                                    </div>
                                    <div className={state.first === 2 ? 'active' : ''}>
                                        <div className="videoBox">
                                            <img src="/assets/images/detail/FineVu_GX35-CLOUD_en_08_night.png" alt="day" style={{width: "100%"}}/>
                                        </div>
                                    </div>
                                </div>

                                <ul>
                                    <li>* This photo was taken in QHD quality. The license plate is blurred to protect personal information.</li>
                                    <li>* The quality of recording may vary depending on conditions. (including camera angle, windshield conditions, and light conditions)</li>
                                </ul>
                            </section>

                            <section className="section_3" ref={scrollRef[3]}>
                                <h3>
                                    <span>SONY STARVIS 2 SENSOR</span>
                                    UPGRADED RECORDING QUALITY EVEN AT NIGHT
                                </h3>
                                <p style={{fontSize: '21px'}}>
                                    Upgraded SONY STARVIS 2 SENSOR reduces power consumption by 30% compared to SONY STARVIS 1,<br/> providing unimaginably vivid
                                    image regardless of day or night.
                                </p>

                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_09.png" alt="SONY STARVIS Sensor"/>
                                </div>
                            </section>

                            <section className="section_4" ref={scrollRef[4]}>
                                <h3>
                                    <span>AUTO NIGHT VISION</span>
                                    AUTOMATED NIGHT VISION ADJUSTS<br/>
                                    BRIGHTNESS AND CONTRAST TO SECURE CLARITY
                                </h3>
                                <p>
                                    Smart AI system assesses light environment and finds the perfect time to switch it on,<br/>
                                    Keeping you completely hands-free.
                                </p>

                                <div className="slide_tabBtnWrap">
                                    <div className={`slide_tabBtnBox number2 active_${state.second}`}>
                                        <button onClick={() => setState((prev: any) => ({...prev, second: 1}))}>
                                            <span>Night</span>
                                        </button>
                                        <button onClick={() => setState((prev: any) => ({...prev, second: 2}))}>
                                            <span>Day</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="tabContentBox">
                                    <div className={state.second === 1 ? 'active' : ''}>
                                        <div className="imgBox">
                                            <img src="/assets/images/detail/FineVu_GX35-CLOUD_en_10_night.png" alt="night"/>
                                        </div>
                                    </div>
                                    <div className={state.second === 2 ? 'active' : ''}>
                                        <div className="imgBox">
                                            <img src="/assets/images/detail/FineVu_GX35-CLOUD_en_10_day.png" alt="day"/>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="section_5" ref={scrollRef[5]}>
                                <h3>
                                    <span>HDR</span>
                                    OPTIMIZED WITH HIGH DYNAMIC RANGE(HDR)
                                </h3>
                                <p style={{fontSize: '21px'}}>Exposure is balanced for keeping all details intact.</p>
                                <div className="imgBox">
                                    <img src="/assets/images/detail/FineVu_GX35-CLOUD_en_11.png" alt="HDR"/>
                                </div>
                            </section>

                            <section className="section_10" ref={scrollRef[6]}>
                                <h3>
                                    <span>SMART TIME-LAPSE</span>
                                    RECORD YOUR DRIVE WITH SMART TIME-LAPSE
                                </h3>
                                <p>
                                    SMART TIME-LAPSE feature captures up to 720 minutes with no data loss. <br/>
                                    When an impact is detected, FineVu will automatically save the footage in 30fps.
                                </p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_12.png" alt="SMART TIME-LAPSE"/>
                                </div>
                                <ul>
                                    <li>* SMART TIME-LAPSE Specifications were measured under 32GB and compared to the default driving mode.</li>
                                    <li>* Factors such as collision events may affect the storage volume.</li>
                                </ul>
                            </section>

                            <section className="section_13" ref={scrollRef[7]}>
                                <h3>
                                    <span>BUILT-IN WI-FI</span>
                                    CHECK THE RECORDED VIDEOS AND UPDATE <br/>
                                    THE FIRMWARE ON FINEVU APP
                                </h3>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_13.jpg" alt="BUILT-IN WI-FI"/>
                                </div>
                                <ul>
                                    <li>* This product is built-in Wi-Fi and does not require a separate Wi-Fi dongle.</li>
                                    <li>* The FineVu Wi-Fi app (<img src="/assets/images/wifi_app.png" alt="wifi"/>) can be downloaded from the Google Play Store / App Store.</li>
                                    <li>* For detailed connection method, please refer to the manual.</li>
                                </ul>
                            </section>

                            <section className="section_7" ref={scrollRef[8]}>
                                <h3>
                                    <span>POWER SAVING PARKING MODE</span>
                                    PROLONGED RECORDING TIME
                                </h3>
                                <p>Consuming 98% less power, GX35 CLOUD records 13,950 more hours than standard parking mode.</p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_14.jpg" alt="POWER SAVING PARKING MODE"/>
                                </div>
                                <ul>
                                    <li>* The number of recording-standby hours is based on maximum hours provided by hardwiring to fuse tap until low voltage cut-off initiates.</li>
                                    <li>* Depending on dashcam’s power consumption, environmental factors, and vehicle’s battery condition actual recording standby hours may vary.</li>
                                    <li>* Certain features may not be featured during Power Saving Parking Mode.</li>
                                </ul>
                            </section>


                            <section className="section_8">
                                <h3>A MINUTE LONG SURVEILLANCE</h3>
                                <p style={{fontSize: '21px'}}>And that is not it. Any motion caught in the next 60-seconds will be secured in a minute-long footage.</p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_15.jpg" alt="A MINUTE LONG SURVEILLANCE"/>
                                </div>
                                <p>
                                    Vacation, business trip, or any other long absence it may be. FineVu will look after your car.
                                    <mark>* Impact triggers recordings.</mark>
                                </p>
                            </section>

                            <section className="section_9">
                                <h3>
                                    <span>ABSOLUTE PARKING MODE</span>
                                    20-SECONDS OF COMPLETE EVIDENCE
                                </h3>
                                <p>FineVu’s Absolute Parking Mode secures vital 20-seconds of the accident scene.</p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_16.png" alt="ABSOLUTE PARKING MODE"/>
                                </div>
                                <p>
                                    10-seconds prior to the event, and 10-seconds after the event. No detail is left out from the scene.<br/>
                                    Secure the right moment with included hardwire cable.
                                    <mark>* Requires hardwire cable installation</mark>
                                </p>
                            </section>

                            <section className="section_11" ref={scrollRef[9]}>
                                <h3>
                                    <span>ADAS PLUS</span>
                                    ADVANCED DRIVER SAFETY WARNING SYSTEMS<br/>
                                    KEEP YOU SAFE ON THE ROAD
                                </h3>
                                <p>
                                    ADAS(Advanced Driver Assistance Systems) helps safe driving with sound alerts.<br/>
                                    The system evaluates its surroundings with integrated sensors, and warns driver if necessary.
                                </p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_17_01.png" alt="ADAS PLUS"/>
                                </div>
                                <div className="imgBox">
                                    <div>
                                        <p>
                                            <strong>Front Vehicle Motion Alert (FVMA)</strong>
                                            While stopped in traffic, the movement of stationary vehicle ahead triggers alert to driver.
                                        </p>
                                    </div>
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_17_02.png" alt="ADAS PLUS"/>
                                </div>

                                <div className="imgBox">
                                    <div>
                                        <p>
                                            <strong>Lane Departure Warning System (LDWS)</strong>
                                            Alert is triggered as vehicle veers off driving lane while in motion.
                                        </p>
                                    </div>
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_17_03.png" alt="ADAS PLUS"/>
                                </div>
                            </section>

                            <section className="section_13" ref={scrollRef[10]}>
                                <h3>
                                    <span>LOW VOLTAGE CUT-OFF (WINTER SEASON)</span>
                                    LOW VOLTAGE SETTING ACCORDING TO VEHICLE TYPE
                                </h3>
                                <p style={{fontSize: '21px'}}>
                                    FineVu is equipped with Battery Protection System.<br/>
                                    Dashcam powers down when battery voltage falls below preset value.<br/>
                                    Select your vehicle type and your car’s specific power setting on FineVu Wi-Fi app<br/> to maximize efficiency of both dashcam and car batter.
                                </p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_18.jpg" alt="LOW VOLTAGE CUT-OFF (WINTER SEASON)"/>
                                </div>
                                <ul>
                                    <li>* FineVu recommends changing the low voltage settings to ‘hybrid’ when using the ISG system.</li>
                                    <li>* During winter season, dashcam is automatically cut-off at a point when voltage is 0.2V higher than set value.</li>
                                </ul>
                            </section>

                            <section className="section_18" ref={scrollRef[11]}>
                                <h3>
                                    <span>CUSTOMIZING OSD PHRASES</span>
                                    SYMBOLIC NAME ACCORDING TO USER’S TASTE
                                </h3>
                                <p style={{fontSize: '21px'}}>
                                    Users can change the wording of the On Screen Display(OSD) to user’s preference. <br/>Up to 20 characters can be entered in English letters,
                                    numbers, and special characters.
                                </p>
                                <div className="imgBox">
                                    <img src="/assets/images/detail/FineVu_GX35-CLOUD_en_19.png" alt="CUSTOMIZING OSD PHRASES"/>
                                </div>
                                <ul>
                                    <li>* OSD Phrases can be set up in the FineVu CLOUD & Wi-Fi app.</li>
                                    <li>* Special characters are only available for '&#126;&#33;&#64;&#36;&#94;&#38;&#42;&#40;&#41;&#45;&#95;&#61;&#43;&#58;&#59;&#91;&#93;&#60;&#62;? (Spaces are not supported.)</li>
                                </ul>
                            </section>

                            <section className="section_14" ref={scrollRef[12]}>
                                <h3>
                                    <span>MEMORY ALLOCATION</span>
                                    EFFICIENT MEMORY MANAGEMENT
                                </h3>
                                <p>The memory allocation function enables efficient memory management according to the driving pattern.</p>
                                <div className="slide_tabBtnWrap">
                                    <div className={`slide_tabBtnBox number4 active_${state.third}`}>
                                        <button onClick={() => setState((prev: any) => ({...prev, third: 1}))}>
                                            <span>Driving Priority</span>
                                        </button>
                                        <button onClick={() => setState((prev: any) => ({...prev, third: 2}))}>
                                            <span>Event Priority</span>
                                        </button>
                                        <button onClick={() => setState((prev: any) => ({...prev, third: 3}))}>
                                            <span>Parking Priority</span>
                                        </button>
                                        <button onClick={() => setState((prev: any) => ({...prev, third: 4}))}>
                                            <span>Driving Only</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="tabContentBox">
                                    <div className={state.third === 1 ? 'active' : ''}>
                                        <div className="imgBox">
                                            <img src="/assets/images/FineVu_GX35-CLOUD_en_20_01.png" alt="MEMORY STORAGE ALLOCATION"/>
                                        </div>
                                    </div>
                                    <div className={state.third === 2 ? 'active' : ''}>
                                        <div className="imgBox">
                                            <img src="/assets/images/FineVu_GX35-CLOUD_en_20_02.png" alt="MEMORY STORAGE ALLOCATION"/>
                                        </div>
                                    </div>
                                    <div className={state.third === 3 ? 'active' : ''}>
                                        <div className="imgBox">
                                            <img src="/assets/images/FineVu_GX35-CLOUD_en_20_03.png" alt="MEMORY STORAGE ALLOCATION"/>
                                        </div>
                                    </div>
                                    <div className={state.third === 4 ? 'active' : ''}>
                                        <div className="imgBox">
                                            <img src="/assets/images/FineVu_GX35-CLOUD_en_20_04.png" alt="MEMORY STORAGE ALLOCATION"/>
                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li>* The MicroSD card is formatted when changing the memory allocation.</li>
                                    <li>* The memory format type is FAT32, and if the format type is different, the dashcam proceeds with formatting automatically.</li>
                                    <li>(After formatting, once you insert it into the device and supply it with power, the formatting will be in progress to set the memory.)</li>
                                </ul>
                            </section>

                            <section className="section_16" ref={scrollRef[13]}>
                                <h3>
                                    <span>FORMAT FREE 2.0</span>
                                    ADVANCED FORMAT FREE TECHNOLOGY
                                </h3>
                                <p style={{fontSize: '21px'}}>
                                    Format Free 2.0 technology eliminates the need for manual reformatting of the micro SD memory card. Also, it helps to extend memory card
                                    lifespan and improve reliability.
                                </p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_21.png" alt="FORMAT FREE 2.0"/>
                                </div>
                            </section>

                            <section className="section_17" ref={scrollRef[14]}>
                                <h3>
                                    <span>SPEED CAMERA ALERT</span>
                                    FINEVU KEEPS UP WITH<br/> THE LATEST SAFETY CAMERA ALERT
                                </h3>
                                <p>
                                    FineVu safety camera database is always up to date.<br/>
                                    Download the quarterly updates, and get informed with FineVu's voice & visual alerts. <br/>
                                    Worry no more about accidents and speeding tickets.
                                </p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_22.png" alt="SPEED CAMERA ALERT"/>
                                </div>
                                <ul>
                                    <li>* This feature is not available in all areas.</li>
                                    <li>* It operates when GPS signals are received.</li>
                                </ul>
                            </section>

                            <section className="section_19" ref={scrollRef[15]}>
                                <h3>
                                    <span>POLARIZATION(CPL) FILTER SUPPORT</span>
                                    CLEAR DIFFERENCE WITH CPL FILTER
                                </h3>
                                <p style={{fontSize: '21px'}}>
                                    The Polarization CPL filter is a filter that enhances clarity by removing diffuse reflection of light.<br/>
                                    It hepls improve video recording quality by preventing unnecessary light reflection.
                                </p>
                                <div className="imgBox">
                                    <img src="/assets/images/FineVu_GX35-CLOUD_en_23.jpg" alt="CPL filter"/>
                                </div>
                                <ul>
                                    <li>* The CPL filter is sold separately.</li>
                                </ul>
                            </section>
                        </div>
                        {/* <!-- Features Fin --> */}

                        <div className={`description ${tabIndex === 2 ? 'active' : ''}`}>
                            <section>
                                <h3>Product Description</h3>
                                <div className="imgBox">
                                    <img src="/assets/images/fineVu_GX35_spec.png" alt="Product Description"/>
                                </div>
                            </section>
                        </div>
                        {/* <!-- Description Fin --> */}

                        <div className={`whatsInTheBox ${tabIndex === 3 ? 'active' : ''}`}>
                            <section>
                                <div>
                                    <h3>Basic Configuration</h3>
                                    <div className="imgBox">
                                        <img src="/assets/images/GX35_basic_configuration.png" alt="Basic Configuration"/>
                                    </div>
                                    <ul>
                                        <li>※ To improve the performance of the product, its components may change without prior notice.</li>
                                        <li>※ The warranty period of the Micro SD card is 6 months.</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3>Additional Options</h3>
                                    <div className="imgBox">
                                        <img src="/assets/images/GX35_additional_options.png" alt="Additional Options"/>
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/* <!-- What's In The Box Fin --> */}

                        <div className={`specifications ${tabIndex === 4 ? 'active' : ''}`}>
                            <div className="tableWrap">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Model</th>
                                            <th>FineVu GX35</th>
                                            <th>Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Image Sensor</th>
                                            <td>
                                                Front : SONY STARVIS 2 IMX675 5.12MP (HDR)<br/>
                                                Rear : CMOS Image Sensor 2MP
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Resolution</th>
                                            <td>
                                                Front : 2560 * 1440P (Quad HD)<br/>
                                                Rear : 1920 * 1080 (Full HD)
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Frames Per Second</th>
                                            <td>
                                                Driving : 30fps, 15fps, Smart Time Lapse 6fps<br/>
                                                Parking : 30fps, 15fps, Smart Time Lapse 2fps
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Viewing Angle</th>
                                            <td>
                                                Front : 147.4°<br/>
                                                Rear : 143.2°
                                            </td>
                                            <td>Diagonal</td>
                                        </tr>
                                        <tr>
                                            <th>Bitrate</th>
                                            <td>
                                                Front : 16~12 Mbps<br/>
                                                Rear : 12~8 Mbps
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Recording Mode</th>
                                            <td>Drive / Parking / Smart Time Lapse / 15fps / Emergency</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Display</th>
                                            <td>Non-LCD</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Acceleration Sensor</th>
                                            <td>3-Axis Acceleration Sensor</td>
                                            <td>+2g / -2g</td>
                                        </tr>
                                        <tr>
                                            <th>Cloud</th>
                                            <td>Supported</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>GPS</th>
                                            <td>External</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Wi-Fi</th>
                                            <td>Built-in(Dongle)</td>
                                            <td>2.4GHz/5.0GHzBT4.2</td>
                                        </tr>
                                        <tr>
                                            <th>Bluetooth</th>
                                            <td>X</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Storage Capacity</th>
                                            <td>FineVu Micro SD Card(32GB~128GB)</td>
                                            <td>
                                                Class 10 or above,<br/>
                                                UHS-1 recommended, SDXC compatible
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Battery</th>
                                            <td>Supercapacitor</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>MiC</th>
                                            <td>Built-in</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Speaker</th>
                                            <td>Built-in</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Security LED</th>
                                            <td>
                                                Front : White / Blue / Blue<br/>
                                                Rear : White
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Input Power</th>
                                            <td>12V ~ 24V DC</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Power Consumption</th>
                                            <td>
                                                Parking Mode : 4.32W<br/>
                                                Power Saving Parking Mode : 0.012W
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>High-Temperature Cut-Off</th>
                                            <td>AI Heat Monitoring</td>
                                            <td>During parking mode</td>
                                        </tr>
                                        <tr>
                                            <th>Low Voltage Cut-Off</th>
                                            <td>Supported</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Temperature</th>
                                            <td>
                                                Operation : -20℃ ~ 70℃<br/>
                                                Storage : -30℃ ~ 80℃
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Size</th>
                                            <td>
                                                Front : 74.1 x 37.1 x 23.0 mm(2.9" x 1.5" x 0.9")<br/>
                                                Rear : 46.85 x 36.5 x 29.2 mm(1.8" x 1.4" x 1.1")
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Weight</th>
                                            <td>
                                                Front : 57g(2oZ)<br/>
                                                Rear : 23g(0.8oZ)
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Language Supported</th>
                                            <td>
                                                English, Korean, Japanese, Russian, Polish, Vietnamese, German, Spanish, French, Hebrew, Thai, Chinese(Mandarin), Malay, Arabic, Turkish
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Mobile Application</th>
                                            <td>FineVu CLOUD&amp;Wi-Fi App.</td>
                                            <td>Android, IOS</td>
                                        </tr>
                                        <tr>
                                            <th>PC Viewer</th>
                                            <td>FineVu Player 2.0</td>
                                            <td>Windows, MAC</td>
                                        </tr>
                                        <tr>
                                            <th>Features</th>
                                            <td>
                                                Auto Night Vision, HDR, Power Saving Parking Mode, Smart Time Lapse, ADAS PLUS (FVMA&LDWS), AI Heat Monitoring, Low Voltage Cut-Off, Memory Storage Allocation, Speed Camera Alert
                                            </td>
                                            <td>ADAS and Speed Camera Alert requires GPS reception</td>
                                        </tr>
                                        <tr>
                                            <th>Certification</th>
                                            <td className="imgArea">
                                                <div>
                                                    <img src="/assets/images/table_certification.png" alt="Certification"/>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* <!-- Specifications Fin --> */}

                        {tabIndex === 5 ? <ManualBox/> : ''}
                        {/* <!-- Manuals & Firmware Fin --> */}

                        <div className={`usersReview ${tabIndex === 6 ? 'active' : ''}`}>
                            <div>
                                <ul>
                                    <li>
                                        <img src="/assets/images/sgcarmart_logo.png" alt="sgcarmart logo"/>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span>Donny Ang</span>
                                                <ul className="starBox">
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p>
                                                    Crispy clear image. easy connection to phone for straight forward download. - Donny Ang, Owner of Honda Shuttle Hybrid SMX37--G
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span>Baharudin Bin Selamat</span>
                                                <ul className="starBox">
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p>
                                                    The camera, compact and it nicely on the front and rear. - Baharudin Bin Selamat, Owner of Honda Stepwagon Hybrid Spada SNR15--R
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span>Kevin</span>
                                                <ul className="starBox">
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p>
                                                    Clear video and easy to use! Will recommend to friends for installation. - Kevin, Owner of Mazda 3 SLW95--M
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                {/* <!-- amazon Fin --> */}

                                {/* <ul>
                                    <li>
                                        <img src="/assets/images/blackBoxMyCar_logo.png" alt="blackBoxMyCar logo"/>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span>Gus</span>
                                                <ul className="starBox">
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p>
                                                    I’ve had a couple of dashcams in the past. Some were very difficult to navigate. With
                                                    numerous buttons and a small unit viewing window they became complicated for this ‘ol
                                                    man to use. This time I wanted a simple and easy dashcam to operate.
                                                </p>
                                                <p>
                                                    The FineVu GX1000 meets that criteria. It’s fairly new on the market. But found a few
                                                    YouTube videos that walked you through the set up in car, how to use and review files.
                                                    The simplicity of setup and operation are genius. It tucks up nicely (w/o any ugly wires)
                                                    to the windshield. Plus, with the thin cables marks hardwiring a breeze.
                                                </p>
                                                <p>
                                                    The video and audio quality are fantastic. Whether you’re watching on your phone or a
                                                    32 inch monitor! There are many different configurations you can use capture video.
                                                    Many options for notifications and parking mods. As I said, I’m a simple man and have
                                                    only a notice when I start my car starts up. I’ve deselected most of the dashcams
                                                    features. Why? My new 2022 vehicle already has all the safety nannies I need.
                                                </p>
                                                <p>
                                                    Lastly, I like to review the videos after taking a leisurely trip through the back country
                                                    roads.
                                                </p>
                                                <p>
                                                    I highly recommend this unit if you’re looking for a well built, easy to setup and use dash
                                                    cam.
                                                </p>
                                                <p>GWB</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span>Frank T.</span>
                                                <ul className="starBox">
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p>
                                                    So far so good. I hardwired the dashcam to my truck. I also like the feature where I can
                                                    manually shut the camera off with a slide switch. This prevents the vehicles battery from
                                                    being drained.
                                                </p>
                                                <p>
                                                    Michael at BlackboxMyCar (Canada) has been extremely helpful with answering any
                                                    questions. Great customer support👏
                                                </p>
                                                <p>I've purchased from this company in the past and will continue to do so.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul> */}
                                {/* <!-- blackBoxMyCar Fin --> */}

                                {/* <ul>
                                    <li>
                                        <img src="/assets/images/unavi_logo.png" alt="unavi logo"/>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span>Derrick Thomas</span>
                                                <ul className="starBox">
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p>
                                                    One of the easiest dash cameras to down load and view videos from. The videos can be
                                                    Wi-Fi directly to the phone and sent via email or text. Crystal clear video and audio. Very
                                                    impressed
                                                </p>
                                                <p>User picture</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <div>
                                                <span>Ali A.</span>
                                                <ul className="starBox">
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p>
                                                    Most dashcams I’ve seen require it to be hooked up to the cars battery which makes
                                                    you tuck wires everywhere. This one plugs in and uses power straight from the rear view
                                                    mirror, not having to tuck or hide any wires.
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>
        </>
    )
}
