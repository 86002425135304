import React from "react";

export const Troubleshooting = () => {

    return(
        <div className="troubleshooting">
            <div className="contentBox">
                <h2>Connecting Issues</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">Dashcam disconnecting issue</p>
                        <p className="list-detail">If your smartphone and vehicle are connected via wireless CarPlay or Android Auto, there may be interference with the dashcam's Wi-Fi connection. In this case, temporarily disconnect CarPlay or Android Auto to connect the dashcam.</p>
                        <ul>
                            <li>
                                <p>How to disconnect Apple Carplay:</p>
                                <ul>
                                    <li>‘Settings’ &gt; ‘General’ &gt; ‘CarPlay’<br/>
                                        Set CarPlay to OFF and then retry connecting the dashcam
                                    </li>
                                </ul>
                                <p>How to disconnect Android Auto:</p>
                                <ul>
                                    <li>When connected to wireless Android Auto, pull down the smartphone settings bar.<br/>
                                        Select 'Disconnect' in the Android Auto connection notification.<br/>
                                        Retry connecting the dashcam.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="list-title">Dashcam cannot be connected unless airplane mode is ON</p>
                        <p className="list-detail">Please re-set the network by following this steps.</p>
                        <img src="/assets/images/reset.png" alt="Image" className="troubleshooting-pc-img" />
                        <img src="/assets/images/reset_mobile.png" alt="Image" className="troubleshooting-mo-img" />
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>APP Issues</h2>
                <ul className="list-style-01">
                    <li>
                        <h3>Dashcam Settings:</h3>
                        <p className="list-title">Can’t enter ‘Settings’</p>
                        <p className="list-detail">For devices connected to the FineVu Wi-Fi app, accessing the settings menu is not possible while in parking mode. Switching to driving mode is required to proceed.</p>
                        <p className="list-detail">For devices connected to the FineVu CLOUD & Wi-Fi app, accessing the settings menu is not possible if the cloud connection is lost.<br/>
                            Potential reasons for cloud connection loss include:</p>
                        <ul>
                            <li>
                                <ul>
                                    <li><p>Dashcam power off:</p>
                                        ‘Settings’ &gt; ‘General’ &gt; ‘CarPlay’<br/>
                                        Set CarPlay to OFF and then retry connecting the dashcam
                                    </li>
                                    <li><p>Power saving parking mode:</p>
                                        During Power saving parking mode, the dashcam enters ‘sleep mode’ to conserve power, causing a disconnected cloud connection.<br/>
                                        Switching to driving mode is required to access the settings menu.
                                    </li>
                                    <li><p>Disabled parking mode setting:</p>
                                        If the parking mode setting is disabled, the dashcam will turn off while parked, leading to a disconnected cloud connection.<br/>
                                        Switching to driving mode is necessary to proceed.
                                    </li>
                                    <li><p>Memory Partition – Driving Only:</p>
                                        When the memory partition is set to 'Driving Only,' recording does not occur while parking, causing the dashcam to turn off.
                                        Switching to driving mode is required to access the settings menu.
                                    </li>
                                    <li><p>Mobile router communication error:</p>
                                        Poor communication status of the mobile router may prevent cloud connection.<br/>
                                        Reconnecting is recommended once communication status improves.
                                    </li>
                                    <li><p>Smartphone communication error:</p>
                                        Unsmooth smartphone communication or blocked mobile data access can prevent connection.<br/>
                                        Ensuring mobile data is enabled and attempting connection in an area with good communication is advised.
                                    </li>
                                    <li><p>Server communication error:</p>
                                        In the event of a server communication error, please try again later.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>Firmware:</h3>
                        <p className="list-title">How to update Firmware</p>
                        <ul>
                            <li>
                                <ul>
                                    <li><p>Updating via SD Card</p>
                                        <ol>
                                            <li>① Download the latest firmware.</li>
                                            <li>② Remove the Micro SD card, insert it into the Micro SD card reader, and connect the reader to a PC.</li>
                                            <li>③ When the reader is connected to the PC, a USB drive or new disk drive will appear. It is recommended to format Micro SD cards used in other devices before upgrading the firmware.</li>
                                            <li>④ Copy the downloaded firmware to the root directory of the memory card.</li>
                                            <li>⑤ Insert the Micro SD card into the device. Turn on the device by turning on the vehicle (ACC On) or starting the engine.</li>
                                            <li>⑥ The firmware update will start automatically.</li>
                                            <li>⑦ Upon completion of the firmware update, the system will automatically restart.</li>
                                        </ol>
                                    </li>
                                    <li><p>Updating via APP</p>
                                        <ol>
                                            <li>① Connect the app to the dashcam.</li>
                                            <li>② If new firmware is available, a ‘Firmware update notification’ pop-up will appear. Tap the ‘Confirm’ button.</li>
                                            <li>③ The firmware update process will begin.</li>
                                            <li>④ Once the firmware update is complete, the system will automatically restart and the connection between the dashcam and the app will be disconnected.</li>
                                        </ol>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>Sound issues:</h3>
                        <p className="list-title">Frequent beeping sounds while driving</p>
                        <p className="list-detail">Beeping sounds occur when the dashcam detects an impact. The sound can be minimized by reducing the system volume or lowering the driving impact sensitivity. Refer to the instructions below:</p>
                        <ul>
                            <li><p>Adjusting Volume:</p>
                                <ul>
                                    <li><p>APP: <span>Connect to the dashcam &gt; ‘Settings’ &gt; ‘Sound’</span></p>
                                        Set the event sensitivity to Low or OFF.
                                    </li>
                                    <li><p>LCD model: <span>Adjust settings directly on the device.</span></p>
                                        Navigate to ‘Menu’ &gt; ‘Recording Mode’ &gt; ‘Sensitivity Settings’<br/>
                                        Set the event sensitivity to Low.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="list-title">Beep sound occurs when the car in front starts.</p>
                        <p className="list-detail">The beep sound when the vehicle in front starts moving indicates that the FVMA (Front Vehicle Moving Alert) feature is turned on. This feature provides an audible alert when the vehicle in front begins to move.</p>
                        <ul>
                            <li><p>How to set up ADAS function:</p>
                                <ul>
                                    <li><p>APP: <span>Connect to the dashcam &gt; ‘Settings’ &gt; ‘ADAS & Safe Driving’</span></p>
                                        Adjust the FVMA setting as needed.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="list-title">No sound when playing recorded files</p>
                        <p className="list-detail">If voice recording is turned off, audio will not be captured during video recording. Verify that the MIC indicator in the OSD at the top of the recorded video is ON. If it is OFF, follow the instructions below to change the settings.</p>
                        <ul>
                            <li><p>How to set up voice recording:</p>
                                <ul>
                                    <li><p>APP: <span>Connect to the dashcam &gt; ‘Settings’ &gt; ‘MIC’</span></p></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>Time Issues:</h3>
                        <p className="list-title">Time of the FineVu dashcam is incorrect</p>
                        <p className="list-detail">Please adjust the dashcam time using following methods:</p>
                        <ul>
                            <li>
                                <ul>
                                    <li><p>APP: <span>Connect to the dashcam &gt; ‘Settings’ &gt; ‘Time’</span></p>
                                        Change the UTC settings in the menu or adjust the time via ‘Match Mobile Time’.
                                    </li>
                                    <li><p>LCD model: <span>Adjust settings directly on the device.</span></p>
                                        Navigate to ‘Settings’ &gt; ‘System’ &gt; ‘Date/Time Settings’ to manually set UTC and time.
                                    </li>
                                    <li>Additionally, if the device is equipped with GPS, the time is automatically set when GPS is received.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="list-title">How should I set the time during Summer Time? </p>
                        <p className="list-detail">To adjust the time for Summer Time, set the dashcam to regular UTC and then enable the Summer Time settings as follows:</p>
                        <ul>
                            <li>
                                <ul>
                                    <li><p>APP: <span>Connect to the dashcam &gt; ‘Settings’ &gt; ‘Time’</span></p>
                                        Enable Summer Time in the menu or apply it through the mobile time synchronization function.
                                    </li>
                                    <li><p>LCD model: <span>Adjust settings directly on the device.</span></p>
                                        Navigate to ‘Settings’ &gt; ‘System’ &gt; ‘Date/Time’ menu to enable Summer Time.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="list-title">The time on the dashcam suddenly being delayed by an hour</p>
                        <p className="list-detail">It is likely due to the Summer Time settings. When Summer Time setting is activated, the time on the dashcam is set back by one hour.<br/>
                            Note that, this change is not applied immediately but is saved once the setting is adjusted and the page is exited.<br/>
                            The issue could be caused by one of the following reasons:
                        </p>
                        <ul>
                            <li>
                                <ul>
                                    <li>Incorrect UTC settings.</li>
                                    <li>Enabled Summer Time setting.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>Live View:</h3>
                        <p className="list-title">Mosaicized video in live view</p>
                        <p className="list-detail">Due to slow internet speeds, the video may appear broken in live view, but the downloaded video can be viewed clearly.</p>
                    </li>
                    <li>
                        <p className="list-title">OSD does not appear on the live view of the GX4K</p>
                        <p className="list-detail">The OSD (model info, firmware version info, etc.) is not visible on the live view of the GX4K due to the high resolution of UHD, which makes OSD appear too small. However, the OSD can be seen on the saved video footage.</p>
                    </li>
                    <li>
                        <h3>Resetting Dashcam:</h3>
                        <p className="list-title">How to reset all the settings</p>
                        <ul>
                            <li>
                                <ul>
                                    <li><p>APP: <span>Connect to the dashcam &gt; ‘Settings’ &gt; ‘Dash Cam Reset’ to reset to default settings.</span></p>
                                        ※ Note: Device initialization is not supported in CLOUD mode. Ensure to connect in Wi-Fi mode before proceeding with the reset.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>


            <div className="contentBox">
                <h2>Wi-Fi Issues</h2>
                <ul className="list-style-01">
                    <li>
                        <h3>Wi-Fi Connection Issues:</h3>
                        <p className="list-title">How to reset all the settings</p>
                        <p className="list-detail">The password can be reset by initializing the device's Wi-Fi settings. To do this, press the Wi-Fi button for more than 2 seconds. This will initiate the reset process, and the password will be set to ‘12345678’.</p>
                    </li>
                    <li>
                        <p className="list-title"><h3>The Wi-Fi connection lasts only briefly and then drops.</h3></p>
                        <p className="list-detail">Smartphones prioritize the task at hand. Therefore, when an app is closed or placed in the background on your smartphone, the Wi-Fi connection is usually lost. This behavior is normal and expected due to the way smartphones manage active connections and prioritize running applications.</p>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>Parking Mode</h2>
                <ul className="list-style-01">
                    <li>
                        <h3>Parking mode recording settings:</h3>
                        <p className="list-title">Recording while parked</p>
                        <p className="list-detail">Recording in parking mode depends on the user settings:</p>
                        <ul>
                            <li>
                                <ul>
                                    <li><p>Motion: <span>Records video only when motion or impact is detected.</span></p></li>
                                    <li><p>Smart Time Lapse: <span>Records continuously at 2fps while parked.</span></p></li>
                                    <li><p>15fps: <span>Records at 15fps only when motion or impact is detected.</span></p></li>
                                    <li><p>Parking Recording Disabled: <span>No recording is performed in parking mode.</span></p>
                                        ※ Note : If the vehicle is parked for an extended period, the dashcam may turn off due to low vehicle battery voltage.
                                        If the memory partition is set to ‘Driving Only’, parking recording will not be performed.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>Dashcam Turning OFF:</h3>
                        <p className="list-title">Dashcam not turning on in Parking Mode</p>
                        <p className="list-detail">Dashcam could be turned off during parking mode.<br/>
                            Please refer to the information below:</p>
                        <ul>
                            <li>
                                <ul>
                                    <li><p>Low volage of vehicle battery:</p>
                                        If the vehicle is parked for an extended period, the dashcam may turn off due to low voltage in the vehicle battery.
                                    </li>
                                    <li><p>Disabled parking mode setting:</p>
                                        If the parking mode setting is disabled, the dashcam will turn off while parked, causing the dashcam to turn off.
                                    </li>
                                    <li><p>Memory Partition – Driving Only:</p>
                                        When the memory partition is set to 'Driving Only,' recording does not occur while parking, causing the dashcam to turn off.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>Hybrid Car:</h3>
                        <p className="list-title">How to set parking mode for hybrid car</p>
                        <p className="list-detail">Hybrid vehicles have slightly higher voltage cut-off standards than regular internal combustion engine vehicles. To set up a hybrid vehicle, follow the instructions below:</p>
                        <ul>
                            <li>
                                <ul>
                                    <li><p>App: <span>Connect to the dashcam &gt; ‘Preferences’ &gt; ‘Vehicle Model Settings’ to set the vehicle model to hybrid.</span></p>
                                        ※ Note: Menus may vary depending on the dashcam model. If the configuration menu is not available, please check the system settings.
                                    </li>
                                    <li><p>LCD model: <span>Adjust settings directly on the device.</span></p>
                                        Navigate to ‘Settings’ &gt; ‘Power’ to set the model to hybrid.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>Low Voltage Cut-Off</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">How to turn ‘Low Voltage Cut-Off’ feature on</p>
                        <p className="list-detail">The low voltage cut-off feature enables the dashcam to automatically turn off when the battery voltage reaches a specified level. This function is designed to prevent the car from failing to start due to low battery voltage. Voltage levels are fully adjustable by the user. For reference, the minimum voltage level for the vehicle to start is 11.6V, and the lowest voltage cut-off level is also set at 11.6V.</p>
                        <ul>
                            <li><p>How to set Low Voltage Settings:</p>
                                <ul>
                                    <li><p>APP: <span>Connect to the dashcam &gt; ‘Settings’ &gt; ‘Power’ &gt; ‘Low Voltage Settings’</span></p>
                                        Set the voltage level as desired.
                                    </li>
                                    <li><p>LCD model: <span>Adjust settings directly on the device.</span></p>
                                        Navigate to ‘Menu’ &gt; ‘Power’ &gt; ‘Low Voltage Settings’<br/>
                                        Set the voltage level as desired.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="list-title">Does the dashcam have a low voltage cut-off feature for mobile router?</p>
                        <p className="list-detail">The dashcam and mobile router are separate devices.<br/>Mobile router must be installed with a separate low-voltage cut-off device.</p>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>Video Footages</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">Unable to View dashcam Video on Desired Date</p>
                        <p className="list-detail">If the dashcam video cannot be viewed for the desired date, it is often due to incorrect date and time settings on the dashcam. Please follow the instructions below to correct the time settings and then check if the video is accessible. </p>
                        <ul>
                            <li><p>How to set time:</p>
                                <ul>
                                    <li><p>APP: <span>Connect to the dashcam &gt; ‘Settings’ &gt; ‘Time’</span></p>
                                        Set the UTC time zone and adjust the Summer Time setting (ON/OFF).The UTC can be set from -12 to +14.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="list-title">The OSD in the video is all broken and unrecognizable</p>
                        <p className="list-detail">Please check if it is the original video. If it is the original video, the OSD text can be seen clearly.</p>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>Memory Card Issues</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">The memory card is formatted and rebooted repeatedly.</p>
                        <p className="list-detail">① Press the REC/MIC (left) and Wi-Fi (right) buttons simultaneously for more than 3 seconds.</p>
                        <p className="list-detail">② The messages "Formatting memory card" and "Rebooting system" will be heard.<br/>※ Note: If the SD card is damaged, the above method may not solve the problem. In this case, replacing the SD card is recommended.</p>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>Power Issues</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">No Power / Power OFF while driving / Rebooting while driving</p>
                        <p className="list-detail">This could be due to a malfunctioning device or a wiring problem. Please contact your dealer, or reach out to us via email or messenger through Pressing ‘i’ on the main page &gt; ‘Contact Us’ menu in the app.</p>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>PC Player</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">When playing recorded video on a PC, only the front screen appears. How do I view the rear camera?</p>
                        <p className="list-detail">We recommend using FineVu PC Player 2.0. You can download it through the link below.</p>
                        <p className="list-detail"><a href="https://www.finevu.com/en/sub3/sub1.html">http://www.finevu.com/en/sub3/sub1.html</a></p>
                    </li>
                    <li>
                        <p className="list-title">The PC player opens so small that the text is not visible.</p>
                        <p className="list-detail">FineVu Player is optimized for full HD monitors, so the PC Player may appear small on 4K resolution monitors. A new player is scheduled to be released later this year. Before that, please check using a QHD/FHD resolution monitor.</p>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>Purchase Inquiry</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">Purchasing just the rear camera</p>
                        <p className="list-detail">To check availability, please provide information on where FineVu could be purchased. If the rear camera is available for separate purchase, arrangements will be made to connect with the dealer.</p>
                    </li>
                    <li>
                        <p className="list-title">Purchasing CPL filter</p>
                        <p className="list-detail">Products that can apply CPL filter: GX1500 CLOUD, GX450 CLOUD, GX35 CLOUD, GX35</p>
                    </li>
                    <li>
                        <p className="list-title">Purchasing adhesive tape used when installing dashcam</p>
                        <p className="list-detail">To check availability, please provide information on where FineVu could be purchased. If the adhesive tape is available for separate purchase, arrangements will be made to connect with the dealer.</p>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>CLOUD Plan</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">Minimum data required for using CLOUD features per month</p>
                        <p className="list-detail">The amount of usage varies depending on the user's driving time and habits, but on average, approximately 4 GB is used per month.</p>
                    </li>
                </ul>
            </div>

            <div className="contentBox">
                <h2>Others</h2>
                <ul className="list-style-01">
                    <li>
                        <p className="list-title">I have FineDrive Navigation. Can I change the language to English?</p>
                        <p className="list-detail">Car navigation system was developed for the domestic Korean market and therefore does not support English.<br/>
                            We apologize for any inconvenience.</p>
                    </li>
                    <li>
                        <p className="list-title">I have a FineVu model 000, but it only supports Korean. Is support available in English and other languages?</p>
                        <p className="list-detail">We regret to inform you that the 000 model is only supported in Korean. Unfortunately, it is not possible to change the language settings to English. We apologize for any inconvenience this may cause. Similarly, the 000 model is designed for the Korean market and does not support English firmware. We apologize for any inconvenience this may cause.</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}