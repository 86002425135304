import React from "react";
import { useUptCountryStatusMutation } from "../../../../../../store/admin/adminCountryApiSlice";
export interface Props {data:any;setPageInfo:Function,getList:Function}
export const ListCountry:React.FC<Props> = ({data,setPageInfo,getList}) => {
    const [uptCountryStatusApi] = useUptCountryStatusMutation()
    const handleSelectCountry = (idx : string) => {
        setPageInfo((prev:any) => ({...prev, isActive : true, countryId : idx}))
    }
    const handleChange = async(idx: string , status : string) => {
        const formData : any = new FormData()
        formData.append('idx', idx)
        formData.append('share' , status==='Y'?'N':'Y')
        const result : any = await uptCountryStatusApi(formData)
        if(result.data.result === true){getList()}
    }
    return(
        <>
        <tbody>
            {data?.map((list:any)=>(
            <tr key={list?.idx}>
                <td onClick={()=>handleSelectCountry(list?.idx)}>
                    <span className="ellipsis">{list?.country}</span>
                </td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id="member_statUs_1" className="toggleIpt" onChange={()=>handleChange(list?.idx,list?.share_yn)} checked={list?.share_yn==='Y'?true:false} hidden/> 
                        <label htmlFor="member_statUs_1" className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}