import React from "react";
export interface Props {data : any, lang: any}
export const FirmWare:React.FC<Props> = ({data, lang}) => {
    console.log(lang)
    const handleDownLoad = (file : any) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    return(
        <>
        <div className={`firmware common active`}>
            {data?.map((list:any) => (
                <>
                <p>
                    <span>Latest Version/Date</span>
                    {list?.docVersion}  ({list?.docMdate})
                </p>
                <div className="btnBox">
                    <button className="downloadBtn active" onClick={()=>handleDownLoad(list?.docFile)}>DOWNLOAD</button>
                </div>
                </>
            ))}
            <div className="txtBox">
                <span>Instructions</span>
                <ol>
                    <li>
                        <span>1</span>
                        Download the latest firmware.
                    </li>
                    <li>
                        <span>2</span>
                        Remove the Micro SD card from your dashcam. Insert it into Micro SD card reader and connect the reader to the PC.
                    </li>
                    <li>
                        <span>3</span>
                        When the reader is connected to the PC, a USB drive or new disk drive will be created.(It’s recommended to format the Micro SD card used for another device before you upgrade the firmware.)
                    </li>
                    <li>
                        <span>4</span>
                        Copy the downloaded firmware on the top-level root of the memory card.
                    </li>
                    <li>
                        <span>5</span>
                        Insert the Micro SD card into on the device. Turn on the vehicle or start the engine to turn on the device.
                    </li>
                    <li>
                        <span>6</span>
                        The firmware update starts automatically.
                    </li>
                    <li>
                        <span>7</span>
                        The system will automatically restart once the firmware update is completed.
                    </li>
                </ol>
                <p>
                    Do not power off your dashcam until it begins continuous recording, as it may cause permanent
                    damage to the dashcam.
                </p>
            </div>
        </div>
        </>
    )
}