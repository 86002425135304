import React, { useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { RegistSpec } from "../../../pages/admin/dashboard/regist/regist-spec";
import { useUpdSpecOptionActiveStatusMutation } from "../../../store/admin/adminProductSpecApiSlice";
export interface Props {data:any;setSpecInfo:Function;specType:string;specInfo:any;getList:any}
export const SpecRightList:React.FC<Props> = ({data,setSpecInfo,specType,specInfo,getList}) => {
    const [updSpecOptionActiveStatusApi] = useUpdSpecOptionActiveStatusMutation()
    const handleModifySpec = (spec:any) => {
        setSpecInfo((prev:any)=>({...prev, isActive : true, id : spec?.idx, optionName : spec?.optionSpecification, imageUrl : spec?.optionImageFile}))
    }
    const handleActiveChange = async(option : any) => {
        const formData : any = new FormData()
        formData.append('optionId', option?.idx)
        formData.append('optionActiveStatus', option?.optionActiveStatus === 'Y'?'N':'Y')
        const result : any = await updSpecOptionActiveStatusApi(formData)
        if(result.data.result === true){getList()}
    }
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr key={list?.idx}>
                <td><span className="ellipsis">{list?.idx}</span></td>
                <td><span className="ellipsis">{list?.optionSpecification}</span></td>
                <td><Moment format="YYYY.MM.DD">{list?.optionMdate}</Moment></td>
                <td>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={list?.idx} checked={list?.optionActiveStatus==='Y'?true:false} onChange={()=>handleActiveChange(list)} className="toggleIpt" hidden/> 
                        <label htmlFor={list?.idx} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
                <td>
                    <button onClick={()=>handleModifySpec(list)}></button>
                </td>
            </tr>
            ))}
        </tbody>
        </>
    )
}