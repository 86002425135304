import React, { useEffect, useLayoutEffect, useState } from "react";
import { GoogleMaps } from "../../../components/googleMap/googleMap";
import { useGetRegistCountryListMutation } from "../../../store/user/commonApiSlice";
import Select from 'react-select';
import { useGetWhereToBuyMutation } from "../../../store/user/contentsApiSlice";
import { Paginate } from "../../../components/paginate/paginate_admin";
interface ParamType {page : any, size : number, countryId : any, where : string, retail : string, installer : string}
export const Location = () => {
    const [param, setParam] = useState<ParamType>({page : 1, size: 5, countryId : 0, where : 'W', retail : 'R', installer : 'I'})
    const [country, setCountry] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [storeInfo, setStoreInfo] = useState<any>({id : null , address : null})
    const [count, setCount] = useState<any>({online : 0, retailer : 0, installer : 0})
    const [totalCount, setTotalCount] = useState<number>(0)
    const [getCountryListApi] = useGetRegistCountryListMutation()
    const [getWhereToBuyApi] = useGetWhereToBuyMutation()
    const getWhereToBuy = async() => {
        const result : any = await getWhereToBuyApi(param)
        if(result.data.result === true){
            setData(result.data.list); setTotalCount(result.data.total_cnt);
            if(result.data.list?.length > 0 && result.data.list !== null){
                setStoreInfo((prev:any) => ({...prev, id : result.data.list[0].storeId, address : result.data.list[0].storeAddress}))
                setCount((prev:any) => ({...prev, online : result?.data?.onlineCnt, retailer : result?.data?.retailerCnt, installer : result?.data?.installerCnt}))
            }else{
                setStoreInfo((prev:any) => ({...prev, id : null, address : null}))
                setCount((prev:any) => ({...prev, online : 0, retailer : 0, installer : 0}))
            }
        }
    }
    const getCountry = async() => {
        const result : any = await getCountryListApi({})
        if(result.data.result === true){
            const formatData = result.data.list.map((x:any)=>({value : parseInt(x?.idx), label : x?.country}))
            setCountry(formatData)
            // getWhereToBuy()
        }
    }
    const handlePage = (pageNumber : number) => {
        
    }
    // store checkbox Event
    const handleChange = (e:any) => {
        const {type, name, value, checked} = e.target
        if(type === 'checkbox'){
            if(checked){
                setParam(prev => ({...prev, [name] : ''}))
            }else{
                setParam(prev => ({...prev, [name] : value}))
            }
        }
    }
    // country selector box
    const handleSelectCountry = (e:any,type:string) => {
        // setParam((prev)=>({...prev, countryId : e.value }))
        setParam((prev) => ({...prev, countryId : e.target.value}))
    }
    // store select
    const handleSelectStore = (list:any) => {
        setStoreInfo({id : list?.storeId, address : list?.storeAddress})
    }
    
    useEffect(()=>{getWhereToBuy()},[param])
    useLayoutEffect(()=>{getCountry()},[])
    return(
        <>
        <div className="contentBox">
            <h2>Where to Buy</h2>
            <p>
                You can visit our distributor websites worldwide to place orders for our products. Our distributors provide the same
                level of customer service as our headquarters. Please refer to the pricing policy for each country, as it may vary.
            </p>

            <div>
                <div className="top">
                    <div className="left selectBox">
                        {/* <Select
                            className="country_react_selectBox"
                            // defaultValue={country?.find((x:any)=>x.value === 2)}
                            value={country?.find((x:any)=>x.value === param.countryId)}
                            name={'country'}
                            options={country}
                            onChange={(e)=>handleSelectCountry(e,'country')}
                            isSearchable={true}
                            placeholder={'Please Choose a Country'}
                        /> */}
                        <select onChange={(e)=>handleSelectCountry(e, 'country')}>
                            <option value={'0'} selected>All</option>
                            {country?.map((country : any) => (
                                <option value={country?.value}>{country?.label}</option>
                            ))}
                        </select>
                    </div>

                    <div className="right">
                        <div>
                            {/* <div>
                                <input type="checkbox" name="where" value={'W'} onChange={handleChange} checked={param.where === 'W' ? false : true} id="whereToBuy_online"/>
                                <label htmlFor="whereToBuy_online">Where to buy Online ({count?.online})</label>
                            </div>
                            <div>
                                <input type="checkbox" name="retail" value={"R"} onChange={handleChange} checked={param.retail === 'R' ? false : true} id="whereToBuy_retailers"/>
                                <label htmlFor="whereToBuy_retailers">Retailers ({count?.retailer})</label>
                            </div>
                            <div>
                                <input type="checkbox" name="installer" value={"I"} onChange={handleChange} checked={param.installer === 'I' ? false : true} id="whereToBuy_installers"/>
                                <label htmlFor="whereToBuy_installers">Installers ({count?.installer})</label>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="left">
                        <ul>
                            {data?.map((list:any)=>(
                                <li className={storeInfo.id === list?.storeId ? 'active' : ''} key={list?.storeId} onClick={()=>handleSelectStore(list)}>
                                    <span>{list?.storeName}</span>
                                    <address>
                                        {list?.storeAddress}
                                    </address>
                                    <div>
                                        <div>
                                            <span>TEL</span>
                                            <span>{list?.storeTel}</span>
                                        </div>
                                        <div>
                                            <span>MAIL</span>
                                            <span>{list?.storeEmail}</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {/* {data?.length === 0 ? '' : 
                        <div className="pagerBox">
                            <Paginate page={param.page} size={param.size} totalCount={totalCount} setPage={handlePage} />
                        </div>
                        } */}
                    </div>

                    <div className="right">
                        {storeInfo.address && !storeInfo?.address?.startsWith('https') ?
                        <GoogleMaps address={storeInfo.address} google={null} />
                        :
                        ''
                        }

                        {storeInfo.address && storeInfo?.address?.startsWith('https') ?
                        <iframe src={storeInfo?.address} style={{border : '0'}} loading="lazy"></iframe>
                        :
                        ''
                        }
                        
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export const Data : any = [
    {id : 1, name : 'afd'},
    {id : 1, name : 'afd'},
    {id : 1, name : 'afd'},
    {id : 1, name : 'afd'},
    {id : 1, name : 'afd'},
]