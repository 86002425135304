import React from "react";

export const Section_7 = () => {

    return(
        <>
        <section className="mainSection_7">
            <div>
                <div className="left">
                    <h2>
                        FINEVU
                        <span>APPLICATION</span>
                    </h2>
                    <p>
                        Get more out of your security cameras with FINEVU integrated mobile app.
                        Configure, change, or update devices/features and feature settings from
                        your smartphone in minimal time.
                    </p>
                    <div>
                        <span>Wi-Fi App</span>
                        <a href="https://apps.apple.com/us/app/finevu-wi-fi/id1514069940" target="_blank"></a>
                        <a href="https://play.google.com/store/apps/details?id=com.finedigital.finevu3 " target="_blank"></a>
                    </div>
                    <div>
                        <span>CLOUD & Wi-Fi App</span>
                        <a href="https://apps.apple.com/kr/app/%ED%8C%8C%EC%9D%B8%EB%B7%B0-cloud-wi-fi/id6468760045" target="_blank"></a>
                        <a href="https://play.google.com/store/apps/details?id=com.finedigital.finevu4a" target="_blank"></a>
                    </div>
                </div>
                <div className="right">
                    <div className="imgBox">
                        <img src="/assets/images/application_mockup.png" alt="APPLICATION"/>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}