import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetMainSpecRemarkMutation, useUpdMainSpecRemarkMutation } from "../../../../store/admin/adminProductSpecApiSlice";
export interface Props {specInfo : any,specType:string, getList:any, onClose:Function}
export const RegistRemark:React.FC<Props> = ({specInfo, specType, getList, onClose}) => {
    const {typeId}:any = useParams() 
    const [setMainSpecRemarkApi] = useSetMainSpecRemarkMutation()
    const [updMainSpecRemarkApi] = useUpdMainSpecRemarkMutation()
    const [remarkText, setRemarkText] = useState<string>('')
    const handleChange = (e:any) => {setRemarkText(e.target.value)}
    const handleSave = async() => {
        const formData = new FormData()
        if(specInfo.id !== 0){formData.append('remarkId', specInfo.id)}
        if(specInfo.id === 0){formData.append('specId', typeId)}
        formData.append('remarkEn', remarkText)
        if(specInfo.id === 0){
            const result : any = await setMainSpecRemarkApi(formData)
            if(result.data.result === true){alert("등록이 완료되었습니다.");getList();onClose()}
        } else{
            const result : any = await updMainSpecRemarkApi(formData)
            if(result.data.result === true){alert("수정이 완료되었습니다.");getList();onClose()}
        }
    }
    useEffect(()=>{
        if(specInfo.id !== 0){
            setRemarkText(specInfo?.optionName)
        }
    }, [specInfo.id])
    return(
        <>
        <div className="registration_popUp popUp_active active">
            <button className="closeBtn" onClick={()=>onClose()}>
                <i className="fa-light fa-xmark"></i>
            </button>

            <div className="titleBox">
                <span>Specification</span>
                <span>Specification Remark</span>
            </div>
            <div className="userInfoBox">
                <ul>
                    <li>
                        <span>Remark</span>
                        <textarea name="" id="" value={remarkText} onChange={handleChange} placeholder="비고 입력란"></textarea>
                    </li>
                </ul>
            </div>

            <div className="btnBox">
                <button onClick={()=>onClose()}>취소</button>
                <button onClick={handleSave}>등록</button>
            </div>
        </div>
        </>
    )
}