import React, { useEffect, useRef, useState } from "react";
import { useDelProductDocMutation, useUpdProductDocMutation } from "../../../store/admin/adminProductApiSlice";
import { DownLoadFileUploadbox } from "./downloadFileUploadBox";
export interface Props {data: any, getDetail:Function}
export const VerisonList:React.FC<Props> = ({data, getDetail}) => {
    const versionRef = useRef<any>([])
    const fileRef = useRef<any>([])
    const [updProductDocApi] = useUpdProductDocMutation()
    const [delProductDocApi] = useDelProductDocMutation()
    const handleChange = (e:any) => {
        
    }
    const handleDelete = async(id:any) => {
        const formData = new FormData()
        formData.append('idx', id)
        const confirm = window.confirm("삭제하시겠습니까?");
        if(confirm){
            const result : any = await delProductDocApi(formData);
            if(result.data.result === true){getDetail()}
        }
    }
    const handleSave = async(list:any) => {
        const formData : any = new FormData()
        formData.append('docIdx', list?.docId)
        formData.append('docType', list?.docType)
        formData.append('docLang', list?.docLang)
        formData.append('docVersion', versionRef.current[list?.docId].value)
        if(fileRef.current[list?.docId].files[0] !== '' || fileRef.current[list?.docId].files[0] !== undefined){
            formData.append('docPdsFile', fileRef.current[list?.docId].files[0])
        }
        const result : any = await updProductDocApi(formData)
        if(result.data.result === true){
            alert("수정 완료"); getDetail()
        }
    }
    return(
        <>
        {data?.map((list:any, index:number)=>(
        <li key={list?.docId}>
            <div className="langSelectBox">
                <span>{list?.docLangName}</span>
                <input type="text" ref={(e)=>versionRef.current[list?.docId]=e} name="" id="" onChange={handleChange} defaultValue={list?.docVersion} placeholder="버전을 입력해 주세요."/>

                <DownLoadFileUploadbox
                    list={list}
                    fileRef={fileRef}
                    fileName={list?.docFilename}
                />

                <div className="btnBox">
                    <button className="cancleBtn" onClick={()=>handleDelete(list?.docId)}>삭제</button>
                    <button className="saveBtn" onClick={()=>handleSave(list)}>수정</button>
                </div>
            </div>
        </li>
        ))}
        </>
    )
}
