import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/user";

export const contentsApiSlice = createApi({
  reducerPath: "contentsApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 블로그 리스트 가져오기
    getUserBlogList : builder.mutation({
      query : (param : {page : any, size : number, keyword : string}) => {
        return{
          url : `/contents/getUserContentLists.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}`
        }
      }
    }),
    // 블로그 상세보기
    getUserBlogDetail : builder.mutation({
      query : (param : {idx : string}) => {
        return{
          url : `/contents/getUserContentDetails.php?idx=${param.idx}`
        }
      }
    }),
    // 대리점 정보 불러오기
    getWhereToBuy : builder.mutation({
      query : (param : {page: any, size : number, countryId : any, where : string, retail : string, installer : string}) => {
        return{
          url : `/store/getWheretoBuy.php?page=${param.page}&size=${param.size}&countryId=${param.countryId}&where=${param.where}&retail=${param.retail}&installer=${param.installer}`
        }
      }
    }),
    
  }),
});

export const {
  useGetUserBlogListMutation, useGetUserBlogDetailMutation, useGetWhereToBuyMutation
} = contentsApiSlice;