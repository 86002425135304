import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {param : any, setParam : Function}
export const ListFilter:React.FC<Props> = ({param, setParam}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const page : any = new URLSearchParams(location.search).get('page') || 1
    const keyword : any = new URLSearchParams(location.search).get('keyword') || ''
    const sortColumn : any = new URLSearchParams(location.search).get('column') || param.sortColumn;
    const path = location.pathname.split('/')
    const [data, setData] = useState<any>([])
    const [sort, setSort] = useState<any>({order : 'desc', column : 'name'})
    const handleFilter = (column : string) => {
        if(sortColumn === column){navigate(`${location.pathname}?page=${page}&keyword=${keyword}&column=${column}&order=${param.sortOrder === 'desc'?'asc':'desc'}`)}
        else{navigate(`${location.pathname}?page=${page}&keyword=${keyword}&column=${column}&order=desc`)}
    }
    useLayoutEffect(()=>{
        switch(path[3]){
            case 'member' : setData(memberListFilter);break;
            case 'content' : setData(contentListFilter); break;
            case 'country' : setData(countryListFilter); break;
            case 'store' : setData(storeListFilter); break;
            case 'inquiry' : setData(InquiryListFilter); break;
            case 'product' : setData(productListFilter); break;
            case 'download' : setData(downLoadListFilter); break;
            case 'language' : setData(languageListFilter); break;
            case 'mainSpec' : setData(mainSpecListFilter); break;
            case 'specOption' : setData(specOptionFilterList); break;
        }
    }, [location])
    return(
        <>
        <thead>
            <tr>
                {data.map((list:any) => {
                    const disPlay = list.column === '' ? '' : 'sort'
                    return(
                        <>
                        <th>
                            <span className="sortWrap">
                                {list.title}
                                {list.column === param.sortColumn ? 
                                <span className={`sort ${param.sortOrder === 'desc' ? 'desc':'asc'}`} onClick={()=>handleFilter(list?.column)}></span> :
                                <span className={disPlay} onClick={()=>handleFilter(list?.column)}></span>
                                }
                            </span>
                        </th>
                        </>
                    )
                })}
            </tr>
        </thead>
        </>
    )
}

const memberListFilter = [
    {idx: 1, title: 'Name', column : 'dots_fullname'},
    {idx: 2, title: 'Email', column : 'dots_user_email'},
    {idx: 3, title: 'Country', column : ''},
    {idx: 4, title: 'Product', column : 'dots_product'},
    {idx: 5, title: 'Serial Number', column : 'dots_serial'},
    {idx: 6, title: 'Purchase From', column : 'dots_purchase_form'},
    {idx: 7, title: 'Reseller Name', column : 'dots_reseller'},
    {idx: 8, title: '회원상태', column : ''}
]

const productListFilter = [
    {idx : 1, title : '썸네일', column : ''},
    {idx : 1, title : '제품명', column : 'productName'},
    {idx : 1, title : '등록일자', column : 'date'},
    {idx : 1, title : '공개여부', column : ''},
]

const contentListFilter = [
    {idx : 1, title : '썸네일', column : ''}, 
    {idx : 2, title : '제목', column : 'title'}, 
    {idx : 3, title : '등록일자', column : 'date'}, 
    {idx : 4, title : '공개여부', column : ''}, 
]

const countryListFilter = [
    {idx : 1, title : 'Country', column : 'country'}, 
    {idx : 2, title : '공개상태', column : ''},
]

const storeListFilter = [
    {idx : 1, title : 'Country', column : 'country'},
    {idx : 2, title : 'Type', column : 'type'},
    {idx : 3, title : 'Store Name', column : 'store_name'},
    {idx : 4, title : 'Address', column : 'address'},
    {idx : 5, title : 'TEL', column : 'tel'},
    {idx : 6, title : 'MAIL', column : 'email'},
    {idx : 7, title : '공개상태', column : ''},
]

const InquiryListFilter = [
    {idx : 1, title : '상태', column : 'state'},
    {idx : 2, title : '문의유형', column : 'type'},
    {idx : 3, title : '문의제목', column : 'title'},
    {idx : 4, title : '문의일자', column : 'date'},
]

const downLoadListFilter = [
    {idx : 1, title : '썸네일', column : ''},
    {idx : 2, title : '제품명', column : 'productName'},
    {idx : 3, title : 'Firmware', column : ''},
    {idx : 4, title : 'Manual', column : ''},
    {idx : 5, title : 'Speed Cam Data', column : ''},
]

const languageListFilter = [
    {idx : 1, title : 'Language', column : 'langName'},
    {idx : 3, title : 'Type', column : ''},
    {idx : 2, title : '공개상태', column : ''}
]

const mainSpecListFilter = [
    {idx : 1, title : 'Main Spec', column : 'specName'},
    {idx : 2, title : 'Spec Option', column : ''},
    {idx : 3, title : 'Remark', column : ''},
    {idx : 4, title : 'Image', column : ''},
    {idx : 4, title : '공개상태', column : ''},
    {idx : 5, title : '수정', column : ''},
]

const specOptionFilterList = [
    {idx : 1, title : 'Spec Option', column : 'specSubName'},
    {idx : 1, title : '공개상태', column : ''},
    {idx : 1, title : '수정', column : ''},
]