import React, { useRef, useState } from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Section_5 = () => {
    const [index, setIndex] = useState<number>(50)
    const setting = {
        dots : false,
        arrows : false,
        infinte : false,
        speed : 500,
        slidesToScroll: 5,
        slidesToShow: 1.6,
        vertical: false,
        centerMode : true,
        centerPadding: `${11+index}px`,
        // autoplay: true,
        // autoplaySpeed: 3000,
        // centerMode: true,
    }
    const slickRef = useRef<any>([])
    const [state, setState] = useState<number>(1)
    const handleSlider = (index : number) => {
        setState(index + 1)
        if(slickRef.current) slickRef.current.slickGoTo(index)
    }
    return(
        <>
        <section className="mainSection_5">
            <h2>
                A picture is worth a thousand words.
                <span>Find the video you like!</span>
            </h2>

            <div className="slide_tabBtnWrap">
                <div className={`slide_tabBtnBox active_${state}`}>
                    <button onClick={()=>handleSlider(0)}>
                        <span>GX35 CLOUD</span>
                    </button>
                    <button onClick={()=>handleSlider(1)}>
                        <span>GX1000 CLOUD</span>
                    </button>
                    <button onClick={()=>handleSlider(2)}>
                        <span>GX4K</span>
                    </button>
                    <button onClick={()=>handleSlider(3)}>
                        <span>GX35</span>
                    </button>
                    <button onClick={()=>handleSlider(4)}>
                        <span>GX1000</span>
                    </button>
                    <button onClick={()=>handleSlider(5)}>
                        <span>GX400</span>
                    </button>
                    <button onClick={()=>handleSlider(6)}>
                        <span>GX7000</span>
                    </button>
                    <button onClick={()=>handleSlider(7)}>
                        <span>GX33</span>
                    </button>
                    <button onClick={()=>handleSlider(8)}>
                        <span>GX5000</span>
                    </button>
                    <button onClick={()=>handleSlider(9)}>
                        <span>GX300</span>
                    </button>
                    <button onClick={()=>handleSlider(10)}>
                        <span>GX30</span>
                    </button>
                </div>
            </div>

            <div className="sliderWrap">
                <ul>
                    <Slider {...setting} ref={slickRef}>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                <iframe src="https://www.youtube.com/embed/hzwDWXF5xrQ?si=tNaceNdo3nAs5uY6&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                <iframe src="https://www.youtube.com/embed/UghJwQP0D-I?si=FFN7ZAWMjBwmA2Zp" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                <iframe src="https://www.youtube.com/embed/Z2ZceJFblqM?si=M0oaPnTS3hz1NcIz&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                <iframe src="https://www.youtube.com/embed/hzwDWXF5xrQ?si=9LpIWnlJSPeoyKty&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                {/* <iframe src="https://www.youtube.com/embed/6g2rhcc752M?si=yFSd31qfA5bWuFFG&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                                <iframe src="https://www.youtube.com/embed/vIClnlIav20?si=5duniUtd95_7KlT8&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                {/* <iframe src="https://www.youtube.com/embed/YgX7R8coLFg?si=S8r1y1YfjSdb23VS&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                                <iframe src="https://www.youtube.com/embed/BBvcNq4Ho-w?si=iojv3Jh4j9_CujRz&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                {/* <iframe src="https://www.youtube.com/embed/tAa0IKBH8Jk?si=QNzY_t4ynzAdeJnZ&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                                <iframe src="https://www.youtube.com/embed/6g2rhcc752M?si=yFSd31qfA5bWuFFG&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                {/* <iframe src="https://www.youtube.com/embed/lMLCPWJLBTU?si=B8cwHbdShcDuCwJ9&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                                <iframe src="https://www.youtube.com/embed/YgX7R8coLFg?si=ThV6VfwBn8T5rygf&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                {/* <iframe src="https://www.youtube.com/embed/6lD4JJyhhxY?si=ThV6VfwBn8T5rygf&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                                <iframe src="https://www.youtube.com/embed/tAa0IKBH8Jk?si=JqPk5vEkjrEinwkN&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                {/* <iframe src="https://www.youtube.com/embed/XbSi-6fqRUM?si=adtDhpewFRkZxXUW&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                                <iframe src="https://www.youtube.com/embed/lMLCPWJLBTU?si=KmFHu-qXDCzIjEVP&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                {/* <iframe src="https://www.youtube.com/embed/PHbglZaZ0NE?si=p-jblhk7qk4Ih5LH&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                                <iframe src="https://www.youtube.com/embed/6lD4JJyhhxY?si=vjY9H3n1j_FQvWwy&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="videoBox">
                            <div className="video">
                                {/* <iframe src="https://www.youtube.com/embed/uxawV7GHE9k?si=UPNIhNAIfxjPsbCp&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe> */}
                                <iframe src="https://www.youtube.com/embed/w37CymyMCBI?si=fNQqHrDxEL27piUv&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </div>
                        </div>
                    </li>
                    

                    
                    </Slider>
                </ul>
            </div>
             {/* <!-- slider 양쪽 끝 잘려야함, centerMode(XD 참고) -->
             <!-- slider Fin --> */}
        </section>
        </>
    )
}