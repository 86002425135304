import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RegistCountry } from "../regist/regist-country";
import { useGetMainSpecListMutation, useGetMainSpecListWithSubMutation, useGetMainSpecSubList2Mutation, useGetMainSpecSubListMutation } from "../../../../store/admin/adminProductSpecApiSlice";
import { useGetContentsListMutation } from "../../../../store/admin/adminContentsApiSlice";
import { useGetCountryListMutation } from "../../../../store/admin/adminCountryApiSlice";
import { ListContents } from "./category/cotents/list-contents";
import { ListCountry } from "./category/store/list-country";
import { ListMember } from "./category/list-member";
import { ListSpecification } from "./category/specification/list-spec";
import { ListFilter } from "./element/list-filter";
import { ListHeader } from "./element/list-header";
import { ListStore } from "./category/store/list-store";
import { useGetStoreListMutation } from "../../../../store/admin/adminStoreApiSlice";
import { Paginate } from "../../../../components/paginate/paginate_admin";
import { ListInquiry } from "./category/list-inquriy";
import { useGetInquiryListMutation } from "../../../../store/admin/adminInquiryApiSlice";
import { useGetUserListMutation } from "../../../../store/admin/adminUserApiSlice";
import { ListProduct } from "./category/list-product";
import { ListDownload } from "./category/list-download";
import { ListLanguage } from "./category/list-language";
import { RegistLanguage } from "../regist/regist-language";
import { useGetProductDocLangListMutation, useGetProductDocListMutation, useGetProductListMutation } from "../../../../store/admin/adminProductApiSlice";
import { ListMainSpec } from "./category/specification/list-mainSpec";
import { RegistMainSpec } from "../regist/regist-mainSpec";
import { ListRemark } from "./category/specification/list-remark";
import { ListSpecOption } from "./category/specification/list-specOption";
import { RegistSpecOption } from "../regist/regist-specOption";
export interface Props {setMainSpecData:Function}
export const DashboardList:React.FC<Props> = ({setMainSpecData}) => {
    const [getMainSpecListWithSubApi] = useGetMainSpecListWithSubMutation()
    const [getMainSpecSubList2Api] = useGetMainSpecSubList2Mutation()
    const [getContentsListApi] = useGetContentsListMutation()
    const [getCountryListApi] = useGetCountryListMutation()
    const [getStoreListApi] = useGetStoreListMutation()
    const [getInquiryListApi] = useGetInquiryListMutation()
    const [getUserListApi] = useGetUserListMutation()
    const [getProductDocListApi] = useGetProductDocListMutation()
    const [getMainSpecListApi] = useGetMainSpecListMutation()
    const [getProductListApi] = useGetProductListMutation()
    const [getProductDocLangListApi] = useGetProductDocLangListMutation()
    const {type, typeId} = useParams()
    const location = useLocation()
    const path = location.pathname.split('/')
    const navigate = useNavigate()
    const [sort, setSort] = useState({column : '', order : '', unique : 0})

    const page : any = new URLSearchParams(location.search).get('page') || 1;
    const keyword : any = new URLSearchParams(location.search).get('keyword') || '';
    const column : any = new URLSearchParams(location.search).get('column') || sort.column;
    const order : any = new URLSearchParams(location.search).get('order') || sort.order;
    
    const [param, setParam] = useState<any>({type : '', page : page, size : 10, keyword : keyword, sortColumn : '', sortOrder : '', unique : 0, docType : ''})
    const [pageInfo, setPageInfo] = useState<any>({title1 : '', title2 : '', className : '', totalCount : 0, isActive : false, countryId : '', languageId : '', mainSpecId : '', optionId : '', subSpecId : '', subSpecName : ''});
    const [data, setData] = useState<any>([])

    const getList = async() => {
        if(path[2] === 'contents'){
            // 컨텐츠 리스트
            const result : any = await getContentsListApi(param);
            if(result.data.result === true){setData(result.data.list); setPageInfo((prev:any)=>({...prev,totalCount:result.data.total_cnt}))}
        }else if(path[2] === 'specification' && (path[3] === 'productSpec' || path[3] === 'remark')){
            // 메인스펙 ( 서브 유형 포함 ) 리스트 가져오기
            const result : any = await getMainSpecListWithSubApi(param);
            if(result.data.Result === 'true'){setData(result.data.List); setMainSpecData(result.data.List);}
        }else if(path[2]==='specification' && path[3] === 'mainSpec'){
            // 메인스펙 가져오기
            const result : any = await getMainSpecListApi(param)
            if(result.data.Result === 'true'){setData(result.data.List); setPageInfo((prev:any)=>({...prev,totalCount:result.data.totalCnt}))}
        }else if(path[2]==='specification' && path[3] === 'specOption'){
            // 스펙 유형 가져오기
            const result : any = await getMainSpecSubList2Api(param)
            if(result.data.Result === 'true'){setData(result.data.List); setPageInfo((prev:any)=>({...prev, totalCount : result.data.totalCnt}))}
        }else if(path[2] === 'stores'&& path[3] === 'country'){
            // 국가 리스트
            const result : any = await getCountryListApi(param)
            if(result.data.result === true){setData(result.data.list); setPageInfo((prev:any)=>({...prev,totalCount:result.data.total_cnt}))}
        } else if(path[2] === 'stores'&& path[3] === 'store'){
            // 대리점
            const result : any = await getStoreListApi(param)
            if(result.data.result === true){setData(result.data.list); setPageInfo((prev:any)=>({...prev,totalCount:result.data.total_cnt}))}
        } else if(path[2] === 'inquirys'){
            // 문의내용
            const result : any = await getInquiryListApi(param)
            if(result.data.result === true){setData(result.data.list); setPageInfo((prev:any)=>({...prev,totalCount:result.data.total_cnt}))}
        } else if(path[2] === 'members'){
            // 유저 리스트
            const result : any = await getUserListApi(param);
            if(result.data.result === true){setData(result.data.list); setPageInfo((prev:any)=>({...prev,totalCount:result.data.total_cnt}))}
        } else if(path[2]==='products'&&path[3]==='product'){
            // 제품 리스트
            const result : any = await getProductListApi(param)
            if(result.data.Result === 'true'){setData(result.data.List); setPageInfo((prev:any)=>({...prev,totalCount : result.data.totalCnt}))}
        }else if(path[2]==='products'&&path[3]==='download'){
            // 제품 다운로드 리스트
            const result : any = await getProductDocListApi(param)
            if(result.data.Result === 'true'){setData(result.data.List); setPageInfo((prev:any)=>({...prev,totalCount:result.data.totalCnt}))}
        }else if(path[2]==='products'&&path[3]==='language'){
            // 제품 등록 언어 리스트
            const result : any = await getProductDocLangListApi(param)
            if(result.data.Result === 'true'){setData(result.data.List); setPageInfo((prev:any)=>({...prev,totalCount:result.data.totalCnt}))}
        }
    }
    
    const handlePage = (pageNumber:number) =>{
        navigate(`${location.pathname}?page=${pageNumber}&keyword=${keyword}`)
    }
    useEffect(()=>{setParam((prev:any)=>({...prev, page : page, keyword : keyword, sortColumn : column, sortOrder : order, unique : sort.unique}))}, [ page, keyword, column, order, sort.unique])
    useEffect(()=>{
        if(param.page&&param.sortColumn&&param.sortOrder){getList()}
        setPageInfo((prev:any)=>({...prev, isActive : false, countryId : '', languageId : 0, languageName : '', mainSpecId : '', optionId : '', subSpecId : '', subSpecName : ''}))
    }, [param])
    useEffect(()=>{
        switch(type){
            case 'member' : setPageInfo((prev:any)=>({...prev, className : 'listOfMembers'})); setSort({...sort, column : 'dots_fullname', order : 'desc'}); break;
            case 'productSpec' : setPageInfo((prev:any)=>({...prev, className : 'productSpec'})); setSort({...sort, column : 'specName', order : 'asc', unique : 0});break;
            case 'content' : setPageInfo((prev:any)=>({...prev, className : 'listOfContent thumb_table thumbType_a'})); setSort({...sort, column : 'date', order : 'desc'}); break;
            case 'country' : setPageInfo((prev:any)=>({...prev, className : 'listOfCountry thumbType_a'})); setSort({...sort, column : 'country', order : 'desc', unique : 0}); break;
            case 'store' : setPageInfo((prev:any)=>({...prev, className : 'listOfStore thumbType_a'}));setSort({...sort, column : 'country', order : 'desc', unique : 1}); break;
            case 'inquiry' : setPageInfo((prev:any)=>({...prev, className : 'listOfInquiry'})); setSort({...sort, column : 'state', order : 'desc'}); break;
            case 'product' : setPageInfo((prev:any)=>({...prev, className : 'listOfProducts thumb_table thumbType_a'})); setSort({...sort, column : 'date', order : 'desc', unique : 0}); break;
            case 'download' : setPageInfo((prev:any)=>({...prev, className : 'listOfDownloadFile thumb_table thumbType_a'})); setSort({...sort, column : 'productName', order : 'desc', unique : 1}); break;
            case 'language' : setPageInfo((prev:any)=>({...prev, className : 'listOfCountry thumbType_a'})); setSort({...sort, column : 'langName', order : 'desc'}); break;
            case 'mainSpec' : setPageInfo((prev:any)=>({...prev, className : 'listOfMainSpec thumbType_a'})); setSort({...sort, column : 'specName', order : 'asc'}); break;
            case 'remark' : setPageInfo((prev:any)=>({...prev, className : 'productSpec listOfRemark'})); setSort({...sort, column : 'specName', order : 'asc', unique : 1}); break;
            case 'specOption' : setPageInfo((prev:any)=>({...prev, className : 'listOfSpecOption thumbType_a'})); setSort({...sort, column : 'specSubName', order : 'desc'});break;
        }
    }, [type])
    return(
        <>
        <div className={`contentBox ${pageInfo.className}`}>
            <ListHeader pageInfo={pageInfo} setPageInfo={setPageInfo} setParam={setParam} totalCount={pageInfo.totalCount}/>
            {/* 스펙 정보 리스트는 별도 분리 */}
            {location.pathname === `/admin/specification/productSpec/${typeId}` ? <ListSpecification data={data} getList={getList} setPageInfo={setPageInfo}/> : '' }
            {location.pathname === `/admin/specification/remark/${typeId}` ? <ListSpecification data={data} getList={getList} setPageInfo={setPageInfo}/> : ''}

            {/* 그외 리스트 페이지 */}
            <table className={`table_hover ${pageInfo.className}`}>

                <ListFilter param={param} setParam={setParam}/>

                {location.pathname === '/admin/specification/mainSpec' ? <ListMainSpec data={data} setPageInfo={setPageInfo} getList={getList}/> : ''}
                {location.pathname === '/admin/members/member' ? <ListMember data={data} getList={getList}/> : ''}
                {location.pathname === '/admin/contents/content' ? <ListContents data={data} refetch={getList}/> : ''}
                {location.pathname === '/admin/stores/country' ? <ListCountry data={data} setPageInfo={setPageInfo} getList={getList}/> : ''}
                {location.pathname === '/admin/stores/store' ? <ListStore data={data} getList={getList} page={page} totalCount={pageInfo?.totalCount}/> : ''}
                {location.pathname === '/admin/inquirys/inquiry'? <ListInquiry data={data}/> : ''}
                {location.pathname === '/admin/products/product' ? <ListProduct data={data} getList={getList}/> : ''}
                {location.pathname === '/admin/products/download' ? <ListDownload data={data}/> : ''}
                {location.pathname === '/admin/products/language' ? <ListLanguage data={data} getList={getList} setPageInfo={setPageInfo}/> : ''}
                {location.pathname === '/admin/specification/specOption'?<ListSpecOption data={data} setPageInfo={setPageInfo} getList={getList}/> : ''}

            </table>
            <div className="pagerBox">
                <p>Showing 1 to 10 of 98 entries</p>
                <Paginate page={page} size={10} totalCount={pageInfo.totalCount} setPage={handlePage} />
            </div>
            {pageInfo.isActive && path[3]==='mainSpec'?<RegistMainSpec mainSpecId={pageInfo.mainSpecId} setPageInfo={setPageInfo} getList={getList}/> : ''}
            {pageInfo.isActive && path[3]==='country' ?<RegistCountry countryId={pageInfo.countryId} setPageInfo={setPageInfo} getList={getList}/> : ''}
            {pageInfo.isActive && path[3]==='language' ? <RegistLanguage languageId={pageInfo.languageId} languageName={pageInfo.languageName} docType={pageInfo.docType} setPageInfo={setPageInfo} getList={getList}/> : ''}
            {pageInfo.isActive && path[3]==='specOption' ? <RegistSpecOption subSpecId={pageInfo.subSpecId} subSpecName={pageInfo.subSpecName} setPageInfo={setPageInfo} getList={getList}/> : ''}
        </div>
        </>
    )
}