import React from "react";
export interface Props {data:any, setData : Function}
export const RadioGroupBox:React.FC<Props> = ({data,setData}) => {
    const handleChange = (type : string, value : number) => {
        setData((prev:any)=>({...prev, [type] : value}))
    }
    return(
        <>
        <tr>
            <th>Channel</th>
            <td>
                <div className="radioWrap">
                    <div className="radioBox">
                        <input type="radio" name="channel" id="1CH" onChange={()=>handleChange('channelType', 1)} checked={data?.channelType === 1?true : false}/>
                        <label htmlFor="1CH">1CH</label>
                    </div>
                    <div className="radioBox">
                        <input type="radio" name="channel" id="2CH" onChange={()=>handleChange('channelType', 2)} checked={data?.channelType === 2?true : false}/>
                        <label htmlFor="2CH">2CH</label>
                    </div>
                    <div className="radioBox">
                        <input type="radio" name="channel" id="3CH" onChange={()=>handleChange('channelType', 3)} checked={data?.channelType === 3?true : false}/>
                        <label htmlFor="3CH">3CH</label>
                    </div>
                </div>
            </td>
        </tr>
        
        <tr>
            <th>Resolution</th>
            <td>
                <div className="radioWrap">
                    <div className="radioBox">
                        <input type="radio" name="resolution" id="FHD" onChange={()=>handleChange('resolutionType', 4)} checked={data?.resolutionType === 4?true : false}/>
                        <label htmlFor="FHD">FHD</label>
                    </div>
                    <div className="radioBox">
                        <input type="radio" name="resolution" id="QHD" onChange={()=>handleChange('resolutionType', 5)} checked={data?.resolutionType === 5?true : false}/>
                        <label htmlFor="QHD">QHD</label>
                    </div>
                    <div className="radioBox">
                        <input type="radio" name="resolution" id="UHD"onChange={()=>handleChange('resolutionType', 6)} checked={data?.resolutionType === 6?true : false}/>
                        <label htmlFor="UHD">UHD</label>
                    </div>
                    <div className="radioBox">
                        <input type="radio" name="resolution" id="front_cam_only" onChange={()=>handleChange('resolutionType', 7)} checked={data?.resolutionType === 7?true : false}/>
                        <label htmlFor="front_cam_only">(front cam only)</label>
                    </div>
                </div>
            </td>
        </tr>

        <tr>
            <th>Features</th>
            <td>
                <div className="radioWrap">
                    <div className="radioBox">
                        <input type="radio" name="features" id="cloud" onChange={()=>handleChange('featuresType', 8)} checked={data?.featuresType === 8?true : false}/>
                        <label htmlFor="cloud">Cloud</label>
                    </div>
                    <div className="radioBox">
                        <input type="radio" name="features" id="LCD" onChange={()=>handleChange('featuresType', 9)} checked={data?.featuresType === 9?true : false}/>
                        <label htmlFor="LCD">LCD</label>
                    </div>
                    <div className="radioBox">
                        <input type="radio" name="wifi" id="wifi" onChange={()=>handleChange('featuresType', 10)} checked={data?.featuresType === 10 ? true : false}/>
                        <label htmlFor="wifi">Wi-Fi</label>
                    </div>
                </div>
            </td>
        </tr>
        </>
    )
}