import React, { useEffect, useLayoutEffect, useState } from "react";
import { useGetProductDocLangListMutation } from "../../../store/admin/adminProductApiSlice";
export interface Props {fileType:string, fileLang:number, fileLangName: string, setType : Function}
export const FileTypeAndLangBox:React.FC<Props> = ({fileType,fileLang,setType}) => {
    const [langList, setLangList] = useState<any>([])
    const param = {page : 1, size : 99, sortColumn : 'langName', sortOrder : 'desc', keyword : '', docType : fileType}
    const [getProductDocLangListApi] = useGetProductDocLangListMutation()
    const getLangList = async() => {
        const result:any = await getProductDocLangListApi(param)
        if(result.data.Result === 'true' && result.data.List){
            const findYStatus = result.data.List.filter((item : any) => item.langActiveStatus === 'Y')
            if(findYStatus){setLangList(findYStatus)}
        }
    }
    const handleChange = (e:any) => {
        const { name, value } = e.target;
        if (name === 'fileLanguage') {
            const selectedLang = langList.find((lang: any) => lang.langIdx === parseInt(value));
            setType((prev: any) => ({ ...prev, fileLangName: selectedLang?.langNameEn }));
        }
        setType((prev: any) => ({ ...prev, [name]: value }));
    }
    const handleAddVersionList = () => {
        if(fileLang === 0){alert("언어를 선택하셔야 합니다."); return;}
        else{setType((prev:any)=>({...prev, isActive : true}))}
    }
    useEffect(()=>{setType((prev:any)=>({...prev, fileLanguage : 0}))}, [fileType])
    useEffect(()=>{getLangList()}, [fileType])
    return(
        <>
        <table>
            <tbody>
                <tr>
                    <th>
                        <div className="selectBox">
                            <select name={'fileType'} defaultValue={'F'} onChange={handleChange}>
                                {/* <option value="파일유형">파일유형</option> */}
                                <option selected value="F">Firmware</option>
                                <option value="M">Manual</option>
                                <option value="S">Speed Cam Data</option>
                            </select>
                        </div>
                    </th>
                    <td className="langSelectBox">
                        <div className="flexBox">
                            <div className="selectBox">
                                <select value={fileLang} name={"fileLanguage"} onChange={handleChange} placeholder="언어를 선택해주세요.">
                                <option value={0} disabled selected>언어를 선택해주세요.</option>
                                    {langList?.map((lang : any) => (
                                        <option key={lang?.langIdx} value={lang?.langIdx}>{lang?.langNameEn}</option>
                                    ))}
                                </select>
                            </div>
                            
                            <button onClick={handleAddVersionList}>적용</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </>
    )
}