import React, { useEffect, useState } from "react";
import { useGetCountryDetailMutation, useSetCountryMutation, useUptCountryMutation } from "../../../../store/admin/adminCountryApiSlice";
export interface Props {countryId:string,setPageInfo : Function,getList:Function}
export const RegistCountry:React.FC<Props> = ({countryId,setPageInfo,getList}) => {
    const [getCountryDetailApi] = useGetCountryDetailMutation()
    const [uptCountryApi] = useUptCountryMutation()
    const [setCountryApi] = useSetCountryMutation()
    const [country, setCountry] = useState<string>('')
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setCountry(e.target.value)
    }
    const getDetail = async () => {
        const result : any = await getCountryDetailApi({idx : countryId})
        setCountry(result.data.country)
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        if(countryId){formData.append('idx', countryId)}
        formData.append('country', country)
        if(countryId){
            const result : any = await uptCountryApi(formData)
            if(result.data.result===true){getList(); alert("수정이 완료되었습니다.");setPageInfo((prev:any)=>({...prev, isActive : false, countryId : ''}))}
        }else{
            const result : any = await setCountryApi(formData)
            if(result.data.result===true){getList(); alert("등록이 완료되었습니다.");setPageInfo((prev:any)=>({...prev, isActive : false, countryId : ''}))}
        }
    }
    const handleEnter = (e:any) => {
        if(e.key === 'Enter'){handleSave()}
    }
    useEffect(()=>{if(countryId){getDetail()}}, [countryId])
    return(
        <>
        <div className="registration_popUp popUp_active active">
            <button className="closeBtn" onClick={()=>setPageInfo((prev:any)=>({...prev, isActive : false, countryId : ''}))}>
                <i className="fa-light fa-xmark"></i>
            </button>

            <div className="titleBox">
                <span>Store</span>
                <span>{countryId?'Edit':'Add'} Country</span>
            </div>
            <div className="userInfoBox">
                <ul>
                    <li>
                        <label htmlFor="">Country</label>
                        <input type="text" name="" id="" value={country} placeholder="국가명을 입력해 주세요." onChange={handleChange} onKeyDown={handleEnter}/>
                    </li>
                </ul>
            </div>

            <div className="btnBox">
                <button onClick={()=>setPageInfo((prev:any)=>({...prev, isActive : false, countryId : ''}))}>취소</button>
                <button onClick={handleSave}>{countryId?'수정':'등록'}</button>
            </div>
        </div>
        </>
    )
}