import React from "react";
import { useNavigate } from "react-router-dom";
export interface Props {data : any}
export const ListDownload:React.FC<Props> = ({data}) => {
    const navigate = useNavigate()
    return(
        <>
        <tbody>
            {data?.map((x:any) => (
            <tr onClick={()=>navigate(`/admin/products/download/${x?.productIdx}`)}>
                <td>
                    <img src={x?.productImageFile} alt="모델링 썸네일 이미지"/>
                </td>
                <td>
                    <span className="ellipsis">{x?.productNameKr}</span>
                </td>
                <td>{x?.firmwareCount}</td>
                <td>{x?.ManualCount}</td>
                <td>{x?.scdCount}</td>
            </tr>
            ))}
        </tbody>
        </>
    )
}