import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSetProductDocMutation } from "../../../store/admin/adminProductApiSlice";
export interface Props {docInfo : any, getDetail : Function, onClose : Function}
export const VersionRegistItem:React.FC<Props> = ({docInfo, getDetail, onClose}) => {
    const {productId} : any = useParams()
    const [setProductDocApi] = useSetProductDocMutation()
    const [data, setData] = useState<any>({version : '', file : '', fileName : ''})
    const handleChange = (e:any) => {
        const {type, value} = e.target;
        if(type === 'file'){
            const reader = new FileReader();
            if(e.target.files[0]){reader.readAsDataURL(e.target.files[0])}
            reader.onload=()=>{
                setData((prev:any)=>({...prev, file : e.target.files[0], fileName : e.target.files[0].name}));
            }
        } else{
            setData((prev:any) => ({...prev, version : value}))
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        formData.append('productId' , productId)
        formData.append('docType', docInfo?.fileType)
        formData.append('docLang', docInfo?.fileLanguage)
        formData.append('docVersion', data?.version)
        formData.append('docPdsFile', data?.file)
        const result : any = await setProductDocApi(formData)
        if(result.data.result === true){alert("등록이 완료되었습니다."); getDetail(); onClose();}
    }

    return(
        <>
        <li>
            <div className="langSelectBox">
                <span>{docInfo?.fileLangName}</span>
                <input type="text" name="" id="" value={data?.version} onChange={handleChange} placeholder="버전을 입력해 주세요."/>

                <div className="selectFileBox"> 
                    <input type="file" id={docInfo?.fileLangName} onChange={handleChange}/>
                    <label htmlFor={docInfo?.fileLangName}>Select a file</label>
                </div>

                <div className="selectFileList">
                    {data?.fileName === '' ? 
                    <div>
                        <span>No file selected</span>
                    </div>:
                    <div>
                        <span>{data?.fileName}</span>
                        {/* <button></button> */}
                    </div>
                    }
                </div>

                <div className="btnBox">
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="saveBtn" onClick={handleSave}>저장</button>
                </div>
                {/* <!-- 변경된 값이 있을 때만 활성화 --> */}
            </div>
        </li>
        </>
    )
}