import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../store/storeHooks";
import { menuListInfo } from "../../../../store/stroeApi/menuInfoSlice";
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {channel:any,resolution:any,features:any}
export const ProductsItemFilter:React.FC<Props> = ({channel,resolution,features}) => {
    const location = useLocation()
    const navigate = useNavigate()
    // menuList in Store
    const menuInfo : menuListInfo = useAppSelector(state => state.menuInfo.menuInfo)

    const openRef = {channelRef:useRef<any>(null), resolutionRef:useRef<any>(null), featuresRef:useRef<any>(null)}

    const findChannel = menuInfo?.channel?.find((x:any)=>x.menuId===channel)
    const findResolution = menuInfo?.resolution?.find((x:any)=>x.menuId===resolution)
    const findFeatures = menuInfo?.features?.find((x:any)=>x.menuId===features)

    const [isOpen, setIsOpen] = useState<any>([false, false, false])
    const handleOpen = (index:number) => {
        const state = [...isOpen]
        state[index] = !state[index]
        setIsOpen(state)
    }
    
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (openRef.channelRef.current && !openRef.channelRef.current.contains(event.target as Node)) {
            setIsOpen((prev:any) => [false, prev[1], prev[2]]);
          }
          if (openRef.resolutionRef.current && !openRef.resolutionRef.current.contains(event.target as Node)) {
            setIsOpen((prev:any) => [prev[0], false, prev[2]]);
          }
          if (openRef.featuresRef.current && !openRef.featuresRef.current.contains(event.target as Node)) {
            setIsOpen((prev:any) => [prev[0], prev[1], false]);
          }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
    }, [openRef]);
    
    return(
        <>
        <div>
            <div className="selectWrap">
                <div className="selectBox" ref={openRef.channelRef} onClick={()=>handleOpen(0)}>
                    <span>Channel</span>
                    <div className={isOpen[0] ? 'active' : ''}>
                        {menuInfo?.channel?.map((channel : any, index : number)=>(
                            <span key={index} onClick={()=>navigate(`${location.pathname}?ch=${channel?.menuId}&rs=${resolution}&ft=${features}`)}>
                                {channel?.menuName}
                            </span>
                        ))}
                        
                    </div>
                </div>
                <div className="selectBox" ref={openRef.resolutionRef} onClick={()=>handleOpen(1)}>
                    <span>Resolution</span>
                    <div className={isOpen[1] ? 'active' : ''}>
                        {menuInfo?.resolution?.map((resolution : any, index : number) => (
                            <span key={index} onClick={()=>navigate(`${location.pathname}?ch=${channel}&rs=${resolution?.menuId}&ft=${features}`)}>
                                {resolution?.menuName}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="selectBox" ref={openRef.featuresRef} onClick={()=>handleOpen(2)}>
                    <span>Features</span>
                    <div className={isOpen[2] ? 'active' : ''}>
                        {menuInfo?.features?.map((features : any, index : number) => (
                            <span key={index} onClick={()=>navigate(`${location.pathname}?ch=${channel}&rs=${resolution}&ft=${features?.menuId}`)}>
                                {features?.menuName}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            {/* <!-- selectWrap Fin --> */}

            <div className="filterWrap">
                {!channel && !resolution && !features ? 
                <div className="all_items">
                    <button>All Items</button>
                </div> : ''
                }
                {/* <!-- 전체 아이템 --> */}
                
                <div className="filterBox">
                    <ul>
                        {findChannel ? 
                        <li>
                            {findChannel?.menuName}
                            <button onClick={()=>navigate(`${location.pathname}?ch=&rs=${resolution}&ft=${features}`)}></button>
                        </li>
                        : ''
                        }
                        {findResolution ? 
                        <li>
                            {findResolution?.menuName}
                            <button onClick={()=>navigate(`${location.pathname}?ch=${channel}&rs=&ft=${features}`)}></button>
                        </li>
                        :''
                        }
                        {findFeatures ? 
                        <li>
                            {findFeatures?.menuName}
                            <button onClick={()=>navigate(`${location.pathname}?ch=${channel}&rs=${resolution}&ft=`)}></button>
                        </li>
                        : ''
                        }
                    </ul>
                    {channel || resolution || features ?
                    <button onClick={()=>navigate(`${location.pathname}?ch=&rs=&ft=`)}>Clear all</button>
                    :''
                    }
                </div>
            </div>
        </div>
        </>
    )
}