import React from "react";
export interface Props {data:any}

export const Manual:React.FC<Props> = ({data}) => {
    const handleDownLoad = (file : any) => {
        const fileUrl: any = file;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    return(
        <>
        {data?.map((list:any) => (
        <div className={`userManual active`}>
            <div className="btnBox">
                <button className="downloadBtn active" onClick={()=>handleDownLoad(list?.docFile)}>DOWNLOAD</button>
            </div>
            {/* <div className="imgBox">
                <img src="/assets/images/gx4k_userManual.png" alt="user Manual"/>
            </div> */}
        </div>
        ))}
        </>
    )
}