import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from 'react-select';
export interface Props {initData :any, detailData:any, productSpec:any, setData:Function}
export const SpecSelectBox:React.FC<Props> = ({initData, detailData, productSpec, setData}) => {
    const {productId} = useParams()
    const [optionType, setOptionType] = useState<any>({})
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: number }>({});
    const handleChange = (e:any, specIdx: number, specSubType : number) => {
        const newSelectedOptions = { ...selectedOptions, [`${specIdx}_${specSubType}`]: e.value };
        setSelectedOptions(newSelectedOptions);
        setOptionType(e.value)
    }
    useEffect(()=>{

        if(productId === 'regist'){

            const newData = initData.map((item: any) => {
                const key = `${item.specIdx}_${item.specSubType}`;
                return `${item.specIdx},${item.specSubType},${selectedOptions[key] || 0}`;
            }).join(';');
            setData((prev:any)=>({...prev, productSpec : newData}))

        }else{
            // differnet item : 새로 추가된 MainSpec이 있을 경우, productSpec에 추가
            const initDataSet : any = new Set(initData?.map((x:any)=>x?.specIdx))
            const detailDataSet  : any = new Set(detailData?.map((y:any)=>y?.productSpecId))
            const differentValues = [...initDataSet]?.filter((specIdx : any) => !detailDataSet?.has(specIdx.toString()))
            const differentItems = initData?.filter((z:any)=>differentValues?.includes(z?.specIdx)).map((item: any) => {
                const key = `${item.specIdx}_${item.specSubType}`
                return `${item.specIdx},${item.specSubType},${selectedOptions[key] || 0}`
            }).join(';')

            // detail item : detail 정보가 있을 경우, Different Data와 결합하여 productSpec에 추가
            if(detailData){
                const newData = detailData?.map((item:any) => {
                    const key = `${item.productSpecId}_${item.productSpecSubType}`;
                    return `${item.productSpecId},${item.productSpecSubType},${selectedOptions[key] || item.productSpecOptionId}`
                }).join(';')
    
                // Sorting : Main Spec에 따라 순서대로 나열하여 productSpec에 저장
                const mergedData = `${newData};${differentItems}`.split(';').sort((x, y) => {
                    const aNumber = parseInt(x.split(',')[0]);
                    const bNumber = parseInt(y.split(',')[0]);
                    return aNumber - bNumber;
                  }).join(';');
                  setData((prev:any)=>({...prev, productSpec : mergedData}))
            }else{
                setData((prev:any)=>({...prev, productSpec : differentItems}))
            }

        }
    }, [initData, selectedOptions, productSpec, detailData])
    
    return(
        <>
        <div className="left">
            <ul>
                {initData?.map((list:any, index: number)=>{
                    const formatData = productSpec?.split(';');
                    const lastValuesArray = formatData?.map((str:any) => {
                        const valuesArray = str.split(',');
                        return valuesArray[valuesArray.length - 1];
                    }) ?? [];
              
                    return(
                        <>
                        <li key={list?.specIdx}>
                            <span>{list?.specName}<span> *</span></span>
                            <div className="selectBox">
                                <Select
                                    value={list?.specOption.find((x:any) => x.value === parseInt(lastValuesArray[index], 10))}
                                    options={list?.specOption}
                                    className="selectBox1"
                                    onChange={(e) => handleChange(e, list.specIdx, list.specSubType)}
                                    isSearchable={true}
                                    placeholder={'Please choose the specifications.'}
                                />
                            </div>
                        </li>
                        </>
                    )
                })}
            </ul>
        </div>
        </>
    )
}
