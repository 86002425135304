import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ProductImageUploadBox } from "../../../../components/admin/products/item-imageUploadBox";
import { SpecMountedCheckBox } from "../../../../components/admin/products/specRadioBoxList";
import { SpecSelectBox } from "../../../../components/admin/products/item-selectBox";
import { useGetProductAllSpecsMutation, useGetProductDetailMutation, useSetProductMutation, useSetToastFileUploadMutation, useUpdProductMutation } from "../../../../store/admin/adminProductApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { RadioGroupBox } from "../../../../components/admin/products/item-radioGroupBox";
import { ToastUIEditor } from "../../../../components/editor/toast-ui-editor";

export const RegistProduct = () => {
    const focusRef= useRef<any>(null)
    const navigate = useNavigate()
    const [isToggle, setIsToggle] = useState<boolean>(false)
    const [imageFile, setImageFile] = useState<any>({productWhiteImageFile : '', productBlackImageFile : '', whiteImageUrl : '', blackImageUrl : ''})

    const [data, setData] = useState<any>({modelName : '', productSpec : [], 
        channelType : 1, resolutionType : 4, featuresType : 8,
        hdr:'N',autoNightVersion:'N',imageQualitySetting:'N',drivingRecordingModeNormal:'N',drivingRecordingModeFps:'N',drivingRecordingModeStl:'N',parkingRecordingModeStl:'N',
        parkingRecordingModeMotionImpact:'N',parkingRecordingModeFps:'N',parkingRecordingModePowerSaving:'N',parkingEventVoiceAlert:'N',
        emergencyRecording:'N',aiHeatMonitoring:'N',aiDamageDetection:'N',adasFvma:'N',adasLdws:'N',speedCameraAlert:'N',highTemperatureCutOff:'N',
        highTemperatureCutOffHeatMonitoring:'N',lowVoltageCutOff:'N',overwriteOnOff:'N',formatFree:'N',memoryStorageAllocation:'N',privacyMode:'N',
        inAppLiveView:'N',lcdLiveView:'N',installationAngleGuide:'N',drivingTestGuide:'N',timeAlarm:'N', icons : ''
    })

    const {productId} = useParams()
    const [initData, setInitData] = useState<any>({productSpec1 : []})
    const [detailData, setDetailData] = useState<any>([])
    const [getProductAllSpecsApi] = useGetProductAllSpecsMutation()
    const [getProductDetailApi] = useGetProductDetailMutation()
    const [setProductApi] = useSetProductMutation()
    const [updProductApi] = useUpdProductMutation()
    // 기본적으로 가져오는 모든 사양 리스트
    const getAllList = async() => {
        const result : any = await getProductAllSpecsApi({})
        if(result.data.Result === 'true'){setInitData((prev:any)=>({...prev, productSpec1 : result.data?.productAllSpecList}))}
    }
    const getDetail = async() => {
        const result : any = await getProductDetailApi({idx: productId})
        if(result.data.Result === 'true'){
            const data = result.data.productInfo[0]
            setDetailData(result.data.productSpecList)
            setImageFile((prev:any)=>({...prev,
                whiteImageUrl:data.productWhiteImageFile,blackImageUrl:data.productBlackImageFile,
            }))
            setData((prev:any)=>({...prev, modelName : data.modelName,
            channelType : data.channelType, resolutionType : data.resolutionType, featuresType : data.featuresType,
            hdr:data.hdr,autoNightVersion:data.autoNightVersion,imageQualitySetting:data.imageQualitySetting,drivingRecordingModeNormal:data.drivingRecordingModeNormal,drivingRecordingModeFps:data.drivingRecordingModeFps,
            drivingRecordingModeStl:data.drivingRecordingModeStl,parkingRecordingModeStl:data.parkingRecordingModeStl,
            parkingRecordingModeMotionImpact:data.parkingRecordingModeMotionImpact,parkingRecordingModeFps:data.parkingRecordingModeFps,parkingRecordingModePowerSaving:data.parkingRecordingModePowerSaving,parkingEventVoiceAlert:data.parkingEventVoiceAlert,
            emergencyRecording:data.emergencyRecording,aiHeatMonitoring:data.aiHeatMonitoring,aiDamageDetection:data.aiDamageDetection,adasFvma:data.adasFvma,adasLdws:data.adasLdws,speedCameraAlert:data.speedCameraAlert,highTemperatureCutOff:data.highTemperatureCutOff,
            highTemperatureCutOffHeatMonitoring:data.highTemperatureCutOffHeatMonitoring,lowVoltageCutOff:data.lowVoltageCutOff,overwriteOnOff:data.overwriteOnOff,formatFree:data.formatFree,memoryStorageAllocation:data.memoryStorageAllocation,privacyMode:data.privacyMode,
            inAppLiveView:data.inAppLiveView,lcdLiveView:data.lcdLiveView,installationAngleGuide:data.installationAngleGuide,drivingTestGuide:data.drivingTestGuide,timeAlarm:data.timeAlarm, icons : data?.icons}))
        }
    }
    const handleChange = (e:any) => {setData((prev:any) => ({...prev, modelName : e.target.value}))}
    const handleSave = async() => {
        const formData : any = new FormData();
        for (const [key, value] of Object.entries(data)) {
            if (value instanceof File) {
                formData.append(key, value);
            } else {
                formData.append(key, value != null ? String(value) : '');
            }
        }
        if(imageFile?.productWhiteImageFile){formData.append('productWhiteImageFile', imageFile?.productWhiteImageFile)}
        if(imageFile?.productBlackImageFile){formData.append('productBlackImageFile', imageFile?.productBlackImageFile)}
        if (productId !== 'regist') {
            formData.append('productId', productId);
        }
        if(productId === 'regist'){
            const result : any = await setProductApi(formData);
            if(result){
                alert("Registered.")
                navigate(-1)
            }
        }else{
            const result : any = await updProductApi(formData)
            if(result){
                alert("Changed")
                navigate(-1)
            }
        }
    }
    useEffect(()=>{
        if(productId !== 'regist'){getDetail()}
        getAllList()
    }, [])
    
    return(
        <>
        <div className="contentBox upload">
            <h3 ref={focusRef}>Product</h3>
            <div className="flexWrap">
                <div className="flexBox">
                    <h4>
                        Add Product
                    </h4>
                </div>
                <div>
                    <button className="blackBtn">목록으로</button>
                    <button className="blueBtn" onClick={handleSave}>저장하기</button>
                </div>
            </div>
            
            <table>
                <tbody>
                    <tr>
                        <th>제목</th>
                        <td>
                            <input type="text" name="" value={data?.modelName} onChange={handleChange} id="" placeholder="제품명을 입력해 주세요."/>
                        </td>
                    </tr>
                    <ProductImageUploadBox imageUrl={imageFile?.whiteImageUrl} title={'썸네일 (White)'} name={'productWhiteImageFile'} setData={setImageFile}/>
                    <ProductImageUploadBox imageUrl={imageFile?.blackImageUrl} title={'썸네일 (Black)'} name={'productBlackImageFile'} setData={setImageFile}/>
                    <RadioGroupBox data={data} setData={setData}/>
                </tbody>
            </table>

            <div className="specificationWrap">
                <span>Specification</span>
                <div className="specificationBox">
                    
                    <SpecSelectBox
                        initData={initData?.productSpec1}
                        detailData={detailData}
                        productSpec={data.productSpec}
                        setData={setData}
                    />
                        
                    <SpecMountedCheckBox
                        data={data}
                        setData={setData}
                    />
                </div>

                <div className="toggleBox">
                    <span>Feature icon</span>
                    <div>
                        <span className="toggleSwitch">
                            <input type="checkbox" id="featureIcon" className="toggleIpt" checked={isToggle ? true : false} hidden/> 
                            <label htmlFor="featureIcon" onClick={()=>setIsToggle(!isToggle)} className="toggleSwitch">
                                <span className="toggleButton"></span>
                            </label>
                        </span>
                    </div>
                </div>
                {isToggle && 
                <div className="editBox">
                    <ToastUIEditor initData={data?.icons} setData={setData}/>
                </div>
                }

                <div className="btnBox">
                    <button>취소</button>
                    <button onClick={handleSave}>저장</button>
                </div>
            </div>
        </div>
        </>
    )
}