import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './locale/i18n'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Test } from './pages/user/test/test';
import LayOut from './pages/user/layout';
import { AdminLogin } from './pages/admin/manager';
import { getCookie, setCookie } from './util/cookie/cookies';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Dashboard } from './pages/admin/dashboard';
import { Test2 } from './pages/user/test/test2';
import { useGetAdminInfoMutation } from './store/admin/adminStratorsApiSlice';
import { useAppDispatch } from './store/storeHooks';
import { setAdminInfo } from './store/admin/adminInfoSlice';
function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const splitPath = location.pathname.split('/')
  const dispatch = useAppDispatch()
  // const ssid = sessionStorage.getItem('key')
  const [cookies] = useCookies(['key']);
  const ssid = cookies.key;
  const [getAdminInfoApi] = useGetAdminInfoMutation()
  const getAdminInfo = async() => {
    const result : any = await getAdminInfoApi({id : ssid || 0})
    if(result.data.result === true && result.data.manager_login) {
      dispatch(setAdminInfo({adminId : result.data.id, account : result.data.manager_login, name : result.data.manager_name, groupId : ''}))
      if(splitPath[1] === 'admin' && !splitPath[2]) {navigate('/admin/members/member')}
    }else{
      if(splitPath[1] === 'admin' && splitPath[2]){
        alert('관리자 로그인이 필요합니다.');
        navigate('/admin')
      }
    }
  }
  useEffect(() => {
    if(splitPath[1] === 'admin'){
      getAdminInfo()
    }
  }, [dispatch])
   
  return (
    <>
    <Routes>
      <Route path='/' element={<LayOut/>}/>
      <Route path='/products' element={<LayOut/>}/>
      <Route path='/products/:productId' element={<LayOut/>}/>
      <Route path='/productComparison' element={<LayOut/>} />
      <Route path='/productComparison/detail' element={<LayOut/>} />
      <Route path='/support/:supportType' element={<LayOut/>} />
      <Route path='/support/:supportType/:supportName' element={<LayOut/>} />
      <Route path='/support/:supportType/:supportName/:id' element={<LayOut/>} />
      <Route path='/blog' element={<LayOut/>} />
      <Route path='/blog/:blogId' element={<LayOut/>} />
      <Route path='/fineVu' element={<LayOut/>} />
      <Route path='/location' element={<LayOut/>} />
      <Route path='/sitemap' element={<LayOut/>} />
      <Route path='/privacy-policy' element={<LayOut/>}/>
      
      <Route path='/admin' element={<AdminLogin/>}/>
      <Route path='/admin/members/:type' element={<Dashboard/>}/>
      <Route path='/admin/products/:type' element={<Dashboard/>}/>
      <Route path='/admin/products/:type/:productId' element={<Dashboard/>}/>
      <Route path='/admin/specification/:type' element={<Dashboard/>}/>
      <Route path='/admin/specification/:type/:typeId' element={<Dashboard/>}/>
      <Route path='/admin/setting/:type' element={<Dashboard/>}/>
      <Route path='/admin/setting/:type/regist' element={<Dashboard/>}/>
      <Route path='/admin/setting/:type/:adminId' element={<Dashboard/>}/>
      <Route path='/admin/contents/:type' element={<Dashboard/>}/>
      <Route path='/admin/contents/:type/regist' element={<Dashboard/>}/>
      <Route path='/admin/contents/:type/:contentsId' element={<Dashboard/>}/>
      <Route path='/admin/stores/:type' element={<Dashboard/>}/>
      <Route path='/admin/stores/:type/regist' element={<Dashboard/>}/>
      <Route path='/admin/stores/:type/:storeId' element={<Dashboard/>}/>
      <Route path='/admin/inquirys/:type' element={<Dashboard/>}/>
      <Route path='/admin/inquirys/:type/:inquiryId' element={<Dashboard/>}/>

      <Route path='/test' element={<Test/>}/>
      <Route path='/test2' element={<Test2/>}/>

    </Routes>
    </>
  );
}

export default App;
