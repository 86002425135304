import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/user";

export const userInquiryApiSlice = createApi({
  reducerPath: "userInquiryApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 문의하기 
    setInquiry : builder.mutation({
        query : (body : any) => {
            return{
                url : '/inquiry/setUserInquirySendMail.php', method : 'post', body
            }
        }
    }),
  }),
});

export const {
  useSetInquiryMutation
} = userInquiryApiSlice;