import React, { useRef, useState } from "react";
import { ManualBox } from "../manualsBox/products-manualsBox";
export const tabList = [
    {idx: 1, title : 'Features'},
    {idx: 2, title : 'Description'},
    {idx: 3, title : "What's In The Box"},
    {idx: 4, title : 'Specifications'},
    {idx: 5, title : 'Manuals & Firmware'},
    {idx: 6, title : "User's Review"},
]
export const GX1000Cloud = () => {
    const [state, setState] = useState<any>({first : 1, second : 1, third : 1})
    const [tabIndex, setTabIndex] = useState<number>(1)
    const scrollRef = [
        useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)
    ];
    const handleScroll = (ref : number) => {
        if(scrollRef[ref] && scrollRef[ref].current){
            scrollRef[ref].current.scrollIntoView({
                behavior : 'smooth'
            })
        }
    }
    return(
        <>
            <div className="subPage products product_view gx1000Cloud">
                <div className="contentBox">
                    <div className="bgBox" style={{backgroundImage: "url('/assets/images/banner_GX1000Cloud.png')"}}>
                        <div className="centerBox">
                            <h2><span>GX</span>1000 CLOUD</h2>
                            <p>FAST & EASY CLOUD DASHCAM</p>
                            <ul>
                                <li onClick={()=>handleScroll(0)}>FineVu CLOUD</li>
                                <li onClick={()=>handleScroll(1)}>QHD/QHD</li>
                                <li onClick={()=>handleScroll(2)}>Auto Night Vision</li>
                                <li onClick={()=>handleScroll(3)}>HDR</li>
                                <li onClick={()=>handleScroll(4)}>SONY STARVIS Sensor</li>
                                <li onClick={()=>handleScroll(5)}>Power Saving Parking Mode</li>
                                <li onClick={()=>handleScroll(6)}>Absolute Parking Mode</li>
                                <li onClick={()=>handleScroll(7)}>Built-in GPS & Wi-Fi</li>
                                <li onClick={()=>handleScroll(8)}>ADAS PLUS</li>
                                <li onClick={()=>handleScroll(9)}>AI Heat Monitoring</li>
                                <li onClick={()=>handleScroll(10)}>Low Voltage Cut-off (Winter Season)</li>
                                <li onClick={()=>handleScroll(11)}>Speed Camera Alert</li>
                                <li onClick={()=>handleScroll(12)}>Customizing OSD Phrases</li>
                                <li onClick={()=>handleScroll(13)}>Memory Allocation</li>
                                <li onClick={()=>handleScroll(14)}>Format Free 2.0</li>
                            </ul>
                        </div>

                        <div className="scrollDownBox">
                            <div>
                                <span></span>
                            </div>
                            <span>SCROLL</span>
                        </div>
                    </div>
                    {/* <!-- bgBox Fin --> */}

                    <div className="tabWrap">
                        <div className="tabBtnWrap">
                            <div className="tabBtnBox">
                            {tabList.map((list:{idx:number,title:string})=>(
                                <button className={tabIndex===list?.idx?'active':''} onClick={()=>setTabIndex(list?.idx)}>
                                    {list?.title}
                                </button>
                            ))}
                            </div>
                        </div>

                        <div className="tabContentBox">
                            <div className={`features ${tabIndex === 1? 'active' : ''}`}>
                                <div className="sliderWrap">
                                    <ul>
                                        <li>
                                            <div className="imgBox">
                                                <img src="/assets/images/view_GX1000cloud_1.jpg" alt="GX1000 Cloud" />
                                            </div>
                                        </li>
                                        {/* <!-- <li>
                                            <div className="imgBox">
                                                <img src="/assets/images/view_GX4K_2.png" alt="GX4K">
                                            </div>
                                        </li> --> */}
                                    </ul>
                                    {/* <!-- slider fin --> */}
                                    <div className="dotsBox">
                                        <span></span>
                                        <span className="active"></span>
                                        <span></span>
                                    </div>
                                </div>
                                {/* <!-- sliderArea Fin --> */}

                                <div className="iconBox">
                                    <ul>
                                        <li>
                                            <div>
                                                <img src="/assets/images/fineVu_cloud.png" alt="FineVu Cloud" />
                                            </div>
                                            <span>FineVu Cloud</span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/fineVu_qhd.png" alt="QHD/QHD" />
                                            </div>
                                            <span>QHD/QHD</span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/AutoNightVision.png" alt="Auto Night Vision" />
                                            </div>
                                            <span>
                                                Auto <br />
                                                Night Vision
                                            </span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/hdr_icon.png" alt="HDR" />
                                            </div>
                                            <span>HDR</span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/Sony_STARVIS.png" alt="SONY STARVIS Sensor" />
                                            </div>
                                            <span>
                                                SONY STARVIS <br />
                                                Sensor
                                            </span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/PowerSavingParkingMode.png" alt="Power Saving Parking Mode" />
                                            </div>
                                            <span>
                                                Power Saving <br />
                                                Parking Mode
                                            </span>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <div>
                                                <img src="/assets/images/AbsoluteParkingMode.png" alt="Absolute Parking Mode" />
                                            </div>
                                            <span>
                                                Absolute <br />
                                                Parking Mode
                                            </span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/Built_inGPS.png" alt="Built-in<br>Built-in GPS&Wi-Fi" />
                                            </div>
                                            <span>
                                                Built-in <br />
                                                GPS&Wi-Fi
                                            </span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/ADASPLUS.png" alt="ADAS PLUS" />
                                            </div>
                                            <span>ADAS PLUS</span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/AIHeatMonitoring.png" alt="AI Heat Monitoring" />
                                            </div>
                                            <span>
                                                AI Heat <br />
                                                Monitoring
                                            </span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/LowVoltageCut_off.png" alt="Low Voltage Cut-off" />
                                            </div>
                                            <span>
                                                Low Voltage <br />
                                                Cut-off
                                            </span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/SpeedCameraAlert.png" alt="Speed Camera Alert" />
                                            </div>
                                            <span>
                                                Speed Camera <br />
                                                Alert
                                            </span>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <div>
                                                <img src="/assets/images/customizingOSD.png" alt="Customizing OSD Phrases" />
                                            </div>
                                            <span>
                                                Customizing <br />
                                                OSD Phrases
                                            </span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/MemoryAllocation.png" alt="Memory Allocation" />
                                            </div>
                                            <span>
                                                Memory <br />
                                                Allocation
                                            </span>
                                        </li>
                                        <li>
                                            <div>
                                                <img src="/assets/images/FormatFree.png" alt="Format Free 2.0" />
                                            </div>
                                            <span>
                                                Format Free <br />
                                                2.0
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                {/* <!-- iconBox Fin --> */}

                                <div className="imgBox" ref={scrollRef[0]}>
                                    <img src="/assets/images/view_GX1000cloud_2.png" alt="GX1000 cloud" />

                                    <div className="center">
                                        <h3>
                                            EVENT VIDEOS ARE UPLOADED TO <br />
                                            FINEVU CLOUD AUTOMATICALLY
                                        </h3>


                                        <div className="imgBox">
                                            <img src="/assets/images/view_GX1000cloud_3.jpg" alt="GX1000 cloud" />
                                        </div>
                                        <p>* A wireless Internet connection is required to use FineVu CLOUD feature. (ex. Mobile router, Mobile hotspot…etc.)</p>
                                    </div>
                                </div>

                                <section className="section_1">
                                    <h3>
                                        <span>EASY WI-FI PAIRMG WITH FINEVU APP</span>
                                        ENJOY EVERY FEATURES OF DASHCAM REMOTELY
                                    </h3>

                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX1000cloud_4.png" alt="GX1000 cloud" />
                                    </div>
                                    <ul>
                                        <li>* A wireless Internet connection is required to use FineVu CLOUD feature. (ex. Mobile router, Mobile hotspot ··· etc.)</li>
                                        <li>* In environments where wireless Internet connectivity is not available, only Wi-Fi function is available in the FineVu CLOUD & Wi-Fi app.</li>
                                        <li>* FineVu Cloud & Wi-Fi app and be downloaded from Google Play Store / App Store.</li>
                                        <li>* For detailed connecting steps, please refer to manual.</li>
                                    </ul>
                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX1000cloud_5.png" alt="GX1000 cloud" />
                                    </div>
                                </section>

                                <section className="section_2">
                                    <h3>LIVE PUSH NOTIFICATIONS</h3>
                                    <p>Exposure is balanced for keeping all details intact.</p>
                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX1000cloud_6.png" alt="GX1000 cloud" />
                                    </div>
                                </section>

                                <section className="section_3">
                                    <h3>FREE PLAN</h3>
                                    <div className="freePlan_tableWrap">
                                        <ul>
                                            <li>
                                                <span>FineVu Cloud</span>
                                                <span>Free Plan</span>
                                            </li>
                                            <li>
                                                <span>Subscription Fee (in USD)</span>
                                                <span>$0</span>
                                            </li>
                                            <li>
                                                <span>Max.Dashcams/Account</span>
                                                <span>1</span>
                                            </li>
                                            <li>
                                                <span>Users Per Account</span>
                                                <span>1</span>
                                            </li>
                                            <li>
                                                <span>Live View / day</span>
                                                <span>10 minutes</span>
                                            </li>
                                            <li>
                                                <span>Remote Live View and Download / Month</span>
                                                <span>100 Times (Original video is not supported)</span>
                                            </li>
                                            <li>
                                                <span>Cloud Storage</span>
                                                <span>4.8GB (Auto-Upload) + 1.2GB (Storage)</span>
                                            </li>
                                            <li>
                                                <span>Push Notifications</span>
                                                <span>○</span>
                                            </li>
                                            <li>
                                                <span>Live GPS</span>
                                                <span>○</span>
                                            </li>
                                            <li>
                                                <span>Driving Statistics</span>
                                                <span>60 days</span>
                                            </li>
                                            <li>
                                                <span>Auto Upload</span>
                                                <span>7 Days</span>
                                            </li>
                                        </ul>
                                        <p>* The plan is subject to change later.</p>
                                    </div>

                                </section>

                                <section className="section_4"  ref={scrollRef[1]}>
                                    <div className="imgBox">
                                        <img src="/assets/images/view_GX1000cloud_7.png" alt="GX1000 cloud" />
                                    </div>

                                    <div className="resolution_tableWrap">
                                        <ul>
                                            <li>
                                                <span>Definition</span>
                                                <span>FULL HD</span>
                                                <span>QHD</span>
                                            </li>
                                            <li>
                                                <span>Resolution</span>
                                                <span>1920 × 1080</span>
                                                <span>2560 × 1440</span>
                                            </li>
                                            <li>
                                                <span>Pixels</span>
                                                <span>2,073,600</span>
                                                <span>3,686,400</span>
                                            </li>
                                        </ul>
                                    </div>
                                </section>

                                <section className="section_5">
                                    <h3>
                                        <span>CRYSTAL CLEAR QHD RESOLUTION</span>
                                        QHD CAPTURES EVERY MOMENT REALISTICALLY
                                    </h3>
                                    <div className="slide_tabBtnWrap">
                                        <div className={`slide_tabBtnBox number2 active_${state.first}`}>
                                            <button onClick={()=>setState((prev:any) => ({...prev, first : 1}))}>
                                                <span>Front</span>
                                            </button>
                                            <button onClick={()=>setState((prev:any) => ({...prev, first : 2}))}>
                                                <span>Rear</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="tabContentBox">
                                        <div className={state.first === 1 ? 'active' : ''}>
                                            <div className="imgBox">
                                                <img src="/assets/images/detail/gx1000Cloud_section2_front.png" alt="front" />
                                            </div>
                                        </div>
                                        <div className={state.first === 2 ? 'active' : ''}>
                                            <div className="imgBox">
                                                <img src="/assets/images/detail/gx1000Cloud_section2_rear.png" alt="rear" />
                                            </div>
                                        </div>
                                    </div>
                                    <p>* This video is taken in QHD quality. The license plate is blurred to protect personal information.</p>
                                    <p>* The quality of recording may vary depending on conditions (including camera angle, windshield conditions, and light conditions).</p>
                                </section>

                                <section className="section_6"  ref={scrollRef[2]}>
                                    <h3>
                                        <span>AUTO NIGHT VISION</span>
                                        AUTOMATED NIGHT VISION ADJUSTS <br />
                                        BRIGHTNESS AND CONTRAST TO SECURE CLARITY
                                    </h3>
                                    <p>
                                        Smart AI system assesses light environment and finds the perfect time to switch it on. <br />
                                        Keeping you completely hands-free.
                                    </p>

                                    <div className="slide_tabBtnWrap">
                                        <div className={`slide_tabBtnBox number2 active_${state.second}`}>
                                            <button onClick={()=>setState((prev:any)=>({...prev, second : 1}))}>
                                                <span>Night</span>
                                            </button>
                                            <button onClick={()=>setState((prev:any)=>({...prev, second : 2}))}>
                                                <span>Day</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="tabContentBox">
                                        <div className={state.second === 1 ? 'active' : ''}>
                                            <div className="imgBox">
                                                <img src="/assets/images/detail/gx1000_cloud_section4_night.png" alt="Night" />
                                            </div>
                                        </div>
                                        <div className={state.second === 2 ? 'active' : ''}>
                                            <div className="imgBox">
                                                <img src="/assets/images/detail/gx1000_cloud_section4_day.png" alt="Day" />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className="section_7" ref={scrollRef[3]}>
                                    <h3>
                                        <span>HDR</span>
                                        OPTIMIZED WITH HIGH DYNAMIC RANGE(HDR)
                                    </h3>
                                    <p>Exposure is balanced for keeping all details intact.</p>

                                    <div className="imgBox">
                                        <img src="/assets/images/detail/gx1000_cloud_section3.png" alt="HDR" />
                                    </div>
                                </section>

                                <section className="section_8" ref={scrollRef[4]}>
                                    <h3>
                                        <span>SONY STARVIS SENSOR</span>
                                        CLEAR RECORDING EVEN AT NIGHT
                                    </h3>
                                    <p>SONY STARVIS SENSOR provides unimaginably vivid image in regardless of day or night.</p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail/detail_SONY_STARVIS_SENSOR01.png" alt="SONY STARVIS SENSOR" />
                                    </div>
                                </section>

                                <section className="section_9" ref={scrollRef[5]}>
                                    <h3>
                                        <span>POWER SAVING PARKING MODE</span>
                                        PROLONGED RECORDING TIME
                                    </h3>
                                    <p>Consuming 98% less power, GX1000 CLOUD records 2,325 more hours than standard parking mode.</p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_powerSavingParkingMode.png" alt="power Saving Parking Mode" />
                                    </div>
                                    <ul>
                                        <li> * The number of recording-standby hours is based on maximum hours provided by hardwiring to fuse tap until low voltage cut-off initiates.</li>
                                        <li>* Depending on dashcam’s power consumption, environmental factors, and vehicle’s battery condition actual recording standby hours may vary.</li>
                                        <li>* Certain features may not be featured during Power Saving Parking Mode.</li>
                                    </ul>
                                </section>

                                <section className="section_10">
                                    <h3>
                                        A MINUTE LONG SURVEILLANCE
                                    </h3>
                                    <p>And that is not it. Any motion caught in the next 60-seconds will be secured in a minute-long footage.</p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_A_MINUTE_LONG_SURVEILLANCE.png" alt="Power Saving Parking Mode" />
                                    </div>
                                    <p>
                                        Vacation, business trip, or any other long absence it may be. FineVu will look after your car.
                                        <span>* Requires hardwire cable installation</span>
                                    </p>
                                </section>

                                <section className="section_11" ref={scrollRef[6]}>
                                    <h3>
                                        <span>ABSOLUTE PARKING MODE</span>
                                        20-SECONDS OF COMPLETE EVIDENCE
                                    </h3>
                                    <p>FineVu’s Absolute Parking Mode secures vital 20-seconds of the accident scene.</p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_AbsoluteParkingMode.png" alt="ABSOLUTE PARKING MODE" />
                                    </div>
                                    <p>
                                        10-seconds prior to the event, and 10-seconds after the event. No detail is left out from the scene. <br />
                                        Secure the right moment with included hardwire cable.
                                        <span>* Requires hardwire cable installation</span>
                                    </p>
                                </section>

                                <section className="section_12" ref={scrollRef[7]}>
                                    <h3>
                                        <span>BUILT-IN GPS</span>
                                        WITHOUT THE BURDEN OF PURCHASING ADDITIONAL <br />
                                        EXTERNAL GPS
                                    </h3>
                                    <p>FEEL FREE TO USE FUNCTIONS SUCH AS SPEED CAMERA ALERT AND ADAS PLUS.</p>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx400_section3.png" alt="BUILT-IN GPS" />
                                    </div>
                                </section>


                                <section className="section_13">
                                    <h3>
                                        <span>BUILT-IN WI-FI</span>
                                        CHECK THE RECORDED VIDEOS AND UPDATE <br />
                                        THE FIRMWARE ON FINEVU APP
                                    </h3>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_builtInWifi.png" alt="BUILT-IN WI-FI" />
                                    </div>
                                    <ul>
                                        <li>* This product is built-in Wi-Fi and does not require a separate Wi-Fi dongle.</li>
                                        <li>* The FineVu Wi-Fi app (<img src="/assets/images/wifi_app.png" alt="wifi" />) can be downloaded from the Google Play Store / App Store.</li>
                                        <li>* For detailed connection method, please refer to the manual.</li>
                                    </ul>
                                </section>

                                <section className="section_14" ref={scrollRef[8]}>
                                    <h3>
                                        <span>ADAS PLUS</span>
                                        ADVANCED DRIVER SAFETY WARNING SYSTEMS <br />
                                        KEEP YOU SAFE ON THE ROAD
                                    </h3>
                                    <p>
                                        ADAS(Advanced Driver Assistance Systems) helps safe driving with sound alerts. <br />
                                        The system evaluates its surroundings with integrated sensors, and warns driver if necessary.
                                    </p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_ADASPLUS.png" alt="ADAS PLUS" />
                                    </div>
                                    <div className="imgBox">
                                        <div>
                                            <p>
                                                <strong>Front Vehicle Motion Alert (FVMA)</strong>
                                                While stopped in traffic, the movement of stationary vehicle ahead triggers alert to driver.
                                            </p>
                                        </div>
                                        <img src="/assets/images/detail_ADASPLUS_FVMA.png" alt="Front Vehicle Motion Alert (FVMA)" />
                                    </div>
                                    <div className="imgBox">
                                        <div>
                                            <p>
                                                <strong>Lane Departure Warning System (LDWS)</strong>
                                                Alert is triggered as vehicle veers off driving lane while in motion.
                                            </p>
                                        </div>
                                        <img src="/assets/images/detail_ADASPLUS_LDWS.png" alt="Lane Departure Warning System (LDWS)" />
                                    </div>
                                </section>

                                <section className="section_15" ref={scrollRef[9]}>
                                    <h3>
                                        <span>AI HEAT MONITORING</span>
                                        SMART AI DASHCAM CONTROL
                                    </h3>
                                    <p>If FineVu starts to overheat while at parking mode, the AI will automatically switch it to Power Saving Parking Mode. When it cools down and temperature stabilizes, AI will switch it back to motion parking mode.</p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_AI_HEAT_MONITORING.png" alt="AI HEAT MONITORING" />
                                    </div>
                                    <p> * The feature is only applicable during motion/time lapse parking mode.</p>
                                </section>

                                <section className="section_16" ref={scrollRef[10]}>
                                    <h3>
                                        <span>LOW VOLTAGE CUT-OFF (WINTER SEASON)</span>
                                        LOW VOLTAGE SETTING ACCORDING TO VEHICLE TYPE
                                    </h3>
                                    <p>
                                        FineVu is equipped with Battery Protection System. Dashcam powers down when battery voltage falls below preset value. Select your  <br />
                                        vehicle type and your car’s specific power setting on FineVu CLOUD & Wi-Fi app to maximize efficiency of both dashcam and car batter.
                                    </p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_lowVoltage.png" alt="LOW VOLTAGE CUT-OFF (WINTER SEASON)" />
                                    </div>
                                    <ul>
                                        <li>* FineVu recommends changing the low voltage settings to ‘hybrid’ when using the ISG system.</li>
                                        <li>* During winter season, dashcam is automatically cut-off at a point when voltage is 0.2V higher than set value.</li>
                                    </ul>
                                </section>

                                <section className="section_17" ref={scrollRef[11]}>
                                    <h3>
                                        <span>SPEED CAMERA ALERT</span>
                                        FINEVU KEEPS UP WITH THE LATEST SAFETY CAMERA  <br />
                                        ALERT
                                    </h3>
                                    <p>
                                        FineVu safety camera database is always up to date. Download the quarterly updates, and get informed with FineVu's voice & visual alerts. <br />
                                        Worry no more about accidents and speeding tickets.
                                    </p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_speedCameraAlert2.png" alt="SPEED CAMERA ALERT" />
                                    </div>
                                    <ul>
                                        <li>* This feature is not available in all areas.</li>
                                        <li>* It operates when GPS signals are received.</li>
                                    </ul>
                                </section>

                                <section className="section_18" ref={scrollRef[12]}>
                                    <h3>
                                        <span>CUSTOMIZING OSD PHRASES</span>
                                        SYMBOLIC NAME ACCORDING TO USER’S TASTE
                                    </h3>
                                    <p>
                                        Users can change the wording of the On Screen Display(OSD) to user’s preference. Up to 20 characters can be entered in English letters, <br />
                                        numbers, and special characters.
                                    </p>
                                    <div className="imgBox">
                                        <img src="/assets/images/detail_customizingOSD_GX1000_cloud.png" alt="CUSTOMIZING OSD PHRASES" />
                                    </div>
                                    <ul>
                                        <li>* OSD Phrases can be set up in the FineVu CLOUD & Wi-Fi app.</li>
                                        <li>* Special characters are only available for '&#126;&#33;&#64;&#36;&#94;&#38;&#42;&#40;&#41;&#45;&#95;&#61;&#43;&#58;&#59;&#91;&#93;&#60;&#62;? (Spaces are not supported.)</li>
                                    </ul>
                                </section>

                                <section className="section_19" ref={scrollRef[13]}>
                                    <h3>
                                        <span>MEMORY ALLOCATION</span>
                                        EFFICIENT MEMORY MANAGEMENT
                                    </h3>
                                    <p>The memory allocation function enables efficient memory management according to the driving pattern.</p>
                                    <div className="slide_tabBtnWrap">
                                        <div className={`slide_tabBtnBox number4 active_${state.third}`}>
                                            <button onClick={()=>setState((prev:any)=>({...prev, third : 1}))}>
                                                <span>Driving Priority</span>
                                            </button>
                                            <button onClick={()=>setState((prev:any)=>({...prev, third : 2}))}>
                                                <span>Event Priority</span>
                                            </button>
                                            <button onClick={()=>setState((prev:any)=>({...prev, third : 3}))}>
                                                <span>Parking Priority</span>
                                            </button>
                                            <button onClick={()=>setState((prev:any)=>({...prev, third : 4}))}>
                                                <span>Driving Only</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="tabContentBox">
                                        <div className={state.third === 1 ? 'active' : ''}>
                                            <div className="imgBox">
                                                <img src="/assets/images/gx35_section14_1.png" alt="MEMORY STORAGE ALLOCATION" />
                                            </div>
                                        </div>
                                        <div className={state.third === 2 ? 'active' : ''}>
                                            <div className="imgBox">
                                                <img src="/assets/images/gx35_section14_2.png" alt="MEMORY STORAGE ALLOCATION" />
                                            </div>
                                        </div>
                                        <div className={state.third === 3 ? 'active' : ''}>
                                            <div className="imgBox">
                                                <img src="/assets/images/gx35_section14_3.png" alt="MEMORY STORAGE ALLOCATION" />
                                            </div>
                                        </div>
                                        <div className={state.third === 4 ? 'active' : ''}>
                                            <div className="imgBox">
                                                <img src="/assets/images/gx35_section14_4.png" alt="MEMORY STORAGE ALLOCATION" />
                                            </div>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>* The MicroSD card is formatted when changing the memory allocation.</li>
                                        <li>* The memory format type is FAT32, and if the format type is different, the dash cam proceeds with formatting automatically.</li>
                                        <li>(After formatting, once you insert it into the device and supply it with power, the formatting will be in progress to set the memory.)</li>
                                    </ul>
                                </section>

                                <section className="section_20" ref={scrollRef[14]}>
                                    <h3>
                                        <span>FORMAT FREE 2.0</span>
                                        ADVANCED FORMAT FREE TECHNOLOGY
                                    </h3>
                                    <p>
                                        Format Free 2.0 technology eliminates the need for manual reformatting of the Micro SD memory card. Also, it helps to extend memory card <br />
                                        lifespan and improve reliability.
                                    </p>
                                    <div className="imgBox">
                                        <img src="/assets/images/GX1000-CLOUD-section19.png" alt="FORMAT FREE 2.0" />
                                    </div>
                                </section>

                                <section className="section_21">
                                    <h3>
                                        <span>FINEVU PC PLAYER</span>
                                        THE OPTIMAL DASH CAM ANALYSIS PROGRAM, <br />
                                        FINEVU PLAYER 2.0
                                    </h3>
                                    <p>
                                        FineVu PC player is an easy-to-use PC software of FineVu dash cam. <br />
                                        You can playback, screenshot, enlarge the images with full resolution and monitor the video records conveniently on your PC.
                                    </p>
                                    <div className="imgBox">
                                        <img src="/assets/images/gx7000_section18.png" alt="FINEVU PC PLAYER" />
                                    </div>
                                    <p>*This feature can be used after installing FineVu Player program.</p>
                                </section>
                            </div>
                            {/* <!-- Features Fin --> */}

                            <div className={`description ${tabIndex === 2 ? 'active' : ''}`}>
                                <section>
                                    <h3>Product Description</h3>
                                    <div className="imgBox">
                                        <img src="/assets/images/fineVu_GX1000cloud_spec.png" alt="Product Description" />
                                    </div>
                                </section>
                            </div>
                            {/* <!-- Description Fin --> */}

                            <div className={`whatsInTheBox ${tabIndex === 3 ? 'active' : ''}`}>
                                <section>
                                    <div>
                                        <h3>Basic Configuration</h3>
                                        <div className="imgBox">
                                            <img src="/assets/images/GX1000cloud_basic_configuration.png" alt="Basic Configuration" />
                                        </div>
                                        <ul>
                                            <li>※ To improve the performance of the product, its components may change without prior notice.</li>
                                            <li>※ The warranty period of the Micro SD card is 6 months.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>Additional Options</h3>
                                        <div className="imgBox">
                                            <img src="/assets/images/GX1000cloud_additional_options.png" alt="Additional Options" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {/* <!-- What's In The Box Fin --> */}

                            <div className={`specifications ${tabIndex === 4 ? 'active' : ''}`}>
                                <div className="tableWrap">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Model</th>
                                                <th>FineVu GX1000 Cloud</th>
                                                <th>Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Image Sensor</th>
                                                <td>
                                                    Front : SONY STARVIS IMX335 5.1MP (HDR) <br />
                                                    Rear : SONY STARVIS IMX335 5.1MP (HDR)
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Resolution</th>
                                                <td>
                                                    Front : 2560*1440P (Quad HD) <br />
                                                    Rear : 2560*1440P (Quad HD)
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Frames Per Second</th>
                                                <td>
                                                    Driving : 30fps <br />
                                                    Parking : 30fps
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Viewing Angle</th>
                                                <td>
                                                    Front : 139° <br />
                                                    Rear : 141°
                                                </td>
                                                <td>Diagonal</td>
                                            </tr>
                                            <tr>
                                                <th>Bitrate</th>
                                                <td>
                                                    Front : 16 Mbps <br />
                                                    Rear : 16 Mbps
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Recording Mode</th>
                                                <td>Drive / Parking / Emergency</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Display</th>
                                                <td>X</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Acceleration Sensor</th>
                                                <td>3-Axis Acceleration Sensor</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>GPS</th>
                                                <td>O</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Wi-Fi</th>
                                                <td>O</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Cloud</th>
                                                <td>O</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Storage Capacity</th>
                                                <td>FineVu Micro SD Card 128GB</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Battery</th>
                                                <td>Supercapacitor</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Microphone</th>
                                                <td>O</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Speaker</th>
                                                <td>O</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Security LED</th>
                                                <td>
                                                    Front : White <br />
                                                    Rear : Blue
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Input Power</th>
                                                <td>12V ~ 24V DC</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Power Consumption</th>
                                                <td>
                                                    Parking Mode : 4.8W <br />
                                                    Power Saving Parking Mode : 0.06W
                                                </td>
                                                <td>2CH, GPS Off / Wi-Fi Off</td>
                                            </tr>
                                            <tr>
                                                <th>High-Temperature Cut-Off</th>
                                                <td>AI Heat Monitoring</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Low Voltage Cut-Off</th>
                                                <td>O</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Temperature</th>
                                                <td>
                                                    Operation : -20℃ ~ 70℃ <br />
                                                    Storage : -30℃ ~ 80℃
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Size(WxDxH) / Weight</th>
                                                <td>
                                                    Front : 96.5 x 70.0 x 26.5 mm / 114g <br />
                                                    Rear : 46.85 x 36.5 x 29.2 mm / 23g
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Mobile Application</th>
                                                <td>FineVu CLOUD&Wi-Fi App.</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>PC Viewer</th>
                                                <td>FineVu PC Player 2.0</td>
                                                <td>Windows, MAC</td>
                                            </tr>
                                            <tr>
                                                <th>Warranty</th>
                                                <td>3-Years</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Features</th>
                                                <td>
                                                    HDR, Auto Night Vision, Image Quality Setting, Emergency Recording, Parking Event Voice Alert, AI Heat Monitoring, ADAS*(FVMA&LDWS),
                                                    Speed Camera Alert**, High-Temperature Cut-Off, Low Voltage Cut-Off, Overwrite On/Off, Format Free 2.0, Memory Storage Allocation,
                                                    In-App. Live View, Installation Angle Guide, Driving Rest Guide, Time Alarm
                                                </td>
                                                <td>ADAS and Speed Camera Alert requires GPS reception</td>
                                            </tr>
                                            <tr>
                                                <th>Regulatory Compliance</th>
                                                <td>
                                                    KC (R-R-FDK-FineR038) <br />
                                                    Model name : FineVu GX1000 CLOUD <br />
                                                    Date of manufacture : Marked separately <br />
                                                    Name(Model) : Dashcam(GX1000 CLOUD) <br />
                                                    Manufacturer : Finedigital Inc. <br />
                                                    Company name : Finedigital Inc. <br />
                                                    Manufacturing country : Rep. of Korea
                                                </td>
                                                <td>
                                                    Passed KS C 9990 <br />
                                                    (EMC test for vehicles, and internal combustion engines)
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Certification</th>
                                                <td className="imgArea">
                                                    <div>
                                                        <img src="/assets/images/table_certification.png" alt="Certification" />
                                                    </div>
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th>Language Supported</th>
                                                <td>
                                                    English, Korean, Japanese, Russian, Polish, Vietnamese, German, Spanish, French, Hebrew, Thai, Chinese(Mandarin), Malay, Arabic, Turkish
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <!-- Specifications Fin --> */}

                            {tabIndex === 5 ? <ManualBox/> : ''}
                            {/* <!-- Manuals & Firmware Fin --> */}

                            <div className={`usersReview ${tabIndex === 6 ? 'active' : ''}`}>
                                <div>
                                    <ul>
                                        <li>
                                            <img src="/assets/images/amazon_logo.png" alt="amazon logo" />
                                        </li>
                                        <li>
                                            <div>
                                                <div>
                                                    <span>Saul & Vivian Batres</span>
                                                    <ul className="starBox">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <p>
                                                        It take great high quality videos, the only thing you have to consider this is hard wire but
                                                        you can purchase a seperate unit to connect to a DC outlet.
                                                    </p>
                                                    <p>It came with a memory card and set up was easy as well.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <div>
                                                    <span>Brandon Lanham</span>
                                                    <ul className="starBox">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <p>
                                                        Awesome dash cam. Works amazing and has high quality display and detail. Loop records
                                                        the entire time you're driving. Highly recommend.
                                                    </p>
                                                    <p>One person found this helpful.</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    {/* <!-- amazon Fin --> */}

                                    <ul>
                                        <li>
                                            <img src="/assets/images/blackBoxMyCar_logo.png" alt="blackBoxMyCar logo" />
                                        </li>
                                        <li>
                                            <div>
                                                <div>
                                                    <span>Gus</span>
                                                    <ul className="starBox">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <p>
                                                        I’ve had a couple of dash cams in the past. Some were very difficult to navigate. With
                                                        numerous buttons and a small unit viewing window they became complicated for this ‘ol
                                                        man to use. This time I wanted a simple and easy dash cam to operate.
                                                    </p>
                                                    <p>
                                                        The FineVu GX1000 meets that criteria. It’s fairly new on the market. But found a few
                                                        YouTube videos that walked you through the set up in car, how to use and review files.
                                                        The simplicity of setup and operation are genius. It tucks up nicely (w/o any ugly wires)
                                                        to the windshield. Plus, with the thin cables marks hardwiring a breeze.
                                                    </p>
                                                    <p>
                                                        The video and audio quality are fantastic. Whether you’re watching on your phone or a
                                                        32 inch monitor! There are many different configurations you can use capture video.
                                                        Many options for notifications and parking mods. As I said, I’m a simple man and have
                                                        only a notice when I start my car starts up. I’ve deselected most of the dash cams
                                                        features. Why? My new 2022 vehicle already has all the safety nannies I need.
                                                    </p>
                                                    <p>
                                                        Lastly, I like to review the videos after taking a leisurely trip through the back country
                                                        roads.
                                                    </p>
                                                    <p>
                                                        I highly recommend this unit if you’re looking for a well built, easy to setup and use dash
                                                        cam.
                                                    </p>
                                                    <p>GWB</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <div>
                                                    <span>Frank T.</span>
                                                    <ul className="starBox">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <p>
                                                        So far so good. I hardwired the dashcam to my truck. I also like the feature where I can
                                                        manually shut the camera off with a slide switch. This prevents the vehicles battery from
                                                        being drained.
                                                    </p>
                                                    <p>
                                                        Michael at BlackboxMyCar (Canada) has been extremely helpful with answering any
                                                        questions. Great customer support👏
                                                    </p>
                                                    <p>I've purchased from this company in the past and will continue to do so.</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    {/* <!-- blackBoxMyCar Fin --> */}

                                    <ul>
                                        <li>
                                            <img src="/assets/images/unavi_logo.png" alt="unavi logo" />
                                        </li>
                                        <li>
                                            <div>
                                                <div>
                                                    <span>Derrick Thomas</span>
                                                    <ul className="starBox">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <p>
                                                        One of the easiest dash cameras to down load and view videos from. The videos can be
                                                        Wi-Fi directly to the phone and sent via email or text. Crystal clear video and audio. Very
                                                        impressed
                                                    </p>
                                                    <p>User picture</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <div>
                                                    <span>Ali A.</span>
                                                    <ul className="starBox">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <p>
                                                        Most dash cams I’ve seen require it to be hooked up to the cars battery which makes
                                                        you tuck wires everywhere. This one plugs in and uses power straight from the rear view
                                                        mirror, not having to tuck or hide any wires.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
