import React from "react";
export interface Props {setData:Function,name:string,value:string,placeholder:string, title : string}
export const SingleInputBox:React.FC<Props> = ({setData, name, value, placeholder,title}) => {
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setData((prev:any)=>({...prev, [name] : value}))
    } 
    return(
        <>
        <tr>
            <th>{title}</th>
            <td>
                <input type="text" name={name} id="" value={value} placeholder={placeholder} onChange={handleChange}/>
            </td>
        </tr>
        </>
    )
}