import React, { useState } from "react";
import Header_Moblie from "./mo";
import Header_Pc from "./pc";

const Header = () => {
    
    return(
        <>
        <header className="black">
            <Header_Pc/>
            <Header_Moblie/>
        </header>
       
        </>
    )
}

export default Header;