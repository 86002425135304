import React from "react";
import { useAppSelector } from "../../../store/storeHooks";
import { useLocation, useNavigate } from "react-router-dom";

export const PrivacyPolicy = () => {
    return(
        <>
        <div className="contentBox">
            <h2>Privacy Policy</h2>
            <div>
                <span>Fine Digital Inc. (“the Company”)</span>
                <p>
                    This Policy (the "Policy") explains the way of treatment of the information which is provided or collected in the web sites on which this Policy is <br />
                    posted. In addition the Policy also explains the information which is provided or collected in the course of using the applications of the Company <br />
                    which exist in the websites or platforms of other company.
                </p>
                <p>
                    The Company is the controller of the information provided or collected in the websites on which this Policy is posted and in the course of using <br />
                    the applications of the Company which exist in the websites or platforms of other company.
                </p>
                <p>
                    Through this Policy, the Company regards personal information of the users as important and inform them of the purpose and method of <br />
                    Company's using the personal information provided by the users and the measures taken by the Company for protection of those personal <br />
                    information.
                </p>
                <p>
                    This Policy will be effective on the 30th day of November 2023 and, in case of modification thereof, the Company will make public notice of it <br />
                    through posting it on the bulletin board of Company's website (or individual notice through sending mails, fax or e-mails).
                </p>
            </div>
            <ol>
                <li>
                    <div className="titleBox">
                        <p>
                            <span>1</span>
                            Information to be collected and method of collection
                        </p>
                    </div>
                    <div className="txtWrap">
                        <div className="txtBox">
                            <p>
                                <strong>(1) Personal information items to be collected</strong>
                                Personal information items to be collected by the Company are as follows:
                            </p>
                            <div>
                                <dl>
                                    <dt className="circle">Information provided by the users</dt>
                                    <dd>The Company may collect the information directly provided by the users.</dd>
                                </dl>
                                <div className="tableWrap">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Title of service</th>
                                                <th>Items to be collected</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Internet membership service</th>
                                                <td>
                                                    <p className="borderCircle">
                                                        Name, email address, ID, telephone number, address, national information, encoded
                                                        identification information (CI), identification information of overlapped membership (DI)
                                                    </p>
                                                    <p className="borderCircle">
                                                        For minors, information of legal representatives (name, birth date, CI and DI of legal 
                                                        representatives)
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Online payment service</th>
                                                <td>
                                                    <p className="borderCircle">Name, address, telephone number, and email address</p>
                                                    <p className="borderCircle">Payment information including account number and card number</p>
                                                    <p className="borderCircle">Delivery information including delivery address, name and contact information of recipient</p>
                                                    <p className="borderCircle">Information of bid, purchase and sales</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Social network service</th>
                                                <td>
                                                    <p className="borderCircle">
                                                        Name, email address, ID, telephone number, address, national information, address list <br />
                                                        (acquaintance)
                                                    </p>
                                                    <p className="borderCircle">Information of place of taking pictures and date of creation of files</p>
                                                    <p className="borderCircle">
                                                        Information of service use of members such as the type of contents watched or used by 
                                                        members, the persons interacting or sharing contents with members, frequencies and 
                                                        period of activities of members
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div>
                                <dl>
                                    <dt className="circle">Information collected while the users use services</dt>
                                    <dd>
                                        Besides of information directly provided by the users, the Company may collect information in the course that the users use the service
                                        provided by the Company.
                                    </dd>
                                </dl>
                                <div className="tableWrap">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Lists</th>
                                                <th>Items to be collected</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Equipment information</th>
                                                <td>
                                                    <p className="borderCircle">
                                                        Equipment identifier, operation system, hardware version, equipment set-up, type and 
                                                        set-up of browser, use information of website or application and telephone number
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Online payment service</th>
                                                <td>
                                                    <p className="borderCircle">
                                                        IP address, log data, use time, search word input by users, internet protocol address, 
                                                        cookie and web beacon
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Location information</th>
                                                <td>
                                                    <p className="borderCircle">
                                                        Information of device location including specific geographical location detected through
                                                        GPS , Bluetooth or Wifi (limited to the region permissible under the laws)
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Other information</th>
                                                <td>
                                                    <p className="borderCircle">Preference, advertisement environment, visited pages regarding service use of users</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="txtBox">
                            <p>
                                <strong>(2) Method of collection</strong>
                                The Company collects the information of users in a way of the followings:
                            </p>
                            <div>
                                <p className="circle">webpage, written form, fax, telephone calling, e-mailing, tools for collection of created information</p>
                                <p className="circle">provided by partner companies</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="titleBox">
                        <p>
                            <span>2</span>
                            Use of collected information
                        </p>
                    </div>
                    <div className="txtWrap">
                        <div className="txtBox">
                            <p>The Company uses the collected information of users for the following purposes:</p>
                            <ul>
                                <li className="circle">Member management and identification</li>
                                <li className="circle">To detect and deter unauthorized or fraudulent use of or abuse of the Service</li>
                                <li className="circle">Performance of contract, service fee payment and service fee settlement regarding provision of services demanded by the users</li>
                                <li className="circle">Improvement of existing services and development of new services</li>
                                <li className="circle">Making notice of function of company sites or applications or matters on policy change</li>
                                <li className="circle">To help you connect with other users you already know and, with your permission, allow other users to connect with you</li>
                                <li className="circle">To make statistics on member’s service usage, to provide services and place advertisements based on statistical characteristics</li>
                                <li className="circle">To provide information on promotional events as well as opportunity to participate</li>
                                <li className="circle">To comply with applicable laws or legal obligation</li>
                                <li className="circle">Use of information with prior consent of the users (for example, utilization of marketing advertisement)</li>
                            </ul>
                            <p>
                                The Company agrees that it will obtain a consent from the users, if the Company desires to use the information other than those
                                expressly stated in this Policy.
                            </p>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div className="titleBox">
                            <p>
                                <span>3</span>
                                Disclosure of collected information
                            </p>
                        </div>
                        <div className="txtWrap">
                            <div className="txtBox">
                                <p>Except for the following cases, the Company will not disclose personal information with a 3rd party:</p>
                                <dl>
                                    <dt className="circle">when the Company disclosing the information with its affiliates, partners and service providers;</dt>
                                    <dd className="hyphen">
                                        When the Company's affiliates, partners and service providers carry out services such as bill payment, execution of orders, products delivery and 
                                        dispute resolution (including disputes on payment and delivery) for and on behalf of the Company
                                    </dd>
                                </dl>
                                <dl>
                                    <dt className="circle">when the users consent to disclose in advance;</dt>
                                    <dd className="hyphen">
                                        when the user selects to be provided by the information of products and services of certain companies by sharing his or her personal information 
                                        with those companies
                                    </dd>
                                    <dd className="hyphen">
                                        when the user selects to allow his or her personal information to be shared with the sites or platform of other companies such as social networking
                                        sites
                                    </dd>
                                    <dd className="hyphen">other cases where the user gives prior consent for sharing his or her personal information</dd>
                                </dl>
                                <dl>
                                    <dt className="circle">when disclosure is required by the laws;</dt>
                                    <dd className="hyphen">if required to be disclosed by the laws and regulations; or</dd>
                                    <dd className="hyphen">
                                        if required to be disclosed by the investigative agencies for detecting crimes in accordance with the procedure and method as prescribed in the 
                                        laws and regulations
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div className="titleBox">
                            <p>
                                <span>4</span>
                                Cookies, Beacons and Similar Technologies
                            </p>
                        </div>
                        <div className="txtWrap">
                            <div className="txtBox">
                                <p>
                                    The Company may collect collective and impersonal information through 'cookies' or 'web beacons'.
                                    Cookies are very small text files to be sent to the browser of the users by the server used for operation of the websites of the 
                                    Company and will be stored in hard-disks of the users' computer.
                                </p>
                                <p>
                                    Web beacon is a small quantity of code which exists on the websites and e-mails. By using web beacons, we may know whether an user
                                    has interacted with certain webs or the contents of email.
                                </p>
                                <p>
                                    These functions are used for evaluating, improving services and setting-up users' experiences so that much improved services can be 
                                    provided by the Company to the users
                                </p>
                            </div>
                            <div className="txtBox">
                                <p>The items of cookies to be collected by the Company and the purpose of such collection are as follows:</p>
                                <div className="tableWrap">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Reasons for using cookies and additional information</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>strictly necessary cookies</th>
                                                <td>
                                                    <p>
                                                        This cookie is a kind of indispensible cookie for the users to use the functions of website of
                                                        the Company. Unless the users allow this cookie, the services such as shopping cart or 
                                                        electronic bill payment cannot be provided. This cookie does not collect any information 
                                                        which may be used for marketing or memorizing the sites visited by the users
                                                        <span>(Examples of necessary cookies)</span>
                                                    </p>
                                                    <p className="borderCircle">
                                                        Memorize the information entered in an order form while searching other pages during 
                                                        web browser session
                                                    </p>
                                                    <p className="borderCircle">For the page of products and check-out, memorize ordered services</p>
                                                    <p className="borderCircle">Check whether login is made on website</p>
                                                    <p className="borderCircle">
                                                        Check whether the users are connected with correct services of the website of the 
                                                        Company while the Company changes the way of operating its website
                                                    </p>
                                                    <p className="borderCircle">Connect the users with certain application or server of the services</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>performance cookies</th>
                                                <td>
                                                    <p>
                                                        This cookie collects information how the users use the website of the Company such as the 
                                                        information of the pages which are visited by the users most. This data helps the Company 
                                                        to optimize its website so that the users can search that website more comfortably. This 
                                                        cookie does not collect any information who are the users. Any and all the information 
                                                        collected by this cookie will be processed collectively and the anonymity will be guaranteed.
                                                        <span>(Examples of performance cookies)</span>
                                                    </p>
                                                    <p className="borderCircle">Web analysis: provide statistical data on the ways of using website</p>
                                                    <p className="borderCircle">Advertisement response fee: check the effect of advertisement of the Company</p>
                                                    <p className="borderCircle">
                                                        Tracing affiliated companies; one of visitors of the Company provides anonymously 
                                                        feedback to the affiliated companies
                                                    </p>
                                                    <p className="borderCircle">
                                                        Management of error: measure an error which may occur so as to give a help for 
                                                        improving website
                                                    </p>
                                                    <p className="borderCircle">Design testing: test other design of the website of Company</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>functionality cookies</th>
                                                <td>
                                                    <p>
                                                        This cookie is used for memorizing the set-ups so that the Company provides services and 
                                                        improves visit of users. Any information collected by this cookie do not identify the users 
                                                        individually.
                                                        <span>(Examples of functionality cookies)</span>
                                                    </p>
                                                    <p className="borderCircle">Memorize set-ups applied such as layout, text size, basic set-up and colors</p>
                                                    <p className="borderCircle">Memorize when the customer respond to a survey conducted by the Company</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>targeting cookies or advertising cookies</th>
                                                <td>
                                                    <p>
                                                        This cookie is connected with the services provided by a 3rd party such as the buttons of 
                                                        'good' and 'share'. The 3rd party provides these services by recognizing that the users visits 
                                                        the website of the Company.
                                                        <span>(Examples of targeting cookies or advertising cookies)</span>
                                                    </p>
                                                    <p className="borderCircle">
                                                        carry out PR to the users as targets in other websites by connecting through social 
                                                        networks and these networks use the information of users' visit
                                                    </p>
                                                    <p className="borderCircle">
                                                        provide the information of users' visit to ad agencies so that they can suggest an ad which 
                                                        may attract the interest of the users
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>
                                    The users have an option for cookie installation. So, they may either allow all cookies by setting option in web browser, make each cookie 
                                    checked whenever it is saved, or refuses all cookies to be saved: Provided that, if the user rejects the installation of cookies, it may be 
                                    difficult for that user to use the parts of services provided by the Company.
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div className="titleBox">
                            <p>
                                <span>5</span>
                                User’s right
                            </p>
                        </div>
                        <div className="txtWrap">
                            <div className="txtBox">
                                <p>
                                    The users or their legal representatives, as main agents of the information, may exercise the following rights regarding the collection, use 
                                    and sharing of personal information by the Company:
                                </p>
                                <ul>
                                    <li className="circle">exercise right to access to personal information;</li>
                                    <li className="circle">make corrections or deletion;</li>
                                    <li className="circle">make temporary suspension of treatment of personal information; or</li>
                                    <li className="circle">request the withdrawal of their consent provided before</li>
                                </ul>
                                <p>
                                    If, in order to exercise the above rights, you, as an user, use the menu of 'amendment of member information of webpage or contact the 
                                    Company by sending a document or e-mails, or using telephone to the company(or person in charge of management of personal 
                                    information or a deputy), the Company will take measures without delay: Provided that the Company may reject
                                </p>
                                <p>the request of you only to the extent that there exists either proper cause as prescribed in the laws or equivalent cause.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div className="titleBox">
                            <p>
                                <span>6</span>
                                Security
                            </p>
                        </div>
                        <div className="txtWrap">
                            <div className="txtBox">
                                <p>
                                    The Company regards the security of personal information of uses as very important. The company constructs the following security 
                                    measures to protect the users' personal information from any unauthorized access, release, use or modification
                                </p>
                                <dl>
                                    <dt className="circle">Encryption of personal information</dt>
                                    <dd className="hyphen">Transmit users' personal information by using encrypted communication zone</dd>
                                    <dd className="hyphen">Store important information such as passwords after encrypting it</dd>
                                </dl>
                                <dl>
                                    <dt className="circle">Countermeasures against hacking</dt>
                                    <dd className="hyphen">
                                        Install a system in the zone the external access to which is controlled so as to prevent leakage or damage of users' personal information by hacking 
                                        or computer virus
                                    </dd>
                                </dl>
                                <p className="circle">Establish and execute internal management plan</p>
                                <p className="circle">Install and operate access control system</p>
                                <p className="circle">Take measures to prevent forging or alteration of access record</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div className="titleBox">
                            <p>
                                <span>7</span>
                                Protection of personal information of children
                            </p>
                        </div>
                        <div className="txtWrap">
                            <div className="txtBox">
                                <p>
                                    In principle, the Company does not collect any information from the children under 13 or equivalent minimum age as prescribed in the 
                                    laws in relevant jurisdiction. The website, products and services of the Company are the ones to be provided to ordinary people, in 
                                    principle. The website or application of the Company has function to do age limit so that children cannot use it and the Company does 
                                    not intentionally collect any personal information from children through that function.
                                </p>
                                <p>
                                    (Additional procedure for collecting personal information from children) However, if the Company collects any personal information from 
                                    children under 13 or equivalent minimum age as prescribed in the laws in relevant jurisdiction for the services for unavoidable reason, the 
                                    Company will go through the additional procedure of the followings for protecting that personal information of children:• verify, to the 
                                    extent that efforts are reasonably made, whether they are children of the age at which consent from their guardian is required and the 
                                    consenting person is an authorized one.
                                </p>
                                <ul>
                                    <li className="circle">
                                        obtain consent from the parents or guardian of children so as to collect personal information of children or directly send the 
                                        information of products and services of the Company
                                    </li>
                                    <li className="circle">
                                        give the parents or guardian of children a notice of Company's policy of privacy protection for children including the items, purpose 
                                        and sharing of personal information collected
                                    </li>
                                    <li className="circle">
                                        grant to legal representatives of children a right to access to personal information of that children/correction or deletion of 
                                        personal information/temporary suspension of treatment of personal information/ and request for withdrawal of their consent 
                                        provided before
                                    </li>
                                    <li className="circle">limit the amount of personal information exceeding those necessary for participation in online activities</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div className="titleBox">
                            <p>
                                <span>8</span>
                                Modification of Privacy Protection Policy
                            </p>
                        </div>
                        <div className="txtWrap">
                            <div className="txtBox">
                                <p>
                                    The Company has the right to amend or modify this Policy from time to time and, in such case, the Company will make a public notice of 
                                    it through bulletin board of its website (or through individual notice such as written document, fax or e-mail) and obtain consent from the 
                                    users if required by relevant laws.
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div>
                        <div className="titleBox">
                            <p>
                                <span>9</span>
                                Contact information of Company
                            </p>
                        </div>
                        <div className="txtWrap">
                            <div className="txtBox">
                                <p>
                                    Please use one of the following methods to contact the Company should you have any queries in respect to this policy or wish to update 
                                    your information:
                                </p>
                                <ul>
                                    <li className="circle">
                                        Company name :
                                        <span>Fine Digital Inc.</span>
                                    </li>
                                    <li className="circle">
                                        Address :
                                        <span>
                                            7th Floor, Fine Venture Bldg., 41, Seongnam-daero 925 beon-gil,  Bundang-gu, Seongnam-si, Gyeonggi-do, 13496, Rep. of 
                                            KOREA
                                        </span>
                                    </li>
                                    <li className="circle">
                                        Tel :
                                        <span>USA +1 646 895 6451 / CANADA +1 437 887 2499 / KOREA +82 31 788 8800</span>
                                    </li>
                                    <li className="circle">
                                        E-mail :
                                        <span>support@finedigital.com</span>
                                    </li>
                                </ul>
                                <p>
                                    The Company designates the following Data Protection Officer (DPO) in order to protect personal information of customers and deal with 
                                    complaints from customers.
                                </p>
                                <ul>
                                    <li className="circle">
                                        DPO of the Company Adress :
                                        <span>
                                            7th Floor, Fine Venture Bldg., 41, Seongnam-daero 925 beon-gil,  Bundang-gu, Seongnam-si, 
                                            Gyeonggi-do, 13496, Rep. of KOREA
                                        </span>
                                    </li>
                                    <li className="circle">
                                        Tel :
                                        <span>+82 31 788 8800</span>
                                    </li>
                                    <li className="circle">
                                        E-mail :
                                        <span>support@finedigital.com</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
            <span>The latest update date: November 23, 2023.</span>
        </div>
        </>
    )
}