import React from "react";

export const FineVu = () => {

    return(
        <>
        <div className="contentBox">
            <div className="banner_slider">
                <div>
                    <img src="/assets/images/fineVu_banner1.png" alt="fineVu"/>
                </div>
                {/* <!-- <div>
                    <img src="/assets/images/fineVu_banner2.png" alt="fineVu">
                </div> --> */}
            </div>
            
            <div className="flexBox">
                <div className="arrowBox">
                    <div>
                        {/* <button>←</button>
                        <button>→</button> */}
                    </div>
                </div>
                <div className="rightBox">
                    <div>
                        <p>
                            <strong>"FINEDIGITAL Inc. provides the finest products and solutions in the automotive IT area.</strong>
                        </p>
                        <p>
                            As a leading provider of innovative car electronics systems since 1992, Finedigital has gained a strong foothold in Korea.
                            Developing automotive devices such as dashboard cameras and GPS navigation, Finedigital's trademark - FineVu has also
                            attained significant recognition in the industry. Finedigital is committed to improving customer's satisfaction by bringing market-proven high performance and quality through enhancing innovations.
                        </p>
                        <p>
                            Today, we move forward to go beyond Korea in providing our products and services, and become a global expert in
                            automotive electronics industry.
                        </p>
                    </div>
                    {/* <h2>
                        <span>Distinction from</span>
                        competitors
                    </h2>

                    <div className="listWrap active_1">
                        <div>
                            <ol>
                                <li className="list_1">
                                    <span>Market Share</span>
                                    <div>
                                        <span>GPS Company</span>
                                        <p>(Dashcam/Navigation)</p>
                                    </div>
                                </li>
                                <li className="list_2">
                                    <span>HIT Brand</span>
                                    <div>
                                        <span>BRAND</span><span>HIT</span>
                                        <p>2009-2019 in DANAWA</p>
                                    </div>
                                </li>
                                <li className="list_3">
                                    <span>Defect Rate</span>
                                    <div>
                                        <span>Under</span><span> 0.2%</span>
                                        <p>Defect Rate Below 0.2%</p>
                                    </div>
                                </li>
                                <li className="list_4">
                                    <span>Anniversary of Foundation</span>
                                    <div>
                                        <span>31th</span>
                                        <p>Anniversary</p>
                                    </div>
                                </li>
                                <li className="list_5">
                                    <span>Authorized Retail Stores</span>
                                    <div>
                                        <span>Dashcam/Navigation</span>
                                        <p>in Korea</p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        <div>
                            <ol>
                                <li className="list_1">
                                    <span>Market Share</span>
                                </li>
                                <li className="list_2">
                                    <span>HIT Brand</span>
                                </li>
                                <li className="list_3">
                                    <span>Defect Rate</span>
                                </li>
                                <li className="list_4">
                                    <span>
                                        Anniversary of<br/>
                                        Foundation
                                    </span>
                                </li>
                                <li className="list_5">
                                    <span>
                                        Authorized<br/>
                                        Retail Stores
                                    </span>
                                </li>
                            </ol>
                        </div>
                    </div> */}
                </div>
            </div>
            

        </div>
        </>
    )
}