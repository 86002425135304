import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Test = () => {
    const setting = {
        dots : true,
        arrows : false,
        infinte : false,
        speed : 500,
        slidesToScroll: 3,
        slidesToShow: 3,
        vertical: false,
        centerMode : true,
        appendDots : (dots:any) => (
            <ul>{dots}</ul>
        ),
        customPagin : (i : any) => (
            <button>Click</button>
        )
        // centerPadding: '10px',
        // autoplay: true,
        // autoplaySpeed: 3000,
        // centerMode: true,
    }

    return (
        <>
        <Slider {...setting}>
            <div>1</div>
            <div>2</div>
            <div>3</div>
            <div>4</div>
            <div>5</div>
            <div>6</div>
            <div>7</div>
        </Slider>
        </>
    );
};
