import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../../../util/cookie/cookies";
import { useAppSelector } from "../../../../store/storeHooks";

const Footer = () => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const navigate = useNavigate()
    const menuInfo : any = useAppSelector(state=>state.menuInfo.menuInfo)
    const handleTransPage = (page:string) => {
        if(location.pathname !== page){navigate(page)}
    }
    return(
        <>
        <footer>
            <div>
                <div>
                    <div className="logoBox">
                        <span onClick={()=>navigate('/')}></span>
                    </div>
                    <div className="listBox">
                        <div>
                            <span className="orange" onClick={()=>navigate('/products?ch=&rs=&ft=')}>Products</span>
                            <ul>
                                <li>
                                    <span className="disabled">By Channel</span>
                                    <ul>
                                        {menuInfo?.channel?.map((channel : any, index : number) => (
                                            <li key={index} onClick={()=>navigate(`/products?ch=${channel?.menuId}&rs=&ft=`)}>
                                                {channel?.menuName}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <span className="disabled">By Resolution</span>
                                    <ul>
                                        {menuInfo?.resolution?.map((resolution : any, index : number) => (
                                            <li key={index} onClick={()=>navigate(`/products?ch=&rs=${resolution?.menuId}&ft=`)}>
                                                {resolution?.menuName}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li>
                                    <span className="disabled">By Features</span>
                                    <ul>
                                        {menuInfo?.features?.map((features : any, index : number) => (
                                            <li key={index} onClick={()=>navigate(`/products?ch=&rs=&ft=${features?.menuId}`)}>
                                                {features?.menuName}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="white">Accessories</li>
                                <li className="white" onClick={()=>handleTransPage('/productComparison')}>Product Comparision</li>
                                <li className="orange" onClick={()=>handleTransPage('/blog')}>Blog</li>
                                <li className="orange" onClick={()=>navigate('/location')}>Where to Buy</li>
                                <li className="orange" onClick={()=>handleTransPage('/fineVu')}>FineVu</li>
                            </ul>
                        </div>
                        <div>
                            <span className="orange">Support</span>
                            <ul>
                                <li>
                                    <span className="disabled">Download</span>
                                    <ul>
                                        <li onClick={()=>handleTransPage('/support/download/dashcam')}>FineVu Dashcam</li>
                                        <li onClick={()=>handleTransPage('/support/download/wifiapp')}>FineVu Wi-Fi APP</li>
                                        <li onClick={()=>handleTransPage('/support/download/pcplayer')}>FineVu PC Player</li>
                                    </ul>
                                </li>
                                <li>
                                    <span className="disabled">Warranty Services</span>
                                    <ul>
                                        <li onClick={()=>handleTransPage('/support/warrentyService/prRegistration')}>Product Registration</li>
                                        <li onClick={()=>handleTransPage('/support/warrentyService/policy')}>Warranty Policy</li>
                                    </ul>
                                </li>
                                <li className="white" onClick={()=>handleTransPage('/support/warrentyService/contact')}>Contact</li>
                                <li className="white" onClick={()=>handleTransPage('/sitemap')}>Sitemap</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- 진한 검정색 박스 Fin --> */}
            <div>
                <div>
                    <div>
                        <p>
                            <span>ADD</span>
                            7th Floor, Fine Venture Bldg., 41, Seongnam-daero 925 beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do, 13496 Rep. of KOREA 
                        </p>
                        <div>
                            <p>
                                <span>TEL</span>
                                (USA Toll Free) +1 646 895 6154 / (Canada Toll Free) +1 437 887 2499 / (Korea Only) +82 31 788 8800
                            </p>
                            <p>
                                <span>FAX</span>
                                +82-31-788-8760
                            </p>
                            <p>
                                <span>E-MAIL</span>
                                support@finedigital.com
                            </p>
                        </div>
                    </div>

                    <div>
                        <div>
                            <p>Copyright ⓒ 2023 FINEDIGITAL Inc., ALL RIGHTS RESERVED.</p>
                            <button onClick={()=>navigate(`/privacy-policy`)}>Privacy Policy</button>
                        </div>
                        <ul>
                            <li className="facebook"><a href="https://www.facebook.com/FineVuDASHCAM" target="_blank"></a></li>
                            {/* <li className="twitter_x"></li> */}
                            <li className="youtube"><a href="https://www.youtube.com/channel/UCcjdOYjx1kDfcYbgVz9JuCQ" target="_blank"></a></li>
                            <li className="instagram"><a href="https://www.instagram.com/finevu_global" target="_blank"></a></li>
                            <li className="linkedin"><a href="https://www.linkedin.com/company/finevu/about/" target="_blank"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- 연한 검정색 박스 Fin --> */}
        </footer>
        </>
    )
}
export default Footer;