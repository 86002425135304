import React, { useEffect, useState } from "react";
import { useSetMainSpecSubMutation, useUpdMainSpecSubMutation } from "../../../../store/admin/adminProductSpecApiSlice";
export interface Props {subSpecId : string, subSpecName : string, setPageInfo : Function, getList : Function}
export const RegistSpecOption:React.FC<Props> = ({subSpecId, subSpecName, setPageInfo, getList}) => {
    const [setMainSpecSubApi] = useSetMainSpecSubMutation()
    const [updMainSpecSubApi] = useUpdMainSpecSubMutation()
    const [subSpecNameText, setSubSpecNameText] = useState<string>(subSpecName)
    const handleChange = (e:any) => {setSubSpecNameText(e.target.value)}
    const handleSave = async() => {
        if(subSpecNameText === ''){alert('사양 옵션을 입력해주세요.'); return;}
        const formData : any = new FormData()
        if(subSpecId!==''){formData.append('specSubIdx', subSpecId)}
        formData.append('specSubName', subSpecNameText)
        if(subSpecId === ''){
            const result : any = await setMainSpecSubApi(formData)
            if(result.data.result === true){alert('등록이 완료되었습니다.');getList();setPageInfo((prev:any)=>({...prev, isActive : false}))}
        }else{
            const result : any = await updMainSpecSubApi(formData)
            if(result.data.result === true){alert("수정이 완료되었습니다.");getList();setPageInfo((prev:any)=>({...prev, isActive : false, subSpecId : '', subSpecName : ''}))}
        }
    }
    return(
        <>
        <div className="registration_popUp popUp_active active">
            <button className="closeBtn" onClick={()=>setPageInfo((prev:any)=>({...prev, isActive:false, optionId : ''}))}>
                <i className="fa-light fa-xmark"></i>
            </button>

            <div className="titleBox">
                <span>Specification</span>
                <span>Specification Option</span>
            </div>
            <div className="userInfoBox">
                <ul>
                    <li>
                        <span>Spec Option</span>
                        <textarea name="" id="" value={subSpecNameText} onChange={handleChange} placeholder="사양 입력란"></textarea>
                    </li>
                </ul>
            </div>

            <div className="btnBox">
                <button onClick={()=>setPageInfo((prev:any)=>({...prev, isActive:false, optionId : ''}))}>취소</button>
                <button onClick={handleSave}>등록</button>
            </div>
        </div>
        </>
    )
}