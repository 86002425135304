import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://en.finevu.com/api/controller/admin/manager";

export const adminStratorsApiSlice = createApi({
  reducerPath: "adminStratorsApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 관리자 로그인
    getManagerLogin : builder.mutation({
      query : (body : any) => {
        return{
          url : `/getManagerLogin.php`, method : 'post', body
        }
      }
    }),
    // 관리자 등록
    setManager : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setManagers.php', method : 'post', body
        }
      }
    }),
    // 관리자 리스트
    getManagerList : builder.mutation({
      query : (param : {page:any,size:number,sortOrder:string,sortColumn:string,keyword:string}) => {
        return{
          url : `/getManagerLists.php?page=${param.page}&size=${param.size}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}&keyword=${param.keyword}`
        }
      }
    }),
    // 관리자 상세보기
    getManagerDetails : builder.mutation({
      query : (param : {id:string})=>{
        return{
          url : `/getManagerDetails.php?id=${param.id}`
        }
      }
    }),
    // 관리자 수정
    uptManagers : builder.mutation({
      query : (body : any) => {
        return{
          url : `/uptManagers.php`, method : 'post', body
        }
      }
    }),
    // 관리자 정보 조회
    getAdminInfo : builder.mutation({
      query : (param : {id : any}) => {
        return{
          url : `/getAdminInfo.php?id=${param.id}`
        }
      }
    }),
  }),
});

export const {
  useGetManagerLoginMutation, useSetManagerMutation, useGetManagerListMutation, useGetManagerDetailsMutation, useUptManagersMutation,
  useGetAdminInfoMutation
} = adminStratorsApiSlice;