import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FileTypeAndLangBox } from "../../../../components/admin/download/fileTypeAndLangBox";
import { VerisonList } from "../../../../components/admin/download/versionList";
import { VersionRegistItem } from "../../../../components/admin/download/versionRegistItem";
import { useGetProductDocDetailMutation } from "../../../../store/admin/adminProductApiSlice";

export const RegistDownLoad = () => {
    const {productId} = useParams()
    const navigate = useNavigate()
    const [downloadDoc, setDownloadDoc] = useState<any>({isActive : false, fileType : 'F', fileLanguage : 0, fileLangName : ''});
    const [data, setData] = useState<any>([])
    const [getProductDocDetailApi] = useGetProductDocDetailMutation()
    const getDetail = async() => {
        const result : any = await getProductDocDetailApi({idx: productId})
        if(result.data.Result === 'true'&&result.data.List){
            const findFileTypeList = result.data.List.filter((item : any) => item.docType === downloadDoc?.fileType)
            if(findFileTypeList){setData(findFileTypeList)}
        }
    }
    const handleClose = () => {setDownloadDoc((prev: any) => ({...prev, isActive : false}))}
    useEffect(()=>{if(productId){getDetail()}},[downloadDoc.fileType])
    return(
        <>
        <div className="contentBox upload addDownloadFile">
            <h3>Download</h3>
            <div className="flexWrap">
                <div className="flexBox">
                    <h4>
                        Add Download file
                    </h4>
                </div>
                <div>
                    <button className="blackBtn" onClick={()=>navigate(-1)}>목록으로</button>
                </div>
            </div>
            
            <FileTypeAndLangBox 
                fileType={downloadDoc?.fileType}
                fileLang={downloadDoc?.fileLanguage}
                fileLangName={downloadDoc?.fileLangName}
                setType={setDownloadDoc}
            />

            <ul className="language_resultBox">
                <VerisonList 
                    data={data}
                    getDetail={getDetail}
                />
                {downloadDoc?.isActive && 
                <VersionRegistItem
                    docInfo={downloadDoc}
                    onClose={handleClose}
                    getDetail={getDetail}
                />}

            </ul>

            <div className="btnBox">
                <button onClick={()=>navigate(-1)}>목록으로</button>
            </div>
        </div>
        </>
    )
}