import React, { useEffect, useState } from "react";
import Select from 'react-select';

export const Test2 = () => {
    const [data, setData] = useState<any>([])
    useEffect(()=>{
        const newData = locationData.map((item) => `${item.idx},${item.location},0`).join(';');
        setData(newData)
    }, [])
    return(
        <>
        {locationData?.map((list:any) => {
            return(
                <>
                <div>{list?.title}</div>
                <div>
                    <Select
                        // value={countryList?.find((x:any) => x.value === value)}
                        options={list?.sub}
                        className="selectBox1"
                        // onChange={handleChange}
                        isSearchable={true}
                        placeholder={'Please Choose a country'}
                    />
                </div>
                </>
            )
        })}
        </>
    )
}

export const locationData = [
    {idx: 3, title : 'Incheon', location : 0, sub : [{value: 2, label : 'Namdongu'}, {value: 3, label : 'Yunsu'}]},
    {idx: 6, title : 'GyunSang', location : 2, sub : [{value: 4, label : 'busan'}, {value: 1, label : 'Jinju'}]},
    {idx: 5, title : 'JunRa', location : 1, sub : [{value: 6, label : 'Junju'}, {value: 5, label : 'Gwangju'}]},
]