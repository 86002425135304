import React from "react";
import { useNavigate } from "react-router-dom";

export const Section_1 = () => {
    const navigate = useNavigate()
    return(
        <>
        <section className="mainSection_1">
            
            <div className="imgBox">
                <img className="pc" src="/assets/images/GX35-CLOUD_PC_1080_1920.jpg" alt="mainBG" onClick={()=>navigate('/products/38?product=FineVu-GX35-CLOUD-(New)')}/>
                <img className="mo" src="/assets/images/GX35-CLOUD_M_1080_1920.jpg" alt="mainBG" onClick={()=>navigate('/products/38?product=FineVu-GX35-CLOUD-(New)')}/>
            </div>

        </section>
        </>
    )
}