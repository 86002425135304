import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetDocLangMutation, useGetProductDocMutation } from "../../../../../store/user/userProductApiSlice";

export const DownloadDashCamView = () => {
    const location = useLocation()
    const {id} = useParams();
    const navigate = useNavigate()
    const [getDocLangList] = useGetDocLangMutation()
    const [getProductDoc] = useGetProductDocMutation()
    const [state, setState] = useState<any>('')
    const [langList, setLang] = useState<any>([])
    const [productDocList, setProductDocList] = useState<any>([])
    // 문서 언어 리스트
    const getDocLang = async() => {
        const res : any = await getDocLangList({docType : state, productId : id})
        if(res.data.Result === 'true'){setLang(res.data.List)}
    }
    const handleDownloadBtn = async(type : string) => {
        setState(type)
    }
    // 언어 선택 시 , Version 데이터 가져오기
    const handleSelectLang = async(e:any) => {
        const {name} = e.target;
        setProductDocList([])
        const param = {userId : 1, productId : id, docType : state, langType : e.target.value}
        const res : any = await getProductDoc(param)
        if(res.data.Result === 'true') {
            if(name === 'manual'){
                if(res?.data?.List?.length > 0){
                    downloadFile(res?.data?.List[0]?.docFileName, res?.data?.List[0]?.docFile)
                }else{
                    alert('There is no attached file.'); return;
                }
            }else{
                setProductDocList(res.data.List)
            }
        }else {
            alert("There is no attached file."); return;
        }
    }

    // Version 선택 시 , 파일 다운로드
    const handleSelectVerion = (e:any) => {
        const fomatValue = e.target.value.split(',');
        downloadFile(fomatValue[0], fomatValue[1])
    }

    // 파일 다운로드 함수
    const downloadFile = (fileName: any, url : any) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    
    useEffect(()=>{
        getDocLang()
    }, [state])
    
    return(
        <>
        <div className="contentBox">
            <h2>
                Download
                {id === '1' ? <span>FineVu GX4K</span> : ''}
                {id === '2' ? <span>FineVu GX1000</span> : ''}
                {id === '3' ? <span>FineVu GX400</span> : ''}
                {id === '30' ? <span>FineVu GX7000</span> : ''}
                {id === '31' ? <span>FineVu GX300</span> : ''}
                {id === '32' ? <span>FineVu GX5000</span> : ''}
                {id === '33' ? <span>FineVu GX33</span> : ''}
                {id === '34' ? <span>FineVu GX30</span> : ''}
                {id === '35' ? <span>FineVu GX35</span> : ''}
                {id === '36' ? <span>FineVu GX1000 Cloud</span> : ''}
                {id === '37' ? <span>FineVu GX400 1CH</span> : ''}
            </h2>
            <p>Firmware · Manual · Speed Cam Data</p>

            <ul>
                <li className={state === 'F' ? 'active' : ''}>
                    <span>Firmware</span>
                    <button onClick={()=>handleDownloadBtn('F')}>Download</button>
                    <div>
                        <span>
                            <span>Firmware</span>
                        </span>
                        <div className="selectBox">
                            <select onChange={(e)=>handleSelectLang(e)}>
                                <option value="0" defaultChecked>Firmware</option>
                                {langList?.map((lang : any)=> (
                                    <option value={lang?.langIdx}>{lang?.langNameKr}</option>
                                ))}
                            </select>
                        </div>
                        <div className="selectBox active">
                            <select onChange={handleSelectVerion}>
                                <option value="0" defaultChecked>Version</option>
                                {productDocList?.map((ver:any) => (
                                    <option value={[`${ver?.docFileName}`,`${ver?.docFile}`]} defaultChecked>{ver?.docVersion}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </li>
                <li className={state === 'M' ? 'active' : ''}>
                    <span>Manual</span>
                    <button onClick={()=>setState('M')}>Download</button>
                    <div>
                        <span>
                            <span>Manual</span>
                        </span>
                        <div className="selectBox">
                            <select name="manual" onChange={(e)=>handleSelectLang(e)}>
                                <option value="0" defaultChecked>Language</option>
                                {langList?.map((lang : any)=> (
                                    <option value={lang?.langIdx}>{lang?.langNameKr}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </li>
                <li className={state === 'S' ? 'active' : ''}>
                    <span>Speed Cam Data</span>
                    <button onClick={()=>setState('S')}>Download</button>
                    <div>
                        <span>
                            <span>Speed Cam Data</span>
                        </span>
                        <div className="selectBox">
                            <select onChange={(e)=>handleSelectLang(e)}>
                                <option value="0" defaultChecked>Country</option>
                                {langList?.map((lang : any)=> (
                                    <option value={lang?.langIdx}>{lang?.langNameKr}</option>
                                ))}
                            </select>
                        </div>
                        <div className="selectBox active">
                            <select onChange={handleSelectVerion}>
                                <option value="0" defaultChecked>Version</option>
                                {productDocList?.map((ver:any) => (
                                    <option value={[`${ver?.docFileName}`,`${ver?.docFile}`]} defaultChecked>{ver?.docVersion}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </li>
            </ul>

            <div className="btnBox">
                <button className="blackBtn" onClick={()=>navigate('/support/download/dashcam')}>Back</button>
            </div>
        </div>
        </>
    )
}