import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FirmWare } from "./item/firmware";
import { Manual } from "./item/manuel";
import { SpeedCam } from "./item/speedCam";
import { useGetDocLangMutation, useGetProductDocMutation } from "../../../../../store/user/userProductApiSlice";
import { useGetUserProductDocsMutation } from "../../../../../store/user/userApiSlice";
export const ManualBox = () => {
    const {productId} = useParams() // 제품 아이디
    const {id} = useParams() // 유저 아이디
    const [docType , setDocType] = useState<any>({tab : 1})
    const [getProductDocApi] = useGetProductDocMutation()
    const [getUserProductDocsApi] = useGetUserProductDocsMutation()
    const [getDocLangApi] = useGetDocLangMutation()
    const [data, setData] = useState<any>([])
    const [lang, setLang] = useState<any>([])
    const [param, setParam] = useState<any>({userId : id, productId : productId, docType : 'F', langType : 1})
    // 리스트 가져오기
    const getList = async() => {
        if(productId){ // 제품 상세정보에서의 제품 문서
            const result : any = await getProductDocApi(param)
            if(result.data.Result === 'true'){setData(result.data.List)}
        }
        if(id){ // 유저 MyDashCam 에서의 제품 문서
            const result : any = await getUserProductDocsApi(param)
            if(result.data.Result === 'true'){setData(result.data.List)}
        }
    }
    // 언어 가져오기
    const getLang = async() => {
        const result : any = await getDocLangApi({docType : param?.docType, productId : productId})
        if(result.data.Result === 'true'){setLang(result.data.List)}
    }
    // 탭 변경
    const handleChangeTab = (tab:number, type:string) => {
        setDocType({tab : tab})
        setParam((prev:any)=>({...prev, docType : type}))
    }
    // 언어 변경
    const handleChange = (e:any) => {
        setParam((prev:any)=>({...prev, langType : e.target.value}))
    }
    useEffect(()=>{
        getLang()
    }, [param.docType])
    useEffect(()=>{getList()}, [docType, param.langType])
    return(
        <>
        <div className="manualsAndFirmware active">
            <section>
                <div className="slide_tabBtnWrap">
                    <div className={`slide_tabBtnBox number${productId?3:4} active_${docType.tab}`}>
                        <button onClick={()=>handleChangeTab(1, 'F')}>
                            <span>Firmware</span>
                        </button>
                        <button onClick={()=>handleChangeTab(2, 'M')}>
                            <span>User Manual</span>
                        </button>
                        <button onClick={()=>handleChangeTab(3, 'S')}>
                            <span>Speed Cam Data</span>
                        </button>
                        {productId ? '' : 
                        <button onClick={()=>handleChangeTab(4, '')}>
                            <span>User Warrenty</span>
                        </button>}
                    </div>

                    <div className="selectBox">
                        <select onChange={handleChange}>
                            <option value={''} selected disabled>-</option>
                            {lang?.map((lang:any)=>(
                                <option value={lang?.langIdx}>{lang?.langNameEn}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="tabContentBox">
                    {docType.tab === 1 ? <FirmWare data={data} lang={lang}/> : ''}
                    {docType.tab === 2 ? <Manual data={data}/> : ''}
                    {docType.tab === 3 ? <SpeedCam data={data}/> : ''}
                </div>
            </section>
        </div>
        </>
    )
}