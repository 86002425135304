import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DashboardHeader } from "./dashboard-header";
import { DashboardList } from "./list";
import { ListAdminStrators } from "./list/category/list-adminstrator";
import { ListMember } from "./list/category/list-member";
import { RegistAdminStrators } from "./regist/regist-adminstrators";
import { RegistContents } from "./regist/regist-contents";
import { RegistDownLoad } from "./regist/regist-download";
import { RegistProduct } from "./regist/regist-product";
import { RegistStore } from "./regist/regist-store";
import { DashboardSideBar } from "./sideBar";
import { ViewInquiry } from "./view/view-inquiry";

export const Dashboard = () => {
    const {type, adminId, contentsId, storeId, inquiryId, typeId, productId} = useParams()
    const [mainSpecData, setMainSpecData] = useState<any>([])
    const location = useLocation()
    return(
        <>
        <div id="admin_wrap">
            <DashboardSideBar mainSpecData={mainSpecData}/>
            <div className="dotsContentWrap">
            <DashboardHeader/>
            {location.pathname === `/admin/members/${type}` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/products/product` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/products/product/${productId}` ? <RegistProduct/> : ''}
            {location.pathname === `/admin/products/download` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/products/download/${productId}` ? <RegistDownLoad/> : ''}
            {/* {location.pathname === `/admin/products/download/regist` ? <RegistDownLoad/> : ''} */}
            {location.pathname === `/admin/products/language` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/specification/${type}` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/specification/${type}/${typeId}` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/contents/${type}` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/contents/${type}/regist` ? <RegistContents/> : ''}
            {location.pathname === `/admin/contents/${type}/${contentsId}` ? <RegistContents/> : ''}
            {location.pathname === `/admin/stores/${type}` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/stores/${type}/regist` ? <RegistStore/> : ''}
            {location.pathname === `/admin/stores/${type}/${storeId}` ? <RegistStore/> : ''}
            {location.pathname === `/admin/inquirys/${type}` ? <DashboardList setMainSpecData={setMainSpecData}/> : ''}
            {location.pathname === `/admin/inquirys/${type}/${inquiryId}` ? <ViewInquiry/> : ''}

            {location.pathname === `/admin/setting/${type}` ? <ListAdminStrators/> : ''}
            {location.pathname === `/admin/setting/${type}/regist` ? <RegistAdminStrators/> : ''}
            {location.pathname === `/admin/setting/${type}/${adminId}` ? <RegistAdminStrators/> : ''}
            </div>
        </div>
        </>
    )
}