import React, { useEffect, useState } from "react";
import { useGetMainSpecDetailMutation, useGetMainSpecSubListMutation, useSetMainSpecMutation, useUpdMainSpecMutation } from "../../../../store/admin/adminProductSpecApiSlice";
export interface Props {mainSpecId : string, setPageInfo : Function, getList:Function}
export const RegistMainSpec:React.FC<Props> = ({mainSpecId, setPageInfo, getList}) => {
    const [getMainSpecDetailApi] = useGetMainSpecDetailMutation()
    const [getMainSpecSubListApi] = useGetMainSpecSubListMutation()
    const [updMainSpecApi] = useUpdMainSpecMutation()
    const [setMainSpecApi] = useSetMainSpecMutation()
    const [param, setParam]= useState<any>({page : 1, size : 99, keyword : '', sortOrder : '', sortColum : ''})
    const [data, setData] = useState<any>({codeNameKr : '', codeNameEn : '', codeSpecType : [], codeRemarkStatus : 'N', codeImageStatus : 'N'})
    const [subList, setSubList] = useState<any>([])
    const handleChange = (e:any) => {
        const {type, name, value, checked} = e.target;
        if(type === 'checkbox'){
            if(name==='codeSpecType'){
                setData((prev:any)=>({...prev, codeSpecType : checked ? [...prev.codeSpecType, value] : prev.codeSpecType.filter((y:any)=>y!==value)}))
            } else {
                setData((prev:any)=>({...prev, [name] : data?.[name] === 'Y' ? 'N' : 'Y'}))
            }
        }else{
            setData((prev:any) =>({...prev, [name] : value}))
        }
    }
    // 해당 메인스펙 상세보기
    const getDetail = async() => {
        const result : any = await getMainSpecDetailApi({id : mainSpecId})
        if(result.data.Result==='true'){
            setData((prev: any) => ({...prev, codeNameKr : result.data.List[0].specName, 
            codeSpecType : result?.data?.List[0]?.specSubType === '' ? [] : result?.data?.List[0]?.specSubType?.split(',')}))
        }
    }
    // 서브 유형 리스트 가져오기 
    const getSubList = async () => {
        const result : any = await getMainSpecSubListApi({})
        if(result.data.Result === 'true'){setSubList(result.data.List)}
    }
    const handleSave = async() => {
        const formData:any = new FormData()
        if(mainSpecId !== ''){formData.append('specIdx', mainSpecId)}
        formData.append('specName', data?.codeNameKr)
        formData.append('specSubType', data?.codeSpecType?.toString())

        if(mainSpecId === ''){
            const result : any = await setMainSpecApi(formData)
            if(result.data.result ===true){
                alert("등록이 완료되었습니다."); getList(); setPageInfo((prev:any)=>({...prev, isActive : false, mainSpecId : ''}))
            }
        }else{
            const result : any = await updMainSpecApi(formData)
            if(result.data.result === true){
                alert("수정이 완료되었습니다."); getList(); setPageInfo((prev:any)=>({...prev, isActive : false, mainSpecId : ''}))
            }
        }
    }
    const handleClose = () => {setPageInfo((prev:any)=>({...prev, isActive : false, mainSpecId : ''}))}
    useEffect(()=>{if(mainSpecId!==''){getDetail()}}, [mainSpecId])
    useEffect(()=>{getSubList()},[])
    return(
        <>
        <div className="registration_popUp popUp_active active">
            <button className="closeBtn" onClick={()=>handleClose()}>
                <i className="fa-light fa-xmark"></i>
            </button>

            <div className="titleBox">
                <span>Specification</span>
                <span>Main Specification</span>
            </div>
            <div className="userInfoBox">
                <ul>
                    <li>
                        <span>Main Spec</span>
                        <textarea name="codeNameKr" id="" value={data?.codeNameKr} placeholder="사양 입력란" onChange={handleChange}></textarea>
                    </li>
                    <li>
                        <span>Spec Option</span>
                        <div className="checkWrap">
                            {subList.map((x:any)=>(
                            <div className="checkBox" key={x.specSubIdx}>
                                <input type="checkbox" name={'codeSpecType'} id={x.specSubIdx} value={x.specSubIdx} onChange={handleChange} checked={data?.codeSpecType.includes((x.specSubIdx).toString())}/>
                                <label htmlFor={x.specSubIdx}>{x.specSubName}</label>
                            </div>
                            ))}
                            
                        </div>
                    </li>
                </ul>
            </div>

            <div className="btnBox">
                <button onClick={()=>handleClose()}>취소</button>
                <button onClick={handleSave}>등록</button>
            </div>
        </div>
        </>
    )
}