import { combineReducers, configureStore  } from '@reduxjs/toolkit'
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import { adminProductSpecApiSlice } from './admin/adminProductSpecApiSlice';
import { adminContentsApiSlice } from './admin/adminContentsApiSlice';
import { adminCountryApiSlice } from './admin/adminCountryApiSlice';
import { adminInquiryApiSlice } from './admin/adminInquiryApiSlice';
import { adminStoreApiSlice } from './admin/adminStoreApiSlice';
import { adminStratorsApiSlice } from './admin/adminStratorsApiSlice';
import { commonApiSlice } from './user/commonApiSlice';
import { contentsApiSlice } from './user/contentsApiSlice';
import { userApiSlice } from './user/userApiSlice';
import { adminUserApiSlice } from './admin/adminUserApiSlice';
import { adminProductApiSlice } from './admin/adminProductApiSlice';
import { menuApiSlice } from './user/menuApiSlice';
import { userProductApiSlice } from './user/userProductApiSlice';
import menuInfoReducer from './stroeApi/menuInfoSlice';
import loginUserReducer from './user/loginUserSlice';
import { userInquiryApiSlice } from './user/inquiryApiSlice';
import loginAdminReducer from './admin/adminInfoSlice';

const reducer = combineReducers({})

export const store = configureStore({
  reducer:  {
    menuInfo : menuInfoReducer,
    loginUser : loginUserReducer,
    loginAdmin : loginAdminReducer,
    [contentsApiSlice.reducerPath] : contentsApiSlice.reducer,
    [adminStratorsApiSlice.reducerPath] : adminStratorsApiSlice.reducer,
    [adminProductSpecApiSlice.reducerPath] : adminProductSpecApiSlice.reducer,
    [adminContentsApiSlice.reducerPath] : adminContentsApiSlice.reducer,
    [adminCountryApiSlice.reducerPath] : adminCountryApiSlice.reducer,
    [adminStoreApiSlice.reducerPath] : adminStoreApiSlice.reducer,
    [adminInquiryApiSlice.reducerPath] : adminInquiryApiSlice.reducer,
    [adminUserApiSlice.reducerPath] : adminUserApiSlice.reducer,
    [commonApiSlice.reducerPath] : commonApiSlice.reducer,
    [userApiSlice.reducerPath] : userApiSlice.reducer,
    [adminProductApiSlice.reducerPath] : adminProductApiSlice.reducer,
    [menuApiSlice.reducerPath] : menuApiSlice.reducer,
    [userProductApiSlice.reducerPath] : userProductApiSlice.reducer,
    [userInquiryApiSlice.reducerPath] : userInquiryApiSlice.reducer
  },
  
  middleware: getMiddleware => getMiddleware().concat(contentsApiSlice.middleware, adminProductSpecApiSlice.middleware, adminStratorsApiSlice.middleware, adminContentsApiSlice.middleware,
    adminCountryApiSlice.middleware, adminStoreApiSlice.middleware, adminInquiryApiSlice.middleware, commonApiSlice.middleware, userApiSlice.middleware,
    adminUserApiSlice.middleware, adminProductApiSlice.middleware, menuApiSlice.middleware, userProductApiSlice.middleware, userInquiryApiSlice.middleware),
  devTools: process.env.NODE_ENV === 'development'
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
